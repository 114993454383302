<script>
  import Icon from "@iconify/svelte";
  import { toast } from "svelte-sonner";
  import { push } from "svelte-spa-router";
  import { fetchPatch } from "../../helpers";
  import { convertBoolToBuilder } from "../../ruleBuilderConverter";
  import Modal from "../Modals/Modal.svelte";
  import QueryPrettyPrint from "./QueryPrettyPrint.svelte";
  import { isParentConditionalRule } from "../../lib/utils/RuleUtils";

  export let rule;
  export let hit;
  export let report_id;

  let example_field = select_highlight(hit); //hit.highlight.length !== 0 ? hit.highlight : hit.text
  //hit.highlight.length > 0 ? hit.highlight.replace(/<\/?em>/g, "") : hit.text;

  function select_highlight(hit) {
    // Check if there is text within <em> tags in hit.highlight
    const emTextMatch = hit.highlight && hit.highlight.match(/<em>(.*?)<\/em>/);
    if (emTextMatch && emTextMatch[1]) {
      return emTextMatch[1]; // Use text within the first <em> tags
    } else if (hit.highlight && hit.highlight.length > 0) {
      return hit.highlight; // Use entire highlight if no <em> text
    } else {
      return hit.text; // Fallback to hit.text if highlight is empty
    }
  }

  const obtainEditable = (rule) => {
    let query = JSON.parse(rule.query);
    rule.examples = "(" + rule.examples + ")";
    return {
      name: rule.name,
      description: rule.description,
      remediation_step: rule.remediation_step,
      examples:
        rule.rule_type === "TENSOR"
          ? Object.entries(query.examples).map((e) => ({
              text: e[0],
              score: e[1],
            }))
          : [{ text: "", score: 1 }],
      builder:
        rule.rule_type === "BOOLEAN"
          ? convertBoolToBuilder(JSON.parse(rule?.query)?.doc_query)
          : undefined,
      lexical: rule.examples.substring(1, rule.examples.length - 1),
      operator: query.operator,
      doc_query: rule.doc_query,
      section_query: "",
      affirmative_rule: rule.affirmative_rule,
      limit: query.limit,
      search_sources: {
        DOCUMENT: true,
        IMAGE_TEXT: false,
        IMAGE_FEATURE: false,
      },
      index: query.index,
      strong_match_threshold: rule.strong_match_threshold,
      no_match_threshold: rule.no_match_threshold,
      search_type: rule.rule_type,
      type: rule.type,
    };
  };

  const edit = async (rule) => {
    const res = await fetchPatch(`/asset/rule/${rule.id}`, {
      rule: {
        examples:
          rule.search_type === "CONTEXTUAL"
            ? rule.examples
            : rule.search_type === "LEXICAL"
              ? rule.lexical.includes('" OR "')
                ? rule.lexical
                : rule.lexical //If Lexical just search the string without all the queried paramterisation
              : rule.search_type === "BOOLEAN"
                ? "*"
                : rule.examples
                    .filter((ex) => ex.text.trim() !== "")
                    .reduce((examples, ex) => {
                      examples[ex.text] = ex.score;
                      return examples;
                    }, {}),
        operator: rule.operator,
        boolean: rule.search_type === "BOOLEAN" ? rule.queryBoolean : "",
        doc_query:
          rule.search_type === "BOOLEAN" ? rule.queryBoolean : rule.doc_query,
        section_query:
          rule.search_type === "BOOLEAN"
            ? rule.section_query.length === 0
              ? rule.lexical
              : rule.section_query + " AND " + rule.lexical
            : rule.section_query,
        index: rule?.index ?? rule?.data_type ?? "vector-db-index", // Default to vector-db-index if no index nor data type
        name: rule.name,
        description: rule.description,
        remediation_step: rule.remediation_step,
        strong_match_threshold: rule.strong_match_threshold,
        no_match_threshold: rule.no_match_threshold,
        affirmative_rule: rule.affirmative_rule,
        search_type: rule.search_type,
        search_sources: Object.entries(rule.search_sources)
          .filter(([key, value]) => value)
          .map(([key, _]) => key),
        limit: rule.limit,
        type: rule.type,
      },
      stream_id: report_id,
    });

    return res;
  };
</script>

<Modal
  modalId="rule_add_example_{rule.id}_{hit.hit_id}"
  cornerCloseButton={false}
>
  <div class="flex items-center mb-2">
    <button class="btn btn-circle btn-primary mx-auto">
      <Icon icon="iconoir:folder-plus" class="text-2xl" />
    </button>

    <h2 class="text-center font-semibold -ml-[48px] flex-grow">Provide Folder Level Feedback</h2>
  </div>

  <span class="text-sm font-medium"> Hit Content </span>
  <div class="bg-base-200 rounded-lg px-2 py-1 text-sm">
    {@html hit.highlight.length !== 0 ? hit.highlight : hit.text}
  </div>

  <span class="text-sm font-medium">
    What example do you want to {rule.affirmative_rule ? "include" : "exclude"}?
  </span>
  <textarea
    class="textarea textarea-bordered max-h-full w-full"
    style="height: auto; min-height:100px"
    placeholder="Example"
    bind:value={example_field}
    on:keypress={(e) => {
      if (e.shiftKey && e.key === "Enter") {
        e.preventDefault();
      }
    }}
  ></textarea>

  <div class="collapse-arrow bg-base-200 collapse rounded border">
    <input type="checkbox" class="peer min-h-[unset]" />
    <div class="collapse-title min-h-[unset] py-2 after:!top-5">
      Rule Information
    </div>

    <div
      class="collapse-content bg-base-100 !mt-0 rounded peer-checked:m-2 peer-checked:p-4"
    >
      <h2 class="max-w-xs truncate text-xl font-semibold">
        {rule.name}
      </h2>

      <p class="text-base-content/80 mt-4 text-sm">
        ID: #{rule.id}
      </p>

      {#if rule.description}
        <div class="mt-4 space-y-2">
          <p class="text-sm font-bold">Description</p>
          <p class="text-base-content/80 text-sm">{rule.description}</p>
        </div>
      {/if}

      {#if rule.remediation_step}
        <div class="mt-4 space-y-2">
          <p class="text-sm font-bold">Remediation Step</p>
          <p class="text-base-content/80 text-sm">
            {rule.remediation_step}
          </p>
        </div>
      {/if}

      <div class="mt-4 space-y-2">
        <p class="text-sm font-bold">Query</p>
        <QueryPrettyPrint
          {rule}
          isParentConditional={isParentConditionalRule(rule)}
        />
      </div>
    </div>
  </div>

  <form
    method="dialog"
    class="bg-base-200 mt-0 flex flex-col gap-2 pb-2 pt-2"
  >
    <div
      class="tooltip"
      data-tip="This will take you back to the Asset Folder."
    >
      <button
        class="btn btn-primary w-full"
        disabled={!example_field}
        on:click={async () => {
          let temp = obtainEditable(rule);
          temp.examples.push({
            text: example_field,
            score: rule.affirmative_rule ? 1 : -1,
          });
          temp.id = rule.id;

          let resp = await edit(temp);
          if (resp.success) toast.success("Rule example added!");
          else toast.error(resp.message);

          push(`/review/${report_id}`);
        }}
      >
        Update Rule <Icon icon="iconoir:arrow-right" />
      </button>
    </div>
    <button class="btn btn-ghost text-primary">Cancel</button>
  </form>
</Modal>
