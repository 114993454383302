<script>
  import { getPreviewUrl } from "../lib/utils/AssetReviewUtils";
  import { org_name } from "../stores";
  import Modal from "./Modals/Modal.svelte";
  import ComparePdf from "./PDF/ComparePDF.svelte";
  import Select from "./Select.svelte";
  import { fetchPost } from "../helpers.js";
  import Icon from "@iconify/svelte";
  import { disabledOrganisationModules } from "./../stores.js";
  import SvelteMarkdown from "svelte-markdown";
  import { onMount } from "svelte";
  import { isCompareSupportedFile } from "../lib/utils/AssetReviewUtils";

  export let versions = [];
  export let activeAssetId;
  export let reportId;
  export let showCompareVersions = false;

  let diff = [];
  let showResults = false;
  let isLoading = false;
  let results = "";
  let documentA = "";
  let documentB = "";

  function toggleResults() {
    showResults = !showResults;
  }

  async function summarizeEdit() {
    isLoading = true;
    try {
      const response = await fetchPost(`/asset-rule/summarize_diff`, {
        diff: diff,
      });
      if (response.success) {
        results = response.ai_response.trim();
        console.log(results);
        toggleResults();
      } else {
        console.error("Failed to get a summary:", response.reason);
      }
    } catch (error) {
      console.error("Error creating a summary:", error);
    } finally {
      showResults = true;
      isLoading = false;
    }
  }

  onMount(() => {
    const supportedVersions = versions.filter((v) =>
      isCompareSupportedFile(v.fileType),
    );

    documentA =
      supportedVersions[1].aws_version_id ||
      supportedVersions[0].aws_version_id;
    documentB = supportedVersions[0].aws_version_id;
  });
</script>

<Modal
  modalId="comparePDFModal"
  size="xl"
  on:close={() => (showCompareVersions = false)}
>
  <Select
    items={versions.map((v) => ({
      label: `v${v.version}`,
      value: v.aws_version_id,
      disabled: !isCompareSupportedFile(v.fileType),
      desc: !isCompareSupportedFile(v.fileType)
        ? "Only .pdf, .jpeg, and .png files can be compared."
        : null,
    }))}
    bind:selectedValue={documentA}
    useCustomTooltip={true}
  />
  <Select
    items={versions.map((v) => ({
      label: `v${v.version}`,
      value: v.aws_version_id,
      disabled: !isCompareSupportedFile(v.fileType),
      desc: !isCompareSupportedFile(v.fileType)
        ? "Only .pdf, .jpeg, and .png files can be compared."
        : null,
    }))}
    bind:selectedValue={documentB}
    useCustomTooltip={true}
  />
  {#if $disabledOrganisationModules.every((dm) => dm !== "compare_pdf_summary")}
    <button
      class="btn btn-primary btn-sm"
      on:click={summarizeEdit}
      disabled={isLoading}
    >
      <!-- on:click={suggestFix} -->
      <!-- disabled={isLoading} -->
      <!-- {#if isLoading}
      <span class="loading loading-spinner"></span>
      Loading...
    {:else} -->
      Summarize Diff
      <!-- {/if} -->
    </button>
    <div class="mt-4" id="compare-pdf">
      {#if showResults}
        <div class="mb-4 flex justify-between">
          <button class="bt btn-sm" on:click={toggleResults}>
            Hide Results
          </button>
          <button
            id="nextBtn"
            class="btn-primary btn btn-sm flex flex-col rounded-l-none"
          >
            <p>See next batch</p>
            <Icon icon="iconoir:nav-arrow-right" />
          </button>
        </div>
        <SvelteMarkdown class="pt-2" source={results} />
      {/if}
    </div>
  {/if}

  {#if documentA && documentB}
    {#key [documentA, documentB]}
      <div class="flex gap-2">
        <ComparePdf
          documentA={getPreviewUrl(
            $org_name,
            reportId,
            activeAssetId,
            documentA,
          )}
          documentB={getPreviewUrl(
            $org_name,
            reportId,
            activeAssetId,
            documentB,
          )}
          bind:diff
        />
        <ComparePdf
          documentA={getPreviewUrl(
            $org_name,
            reportId,
            activeAssetId,
            documentB,
          )}
          documentB={getPreviewUrl(
            $org_name,
            reportId,
            activeAssetId,
            documentA,
          )}
          diff={[]}
        />
      </div>
    {/key}
  {/if}
</Modal>

<!-- svelte-ignore css-unused-selector -->
<style>
  h3 {
    font-size: 1rem !important;
    font-weight: 600 !important;
    text-decoration: underline !important;
  }
  h4 {
    font-weight: 600 !important;
  }
  li {
    list-style: inside !important;
  }

  #compare-pdf ul {
    padding: 0 0 0 2rem !important;
  }

  #suggest-fix ul {
    padding: 0 0 0 2rem !important;
  }
</style>
