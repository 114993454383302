import { writable } from "svelte/store";

// To keep the label filtering robust. We are using a store to keep track of
// the label that is being filtered from the LabelSettings.svelte component.
export const labelToFilter = writable(-1);

// Pinned labels are stored so that we can reactively update the labels shown
// on the sidebar.
export const pinnedLabels = writable([]);

// Navigate to the streams page and show the streams with the selected label.
export function showStreamsWithSelectedLabel(labelId, streamType = "live") {
  labelToFilter.set(labelId);

  if (streamType == "live") {
    if (window.location.hash != "#/streams") window.location.hash = "#/streams";
  } else if (streamType === "asset") {
    if (window.location.hash != "#/reviews") window.location.hash = "#/reviews";
  }
}

// Update the list of pinned labels.
export function updatePinnedLabels(labels) {
  // Filter the labels to only include the pinned labels.
  pinnedLabels.set(
    labels.filter(
      (label) => label.pinned || label.pinned_live || label.pinned_asset,
    ),
  );
}
