<script>
  import SavedRulesTableRowConditionalRules from "./../../TableViews/SavedRulesTable/SavedRulesTableRowConditionalRules.svelte";
  import Icon from "@iconify/svelte";
  import { createEventDispatcher, onMount, tick } from "svelte";
  import { toast } from "svelte-sonner";
  import { slide } from "svelte/transition";
  import { fetchGet, fetchPatch } from "../../../helpers";
  import {
    getRuleColourDropDownOptions,
    isParentConditionalRule,
    ruleColourToLabel,
    ruleColourToStyle,
  } from "../../../lib/utils/RuleUtils";
  import { actions } from "../../../stores";
  import Comments from "../../comments/Comments.svelte";
  import LogoLoader from "../../LogoLoader.svelte";
  import AssetReviewHit from "../AssetReviewHit.svelte";
  import AssetRuleInfoDialog from "../AssetRuleInfoDialog.svelte";
  import AssignUsers from "../AssignUsers.svelte";
  import AssetManualSubTask from "../ManualTasks/AssetManualSubTask.svelte";
  import { HitColour } from "./../../../lib/interfaces/Hit.interface.js";
  import {
    ConditionalRuleType,
    RuleSearchType,
    RuleStatus,
  } from "./../../../lib/interfaces/Rule.interface.js";
  import { isEmpty } from "../../../lib/utils/GenericUtils";
  import QueryPrettyPrint from "../QueryPrettyPrint.svelte";
  import ContextualRuleRow from "../ContextualRules/ContextualRuleRow.svelte";

  const dispatch = createEventDispatcher();

  export let activeAssetId = 1;
  export let activeAsset;
  export let activeVersion;
  export let previewSearchText;
  export let reportId;
  export let currentStreamPerm;
  export let currentAssetApprovingPermission = false;
  export let actionTexts = $actions.map(function (elem) {
    return elem.action_text;
  });
  export let rules = [];
  export let versionUpdated = false;
  export let parentRuleId = -1;
  export let parentRuleColour = HitColour.RISK;
  export let parentChanged = false;
  // export let rulePerformanceIndexMap = { groupIdx: -1, ruleIdx: -1 };

  let activeChildRule = -1;
  let activeChildRuleId = -1;
  let childRuleComment = "";
  let activeChildRuleResults = [];
  let activeChildRuleResultsLoading = false;
  let childRuleId;
  let childHitId;
  let workflowStep = activeAsset?.asset?.workflow_step.name;

  async function updateColour(ruleId, assetId, colour, isConditional = false) {
    const colourResponse = await fetchPatch(
      "/asset-rule/status/" + assetId + "/" + ruleId,
      {
        colour: colour,
        is_conditional_rule: isConditional,
      },
    );

    if (!colourResponse?.success) {
      console.error(
        `Error: Cannot update colour for Rule(ID: ${ruleId}) for Asset(ID: ${assetId}`,
      );

      return;
    }
  }

  async function updateRuleComments(assetId, ruleId, ruleIndex) {
    const commentResponse = await fetchGet(
      `/asset-rule/comment/${assetId}/${ruleId}`,
    );

    if (!commentResponse.success) {
      console.error(
        `Error: Cannot retrieve comments for Rule(ID: ${ruleId}) for Asset(ID: ${assetId}).`,
      );

      return;
    }

    rules[ruleIndex].comments = [...commentResponse.data];
  }

  async function refreshRule(ruleId, ruleIdx) {
    try {
      await updateRuleComments(activeAssetId, ruleId, ruleIdx);
    } catch (e) {
      console.error(e);
      toast.error(
        `Something went wrong updating the status of Task(${rules[ruleIdx].rule.name})`,
      );
    }

    activeAsset = activeAsset;
  }

  async function updateActiveRuleHits() {
    activeChildRuleResultsLoading = true;

    if (activeChildRule !== -1 && activeChildRuleId !== -1) {
      const activeRuleId = rules[activeChildRule].rule.id;

      try {
        const res = await fetchGet(
          `/hit/asset/${activeAssetId}/rule/${activeRuleId}`,
        );

        if (!res.success) {
          throw new Error(res.message);
        }

        activeChildRuleResults = res.results.filter(
          (r) => r.version === activeVersion.version,
        );
        activeChildRuleResults = [...activeChildRuleResults];
      } catch (e) {
        console.error(e);
        toast.error(
          `Something went wrong loading results for Asset(${activeAssetId}), Rule(${activeRuleId}).`,
        );
      }
    } else {
      activeChildRuleResults = [];
    }
  }

  async function handleVersionUpdated() {
    if (versionUpdated) {
      await updateActiveRuleHits().then((res) => {
        activeChildRuleResultsLoading = false;
      });
    }
  }

  async function refreshManualRule(event, ruleId, ruleIdx) {
    const activeAssetRuleIdx = activeAsset.rules.findIndex(
      (r) => r.rule.id === parentRuleId,
    );
    const childRuleIdx = activeAsset.rules[
      activeAssetRuleIdx
    ].rule.children.findIndex((c) => c.rule.id === ruleId);

    try {
      if (
        activeAsset.rules[activeAssetRuleIdx].rule.children[childRuleIdx]
          .colour !== event.detail
      ) {
        await updateColour(ruleId, activeAssetId, event.detail);
        activeAsset.rules[activeAssetRuleIdx].rule.children[
          childRuleIdx
        ].colour = event.detail;
        activeAsset.asset.ruleColour = event.detail;

        await updateRuleComments(activeAssetId, ruleId, ruleIdx);
      }
    } catch (e) {
      toast.error(
        `Something went wrong updating the status of Task(${activeAsset.rules[activeAssetRuleIdx].rule.children[childRuleIdx].rule.name})`,
      );
    }

    activeAsset = activeAsset;
  }

  async function handleTaskStatusToggle(ruleId, ruleIdx) {
    const activeAssetRuleIdx = activeAsset.rules.findIndex(
      (r) => r.rule.id === parentRuleId,
    );
    const childRuleIdx = activeAsset.rules[
      activeAssetRuleIdx
    ].rule.children.findIndex((c) => c.rule.id === ruleId);

    try {
      if (
        activeAsset.rules[activeAssetRuleIdx].rule.children[childRuleIdx]
          .colour === HitColour.PASSED
      ) {
        await updateColour(ruleId, activeAssetId, HitColour.REVIEW);
        activeAsset.rules[activeAssetRuleIdx].rule.children[
          childRuleIdx
        ].colour = HitColour.REVIEW;
        activeAsset.asset.ruleColour = HitColour.REVIEW;
      } else {
        await updateColour(ruleId, activeAssetId, HitColour.PASSED);
        activeAsset.rules[activeAssetRuleIdx].rule.children[
          childRuleIdx
        ].colour = HitColour.REVIEW;
        activeAsset.asset.ruleColour = HitColour.PASSED;
      }

      await updateRuleComments(activeAssetId, ruleId, ruleIdx);
    } catch (e) {
      toast.error(
        `Something went wrong updating the status of Task(${activeAsset.rules[activeAssetRuleIdx].rule.name})`,
      );
    }

    activeAsset = activeAsset;
  }

  onMount(async () => {
    const hashParams = new URLSearchParams(
      new URL(window.location.href).hash.split("?")[1],
    );
    childRuleId = Number(hashParams.get("childRuleId"));
    childHitId = Number(hashParams.get("childHitId"));
  });

  $: updateActiveRuleHits().then((res) => {
    activeChildRuleResultsLoading = false;
  }),
    [activeChildRule];
  $: handleVersionUpdated(), [versionUpdated];
</script>

<!--svelte-ignore a11y-no-noninteractive-tabindex-->
<!--svelte-ignore a11y-label-has-associated-control-->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<div
  class="border-l-child-rule-border {parentChanged
    ? 'ml-9'
    : 'ml-3'} flex flex-col gap-2 border-l-2 pl-1"
>
  {#if parentRuleColour === HitColour.PASSED}
    {#each rules as rule, idx}
      <div class="rounded border border-gray-200">
        {#if rule.rule.rule_type !== RuleSearchType.MANUAL}
          <div class="flex w-full flex-col gap-3 py-2">
            <button
              class="flex cursor-pointer items-center justify-between gap-4 text-start"
              on:click={() => {
                activeChildRule = activeChildRule === idx ? -1 : idx;
                activeChildRuleId =
                  activeChildRuleId === rule.rule.id ? -1 : rule.rule.id;
                childRuleComment = "";
              }}
            >
              <div
                class="flex w-40 flex-row items-center justify-between gap-2 py-2 {rule?.changed
                  ? 'pl-2'
                  : 'pl-6'}"
              >
                {#if rule?.changed}
                  <div
                    class="tooltip tooltip-right flex items-center justify-center"
                    data-tip={!isEmpty(rule.change_comment)
                      ? rule.change_comment
                      : "This rule has been modified by an unknown user."}
                  >
                    <Icon
                      icon="material-symbols:circle"
                      class="text-primary h-3 w-3"
                    />
                  </div>
                {/if}
                <span class="flex flex-wrap text-start text-sm">
                  {rule.rule.name}
                </span>
                <button
                  class="btn btn-circle btn-ghost btn-xs"
                  on:click|stopPropagation={() =>
                    document
                      .getElementById("rule_info_modal_" + rule.rule.id)
                      .showModal()}
                >
                  <Icon icon="iconoir:info-circle" class="text-lg" />
                  <!--Note: using material symbols here not iconify, as the iconify info-outline is not rendering properly-->
                </button>
                <!-- Removed out Rule Performance as this requires a refactor for conditional rule children -->
              </div>

              <div class="flex w-80 shrink-0 items-center gap-2">
                {#key rule.colour}
                  <div
                    class={!currentAssetApprovingPermission ||
                    currentStreamPerm !== "edit"
                      ? "tooltip tooltip-bottom"
                      : ""}
                    data-tip={currentStreamPerm === "edit" &&
                    workflowStep === "Uploaded"
                      ? "You don't have permission to change this risk rating. Please submit the asset for approval."
                      : "You don't have permission to change this risk rating."}
                  >
                    <div class="dropdown dropdown-end">
                      <label
                        tabindex="0"
                        class="btn btn-ghost btn-xs w-[116px] flex-nowrap justify-between whitespace-nowrap
                          {ruleColourToStyle(
                          rule.colour,
                          isParentConditionalRule(rule.rule),
                        )}"
                        on:click|stopPropagation
                      >
                        <span class="flex flex-row items-center gap-2">
                          <Icon
                            icon="material-symbols:circle"
                            class="h-2 w-2"
                          />
                          {ruleColourToLabel(
                            rule.colour,
                            isParentConditionalRule(rule.rule),
                          )}
                        </span>
                        {#if currentStreamPerm == "edit"}
                          <Icon
                            icon="iconoir:nav-arrow-down"
                            class="text-base"
                          />
                        {/if}
                      </label>

                      <!--svelte-ignore a11y-no-noninteractive-tabindex-->
                      {#if currentStreamPerm == "edit" && currentAssetApprovingPermission}
                        <ul
                          tabindex="0"
                          class="menu dropdown-content menu-xs bg-base-100 z-[1] mt-1 flex w-full min-w-max flex-col gap-1 rounded border p-1"
                        >
                          {#each getRuleColourDropDownOptions(rule.rule) as item}
                            <li>
                              <button
                                style:color={item.hex}
                                on:click|stopPropagation={async () => {
                                  rule.changed = true;
                                  rule.colour = item.color;
                                  const ruleResponse = await updateColour(
                                    rule.rule.id,
                                    activeAssetId,
                                    item.color,
                                    rule.rule.is_conditional,
                                  );

                                  if (!rule.rule.is_conditional_rule) {
                                    await updateRuleComments(
                                      activeAssetId,
                                      rule.rule.id,
                                      idx,
                                    );
                                  } else {
                                    rule = ruleResponse.rule;
                                  }

                                  rule = rule;
                                }}
                              >
                                <input
                                  type="radio"
                                  class="radio-primary radio radio-xs"
                                  checked={rule.colour === item.color}
                                />
                                {item.text}
                              </button>
                            </li>
                          {/each}
                        </ul>
                      {/if}
                    </div>
                  </div>
                {/key}

                <button
                  class="btn btn-ghost btn-xs"
                  on:click|stopPropagation={async () => {
                    activeChildRule = idx;
                    activeChildRuleId = rule.rule.id;
                    childRuleComment = "";
                    await tick();

                    const ctxtarea =
                      document.getElementById("childRuleComment");
                    if (ctxtarea) {
                      ctxtarea.scrollIntoView({ behavior: "smooth" });
                      ctxtarea.focus();
                    } else console.log("Comment textarea not found");
                  }}
                >
                  <Icon icon="iconoir:chat-lines" class="text-base" />
                  {rule.comments ? rule.comments.length : 0}
                </button>

                <AssignUsers
                  containerClass="ml-auto"
                  rule_id={rule.rule.id}
                  asset_id={activeAssetId}
                  assignees={rule.assigned_users}
                  disableEditing={currentStreamPerm !== "edit"}
                  isCompact
                  on:change={async (e) => {
                    rule.assigned_users = e.detail;
                    rule = rule;

                    await updateRuleComments(activeAssetId, rule.rule.id, idx);
                  }}
                />

                <button class="btn btn-square btn-ghost btn-sm">
                  <Icon
                    icon="iconoir:nav-arrow-down"
                    class="text-lg transition-transform {activeChildRule === idx
                      ? 'rotate-180'
                      : ''}"
                  />
                </button>
              </div>
            </button>

            {#if activeChildRule === idx}
              <div class="flex flex-col gap-2 px-4">
                {#if rule.rule.is_conditional && rule.rule.conditional_type === ConditionalRuleType.IF}
                  <div
                    class="flex flex-col gap-2 border-l {rule.colour ===
                    HitColour.PASSED
                      ? 'border-success'
                      : 'border-error'}"
                  >
                    <svelte:self
                      bind:activeAssetId
                      bind:rules={rule.rule.children}
                    />
                  </div>
                {/if}

                <div class="flex flex-col gap-2">
                  {#if activeChildRuleResultsLoading}
                    <div class="flex self-center align-middle">
                      <LogoLoader size="2.5rem" />
                    </div>
                  {:else}
                    {#each activeChildRuleResults as hit}
                      <AssetReviewHit
                        bind:hit
                        bind:activeAsset={activeAsset.asset}
                        bind:previewSearchText
                        bind:currentAssetApprovingPermission
                        {reportId}
                        {actionTexts}
                        bind:rule={rule.rule}
                        {currentStreamPerm}
                        bind:asset_rule={rule}
                        expanded={hit.hit_id === childHitId}
                        on:refresh={async () => {
                          await refreshRule(rule.rule.id, idx);
                        }}
                      />
                    {:else}
                      <p>No results found</p>
                    {/each}
                  {/if}
                </div>
              </div>

              <div class="px-4">
                <Comments
                  commentInputId="childRuleComment"
                  comments={rule.comments}
                  apiEndpoint={`/asset-rule/comment/${activeAssetId}/${rule.rule.id}`}
                  type="asset"
                  xs
                  isChildConditional={true}
                  on:updateComments={async () =>
                    await updateRuleComments(activeAssetId, rule.rule.id, idx)}
                />
              </div>
            {/if}
          </div>
        {:else}
          <div class="flex w-full flex-col gap-3 py-2" in:slide>
            <button
              class="flex cursor-pointer items-center justify-between gap-4 text-start"
              on:click|stopPropagation={() => {
                activeChildRule = activeChildRule === idx ? -1 : idx;
                activeChildRuleId =
                  activeChildRuleId === rule.rule.id ? -1 : rule.rule.id;
                childRuleComment = "";
              }}
            >
              <div
                class="flex w-40 flex-row items-center justify-between gap-2 py-2 pl-2"
              >
                <div
                  class={currentStreamPerm !== "edit" ||
                  !rule.results.every((h) => {
                    return h.colour === HitColour.PASSED;
                  })
                    ? "tooltip tooltip-right flex content-center"
                    : "flex content-center"}
                  data-tip={currentStreamPerm !== "edit"
                    ? "You do not have permission to modify this task's status"
                    : "All sub-tasks must be completed prior to completing the top-level task"}
                >
                  <input
                    type="checkbox"
                    class="checkbox checkbox-sm border-gray-300 [--chkbg:#c4d4bd] [--chkfg:#336A1D] checked:border-[#336A1D]"
                    checked={rule.colour === HitColour.PASSED ? true : false}
                    name={rule.rule.name}
                    id={`child-manual-rule-${idx}-${rule.rule.id}`}
                    disabled={currentStreamPerm !== "edit" ||
                      !rule.results
                        .filter((r) => r.version === activeVersion?.version)
                        .every((h) => {
                          return h.colour === HitColour.PASSED;
                        })}
                    on:click|stopPropagation={async () => {
                      await handleTaskStatusToggle(rule.rule.id, idx);
                    }}
                  />
                </div>
                <span class="flex flex-wrap text-start text-sm">
                  {rule.rule.name}
                </span>
                <button
                  class="btn btn-circle btn-ghost btn-xs"
                  on:click|stopPropagation={() =>
                    document
                      .getElementById("rule_info_modal_" + rule.rule.id)
                      .showModal()}
                >
                  <Icon icon="iconoir:info-circle" class="text-lg" />
                </button>
              </div>

              <div class="flex w-80 shrink-0 items-center justify-end gap-2">
                <button class="btn btn-ghost btn-xs">
                  <Icon icon="iconoir:check" class="text-base" />
                  {rule.results.reduce(
                    (total, r) =>
                      total +
                      (r.colour === HitColour.PASSED &&
                        r.version === activeVersion?.version),
                    0,
                  )}/{rule.results.filter(
                    (r) => r.version === activeVersion?.version,
                  ).length}
                </button>
                <button
                  class="btn btn-ghost btn-xs"
                  on:click|stopPropagation={async () => {
                    activeChildRule = idx;
                    activeChildRuleId = rule.rule.id;
                    childRuleComment = "";
                    await tick();

                    const ctxtarea =
                      document.getElementById("childRuleComment");
                    if (ctxtarea) {
                      ctxtarea.scrollIntoView({ behavior: "smooth" });
                      ctxtarea.focus();
                    } else console.log("Comment textarea not found");
                  }}
                >
                  <Icon icon="iconoir:chat-lines" class="text-base" />
                  {rule.comments ? rule.comments.length : 0}
                </button>

                <AssignUsers
                  rule_id={rule.rule.id}
                  asset_id={activeAssetId}
                  assignees={rule.assigned_users}
                  disableEditing={currentStreamPerm !== "edit"}
                  isCompact
                  on:change={async (e) => {
                    rule.assigned_users = e.detail;
                    rule = rule;

                    await updateRuleComments(activeAssetId, rule.rule.id);
                  }}
                />

                <button class="btn btn-square btn-ghost btn-sm">
                  <Icon
                    icon="iconoir:nav-arrow-down"
                    class="text-lg transition-transform {activeChildRule === idx
                      ? 'rotate-180'
                      : ''}"
                  />
                </button>
              </div>
            </button>
            {#if activeChildRule === idx}
              <div class="flex flex-col gap-2">
                {#each rule.results.filter((x) => x.version === activeVersion?.version) as hit}
                  <div class="px-4">
                    <AssetManualSubTask
                      bind:hit
                      bind:activeAsset={activeAsset.asset}
                      {reportId}
                      {actionTexts}
                      {currentStreamPerm}
                      bind:asset_rule={rule}
                      on:refresh={async (e) => {
                        await refreshManualRule(e, rule.rule.id, idx);
                      }}
                    />
                  </div>
                {:else}
                  <p>No results found</p>
                {/each}
                {#if rule.rule.is_conditional && rule.rule.conditional_type === ConditionalRuleType.IF}
                  <div
                    class="flex flex-col gap-2 border-l {rule.colour ===
                    HitColour.PASSED
                      ? 'border-success'
                      : 'border-error'}"
                  >
                    <svelte:self
                      bind:activeAssetId
                      bind:rules={rule.rule.children}
                    />
                  </div>
                {/if}
              </div>
              <Comments
                commentInputId="ruleComment"
                comments={rule.comments}
                apiEndpoint={`/asset-rule/comment/${activeAssetId}/${rule.rule.id}`}
                type="asset"
                xs
                isChildConditional={true}
                on:updateComments={() =>
                  updateRuleComments(activeAssetId, rule.rule.id, idx)}
              />
            {/if}
          </div>
        {/if}

        <AssetRuleInfoDialog rule={rule.rule} {activeAssetId} />
      </div>
    {/each}
  {:else}
    {#each rules as rule, idx}
      <div class="rounded border border-gray-200">
        <div class="flex w-full flex-col gap-3">
          <button
            class="flex flex-row items-center justify-between"
            on:click={() => {
              activeChildRule = activeChildRule === idx ? -1 : idx;
              activeChildRuleId =
                activeChildRuleId === rule.rule.id ? -1 : rule.rule.id;
              childRuleComment = "";
            }}
          >
            <span class="flex flex-wrap py-2 pl-4 text-start text-sm">
              {rule.rule.name}
              <p class="text-sm text-gray-400">&nbsp;(Not run)</p>
            </span>
            <button class="btn btn-square btn-ghost btn-sm">
              <Icon
                icon="iconoir:nav-arrow-down"
                class="text-lg transition-transform {activeChildRule === idx
                  ? 'rotate-180'
                  : ''}"
              />
            </button>
          </button>

          {#if activeChildRule === idx}
            <main
              class={`grid grid-cols-3 gap-4 px-4 py-2 pt-0`}
              transition:slide
            >
              {#if rule.rule.rule_type === RuleSearchType.MANUAL}
                <div>
                  <p class="text-sm font-medium">ID</p>
                  <p class="text-sm font-light">
                    #{rule.rule.id}
                  </p>
                </div>
                <div>
                  <p class="text-sm font-medium">Description</p>
                  <p class="text-sm font-light">
                    {rule.rule.description}
                  </p>
                </div>
                <div>
                  <p class="text-sm font-medium">Remediation Step</p>
                  <p class="text-sm font-light">
                    {isEmpty(rule.rule.remediation_step)
                      ? "No remediation step."
                      : rule.rule.remediation_step}
                  </p>
                </div>
                <div class="col-span-3 flex flex-col gap-1">
                  <div class="flex flex-col gap-1">
                    <p class="mt-1 text-sm font-medium">Query</p>
                    <QueryPrettyPrint
                      rule={rule.rule}
                      isParentConditional={isParentConditionalRule(rule.rule)}
                    />
                  </div>
                  <div class="flex flex-col gap-1">
                    <p class="mt-1 text-sm font-medium">Sub-Tasks</p>
                    <ul class="rounded-box list-disc">
                      {#each rule.rule.examples.split(" AND ") as example}
                        <li class="ml-6">
                          <span class="badge h-auto"
                            >{example.slice(1, -1)}</span
                          >
                        </li>
                      {/each}
                    </ul>
                  </div>
                </div>
              {:else if rule.rule.rule_type === RuleSearchType.CONTEXTUAL}
                <ContextualRuleRow
                  bind:rule={rule.rule}
                  isSavedRulesTable={true}
                  isConditionalChild={true}
                />
              {:else}
                <div>
                  <p class="text-sm font-medium">ID</p>
                  <p class="text-sm font-light">
                    #{rule.rule.id}
                  </p>
                </div>
                <div>
                  <p class="text-sm font-medium">Description</p>
                  <p class="text-sm font-light">
                    {rule.rule.description}
                  </p>
                </div>
                <div>
                  <p class="text-sm font-medium">Remediation Step</p>
                  <p class="text-sm font-light">
                    {isEmpty(rule.rule.remediation_step)
                      ? "No remediation step."
                      : rule.rule.remediation_step}
                  </p>
                </div>

                <div class="col-span-3 flex flex-col">
                  <div class="flex flex-col gap-1">
                    <p class="mt-1 text-sm font-medium">Query</p>
                    <QueryPrettyPrint
                      rule={rule.rule}
                      isParentConditional={isParentConditionalRule(rule.rule)}
                    />
                  </div>
                </div>
              {/if}
            </main>
          {/if}
        </div>
      </div>
    {/each}
  {/if}
</div>
