<script>
  import { javascript } from "@codemirror/lang-javascript";
  import Icon from "@iconify/svelte";
  import { onMount } from "svelte";
  import CodeMirror from "svelte-codemirror-editor";
  import { toast } from "svelte-sonner";
  import { push } from "svelte-spa-router";
  import { slide } from "svelte/transition";
  import {
    boolChecker,
    fetchDelete,
    fetchGet,
    fetchPatch,
    fetchPost,
    saveRuleToMyRules,
    tidyPoolLabel,
  } from "../../helpers";
  import { RuleDataType } from "../../lib/interfaces/Rule.interface";
  import { SharedRuleStatus } from "../../lib/interfaces/SharedRule.interface";
  import { isEmpty } from "../../lib/utils/GenericUtils";
  import {
    convertBoolToBuilder,
    convertBuilderToBoolean,
  } from "../../ruleBuilderConverter";
  import LoadRules from "..//Reports/LoadRules.svelte";
  import ActionConfirmation from "../ActionConfirmation.svelte";
  import Badge from "../AssetReview/WorkflowBoard/Badge.svelte";
  import Chat from "../LLMChat/Chat.svelte";
  import LogoLoader from "../LogoLoader.svelte";
  import Rule from "../Rule.svelte";

  export let step = 1;
  export let stepsCompletedTill = 0;
  export let report_id;
  export let rules = [];
  export let rulesToReRun = [];
  export let streamType;
  export let streamName;
  export let streamDesc;

  let test_rules = [];
  let loaded_rule_counter = 0;
  let editingRule = false;
  let saved_rules = [];
  let allRuleSets = [];
  let sharedWith = [];

  let activeTab = 0;
  let activeRulePen = -1;

  let runningRules = false;

  async function runRule(rule) {
    try {
      if (!rule.created_date) {
        await fetchDelete(`/rule/${rule.id}`);
        await fetchPost(`/rule/run`, {
          status: "Draft",
          report_id: report_id,
          name: streamName,
          description: streamDesc,
          remediation_step: rule.remediation_step,
          data_types: rule.data_type.split(", "),
          priority: rule.priority,
          boolean: rule.boolean,
          default_hit_priority: rule.default_hit_priority,
          rule,
        });
      } else {
        await fetchPatch(`/rule/${rule.id}`, {
          boolean: rule.boolean,
          name: rule.name,
          description: rule.description,
          remediation_step: rule.remediation_step,
          data_type: rule.data_type,
          default_hit_priority: rule.default_hit_priority,
        });
        await fetchPatch(`/rule/run/${rule.id}`, { rule });
      }
    } catch (error) {
      toast.error("Something went wrong while running rule.");
    }
  }

  const checkForStepCompletion = async (viewReport = false) => {
    if (rules.length === 0) {
      toast.warning("Please add at least one rule to continue.");
      return;
    } else if (test_rules.length) confirmModal.showModal();
    else {
      if (stepsCompletedTill < step) stepsCompletedTill = step;

      if (viewReport === true && streamType === "edit") {
        fetchPost("/report/1", {
          report_id,
          name: streamName,
          description: streamDesc,
          status: "Live",
        });

        const response = await fetchGet(`/report/${report_id}/rules`);
        const allRules = response.rules;

        runningRules = true;

        for (const rule of allRules) {
          await runRule(rule);
        }

        runningRules = false;

        push(`/stream/${report_id}`);
        return;
      }

      step += 1;
    }
  };

  function addRule() {
    loaded_rule_counter -= 1;
    test_rules = [
      {
        loaded: false,
        index: loaded_rule_counter,
        name: "",
        type: RuleDataType.BUILDER,
        description: "",
        remediation_step: "",
        data_types: [],
        size: 10000,
        boolean: 'text:("Haast" OR "Haaast")',
        builder: [],
      },
    ];
  }

  async function saveRule(ruleIndex, defaultPriority = "Low") {
    let rule_ran = await test_rules.find((f) => f.index == ruleIndex);

    if (rule_ran.data_types.length <= 0) {
      toast.warning("Please select at least one pool to continue.");
      return;
    }

    let indexs = rule_ran.data_types;

    rule_ran.priority = defaultPriority;

    rule_ran.data_type = indexs.join(", ");
    rule_ran.boolean =
      rule_ran.visual_type === RuleDataType.BUILDER
        ? convertBuilderToBoolean(rule_ran.builder)
        : rule_ran.boolean;
    rule_ran.type = RuleDataType.BOOLEAN;

    if (!boolChecker(rule_ran.boolean)) {
      toast.warning("Rule is not valid, please check the syntax.");
      return;
    } else rule_ran.boolean = boolChecker(rule_ran.boolean);

    updateActiveTab(0);

    if (editingRule) {
      await fetchPatch(`/rule/${rule_ran.index}`, {
        boolean: rule_ran.boolean,
        name: rule_ran.name,
        description: rule_ran.description,
        remediation_step: rule_ran.remediation_step,
        data_type: rule_ran.data_type,
        default_hit_priority: rule_ran.default_hit_priority,
      });

      rules = rules.map((r) => {
        if (r.id === rule_ran.index) {
          r.boolean = rule_ran.boolean;
          r.name = rule_ran.name;
          r.description = rule_ran.description;
          (r.remediation_step = rule_ran.remediation_step),
            (r.data_type = rule_ran.data_type);
          r.default_hit_priority = rule_ran.default_hit_priority;
        }
        return r;
      });

      editingRule = false;
      return;
    }

    rules = rules.concat(rule_ran);

    const res = await fetchPost("/rule/add", {
      report_id,
      rule: rule_ran,
      boolean: rule_ran.boolean,
      data_types: indexs,
      priority: defaultPriority,
    });

    rules[rules.length - 1].id = res.rule.id;
    rulesToReRun = rulesToReRun.concat(res.rule.id);
  }

  async function deleteRule(id) {
    try {
      const res = await fetchDelete(`/rule/${id}`);

      if (res.success) rules = rules.filter((f) => f.id != id);

      toast.success("Rule has been deleted along with all the hits.");
    } catch (error) {
      toast.error("Something went wrong while deleting the rule.");
      console.warn("Error while deleting the rule", error);
    }
  }

  const editRule = (id) => {
    duplicateRule(id);
    test_rules[0].index = id;
    test_rules[0].name = rules.find((f) => f.id == id).name;

    editingRule = true;
  };

  const duplicateRule = (id) => {
    activeTab = 1;
    addRule();

    const rule = rules.find((f) => f.id == id);

    test_rules[0].name = `Copy of ${rule.name}`;
    test_rules[0].description = rule.description;
    test_rules[0].remediation_step = rule.remediation_step;
    test_rules[0].boolean = rule.boolean;
    test_rules[0].data_types = rule.data_type.split(", ");
    test_rules[0].default_hit_priority = rule.default_hit_priority;
    test_rules[0].builder = convertBoolToBuilder(rule.boolean);
  };

  let d_types = [];
  async function updateDataTypes() {
    const res = await fetchGet("/pool");
    d_types = await res.data;

    for (let i = 0; i < d_types.length; i++)
      d_types[i].label = tidyPoolLabel(d_types[i].label);
  }

  async function updateSavedRules() {
    const queryParams = new URLSearchParams({
      includeSharedRules: true,
      type: "saved",
    }).toString();
    const res = await fetchGet(`/rule?${queryParams}`);

    if (!res.success) {
      toast.error("Something went wrong retrieving all saved rules.");
    }

    saved_rules = res.rules;
    sharedWith = res.shared_rules ? [...res.shared_rules] : [];

    const ruleSetsRes = await fetchGet("/ruleset");
    allRuleSets = ruleSetsRes.rule_sets ? [...ruleSetsRes.rule_sets] : [];

    for (let i = 0; i < saved_rules.length; i++) {
      saved_rules[i].value = saved_rules[i].name;
      saved_rules[i].label = saved_rules[i].name;
    }
  }

  onMount(async () => {
    updateDataTypes();
    updateSavedRules();

    const response = await fetchGet(`/report/${report_id}?initial=true`);
    rules = response.rules;
  });

  const updateActiveTab = (tab) => {
    if (tab === 1) {
      if (activeTab === 1) return;

      addRule();
    } else test_rules = [];

    activeTab = tab;
  };
</script>

<div class="mt-2 flex flex-wrap gap-4">
  <button
    class="btn
      {activeTab === 1 ? 'btn-primary ' : 'btn-outline border-2 border-dotted'}
      {activeTab === 0 ? 'h-auto w-32 flex-col py-4' : ''}"
    on:click={() => updateActiveTab(1)}
  >
    <Icon icon="iconoir:db" class={activeTab === 0 ? "text-4xl" : "text-2xl"} />
    <span class:font-bold={activeTab === 0}>
      Build Rule {#if activeTab === 0}<br />{/if} (Classic)
    </span>
  </button>
  <button
    class="btn
      {activeTab === 2 ? 'btn-primary ' : 'btn-outline border-2 border-dotted'}
      {activeTab === 0 ? 'h-auto w-32 flex-col p-4' : ''}"
    on:click={() => updateActiveTab(2)}
  >
    <Icon
      icon="iconoir:download"
      class={activeTab === 0 ? "text-4xl" : "text-2xl"}
    />
    <span class:font-bold={activeTab === 0}>
      Load Saved {#if activeTab === 0}<br />{/if} Rule
    </span>
  </button>
  <!-- <button
    class="btn
      {activeTab === 3 ? 'btn-primary ' : 'btn-outline border-2 border-dotted'}
      {activeTab === 0 ? 'w-32 h-auto flex-col p-4' : ''}"
  >
    <Icon
      icon="iconoir:sparks"
      class={activeTab === 0 ? "text-4xl" : "text-2xl"}
    />
    <span class:font-bold={activeTab === 0}>
      Discover{#if activeTab === 0}<br />{/if} (AI)
    </span>
  </button> -->
  <button
    class="btn
      {activeTab === 4 ? 'btn-primary ' : 'btn-outline border-2 border-dotted'}
      {activeTab === 0 ? 'h-auto w-32 flex-col p-4' : ''}"
    on:click={() => updateActiveTab(4)}
  >
    <Icon
      icon="iconoir:sparks-solid"
      class={activeTab === 0 ? "text-4xl" : "text-2xl"}
    />
    <span class:font-bold={activeTab === 0}>
      Build Rules{#if activeTab === 0}<br />{/if} (AI)
    </span>
  </button>
</div>

{#if activeTab === 1}
  {#each test_rules as rule}
    <Rule
      index={rule.index}
      loaded={rule.loaded}
      {d_types}
      on:saveRule={async () => {
        if (editingRule) {
          document.getElementById(`editModal-${rule.id}`).showModal();
        } else {
          await saveRule(rule.index, rule.default_hit_priority);
        }
      }}
      runRule={() => {}}
      deleteRule={() => {
        editingRule = false;
        updateActiveTab(0);
      }}
      bind:data={rule}
      editing={editingRule}
      bind:defaultRulePriority={rule.default_hit_priority}
    />
    {#if editingRule}
      <!-- svelte-ignore missing-declaration -->
      <ActionConfirmation
        message="You are about to edit this rule's search parameters. This will cause all existing results to be removed, with results for the new rule returned. This operation is not reversible. Are you sure you want to edit this rule?"
        modalId={`editModal-${rule.id}`}
        onConfirm={async () => {
          document.getElementById(`editModal-${rule.id}`).close();
          await saveRule(rule.index, rule.default_hit_priority);
        }}
        onCancel={() => {
          document.getElementById(`editModal-${rule.id}`).close();
          editingRule = false;
          updateActiveTab(0);
        }}
      />
    {/if}
  {/each}
{:else if activeTab === 2}
  <LoadRules
    reportId={report_id}
    reportRules={rules}
    {allRuleSets}
    on:ruleDuplicated={async () => {
      const res = await fetchGet(`/report/${report_id}?initial=true`);
      rules = res.rules;
      updateActiveTab(0);
    }}
  />
{:else if activeTab === 3}
  <Chat
    chat_type="discover"
    {d_types}
    {report_id}
    on:updateRulePen={async () => {
      const res = await fetchGet(`/report/${report_id}?initial=true`);
      rules = res.rules;
      updateActiveTab(0);
    }}
  />
{:else if activeTab === 4}
  <Chat
    chat_type="build"
    {d_types}
    {report_id}
    on:updateRulePen={async () => {
      const res = await fetchGet(`/report/${report_id}?initial=true`);
      rules = res.rules;
      updateActiveTab(0);
    }}
  />
{/if}

<div
  class="collapse mt-4 rounded border"
  class:collapse-open={activeTab === 0}
  class:collapse-arrow={activeTab !== 0}
  transition:slide
>
  <input type="checkbox" />
  <div class="collapse-title text-xl font-medium">Rule Pen</div>
  <div class="collapse-content flex flex-col gap-2 overflow-auto">
    <div class="grid min-w-[750px] grid-cols-4 border-b px-2 font-semibold">
      <span class="col-span-1">Rule</span>
      <span class="col-span-1">Channels</span>
      <span class="col-span-1">Shared Rule Status</span>
      <span class="col-span-1 text-end">Actions</span>
    </div>

    {#each rules as e, idx}
      <div class="bg-base-200 min-w-[750px] rounded border">
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <!-- svelte-ignore a11y-no-static-element-interactions -->
        <div
          class="grid grid-cols-4 items-center justify-between gap-4 px-4 py-2"
          on:click={() => (activeRulePen = activeRulePen === idx ? -1 : idx)}
        >
          <div class="tooltip max-w-fit" data-tip={e.name}>
            <h2
              class="col-span-1 overflow-hidden text-ellipsis whitespace-nowrap text-start text-lg font-medium"
            >
              {e.name}
            </h2>
          </div>
          <div
            class="tooltip max-w-fit"
            data-tip={e.data_type}
            class:invisible={activeRulePen === idx}
          >
            <p
              class="col-span-1 overflow-hidden text-ellipsis whitespace-nowrap text-start text-sm"
            >
              {tidyPoolLabel(e.data_type)}
            </p>
          </div>
          <div class="col-span-1 flex items-center gap-1">
            {#if sharedWith.some((sr) => sr.rule_id === e.id && sr.status === SharedRuleStatus.ACTIVE)}
              <div
                class="tooltip"
                data-tip="This rule was created from a shared rule and is up to date with the original rule."
              >
                <Badge bgColor="#336A1D" textColor="white">
                  Shared Rule Current
                </Badge>
              </div>
            {:else if sharedWith.some((sr) => sr.rule_id === e.id && sr.status === SharedRuleStatus.UPDATED)}
              <div
                class="tooltip"
                data-tip="This rule was created from a shared rule and is not up to date with the original rule."
              >
                <Badge bgColor="#FB8C00" textColor="white">
                  Shared Rule Updated
                </Badge>
              </div>
            {:else if sharedWith.some((sr) => sr.rule_id === e.id && sr.status === SharedRuleStatus.DELETED)}
              <div
                class="tooltip"
                data-tip="This rule was created from a shared rule and the original rule has been deleted."
              >
                <Badge bgColor="#FF4E4E" textColor="white">
                  Shared Rule Deleted
                </Badge>
              </div>
            {/if}
          </div>
          <div class="col-span-1 ml-auto flex items-center gap-1">
            <button
              class="btn btn-outline btn-primary btn-xs"
              on:click={(event) => {
                event.stopPropagation();
                editRule(e.id);
              }}
            >
              Edit
            </button>
            <button
              class="btn btn-outline btn-primary btn-xs"
              on:click={(event) => {
                event.stopPropagation();
                duplicateRule(e.id);
              }}
            >
              Copy
            </button>
            <button
              class="btn btn-outline btn-error btn-xs"
              on:click={(ele) => {
                ele.stopPropagation();
                const delRule = document.querySelector(`#delRule-${e.id}`);
                delRule.showModal();
              }}
            >
              Delete
            </button>
            <ActionConfirmation
              modalId="delRule-{e.id}"
              message="Are you sure you want to delete this rule?"
              onConfirm={async () => {
                const delRule = document.querySelector(`#delRule-${e.id}`);
                e.deleting = true;
                await deleteRule(e.id);
                delRule.close();
              }}
              onCancel={() => {
                const delRule = document.querySelector(`#delRule-${e.id}`);
                delRule.close();
              }}
              showLoadingSpinner={true}
              loading={e.deleting}
            />

            <button class="btn btn-square btn-ghost btn-sm">
              <Icon
                icon="iconoir:nav-arrow-{activeRulePen === idx ? 'up' : 'down'}"
                class="text-lg"
              />
            </button>
          </div>
        </div>
        {#if activeRulePen === idx}
          <main class="grid grid-cols-3 gap-4 px-4 py-2 pt-0" transition:slide>
            <div>
              <p class="text-sm font-medium">ID</p>
              <p class="text-sm font-light">
                #{e.id}
              </p>
            </div>
            <div>
              <p class="text-sm font-medium">Description</p>
              <p class="text-sm font-light">
                {e.description}
              </p>
            </div>
            <div class="col-span-1">
              <p class="text-sm font-medium">Channels</p>
              <ul class="ml-4 list-disc text-sm">
                {#each e.data_type.split(", ") as d}
                  <li>{d}</li>
                {/each}
              </ul>
            </div>
            <div class="col-span-3">
              <p class="text-sm font-medium">Remediation Step</p>
              <p class="text-sm font-light">
                {isEmpty(e.remediation_step)
                  ? "No remediation step."
                  : e.remediation_step}
              </p>
            </div>
            <div class="col-span-3 grid grid-cols-2">
              <div class="col-span-1 mr-[36px] flex flex-col gap-1">
                <p class="mt-1 text-sm font-medium">Query</p>
                <CodeMirror
                  bind:value={e.boolean}
                  editable={false}
                  readonly={true}
                  lang={javascript()}
                  styles={{
                    "&": { background: "white", fontSize: "14px" },
                    ".cm-gutters": { display: "none" },
                  }}
                  lineWrapping={true}
                />
              </div>
              <div
                class="col-span-1 ml-auto mr-[36px] flex place-items-end gap-1"
              >
                <button
                  class="btn btn-outline btn-primary btn-xs"
                  on:click={async () => {
                    saved_rules = saveRuleToMyRules(e.id, saved_rules, "live");
                    toast.success("Rule has been saved to My Rules");
                  }}
                >
                  Save to My Rules
                </button>
              </div>
            </div>
          </main>
        {/if}
      </div>
    {:else}
      <div
        class="text-base-content/50 flex flex-col items-center justify-center p-4"
      >
        <Icon icon="iconoir:folder" class="text-8xl" />
        <p class="text-center text-sm">
          No rules yet! <br />
          We're on a clean slate waiting for your brilliance.
        </p>
      </div>
    {/each}
  </div>
</div>

<div class="mt-4 flex gap-2">
  <button
    class="btn btn-sm"
    on:click={() => {
      if (step === 0) return;
      step -= 1;
    }}
  >
    Back
  </button>

  {#if rules.length === 0}
    <div class="tooltip" data-tip="You need at least one rule to proceed.">
      <button class="btn btn-primary btn-sm" disabled>Next Step</button>
    </div>
  {:else}
    <button class="btn btn-primary btn-sm" on:click={checkForStepCompletion}>
      Next Step
    </button>
    {#if streamType === "edit"}
      <button
        class="btn btn-primary btn-sm"
        disabled={runningRules}
        on:click={() => checkForStepCompletion(true)}
      >
        {#if runningRules}
          <LogoLoader size="1.25rem" />
        {/if}
        Save and View Stream
      </button>
    {/if}
  {/if}
</div>

<!-- svelte-ignore missing-declaration -->
<ActionConfirmation
  message="Currently open rule will not be saved. Are you sure you want to proceed?"
  onConfirm={() => {
    test_rules = [];
    checkForStepCompletion();
  }}
  onCancel={() => confirmModal.close()}
/>
