<script>
  import Icon from "@iconify/svelte";
  import { tick } from "svelte";
  import { dndzone } from "svelte-dnd-action";
  import { toast } from "svelte-sonner";
  import { link } from "svelte-spa-router";
  import { flip } from "svelte/animate";
  import { fetchPatch } from "../../helpers";
  import { AssetStatus } from "../../lib/interfaces/Asset.interface";
  import { RuleStatus } from "../../lib/interfaces/Rule.interface";
  import {
    getCurrentStepIndex,
    getPercentageProgressAssetRules,
    getProcessingAssetRules,
    getTotalAssetRules,
  } from "../../lib/utils/AssetReviewUtils";
  import { getRuleColourCounts } from "../../lib/utils/RuleUtils";
  import { org_users } from "../../stores";
  import AssignUsers from "./AssignUsers.svelte";
  import Badge from "./WorkflowBoard/Badge.svelte";

  export let assets = [];
  export let workflow = {};
  export let report_id;
  export let assetStatusMap = new Map();
  export let currentStreamPerm;

  const flipDurationMs = 200;

  let columnItems = [];
  let activeUsers = $org_users.filter((user) => !user.is_deleted);

  let hoveredAsset = { id: null, search: "" };

  function isOverdue(dateString) {
    const dueDate = new Date(dateString);
    const today = new Date();
    return dueDate < today;
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  }

  const updateAssetStatus = async (assetId, status) => {
    const asset = assets.find((a) => a.asset.id === assetId);
    if (!asset) return;

    const currStepIdx = getCurrentStepIndex(workflow, asset);
    let step = workflow.workflow_steps[currStepIdx].id;

    if (
      status === "Approved" &&
      currStepIdx < workflow.workflow_steps.length - 1
    )
      step = workflow.workflow_steps[currStepIdx + 1].id;
    else if (status === "Rejected" && currStepIdx > 0)
      step = workflow.workflow_steps[currStepIdx - 1].id;

    const res = await fetchPatch(`/asset/${assetId}`, {
      status,
      step,
      operation: "approval",
    });

    if (!res.success) {
      toast.error("Failed to update asset status");
      return;
    }

    assets = assets.map((a) => {
      if (a.asset.id === assetId) {
        a.asset.approval_status = status;
        a.asset.workflow_step.id = step;
      }
      return a;
    });

    toast.success("Asset status updated");
  };

  const updateAssetWorkflowstep = async (assetId, workflowStepId) => {
    const res = await fetchPatch(`/asset/${assetId}`, {
      workflow_step_id: workflowStepId,
      operation: "workflow",
    });

    if (!res.success) {
      toast.error("Failed to update asset workflow step");
      return;
    }

    assets = assets.map((a) => {
      if (a.asset.id === assetId) a.asset.workflow_step.id = workflowStepId;
      return a;
    });

    toast.success("Asset workflow step updated");
  };

  const handleDndConsiderCards = (cid, e) => {
    const colIdx = columnItems.findIndex((c) => c.id === cid);
    columnItems[colIdx].items = e.detail.items;
    columnItems = [...columnItems];
  };

  const handleDndFinalizeCards = async (cid, e) => {
    const colIdx = columnItems.findIndex((c) => c.id === cid);
    columnItems[colIdx].items = e.detail.items;
    columnItems = [...columnItems];

    // cid is the column id, e.detail.info.id is assetId
    // e.detail.info.trigger runs twice, can be droppedIntoAnother - orignal column (asset is removed from) OR droppedIntoZone - the new column where asset is dropped)
    if (e.detail.info.trigger === "droppedIntoZone") {
      if (groupBy === "Assignees") {
        console.warn("assignees movement not allowed");
        return;
      } else if (groupBy === "Status")
        await updateAssetStatus(e.detail.info.id, columnItems[colIdx].name);
      else if (groupBy === "Review Group")
        await updateAssetWorkflowstep(e.detail.info.id, cid);

      await tick();
      updateColumnItems();
    }
  };

  const groupAssetsToColumns = () => {
    // uff should have used switch case
    if (groupBy === "Status") {
      columnItems = [
        { id: 1, name: "Pending", items: [] },
        { id: 2, name: "In Review", items: [] },
        { id: 3, name: "Changes Requested", items: [] },
        { id: 4, name: "Approved", items: [] },
        { id: 5, name: "Roadblock", items: [] },
      ];

      assets.forEach((a) => {
        // svelte-dnd needs .id for each items item
        a.id = a.asset.id;

        const cl = columnItems.find((c) => c.name === a.asset.approval_status);
        if (cl) cl.items = [...cl.items, a];
        else {
          columnItems[0].items = [...columnItems[0].items, a];
          console.warn("not column for status", a.asset.approval_status);
        }
      });
    } else if (groupBy === "Review Group") {
      //sort by position
      const sortedWorkflowSteps = workflow.workflow_steps.sort(
        (a, b) => a.position - b.position,
      );
      // Create columns dynamically based on the workflow steps
      columnItems = sortedWorkflowSteps.map((step) => ({
        id: step.id, // Use the workflow step id as the column id
        name: step.name, // Use the workflow step name as the column name
        items: [], // Initialize an empty array for the items
      }));

      // Assign assets to the columns based on their current workflow step
      assets.forEach((a) => {
        a.id = a.asset.id; // svelte-dnd needs .id for each items item

        // Find the column that matches the asset's current workflow step
        const column = columnItems.find(
          (c) => c.id === a.asset.workflow_step?.id,
        );
        if (column) {
          column.items = [...column.items, a]; // If found, add the asset to the column
        } else {
          columnItems[0].items = [...columnItems[0].items, a];
          console.warn("Asset's workflow step not found in columns", a.asset);
        }
      });
    } else if (groupBy === "Assignees") {
      columnItems = activeUsers.map((u) => ({
        id: u.id,
        name: `${u.first_name} ${u.last_name}`,

        items: assets

          .filter((a) =>
            a.asset.asset_assigned_users.some((au) => au.assigned_id === u.id),
          )
          .map((a) => {
            a.id = a.asset.id;
            return a;
          }),
      }));

      // add unassigned column
      columnItems = [
        ...columnItems,
        {
          id: 0,
          name: "Unassigned",
          items: assets
            .filter((a) => a.asset.asset_assigned_users.length === 0)
            .map((a) => {
              a.id = a.asset.id;
              return a;
            }),
        },
      ];
    } else if (groupBy === "Uploaded date") {
      // Extract unique dates from assets
      const uniqueDates = [
        ...new Set(
          assets.map((a) => {
            // Format the date as YYYY-MM-DD
            return new Date(a.asset.created_date).toISOString().split("T")[0];
          }),
        ),
      ];

      // Sort the dates in ascending order
      uniqueDates.sort((a, b) => new Date(a) - new Date(b));

      // Create columns for each date
      columnItems = uniqueDates.map((date) => ({
        id: date,
        name: date, // Column name is the date
        items: [],
      }));

      // Assign assets to the corresponding date columns
      assets.forEach((a) => {
        a.id = a.asset.id; // svelte-dnd needs .id for each item

        const assetDate = new Date(a.asset.created_date)
          .toISOString()
          .split("T")[0];
        const column = columnItems.find((c) => c.id === assetDate);

        if (column) {
          column.items.push(a);
        } else {
          // If no matching column, place in an "Unknown" column
          let unknownColumn = columnItems.find((c) => c.id === "Unknown");
          if (!unknownColumn) {
            unknownColumn = { id: "Unknown", name: "Unknown", items: [] };
            columnItems.push(unknownColumn);
          }
          unknownColumn.items.push(a);
        }
      });
    } else if (groupBy === "Due Date") {
      // Extract assets with valid due dates and those without
      const assetsWithDueDate = assets.filter((a) => {
        const dueDate = new Date(a.asset.due_date);
        return a.asset.due_date && !isNaN(dueDate);
      });

      const assetsWithoutDueDate = assets.filter((a) => {
        const dueDate = new Date(a.asset.due_date);
        return !a.asset.due_date || isNaN(dueDate);
      });

      // Group assets by due date
      const groupedAssets = assetsWithDueDate.reduce((acc, a) => {
        const dueDate = formatDate(a.asset.due_date);
        if (!acc[dueDate]) {
          acc[dueDate] = [];
        }
        a.id = a.asset.id; // svelte-dnd needs .id for each item
        acc[dueDate].push(a);
        return acc;
      }, {});

      // Create columns for each unique due date
      columnItems = Object.entries(groupedAssets).map(([date, items]) => ({
        id: date,
        name: date,
        items: items,
      }));

      // Sort columns by date
      columnItems.sort((a, b) => new Date(a.id) - new Date(b.id));

      // Add "No Due Date" column at the end
      if (assetsWithoutDueDate.length > 0) {
        columnItems.push({
          id: "No Due Date",
          name: "No Due Date",
          items: assetsWithoutDueDate.map((a) => {
            a.id = a.asset.id; // svelte-dnd needs .id for each item
            return a;
          }),
        });
      }
    } else toast.error("Unknown groupBy value");
  };

  const sortColumnItems = () => {
    if (sortBy === "Status") {
      const statuses = [
        "Pending",
        "In Review",
        "Changes Requested",
        "Approved",
        "Roadblock",
      ];
      columnItems = columnItems.map((c) => {
        c.items = c.items.sort(
          (a, b) =>
            statuses.indexOf(a.asset.approval_status) -
            statuses.indexOf(b.asset.approval_status),
        );
        return c;
      });
    } else if (sortBy === "Review Group") {
      columnItems = columnItems.map((c) => {
        c.items = c.items.sort((a, b) => {
          return (
            a.asset.workflow_step.position - b.asset.workflow_step.position
          );
        });
        return c;
      });
    } else if (sortBy === "Assignees") {
      columnItems = columnItems.map((c) => {
        c.items = c.items.sort((a, b) => {
          const aUserId = a.asset.asset_assigned_users[0]?.assigned_id;
          const bUserId = b.asset.asset_assigned_users[0]?.assigned_id;

          if (!aUserId) return 1;
          if (!bUserId) return -1;

          const aName = activeUsers.find((u) => u.id === aUserId).first_name;
          const bName = activeUsers.find((u) => u.id === bUserId).first_name;
          return aName.localeCompare(bName);
        });
        return c;
      });
    } else if (sortBy === "Asset Name") {
      columnItems = columnItems.map((c) => {
        c.items = c.items.sort((a, b) =>
          a.asset.name.localeCompare(b.asset.name),
        );
        return c;
      });
    } else if (sortBy === "Uploaded date") {
      columnItems = columnItems.map((c) => {
        c.items = c.items.sort((a, b) => {
          const dateA = new Date(a.asset.created_date);
          const dateB = new Date(b.asset.created_date);
          return dateA - dateB;
        });
        return c;
      });
    } else if (sortBy === "Due Date") {
      // Sort columns by date ascending, "No Due Date" last
      columnItems.sort((a, b) => {
        if (a.id === "No Due Date") return 1;
        if (b.id === "No Due Date") return -1;
        return new Date(a.id) - new Date(b.id);
      });

      // Sort items within each column by due date
      columnItems = columnItems.map((c) => {
        if (c.id !== "No Due Date") {
          c.items.sort(
            (a, b) => new Date(a.asset.due_date) - new Date(b.asset.due_date),
          );
        }
        return c;
      });
    } else toast.error("Unknown sortBy value");

    if (reverseSort)
      columnItems = columnItems.map((c) => {
        c.items = c.items.reverse();
        return c;
      });
  };

  const updateColumnItems = async () => {
    groupAssetsToColumns();
    sortColumnItems();

    columnItems = columnItems.map((c) => {
      //showArchived
      c.items = c.items.filter((a) =>
        showArchived ? true : a.asset.approval_status !== "Archived",
      );

      // filterByReviewGroup
      c.items = c.items.filter((a) =>
        filterByReviewGroups.includes(a.asset.workflow_step.id),
      );

      // filterByAssignee
      c.items = c.items.filter((a) => {
        const aUserIds = a.asset.asset_assigned_users.map(
          (au) => au.assigned_id,
        );

        if (aUserIds.length === 0) return true;

        return filterByAssignees.some((id) => aUserIds.includes(id));
      });

      // filterByRuleStatus
      c.items = c.items.filter((a) =>
        filterByRuleStatus.every((f) => {
          if (f.colour === undefined) return true;

          const rule = a.rules.find((r) => r.rule.id === f.id);
          return rule && rule.colour === f.colour;
        }),
      );

      // // Apply date range filter
      // c.items = c.items.filter(a => {
      //   const assetDate = new Date(a.asset.created_date);
      //   const today = new Date();

      //   if (dateRangeFilter === "Today") {
      //     return assetDate.toDateString() === today.toDateString();
      //   } else if (dateRangeFilter === "This Week") {
      //     const weekStart = new Date(today);
      //     weekStart.setDate(today.getDate() - today.getDay());

      //     const weekEnd = new Date(weekStart);
      //     weekEnd.setDate(weekStart.getDate() + 6);

      //     // Zero out time for accurate comparison
      //     weekStart.setHours(0, 0, 0, 0);
      //     weekEnd.setHours(23, 59, 59, 999);

      //     return assetDate >= weekStart && assetDate <= weekEnd;
      //   } else if (dateRangeFilter === "This Month") {
      //     return assetDate.getMonth() === today.getMonth() && assetDate.getFullYear() === today.getFullYear();
      //   } else {
      //     // "All" or unrecognized value
      //     return true;
      //   }
      // });

      return c;
    });
  };

  $: if (
    groupBy ||
    sortBy ||
    reverseSort ||
    filterByReviewGroups ||
    filterByAssignees ||
    filterByRuleStatus ||
    showArchived ||
    assets
  )
    updateColumnItems();
  // $: updateColumnItems(), [groupBy,sortBy,reverseSort,filterByReviewGroups,filterByAssignees, showArchived, assets,]

  let groupBy = "Review Group";
  let sortBy = "Asset Name";
  let reverseSort = false;
  let filterByReviewGroups = workflow.workflow_steps.map((s) => s.id);
  let filterByAssignees = activeUsers.map((u) => u.id);
  let showArchived = false;

  // confirm if all rules are always applied to all assets else use the below
  // Object.values(
  //   assets
  //     .flatMap((e) => e.rules)
  //     .map((e) => e.rule)
  //     .reduce((acc, item) => {
  //       acc[item.id] = item;
  //       return acc;
  //     }, {}),
  // );
  let filterByRuleStatus = [];
  let dateRangeFilter = "All";

  if (
    assets &&
    assets.length > 0 &&
    assets[0].rules &&
    Array.isArray(assets[0].rules)
  ) {
    filterByRuleStatus = assets[0].rules.map((e) => ({
      id: e.rule.id,
      colour: undefined,
    }));
  } else {
    console.log("No assets or rules found to initialize filterByRuleStatus");
  }

  // so on initial load, the columns are updated
  let loaded = false;
  $: if (assets.length && workflow && !loaded) {
    updateColumnItems();
    loaded = true;
  }
</script>

<!-- svelte-ignore a11y-no-noninteractive-tabindex -->

<div class="mb-2 flex flex-wrap gap-2 py-4">
  <div class="dropdown">
    <div
      tabindex="0"
      role="button"
      class="btn btn-outline btn-primary btn-sm rounded-full"
    >
      Group: {groupBy}
    </div>
    <ul
      tabindex="0"
      class="menu dropdown-content menu-sm bg-base-100 z-[1] mt-1 w-full min-w-fit rounded border p-1"
    >
      {#each ["Status", "Review Group", "Assignees", "Uploaded date", "Due Date"] as item}
        <li>
          <button on:click={() => (groupBy = item)}>
            <input
              type="radio"
              class="radio-primary radio radio-sm"
              checked={groupBy === item}
            />

            {item}
          </button>
        </li>
      {/each}
    </ul>
  </div>

  <div class="dropdown">
    <div
      tabindex="0"
      role="button"
      class="btn btn-outline btn-primary btn-sm rounded-full"
    >
      Sort: <Icon icon="iconoir:arrow-{reverseSort ? 'down' : 'up'}" />
      {sortBy}
    </div>
    <ul
      tabindex="0"
      class="menu dropdown-content menu-sm bg-base-100 z-[1] mt-1 w-full min-w-fit rounded border p-1"
    >
      {#each ["Status", "Review Group", "Assignees", "Asset Name", "Uploaded date", "Due Date"] as item}
        <li>
          <button
            class="justify-start"
            on:click={() => {
              if (sortBy === item) reverseSort = !reverseSort;
              else {
                sortBy = item;
                reverseSort = false;
              }
            }}
          >
            {#if sortBy === item}
              <Icon
                icon="iconoir:arrow-up"
                class={!reverseSort ? "text-primary" : ""}
              />
              <Icon
                icon="iconoir:arrow-down"
                class="{reverseSort ? 'text-primary' : ''} -ml-2"
              />
            {/if}

            {item}
          </button>
        </li>
      {/each}
    </ul>
  </div>

  <div class="dropdown">
    <div
      tabindex="0"
      role="button"
      class="btn btn-outline btn-primary btn-sm rounded-full"
    >
      Review Group
    </div>
    <ul
      tabindex="0"
      class="menu dropdown-content menu-sm bg-base-100 z-[1] mt-1 max-h-80 max-w-60 flex-nowrap overflow-auto rounded border p-1"
    >
      <!-- Dynamically list workflow steps as options in the dropdown -->
      {#each workflow.workflow_steps as { id, name }}
        <li>
          <button
            on:click={() => {
              if (filterByReviewGroups.includes(id))
                filterByReviewGroups = filterByReviewGroups.filter(
                  (a) => a !== id,
                );
              else filterByReviewGroups = [...filterByReviewGroups, id];
            }}
          >
            <input
              type="checkbox"
              class="checkbox-primary checkbox checkbox-sm"
              checked={filterByReviewGroups.includes(id)}
            />
            {name}
          </button>
        </li>
      {/each}
    </ul>
  </div>

  <div class="dropdown">
    <div
      tabindex="0"
      role="button"
      class="btn btn-outline btn-primary btn-sm rounded-full"
    >
      Assignees
    </div>
    <ul
      tabindex="0"
      class="menu dropdown-content menu-sm bg-base-100 z-[1] mt-1 max-h-80 max-w-60 flex-nowrap overflow-auto rounded border p-1 pb-0"
    >
      <li
        class="bg-base-100 sticky -top-1 z-[1] border-b pb-1 pt-0.5 font-semibold"
      >
        <button
          class="whitespace-nowrap"
          on:click={() => {
            if (filterByAssignees.length === activeUsers.length)
              filterByAssignees = [];
            else filterByAssignees = activeUsers.map((u) => u.id);
          }}
        >
          <input
            type="checkbox"
            class="checkbox-primary checkbox checkbox-sm"
            checked={filterByAssignees.length === activeUsers.length}
            indeterminate={filterByAssignees.length > 0 &&
              filterByAssignees.length < activeUsers.length}
          />

          Assignees
        </button>
      </li>
      {#each activeUsers as user}
        <li>
          <button
            class="whitespace-nowrap"
            on:click={() => {
              if (filterByAssignees.includes(user.id))
                filterByAssignees = filterByAssignees.filter(
                  (a) => a !== user.id,
                );
              else filterByAssignees = [...filterByAssignees, user.id];
            }}
          >
            <input
              type="checkbox"
              class="checkbox-primary checkbox checkbox-sm"
              checked={filterByAssignees.includes(user.id)}
            />

            {user.first_name}
            {user.last_name}
          </button>
        </li>
      {/each}
    </ul>
  </div>

  <div class="dropdown">
    <div
      tabindex="0"
      role="button"
      class="btn btn-outline btn-primary btn-sm rounded-full"
    >
      Filter: Rule Status
    </div>
    {#if assets && assets.length > 0 && assets[0].rules && Array.isArray(assets[0].rules)}
      <ul
        tabindex="0"
        class="menu dropdown-content menu-sm bg-base-100 z-[1] mt-1 max-h-80 max-w-60 flex-nowrap overflow-auto rounded border p-1"
      >
        {#each assets[0].rules.map((e) => e.rule) as e (e.id)}
          <li>
            <details>
              <summary class="whitespace-nowrap">
                {e.name}

                {#if filterByRuleStatus.find((f) => f.id === e.id)?.colour}
                  {@const colour = filterByRuleStatus.find(
                    (f) => f.id === e.id,
                  ).colour}
                  <Badge
                    fullyRound={true}
                    bgColor={colour === "green"
                      ? "#c4d4bd"
                      : colour === "amber"
                        ? "#fbe5b8"
                        : colour === "red"
                          ? "#ff4e4e5e"
                          : undefined}
                    textColor={colour === "green"
                      ? "#336A1D"
                      : colour === "amber"
                        ? "#FF8D00"
                        : colour === "red"
                          ? "#FC0000"
                          : undefined}
                    additionalClass="ml-auto"
                  >
                    {colour === "green"
                      ? RuleStatus.PASSED
                      : colour === "amber"
                        ? RuleStatus.REVIEW
                        : colour === "red"
                          ? RuleStatus.RISK
                          : colour}
                  </Badge>
                {/if}
              </summary>
              <ul>
                {#each [{ name: RuleStatus.PASSED, colour: "green" }, { name: RuleStatus.REVIEW, colour: "amber" }, { name: RuleStatus.RISK, colour: "red" }] as rs}
                  <li>
                    <button
                      class="whitespace-nowrap"
                      on:click={() => {
                        filterByRuleStatus = filterByRuleStatus.map((f) => {
                          if (f.id === e.id)
                            f.colour =
                              f.colour === rs.colour ? undefined : rs.colour;
                          return f;
                        });
                      }}
                    >
                      <input
                        type="radio"
                        class="radio-primary radio radio-xs"
                        checked={filterByRuleStatus.find((f) => f.id === e.id)
                          ?.colour === rs.colour}
                      />

                      {rs.name}
                    </button>
                  </li>
                {/each}
              </ul>
            </details>
          </li>
        {/each}
      </ul>
    {/if}
  </div>
  <!-- <div class="dropdown">
    <div
        tabindex="0"
        role="button"
        class="btn btn-outline btn-primary btn-sm rounded-full"
    >
        Filter: Uploaded Date
    </div>
    <ul
        tabindex="0"
        class="menu dropdown-content menu-sm bg-base-100 z-[1] mt-1 w-full min-w-fit rounded border p-1"
    >
        {#each ["Today", "This Week", "This Month", "All"] as range}
            <li>
                <button on:click={() => (dateRangeFilter = range)}>
                    <input
                        type="radio"
                        class="radio-primary radio radio-sm"
                        checked={dateRangeFilter === range}
                    />
                    {range}
                </button>
            </li>
        {/each}
    </ul>
</div> -->

  <button
    class="btn btn-outline btn-primary btn-sm rounded-full"
    class:btn-active={showArchived}
    on:click={() => (showArchived = !showArchived)}
  >
    {showArchived ? "Hide Archived" : "Show Archived"}
  </button>
</div>

<!-- pb-2 so shadow is visible in the bottom -->
<!-- Update h-[calc if there is change which affects page height -->
<section
  class="-mb-12 flex h-[calc(100vh-384px)] min-h-[250px] w-full gap-4 overflow-x-auto pb-2"
>
  {#each columnItems as column (column.id)}
    <div
      class="bg-base-200 flex w-96 shrink-0 flex-col overflow-y-auto rounded p-4 shadow"
      style="background-color:#f6f8ff; ustify-content: flex-start;"
    >
      <h2 class="py-2 font-semibold">{column.name} ({column.items.length})</h2>

      <div
        class="flex h-full flex-col gap-2 py-2"
        use:dndzone={{
          items: column.items,
          flipDurationMs,
          dragDisabled: true,
        }}
        on:consider={(e) => handleDndConsiderCards(column.id, e)}
        on:finalize={(e) => handleDndFinalizeCards(column.id, e)}
      >
        {#each column.items as asset (asset.id)}
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <div
            class="bg-base-100 flex w-full flex-col gap-1 rounded border p-4 shadow"
            animate:flip={{ duration: flipDurationMs }}
            on:mouseenter={() => (hoveredAsset.id = asset.asset.id)}
            on:mouseleave={() => (hoveredAsset = { id: null, search: "" })}
          >
            <!-- can't use group hover as it affects assignee menu -->
            <div class="flex gap-1">
              <Badge fullyRound={true} bgColor="#c4d4bd" textColor="#336A1D">
                {RuleStatus.PASSED} ({getRuleColourCounts(
                  asset.rules,
                  "green",
                )})
              </Badge>
              <Badge fullyRound={true} bgColor="#fbe5b8" textColor="#FF8D00">
                {RuleStatus.REVIEW} ({getRuleColourCounts(
                  asset.rules,
                  "amber",
                )})
              </Badge>
              <Badge fullyRound={true} bgColor="#ff4e4e5e" textColor="#FC0000">
                {RuleStatus.RISK} ({getRuleColourCounts(asset.rules, "red")})
              </Badge>
            </div>

            <div
              class="tooltip max-w-max break-words"
              data-tip={asset.asset.name}
            >
              <a
                class="hover:text-primary block w-full truncate py-0.5 text-start font-semibold"
                href="/review/{report_id}/asset/{asset.asset.id}"
                use:link
              >
                {asset.asset.name}
              </a>
            </div>

            {#if asset.asset.description}
              <p class="text-pretty text-sm font-light">
                {asset.asset.description}
              </p>
            {/if}

            <div class="bg-base-content/10 my-2 h-[1px]"></div>

            <div class="flex gap-1">
              {#if asset.asset.status === "Ready"}
                <!-- <div
                  class="tooltip h-6"
                  class:hidden={hoveredAsset.id !== asset.asset.id}
                  data-tip="Critical Rule"
                >
                  <button
                    class="btn btn-xs btn-ghost btn-square text-lg hover:text-red-500"
                  >
                    <Icon icon="iconoir:white-flag" />
                  </button>
                </div> -->
                <div
                  class="tooltip h-6"
                  class:hidden={hoveredAsset.id !== asset.asset.id ||
                    currentStreamPerm !== "edit"}
                  data-tip="Tags"
                >
                  <div class="dropdown">
                    <div
                      tabindex="0"
                      role="button"
                      class="btn btn-square btn-ghost btn-xs hover:text-primary text-lg"
                    >
                      <Icon icon="iconoir:bookmark" />
                    </div>
                    <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
                    <div
                      tabindex="0"
                      class="dropdown-content bg-base-100 z-[1] w-52 space-y-2 rounded border p-2 shadow"
                    >
                      <div class="flex flex-wrap gap-1">
                        {#each [asset.asset.approval_status, asset.asset.workflow_step.name] as e}
                          {#if e != null}
                            <span class="badge badge-ghost whitespace-nowrap">
                              {e}
                            </span>
                          {/if}
                        {/each}
                      </div>

                      <label
                        class="input input-sm input-bordered flex w-full items-center gap-2"
                      >
                        <input
                          type="text"
                          class="w-full"
                          placeholder="Search"
                          bind:value={hoveredAsset.search}
                        />
                        <Icon
                          icon="iconoir:search"
                          class="text-base-content/70 h-4 w-4"
                        />
                      </label>

                      <ul
                        class="menu menu-xs h-40 flex-nowrap overflow-y-auto p-0"
                      >
                        <li class="menu-title text-start">Status</li>
                        {#each ["Rejected", "Pending", "In Review", "Changes Requested", "Approved", "Roadblock"] as s}
                          {#if s
                            .toLowerCase()
                            .includes(hoveredAsset.search.toLowerCase())}
                            <li>
                              <label>
                                <input
                                  type="radio"
                                  name="status-{asset.asset.id}"
                                  class="radio-primary radio radio-xs"
                                  checked={asset.asset.approval_status === s}
                                  on:change={() =>
                                    updateAssetStatus(asset.asset.id, s)}
                                />
                                {s}
                              </label>
                            </li>
                          {/if}
                        {/each}

                        <li class="menu-title text-start">Review Group</li>
                        {#each workflow.workflow_steps as { id, name }}
                          {#if name
                            .toLowerCase()
                            .includes(hoveredAsset.search.toLowerCase())}
                            <li>
                              <label>
                                <input
                                  type="radio"
                                  name="rg-{asset.asset.id}"
                                  class="radio-primary radio radio-xs"
                                  checked={asset.asset.workflow_step.id === id}
                                  on:change={() =>
                                    updateAssetWorkflowstep(asset.asset.id, id)}
                                />
                                {name}
                              </label>
                            </li>
                          {/if}
                        {/each}
                      </ul>
                    </div>
                  </div>
                </div>

                <div
                  class="tooltip h-6"
                  class:hidden={hoveredAsset.id !== asset.asset.id}
                  data-tip={asset.asset.approval_status === "Archived"
                    ? "Unarchive"
                    : "Archive"}
                >
                  <!-- hover:text-primary -->
                  <button
                    class="btn btn-square btn-ghost btn-xs text-lg
                      {asset.asset.approval_status === 'Archived'
                      ? 'text-error hover:text-primary'
                      : 'hover:text-error'}"
                    disabled={currentStreamPerm !== "edit"}
                    on:click={() =>
                      updateAssetStatus(
                        asset.asset.id,
                        asset.asset.approval_status === "Archived"
                          ? "Pending"
                          : "Archived",
                      )}
                  >
                    <Icon icon="iconoir:archive" />
                  </button>
                </div>

                <div
                  class="flex flex-wrap gap-1"
                  class:hidden={hoveredAsset.id === asset.asset.id}
                >
                  {#if asset.asset.approval_status && groupBy != "Status"}
                    <Badge
                      fullyRound={true}
                      bgColor="#e0e0e0"
                      textColor="#000000"
                    >
                      {asset.asset.approval_status}
                    </Badge>
                  {/if}
                  {#if asset.asset.workflow_step.name && groupBy != "Review Group" && asset.asset.approval_status != asset.asset.workflow_step.name}
                    <Badge
                      fullyRound={true}
                      bgColor="#e0e0e0"
                      textColor="#000000"
                    >
                      {asset.asset.workflow_step.name}
                    </Badge>
                  {/if}
                  {#if asset.asset.due_date && asset.asset.approval_status !== "Approved" && groupBy != "Due Date"}
                    <Badge
                      fullyRound={true}
                      bgColor={isOverdue(asset.asset.due_date)
                        ? "#FFE4E4"
                        : "#E0E0E0"}
                      textColor={isOverdue(asset.asset.due_date)
                        ? "#D92D20"
                        : "black"}
                    >
                      {formatDate(asset.asset.due_date)}
                    </Badge>
                  {/if}
                </div>

                <AssignUsers
                  asset_id={asset.asset.id}
                  bind:assignees={asset.asset.asset_assigned_users}
                  asset_type={"asset"}
                  containerClass="ml-auto mb-auto"
                  disableEditing={currentStreamPerm !== "edit"}
                  on:change={(e) => {
                    const assignees = e.detail;

                    assets = assets.map((a) => {
                      if (a.asset.id === asset.asset.id)
                        a.asset.asset_assigned_users = assignees;
                      return a;
                    });
                  }}
                />
                <!-- <ApproverStatus
                  bind:activeWorkflowStep={asset.asset.workflow_step}
                  bind:approvers={asset.asset.approvers}
                  bind:workflow
                  assetId={asset.asset.id}
                /> -->
              {:else if asset.asset.status === AssetStatus.PROCESSING}
                <div class="flex w-full items-center justify-between gap-1">
                  <div class="badge badge-neutral">{asset.asset.status}</div>
                  {#key assetStatusMap}
                    <div class="flex min-w-32 flex-row items-center gap-2">
                      <span class="min-w-8 text-start text-sm"> 0&#37; </span>
                      <progress
                        class="progress progress-primary w-24"
                        value="0"
                        max="100"
                      />
                    </div>
                  {/key}
                </div>
              {:else if asset.asset.status === AssetStatus.SEARCHING}
                <div class="flex w-full items-center justify-between gap-1">
                  <div class="badge badge-neutral">{asset.asset.status}</div>
                  {#key assetStatusMap}
                    {#if assetStatusMap.get(asset.asset.id)}
                      <div class="flex min-w-32 flex-row items-center gap-2">
                        <span class="min-w-8 text-start text-sm">
                          {getPercentageProgressAssetRules(
                            assetStatusMap,
                            asset.asset.status,
                            asset.asset.id,
                          )}&#37;
                        </span>
                        <progress
                          class="progress progress-primary w-24"
                          value={getProcessingAssetRules(
                            assetStatusMap,
                            asset.asset.id,
                          )}
                          max={getTotalAssetRules(
                            assetStatusMap,
                            asset.asset.id,
                          )}
                        />
                      </div>
                    {/if}
                  {/key}
                </div>
              {:else}
                <div class="badge badge-neutral">{asset.asset.status}</div>
              {/if}
            </div>
          </div>
        {/each}
      </div>
    </div>
  {/each}
</section>
