<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher } from "svelte";
  import { toast } from "svelte-sonner";
  import { fetchPost } from "../../helpers";
  import LabelBean from "./LabelBean.svelte";

  const dispatch = createEventDispatcher();

  export let allLabels = [];
  export let selectedLabels = [];
  export let disabled = false;

  let search = "";

  // Toggles the label from being filtered. Reactively updates the reports shown.
  function toggleLabel(label) {
    if (selectedLabels.includes(label)) {
      selectedLabels = selectedLabels.filter((l) => l !== label);
      dispatch("labelUnselected", label);
    } else {
      selectedLabels = [...selectedLabels, label];
      dispatch("labelSelected", label);
    }
  }

  const createLabel = async () => {
    if (search.trim().length === 0) {
      toast.warning("Label name cannot be empty");
      return;
    }

    const res = await fetchPost("/label/create", {
      name: search,
      color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
    });

    allLabels = [...allLabels, res.label];
    selectedLabels = [...selectedLabels, res.label];
    dispatch("labelSelected", res.label);
    search = "";
  };
</script>

<div class="dropdown">
  <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
  <label
    tabindex="0"
    class="btn btn-ghost btn-xs border-base-content/30 border-2 border-dashed"
    class:btn-disabled={disabled}
    for=""
  >
    Add Label
  </label>
  <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
  <ul
    tabindex="0"
    class="dropdown-content bg-base-100 z-[1] m-1 max-h-72 w-52 overflow-auto rounded border p-2 shadow"
  >
    <input
      class="input input-sm input-bordered w-full"
      type="text"
      placeholder="Search labels"
      bind:value={search}
    />

    <div class="menu menu-sm mt-2 gap-2 p-0">
      {#each allLabels.filter((l) => l.name
          .toLowerCase()
          .includes(search.toLowerCase())) as label}
        <li class:disabled>
          <button
            class:bg-base-200={selectedLabels.includes(label)}
            {disabled}
            on:click={() => toggleLabel(label)}
          >
            <LabelBean
              name={label.name}
              color={label.color}
              id={label.id}
              on:labelBeanClicked={() => toggleLabel(label)}
              {disabled}
            />
            <Icon
              icon="iconoir:{selectedLabels.includes(label)
                ? 'cancel'
                : 'check'}"
              class="ml-auto"
            />
          </button>
        </li>
      {/each}
      {#if !allLabels.some((l) => l.name.toLowerCase() === search.toLowerCase()) && search.length > 0}
        <li class:disabled>
          <button {disabled} on:click={createLabel}>
            Create Label "{search}"
          </button>
        </li>
      {/if}
    </div>
  </ul>
</div>
