<script>
  import { createEventDispatcher } from "svelte";
  import LabelBean from "./LabelBean.svelte";

  const dispatch = createEventDispatcher();

  export let labels = [];
  export let closeIcon = false;
  export let disabled = false;

  function handleBeanClicked(e) {
    dispatch("labelBeanClicked", e.detail);
  }
</script>

{#each labels as label}
  <LabelBean
    on:labelBeanClicked={handleBeanClicked}
    name={label.name}
    color={label.color}
    id={label.id}
    {closeIcon}
    {disabled}
  />
{/each}
