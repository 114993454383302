<script>
  //TODO: update this component to match the new designs
  import { javascript } from "@codemirror/lang-javascript";
  import Icon from "@iconify/svelte";
  import CodeMirror from "svelte-codemirror-editor";
  import { toast } from "svelte-sonner";
  import {
    RuleSearchType,
    RuleStatus,
  } from "../../lib/interfaces/Rule.interface";
  import CollapsiblePhraseList from "./CollapsiblePhraseList.svelte";
  import Badge from "./WorkflowBoard/Badge.svelte";
  import UnderlineText from "./UnderlineText.svelte";

  export let rule;
  export let isConditionalDialog = false;
  export let isParentConditional = false;

  // List of phrases for exact match
  let exactMatchPhrases; 
  $: (exactMatchPhrases =
    rule && rule.examples && typeof rule.examples === "string"
      ? rule.examples.replaceAll('"', "").split(" OR ")
      : []), [rule];

  // List of included phrases for similarity search
  let includedPhrases 
  $: (includedPhrases =
    rule && rule.examples && typeof rule.examples === "string"
      ? Object.entries(JSON.parse(rule?.query)?.examples)
          .filter(([_, value]) => value > 0) // Filter to determine if phrase is included
          .map(([key, _]) => key)
      : []), [rule];

  // List of excluded phrases for similarity search
  let excludedPhrases;
  $: (excludedPhrases =
    rule && rule.examples && typeof rule.examples === "string"
      ? Object.entries(JSON.parse(rule?.query)?.examples)
          .filter(([_, value]) => value < 0)
          .map(([key, _]) => key)
      : []), [rule]; // Filter to determine if excluded
</script>

<div class={isConditionalDialog ? "min-w-max" : ""}>
  <p class="text-base-content/80 mb-2 text-sm">
    {isConditionalDialog ? "this" : "This"} rule is
    {#if rule?.affirmative_rule}
      <Badge bgColor="#336A1D" textColor="white"
        >{isParentConditional ? RuleStatus.TRUE : RuleStatus.PASSED}</Badge
      >
    {:else}
      <Badge bgColor="#FF4E4E" textColor="white">
        {isParentConditional ? RuleStatus.FALSE : RuleStatus.RISK}
      </Badge>
    {/if}
    if the asset
    {#if rule?.rule_type == RuleSearchType.LEXICAL}
      contains an <UnderlineText
        text="exact match"
        ruleSearchType={RuleSearchType.LEXICAL}
      /> to the following:
      <CollapsiblePhraseList phrases={exactMatchPhrases} />
    {:else if rule?.rule_type == RuleSearchType.TENSOR}
      includes a <UnderlineText
        text="similar term or example"
        ruleSearchType={RuleSearchType.TENSOR}
      /> to the following:
      <CollapsiblePhraseList phrases={includedPhrases} />
      {#if excludedPhrases.length}
        The following phrases are excluded:
        <CollapsiblePhraseList phrases={excludedPhrases} />
      {/if}
    {:else if rule?.rule_type == RuleSearchType.MANUAL}
      has had the user has <UnderlineText
        text="marked"
        ruleSearchType={RuleSearchType.MANUAL}
      /> all subtasks as completed.
    {:else if rule?.rule_type == RuleSearchType.BOOLEAN}
      contains a <UnderlineText
        text="boolean match"
        ruleSearchType={RuleSearchType.BOOLEAN}
      /> to:
    {:else if rule?.rule_type == RuleSearchType.CONTEXTUAL}
      <!-- it is contextually similar to: {JSON.parse(rule.query).context} -->
      contains a match from the <UnderlineText
        text="AI detection"
        ruleSearchType={RuleSearchType.CONTEXTUAL}
      /> from the instructions below.
      {#if Object.entries(JSON.parse(rule?.query)?.examples).map(([key, _]) => key).length}
        Some examples of this are:
        <CollapsiblePhraseList
          phrases={JSON.parse(rule?.query)?.examples.map((ex) => ex.text)}
        />
      {/if}
    {:else if rule?.rule_type == RuleSearchType.IMAGE}
      contains a <UnderlineText
        text="similar feature on the image"
        ruleSearchType={RuleSearchType.IMAGE}
      /> to:
      {#if rule?.query}
        <CollapsiblePhraseList
          phrases={typeof rule?.query === "string"
            ? JSON.parse(rule?.query)?.examples.map((ex) => ex.text)
            : rule?.query?.examples.map((ex) => ex.text)}
        />
      {/if}
    {:else if rule?.rule_type == RuleSearchType.VISUAL}
      there is visual similarity to the following:
    {/if}
  </p>

  {#if rule?.rule_type == RuleSearchType.BOOLEAN}
    <div class="relative">
      <CodeMirror
        value={JSON.parse(rule?.query)?.doc_query}
        editable={false}
        readonly={true}
        lang={javascript()}
        styles={{
          "&": { background: "white", fontSize: "14px" },
          ".cm-gutters": { display: "none" },
        }}
        lineWrapping={true}
      />
      <button
        class="btn btn-square btn-ghost btn-xs absolute right-0 top-0.5"
        on:click={() => {
          navigator.clipboard.writeText(JSON.parse(rule?.query)?.doc_query);
          toast.success("Copied to clipboard");
        }}
      >
        <Icon icon="iconoir:copy" />
      </button>
    </div>
  {/if}
</div>
