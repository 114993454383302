<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher } from "svelte";
  import { toast } from "svelte-sonner";
  import { slide } from "svelte/transition";
  import { fetchGet, fetchPost } from "../../../helpers";
  import { HitColour } from "../../../lib/interfaces/Hit.interface";
  import CommentsView from "../../comments/CommentsView.svelte";
  import AssignUsers from "../AssignUsers.svelte";
  import Comments from "../../comments/Comments.svelte";

  export let hit;
  export let activeAsset;
  export let currentStreamPerm = "none";
  export let asset_rule = {};
  let expanded = false;
  let updating = false;
  let entry = {};
  let checked = hit.colour === HitColour.PASSED ? true : false;
  entry.hit = hit; //compatability layer for Asset Review

  const dispatch = createEventDispatcher();

  async function updateColour(hit, assetId, colour) {
    try {
      const res = await fetchPost(`/hit/${hit.hit_id}/colour`, {
        colour: colour,
        type: "asset_review",
        asset_id: assetId,
        rule_id: hit.rule_id,
        is_manual_task: true,
      });

      if (!res.success) {
        throw new Error(`Error updating status of Hit with Id(${hit.hit_id})`);
      }

      await updateHitComments();
    } catch (e) {
      toast.error("There was an error updating your task's status");
    }
  }

  async function handleClickCheck() {
    if (!updating) {
      updating = true;

      if (checked) {
        await updateColour(hit, activeAsset.id, HitColour.REVIEW);
        hit.colour = HitColour.REVIEW;
      } else {
        await updateColour(hit, activeAsset.id, HitColour.PASSED);
        hit.colour = HitColour.PASSED;
      }

      if (asset_rule.results.every((h) => h.colour === HitColour.PASSED)) {
        dispatch("refresh", HitColour.PASSED);
      } else {
        dispatch("refresh", HitColour.REVIEW);
      }

      checked = hit.colour === HitColour.PASSED ? true : false;
      updating = false;
    }
  }

  let hitComment = "";
  const addHitComment = async () => {
    if (!hitComment || hitComment == "") {
      toast.warning("Please enter a comment");
      return;
    }

    const res = await fetchPost(`/hit/${hit.hit_id}/comment`, {
      text: hitComment,
      hit_id: hit.hit_id,
    });

    if (!res.success) {
      toast.error("Failed to add comment");
      return;
    }

    await updateHitComments();

    hitComment = "";
  };

  const updateHitComments = async () => {
    const res = await fetchGet(`/hit/${hit.hit_id}/comment`);

    if (!res.success) {
      toast.error("Failed to fetch comments");
      return;
    }

    hit.comments = res.comments;
    hit.comments = hit.comments.sort(
      (a, b) => new Date(b.created_date) - new Date(a.created_date),
    );
    hit.comments = [...hit.comments];
  };
  function handleUpdateComments(event) {
    hit.comments = event.detail;
    updateHitComments();
  }
</script>

{#key hit}
  <div class="bg-base-200 rounded border px-4 py-2">
    <div class="flex w-full items-center justify-start gap-2">
      <input
        type="checkbox"
        class="checkbox checkbox-sm border-gray-300 [--chkbg:#c4d4bd] [--chkfg:#336A1D] checked:border-[#336A1D]"
        {checked}
        name={hit.text}
        id={hit.hit_id}
        disabled={currentStreamPerm !== "edit" ? true : false}
        on:click|stopPropagation={async () => {
          await handleClickCheck();
        }}
      />
      <button
        class="flex w-full items-center justify-start gap-2"
        on:click|stopPropagation={() => (expanded = !expanded)}
      >
        <label
          for={hit.text}
          class="text-start text-sm"
          class:line-clamp-4={!expanded}
        >
          {@html hit.text}
        </label>
      </button>
    </div>

    {#if expanded}
      <div class="flex flex-col" transition:slide>
        <div class="divider my-2"></div>
        <div class="mb-2 flex items-center gap-1">
          <AssignUsers
            alignLeft
            hit_id={hit.hit_id}
            assignees={hit.assigned_users}
            asset_type={"hit"}
            disableEditing={currentStreamPerm !== "edit"}
            on:change={async (e) => {
              hit.assigned_users = e.detail;
              hit = hit;

              await updateHitComments();
            }}
          />
        </div>

        <Comments
          comments={hit.comments}
          apiEndpoint={`/hit/${hit.hit_id}/comment`}
          type="asset"
          on:updateComments={handleUpdateComments}
        />
      </div>
    {/if}
  </div>
{/key}
