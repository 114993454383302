<script>
  import Icon from "@iconify/svelte";
  import { link } from "svelte-spa-router";
  import { fetchDelete, fetchPost } from "../../../helpers";
  import { saveDetails } from "../../../lib/utils/AssetReviewUtils";
  import { currentUser } from "../../../stores";
  import LabelSearch from "../../Labels/LabelSearch.svelte";
  import ListOfBeans from "../../Labels/ListOfBeans.svelte";
  import ReportAccess from "../../Reports/ReportAccess.svelte";

  export let reportLoaded;
  export let report;
  export let reportId;
  export let currentStreamPerm;
  export let assets;
  export let accessKeyer;
  export let allLabels;
  export let labels;
  export let isInitialised;

  /**
   *
   * @param {*} event
   */
  export async function labelSelected(event) {
    const selectedLabel = event.detail;

    if (!labels.some((label) => label.id === selectedLabel.id)) {
      await fetchPost(`/label/assign/${selectedLabel.id}`, {
        report_id: reportId,
      });

      labels = [...labels, selectedLabel];
    }
  }

  /**
   *
   * @param {*} event
   */
  export async function deleteLabel(event) {
    const label = event.detail;

    await fetchDelete(`/label/assign/${label.id}`, { report_id: reportId });

    labels = labels.filter((l) => l.id !== label.id);
  }
</script>

<div class="flex items-center gap-4">
  {#if reportLoaded && report}
    <div class="tooltip min-w-0" data-tip={report.name}>
      <h1 class="truncate text-3xl font-semibold">
        {report.name}
      </h1>
    </div>

    {#if ["upload", "edit"].includes(currentStreamPerm)}
      <div class="dropdown">
        <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
        <label tabindex="0" class="btn btn-square btn-ghost btn-sm" for="">
          <Icon icon="iconoir:more-horiz" class="text-xl" />
        </label>
        <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
        <ul
          tabindex="0"
          class="menu dropdown-content bg-base-100 z-10 m-1 w-52 rounded border p-2"
        >
          <li>
            <a href="#/review/edit/{reportId}">
              <Icon icon="iconoir:edit-pencil" class="text-xl" />
              Edit Folder Settings
            </a>
          </li>
          <li class:disabled={assets.length <= 0}>
            <!-- svelte-ignore missing-declaration -->
            <button
              disabled={assets.length <= 0}
              on:click={() => data_export_modal.showModal()}
            >
              <Icon icon="iconoir:download" class="text-xl" />
              Export Data
            </button>
          </li>
          <li class:disabled={currentStreamPerm !== "edit"}>
            <!-- svelte-ignore missing-declaration -->
            <button
              on:click={() => confirmModal.showModal()}
              disabled={currentStreamPerm !== "edit"}
            >
              <Icon icon="iconoir:trash" class="text-xl" /> Delete Folder
            </button>
          </li>
          {#each [{ name: "Uploads", icon: "cloud-upload" }, { name: "Archived", icon: "archive" }] as e}
            <li
              class:hidden={report.status === e.name}
              class:disabled={currentStreamPerm !== "edit"}
            >
              <button
                disabled={currentStreamPerm !== "edit"}
                on:click={async () => {
                  report.status = e.name;
                  isInitialised = await saveDetails(
                    isInitialised,
                    report,
                    reportId,
                    $currentUser.id,
                  );
                }}
              >
                <Icon icon="iconoir:{e.icon}" class="text-xl" />
                Move to {e.name}
              </button>
            </li>
          {/each}
        </ul>
      </div>
    {/if}

    <div class="ml-auto flex items-center gap-2">
      <!--TODO: update this to support upload permissions-->
      <ReportAccess
        canEditPermissions={currentStreamPerm === "edit"}
        reportStatus={report.status}
        bind:loading={accessKeyer}
        assetView={true}
      />
    </div>
  {:else}
    <div class="text-center">
      <div class="loading loading-lg m-auto" />
    </div>
  {/if}
</div>

{#if reportLoaded && report}
  <p class="text-base-content/70 pb-1 pl-0.5 text-sm font-light">
    {report.description.trim() === ""
      ? "Stream Description"
      : report.description}
  </p>
{/if}

<div class="flex items-end gap-4">
  <div class="flex flex-wrap items-center gap-2">
    {#if currentStreamPerm === "edit"}
      <LabelSearch
        {allLabels}
        selectedLabels={labels}
        on:labelSelected={labelSelected}
        on:labelUnselected={deleteLabel}
      />
    {/if}
    {#if labels && labels.length > 0}
      <ListOfBeans
        {labels}
        on:labelBeanClicked={deleteLabel}
        closeIcon={currentStreamPerm == "edit"}
      />
    {/if}
  </div>

  <div class="ml-auto flex gap-2">
    {#if currentStreamPerm === "edit"}
      <a
        class="btn btn-sm"
        href="/review/edit/{reportId}?step=checklist"
        use:link
      >
        Edit Checklists
      </a>
    {:else}
      <button class="btn btn-sm" disabled>Edit Checklists</button>
    {/if}

    {#if currentStreamPerm === "view"}
      <button class="btn btn-primary btn-sm" disabled>Upload Assets</button>
    {:else}
      <a
        class="btn btn-primary btn-sm"
        href="/review/edit/{reportId}?step=upload"
        use:link
      >
        Upload Assets
      </a>
    {/if}
  </div>
</div>
