<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher } from "svelte";
  import { toast } from "svelte-sonner";
  import { slide } from "svelte/transition";
  import {
    AssetRuleTypeOptions,
    ConditionalRuleType,
  } from "../../lib/interfaces/Rule.interface";
  import LogoLoader from "../LogoLoader.svelte";
  import Modal from "../Modals/Modal.svelte";
  import AssetRuleBuilder from "./AssetRuleBuilder.svelte";
  import AssetRuleTypeSelector from "./AssetRuleTypeSelector.svelte";
  import { isEmpty, isEmptyObj } from "../../lib/utils/GenericUtils";
  import { isValidRule } from "../../lib/utils/RuleUtils";

  const dispatch = createEventDispatcher();

  export let conditionalRuleData;
  export let editingSavedRule = false;
  export let loading = false;
  export let isViewing = false;

  let blockSave = true;
  let ifRule = getRuleTemplate();
  let thenRules = [getRuleTemplate(true, 1)];

  function getRuleTemplate(isChild = false, index = 1) {
    return {
      search_type: undefined,
      name: isChild ? `Then Rule ${index}` : "If Condition",
      description: "",
      examples: [{ text: "", score: 1 }],
      operator: "OR",
      boolean: "",
      doc_query: "",
      section_query: "",
      affirmative_rule: true,
      remediation_step: "",
      limit: 5,
      search_sources: {
        DOCUMENT: true,
        IMAGE_TEXT: false,
        IMAGE_FEATURE: false,
      },
      index: "vector-db-index",
      strong_match_threshold: 0.95,
      no_match_threshold: 0.92,
      rule_type: "asset",
      lexical: "",
      conditional_id: null,
      condition_type: isChild
        ? ConditionalRuleType.THEN
        : ConditionalRuleType.IF,
      sequence_order: isChild ? ifRule.sequence_order + 1 : 0,
    };
  }

  function addThenRule() {
    if (conditionalRuleData) {
      toast.error(
        "You cannot add a new Then Rule to an existing Conditional Rule",
      );
      return;
    }

    thenRules = [...thenRules, getRuleTemplate(true, thenRules.length + 1)];
  }

  function removeThenRule(index) {
    if (index === 0) {
      toast.error("You cannot remove the first Then Rule");
      return;
    }

    thenRules = thenRules.filter((_, i) => i !== index);
  }

  function saveConditionalRule() {
    validateCanSave();

    if (!blockSave) {
      dispatch("saveConditionalRule", { ifRule, thenRules });
    } else {
      toast.error(
        "Cannot save conditional rule. Some sub-rules are missing required fields.",
      );
    }
  }

  if (editingSavedRule && conditionalRuleData) {
    conditionalRuleData = {
      ...conditionalRuleData,
      editingSavedRule: true,
      search_type: conditionalRuleData.rule_type,

      children: conditionalRuleData.children.map((r) => ({
        ...r,
        editingSavedRule: true,
        search_type: r.rule_type,
      })),
    };

    ifRule = conditionalRuleData;
    thenRules = conditionalRuleData.children;
    blockSave = false;
  } else if (conditionalRuleData) {
    ifRule = conditionalRuleData.ifRule;
    thenRules = conditionalRuleData.thenRules;
    blockSave = false;

    // if duplicate rule
    if (!conditionalRuleData.ifRule.id) conditionalRuleData = undefined;
  }

  function validateCanSave() {
    if ([ifRule, ...thenRules].some((r) => !isValidRule(r, r.search_type)))
      blockSave = true;
    else blockSave = false;

    blockSave = blockSave;
  }

  $: validateCanSave(), [ifRule, thenRules];
</script>

{#if loading}
  <div
    class="bg-base-100/50 fixed inset-0 z-20 flex items-center justify-center backdrop-blur-sm"
  >
    <h1 class="flex items-center gap-4 text-4xl font-semibold">
      <LogoLoader size="2.5rem" />
      Saving Rule
    </h1>
  </div>
{/if}

<!-- svelte-ignore missing-declaration -->

<div class="flex w-full flex-col gap-6">
  <div class="flex flex-col">
    <div class="flex gap-4">
      <button class="btn btn-xs btn-primary w-16">IF</button>
      {#if ifRule.search_type}
        {@const rst = AssetRuleTypeOptions.find(
          (i) => i.id === ifRule.search_type,
        )}
        <button
          class="btn btn-xs btn-primary"
          disabled={isViewing}
          on:click={() => confirmIfRuleTypeChange?.showModal()}
        >
          <Icon icon={rst.icon} />
          {rst.name}
          <Icon icon="iconoir:nav-arrow-down" />
        </button>
      {:else}
        <div class="flex flex-col gap-2" in:slide>
          <AssetRuleTypeSelector
            bind:value={ifRule.search_type}
            bind:rule={ifRule}
            hideConditionalRule={true}
            isConditionalRule={true}
            isParentConditional={true}
          />
        </div>
      {/if}

      <Modal
        modalId="confirmIfRuleTypeChange"
        bottomButtons={{
          show: true,
          primaryText: "Switch rule type",
          secondaryText: "Cancel",
          primaryAction: () => {
            ifRule = getRuleTemplate();
            confirmIfRuleTypeChange.close();
          },
          secondaryAction: () => confirmIfRuleTypeChange.close(),
        }}
        containerClasses="flex flex-col gap-4 items-center"
        cornerCloseButton={false}
      >
        <span
          class="bg-error flex h-12 w-12 items-center justify-center rounded-full"
        >
          <Icon icon="iconoir:warning-triangle" class="text-xl text-white" />
        </span>

        <h3 class="font-semibold">
          Are you sure you want to switch rule type?
        </h3>
        <p class="text-base-content/70 text-center text-sm">
          If you switch rule type,
          <span class="font-semibold">you will lose your progress</span>
          with the current rule type.
        </p>
      </Modal>
    </div>

    {#if ifRule.search_type}
      <div class="flex w-full gap-4" transition:slide>
        <div class="flex w-16 shrink-0 flex-col items-center py-4">
          <div class="border-r-primary h-full w-0 border-r" />
          <Icon
            icon="iconoir:arrow-down"
            class="text-primary shrink-0 -translate-y-0.5"
          />
        </div>
        <AssetRuleBuilder
          bind:editingSavedRule={ifRule.editingSavedRule}
          bind:rule={ifRule}
          {isViewing}
          isParentConditional={true}
          index={0}
          on:back={() => confirmIfRuleTypeChange?.showModal()}
          on:removeRule={() => confirmIfRuleTypeChange?.showModal()}
          on:saveRule={() =>
            toast.info(
              `All rules are saved together on clicking the "Save Conditional Rule" button in the bottom.`,
            )}
          on:changeRuleType={() => confirmIfRuleTypeChange?.showModal()}
        />
      </div>
    {/if}
  </div>

  {#each thenRules as thenRule, index}
    <div class="flex flex-col">
      <div class="flex gap-4">
        <button class="btn btn-xs btn-success w-16"> THEN {index + 1}</button>
        {#if thenRule.search_type}
          {@const rst = AssetRuleTypeOptions.find(
            (i) => i.id === thenRule.search_type,
          )}
          <button
            class="btn btn-xs btn-success"
            disabled={isViewing}
            on:click={() =>
              document
                .querySelector(`#confirmThenRuleTypeChange-${index}`)
                ?.showModal()}
          >
            <Icon icon={rst.icon} />
            {rst.name}
            <Icon icon="iconoir:nav-arrow-down" />
          </button>
        {:else}
          <div class="flex flex-col gap-2" in:slide>
            <AssetRuleTypeSelector
              bind:value={thenRule.search_type}
              bind:rule={thenRule}
              hideConditionalRule={true}
              isConditionalRule={true}
            />
          </div>
        {/if}

        <Modal
          modalId="confirmThenRuleTypeChange-{index}"
          bottomButtons={{
            show: true,
            primaryText: "Switch rule type",
            secondaryText: "Cancel",
            primaryAction: () => {
              thenRule = getRuleTemplate(true, index + 1);
              document
                .querySelector(`#confirmThenRuleTypeChange-${index}`)
                .close();
            },
            secondaryAction: () =>
              document
                .querySelector(`#confirmThenRuleTypeChange-${index}`)
                .close(),
          }}
          containerClasses="flex flex-col gap-4 items-center"
          cornerCloseButton={false}
        >
          <span
            class="bg-error flex h-12 w-12 items-center justify-center rounded-full"
          >
            <Icon icon="iconoir:warning-triangle" class="text-xl text-white" />
          </span>

          <h3 class="font-semibold">
            Are you sure you want to switch rule type?
          </h3>
          <p class="text-base-content/70 text-center text-sm">
            If you switch rule type,
            <span class="font-semibold">you will lose your progress</span>
            with the current rule type.
          </p>
        </Modal>
      </div>

      {#if thenRule.search_type}
        <div class="flex w-full gap-4" transition:slide>
          <div class="flex w-16 shrink-0 flex-col items-center py-4">
            <div
              class="border-r-success w-0 border-r
                {thenRules.length !== index + 1 ? 'h-full' : 'h-1/2'}"
            />
            {#if thenRules.length !== index + 1}
              <Icon
                icon="iconoir:arrow-down"
                class="text-success shrink-0 -translate-y-0.5"
              />
            {:else}
              <Icon
                icon="iconoir:arrow-right"
                class="text-success shrink-0 -translate-y-2 translate-x-1.5"
              />
            {/if}
          </div>
          <AssetRuleBuilder
            bind:editingSavedRule={thenRule.editingSavedRule}
            bind:rule={thenRule}
            {isViewing}
            {index}
            isChildConditional={true}
            on:back={() =>
              document
                .querySelector(`#confirmThenRuleTypeChange-${index}`)
                ?.showModal()}
            on:removeRule={() => removeThenRule(index)}
            on:saveRule={() =>
              toast.info(
                `All rules are saved together on clicking the "Save Conditional Rule" button in the bottom.`,
              )}
            on:click={() => console.log("element clicked")}
            on:changeRuleType={() =>
              document
                .querySelector(`#confirmThenRuleTypeChange-${index}`)
                ?.showModal()}
          />
        </div>
      {/if}
    </div>
  {/each}

  <div class="mt-4 flex flex-col gap-2">
    <div class="flex gap-2">
      <button
        class="btn btn-outline btn-success btn-sm"
        disabled={isViewing}
        on:click={addThenRule}
      >
        Add Then Rule
      </button>
      {#if blockSave}
        <div
          class="tooltip"
          data-tip="Cannot save conditional rule. Some sub-rules are missing required fields."
        >
          <button class="btn btn-primary btn-sm" disabled>
            Save Conditional Rule
          </button>
        </div>
      {:else}
        <button
          class="btn btn-sm btn-primary"
          disabled={isViewing || blockSave}
          on:click={saveConditionalRule}
        >
          Save Conditional Rule
        </button>
      {/if}
    </div>
    <div class="flex">
      <button
        class="btn btn-outline btn-error btn-sm"
        on:click={() => dispatch("removeRule")}
      >
        <Icon icon="iconoir:trash" class="text-lg" />
        {isViewing ? "Exit" : "Cancel"}
      </button>
    </div>
  </div>
</div>
