<script lang="ts">
  import Icon from "@iconify/svelte";
  import Modal from "../Modals/Modal.svelte";

  export let hit;
</script>

<Modal modalId="hit_info_modal_{hit.hit_id}">
  <h2 class="max-w-m truncate text-xl font-semibold">
    ID: #{hit.id}
  </h2>
</Modal>
