<script>
  /**
   * @todo Update this component to match the new designs
   */
  import { javascript } from "@codemirror/lang-javascript";
  // TODO: construct LanguageSupport object from solr's parser for correct syntax checking
  // import { solr } from "@codemirror/legacy-modes/mode/solr";
  import Icon from "@iconify/svelte";
  import { createEventDispatcher } from "svelte";
  import CodeMirror from "svelte-codemirror-editor";
  import { toast } from "svelte-sonner";
  import { get } from "svelte/store";
  import { slide } from "svelte/transition";
  import {
    RuleSearchType,
    RuleStatus,
  } from "../../lib/interfaces/Rule.interface";
  import { deepCopy, isEmpty } from "../../lib/utils/GenericUtils";
  import {
    convertBoolToBuilder,
    convertBuilderToBoolean,
  } from "../../ruleBuilderConverter";
  import { org_name } from "../../stores";
  import RuleBuilder from "../RuleBuilder/RuleBuilder.svelte";
  import Select from "../Select.svelte";
  import { RuleDataType } from "./../../lib/interfaces/Rule.interface.js";
  import ContextualRuleBuilder from "./ContextualRuleBuilder.svelte";
  import ExactRuleBuilder from "./ExactRuleBuilder.svelte";
  import ImageRuleBuilder from "./ImageRuleBuilder.svelte";
  import ManualRuleBuilder from "./ManualRuleBuilder.svelte";
  import SimilarityRuleBuilder from "./SimilarityRuleBuilder.svelte";
  import VisualRuleBuilder from "./VisualRuleBuilder.svelte";
  import { invalidInputFocus } from "../../lib/Focus";
  import UnderlineText from "./UnderlineText.svelte";

  const dispatch = createEventDispatcher();

  export let rule;
  export let index = 0;
  export let savingRule = false;
  export let isViewing = false;
  export let editingSavedRule = false;
  export let saveAndCreateButton = false;
  export let saveAndCreateMode = false; // Toggle state to switch between "Save" and "Save and Create"
  export let isParentConditional = false;
  export let isChildConditional = false;

  let advanced = false;
  let generateClicked = false;
  let dataType = RuleDataType.BUILDER;
  let builder = deepCopy(rule.builder);
  let builderCopy;
  let noMatchNumericInput = rule.no_match_threshold;
  let strongMatchNumericInput = rule.strong_match_threshold;
  let abandonedInputs = new Map();

  function handleKeyDownOnThresholdField(event, changedThreshold) {
    if (event.key === "Enter") {
      if (changedThreshold === "no_match") {
        handleNoMatchThresholdChange();
      } else {
        handleStrongMatchThresholdChange();
      }
    }
  }

  function validateInput(value) {
    console.log(value);
    if (isNaN(value) || value < 0) {
      return 0;
    }

    return Math.min(value, 1);
  }

  function handleNoMatchThresholdChange() {
    noMatchNumericInput = validateInput(noMatchNumericInput);
    rule.no_match_threshold = noMatchNumericInput;
    adjustThresholds(rule, "no_match");
  }

  function handleStrongMatchThresholdChange() {
    strongMatchNumericInput = validateInput(strongMatchNumericInput);
    rule.strong_match_threshold = strongMatchNumericInput;
    adjustThresholds(rule, "strong_match");
  }

  function handleSaveClick() {
    dispatch("saveRule", { index });
  }

  //keep the min slider below the max one
  function adjustThresholds(rule, changedThreshold) {
    if (
      changedThreshold === "no_match" &&
      rule.no_match_threshold > rule.strong_match_threshold
    ) {
      rule.strong_match_threshold = rule.no_match_threshold;
    } else if (
      changedThreshold === "strong_match" &&
      rule.strong_match_threshold < rule.no_match_threshold
    ) {
      rule.no_match_threshold = rule.strong_match_threshold;
    }

    noMatchNumericInput = rule.no_match_threshold;
    strongMatchNumericInput = rule.strong_match_threshold;

    rule = rule; // Trigger reactivity
  }

  let query;

  // Add new rule types here!
  // TODO: image rule types
  if (editingSavedRule || isViewing) {
    editingSavedRule = false;
    rule.search_type = rule.rule_type; // THIS IS A HACK - see https://github.com/HaastAI/svelte-report-creator-v1/issues/2258

    if (rule.rule_type === RuleSearchType.BOOLEAN) {
      rule.builder = convertBoolToBuilder(rule.boolean);
      builder = deepCopy(rule.builder);
    } else if (rule.rule_type === RuleSearchType.LEXICAL) {
      rule.lexical = rule.examples;
    } else if (
      rule.rule_type === RuleSearchType.TENSOR ||
      rule.rule_type === RuleSearchType.CONTEXTUAL ||
      rule.rule_type === RuleSearchType.IMAGE
    ) {
      if (rule.query && typeof rule.query === "string") {
        rule.query = JSON.parse(rule.query);
      }

      query = rule.query;
      rule.examples = Object.entries(query.examples).map((e) => ({
        text: e[0],
        score: e[1],
      }));
      rule.context = query.context;
      rule.definitions = query.definitions;
      rule.query = query;
      rule.section_query = query?.section_query ?? "";
    }
  }

  const converter = () => {
    const dt =
      dataType === RuleDataType.BUILDER
        ? RuleDataType.BOOLEAN
        : RuleDataType.BUILDER;

    try {
      if (dt === RuleDataType.BOOLEAN && !isEmpty(rule.builder)) {
        rule.boolean = convertBuilderToBoolean(rule.builder);
        dataType = dt;
      } else if (dt === RuleDataType.BUILDER && !isEmpty(rule.boolean)) {
        rule.builder = convertBoolToBuilder(rule.boolean);
        builder = builder;
        dataType = dt;
      }
    } catch (error) {
      toast.warning("There was an error while converting builder to boolean");
    }
  };

  // Function to return appropriate color class based on the value
  function getColorClasses(value) {
    return value
      ? "placeholder:text-success border-success"
      : "placeholder:text-error border-error";
  }

  function invalidManualRuleCheck(rule) {
    let taskList = [];

    if (rule?.examples.length > 0 && typeof rule?.examples[0] !== "object") {
      taskList = rule.examples.split(" AND ").map((e) => e.replaceAll('"', ""));
    }

    if (taskList.length === 0 || taskList.every((task) => isEmpty(task))) {
      return true;
    }

    return false;
  }

  $: rule.examples =
    rule.example_string !== undefined && rule.example_string.includes(";")
      ? rule.example_string.split(";").map((x) => {
          return { text: x, score: 1 };
        })
      : rule.examples;

  function handleBuilderOrBuilderChange() {
    if (dataType === RuleDataType.BUILDER && builder) {
      builderCopy = deepCopy(builder);

      if (builderCopy && builderCopy.length) {
        const booleanCopy = convertBuilderToBoolean(builderCopy);
        rule.builder = deepCopy(builder);
        rule.boolean = deepCopy(booleanCopy);
        rule.queryBoolean = deepCopy(rule.boolean);
        rule = rule;
      }
    } else if (dataType === RuleDataType.BOOLEAN && rule.boolean) {
      const booleanCopy = deepCopy(rule.boolean);

      if (booleanCopy && booleanCopy.length) {
        builder = convertBoolToBuilder(booleanCopy);
        rule.builder = deepCopy(builder);
        rule.boolean = deepCopy(booleanCopy);
        rule.queryBoolean = deepCopy(rule.boolean);
        rule = rule;
      }
    }
  }

  function handleAbandonedFocus(id, isAbandoned) {
    abandonedInputs.set(id, isAbandoned);
    abandonedInputs = abandonedInputs;
  }

  $: handleBuilderOrBuilderChange(), [builder, rule?.boolean];
  $: isInvalidRuleInput =
    !rule.name ||
    !rule.description ||
    (rule.search_type === RuleSearchType.CONTEXTUAL && !generateClicked) ||
    (rule.search_type === RuleSearchType.TENSOR && // Checks if similarity search are empty
      rule.examples.some((item) => !item.text)) ||
    (rule.search_type === RuleSearchType.LEXICAL && // Checks if exact match phrases are empty
      rule.lexical.replace(/["']/g, "").trim().length === 0);
</script>

<article class="my-4 w-full rounded border p-6">
  <div class="form-control">
    <!-- Rule Name and Description -->
    <div class="flex flex-wrap gap-4">
      <div class="form-control grow">
        <label class="label" for="rule_name_{index}">
          <span class="label-text">Rule name</span>
        </label>
        <input
          class="input input-bordered w-full"
          id="rule_name_{index}"
          placeholder="Add a rule name"
          bind:value={rule.name}
          required
          maxlength="63"
          use:invalidInputFocus={{
            id: `rule_name_${index}`,
            onChange: handleAbandonedFocus,
            validateEmpty: true,
          }}
        />
        {#if abandonedInputs.get(`rule_name_${index}`)}
          <p class="text-error ml-1 mt-1 text-xs" in:slide out:slide>
            *Rule name is required.
          </p>
        {/if}
      </div>
      <div class="form-control mb-2 grow-[3]">
        <label class="label" for="rule_description_{index}">
          <span class="label-text">Rule Description</span>
        </label>
        <textarea
          class="textarea textarea-bordered w-full content-center ![field-sizing:fixed]"
          id="rule_description_{index}"
          placeholder="Add a rule description, providing more detail about the particular issues this rule is meant to identify"
          bind:value={rule.description}
          rows="1"
          required
          use:invalidInputFocus={{
            id: `rule_description_${index}`,
            onChange: handleAbandonedFocus,
            validateEmpty: true,
          }}
        />
        {#if abandonedInputs.get(`rule_description_${index}`)}
          <p class="text-error ml-1 mt-1 text-xs" in:slide out:slide>
            *Rule description is required.
          </p>
        {/if}
      </div>
    </div>

    <!-- Rule Configuration -->
    <div class="form-control my-4 gap-2 rounded border p-4">
      {#if rule.search_type !== RuleSearchType.CONTEXTUAL}
        <div class="flex flex-wrap items-center gap-2 text-sm">
          This rule is marked as
          {#if rule.search_type !== RuleSearchType.MANUAL}
            <Select
              bind:selectedValue={rule.affirmative_rule}
              items={isParentConditional
                ? [
                    { value: true, label: RuleStatus.TRUE },
                    { value: false, label: RuleStatus.FALSE },
                  ]
                : [
                    { value: true, label: RuleStatus.PASSED },
                    { value: false, label: RuleStatus.RISK },
                  ]}
              inputClasses="!w-20 {getColorClasses(rule.affirmative_rule)}"
            />
            {#if rule.search_type !== RuleSearchType.CONTEXTUAL}
            if the asset
            {/if}
            {#if rule.search_type == RuleSearchType.LEXICAL}
              contains an <UnderlineText text="exact match" ruleSearchType={RuleSearchType.LEXICAL}/> to the following:
            {:else if rule.search_type == RuleSearchType.TENSOR}
              includes a <UnderlineText text="similar term or example" ruleSearchType={RuleSearchType.TENSOR}/> to the following:
            {:else if rule.search_type == RuleSearchType.BOOLEAN}
              contains a <UnderlineText text="boolean match" ruleSearchType={RuleSearchType.BOOLEAN}/> to:
            {:else if rule.search_type === RuleSearchType.CONTEXTUAL}
              if the <UnderlineText text="AI detects your instructions" ruleSearchType={RuleSearchType.CONTEXTUAL}/> have been met:
            {:else if rule.search_type === RuleSearchType.IMAGE}
              contains a <UnderlineText text="similar feature on the image" ruleSearchType={RuleSearchType.IMAGE}/> to:
            {:else if rule.search_type === RuleSearchType.VISUAL}
              contains a <UnderlineText text="similar feature on the image" ruleSearchType={RuleSearchType.VISUAL}/> to:
            {/if}
          {:else}
            <div
              class="border-success text-success input-sm w-20 rounded-lg border"
            >
              {isParentConditional ? RuleStatus.TRUE : RuleStatus.PASSED}
            </div>
            if a user has <UnderlineText text="marked" ruleSearchType={RuleSearchType.MANUAL}/> the task as complete.
          {/if}
        </div>
      {/if}

      {#if rule.search_type == RuleSearchType.TENSOR}
        <SimilarityRuleBuilder bind:rule excludedMatches={false} {isViewing} />
        <div class="flex min-w-fit flex-wrap items-center gap-2 text-sm">
          This rule is marked as
          <Select
            bind:selectedValue={rule.affirmative_rule}
            items={isParentConditional
              ? [
                  { value: true, label: RuleStatus.FALSE },
                  { value: false, label: RuleStatus.TRUE },
                ]
              : [
                  { value: true, label: RuleStatus.RISK },
                  { value: false, label: RuleStatus.PASSED },
                ]}
            inputClasses="!w-20 {getColorClasses(!rule.affirmative_rule)}"
          />
          if there are no matches.
          <p class="text-sm text-gray-500">
            Note: The rule is marked "{RuleStatus.REVIEW}" if the Similarity Search does not
            detect any strong matches and detects one or more possible matches.
          </p>
        </div>

        <hr class="my-1" />

        <div class="flex min-w-fit flex-wrap items-center gap-2 text-sm">
          The following words and phrases will be excluded from the similarity match:
        </div>
        <SimilarityRuleBuilder bind:rule excludedMatches={true} {isViewing} />
      {:else if rule.search_type == RuleSearchType.LEXICAL}
        <ExactRuleBuilder {index} bind:rule {isViewing} />
      {:else if rule.search_type == RuleSearchType.BOOLEAN}
        {#if dataType === RuleDataType.BOOLEAN}
          <CodeMirror
            bind:value={rule.boolean}
            editable={!isViewing}
            lang={javascript()}
            styles={{
              "&": { fontSize: "14px" },
              ".cm-gutters": { display: "none" },
            }}
            lineWrapping={true}
          />
        {:else}
          <RuleBuilder bind:query={builder} {isViewing} searchModule="pre-ex" />
        {/if}
      {:else if rule.search_type == RuleSearchType.MANUAL}
        <ManualRuleBuilder {index} bind:rule {isViewing} />
        <!-- {#if rule.queryBoolean}
          <CodeMirror
            value={rule.queryBoolean}
            editable={false}
            readonly={true}
            lang={javascript()}
            styles={{
              "&": { background: "white", fontSize: "14px" },
              ".cm-gutters": { display: "none" },
            }}
            lineWrapping={true}
          />
        {/if} -->
      {:else if rule.search_type === RuleSearchType.CONTEXTUAL}
        <ContextualRuleBuilder
          {index}
          bind:rule
          bind:isParentConditional
          {getColorClasses}
          {isViewing}
          bind:generateClicked
        />
      {:else if rule.search_type === RuleSearchType.IMAGE}
        <ImageRuleBuilder {index} bind:rule {isViewing} />
      {:else if rule.search_type === RuleSearchType.VISUAL}
        <VisualRuleBuilder {index} bind:rule {isViewing} />
      {/if}
      <div class="flex min-w-fit flex-wrap items-center gap-2 text-sm">
        {#if rule.search_type !== RuleSearchType.TENSOR && rule.search_type !== RuleSearchType.CONTEXTUAL}
          This rule is marked as
          {#if rule.search_type !== RuleSearchType.MANUAL}
            <Select
              bind:selectedValue={rule.affirmative_rule}
              items={isParentConditional
                ? [
                    { value: true, label: RuleStatus.FALSE },
                    { value: false, label: RuleStatus.TRUE },
                  ]
                : [
                    { value: true, label: RuleStatus.RISK },
                    { value: false, label: RuleStatus.PASSED },
                  ]}
              inputClasses="!w-20 {getColorClasses(!rule.affirmative_rule)}"
            />
              {#if rule.search_type === RuleSearchType.CONTEXTUAL}
                if your instructions have not been met.
              {:else}
                if there are no matches.
              {/if}
          {:else}
            <div class="border-warning text-warning input-sm rounded-lg border">
              {RuleStatus.REVIEW}
            </div>
            if no user has marked the task as complete.
          {/if}
        {/if}
      </div>
      <!-- <div class="flex items-center">
        <label class="label cursor-pointer flex items-center gap-2">
          <span class="label-text text-sm">Critical Rule</span> 
          <input type="checkbox" class="toggle" />
        </label>
      </div> -->
    </div>

    <!-- Action Buttons -->
    <div class="mt-4 flex w-full justify-between">
      <div class="flex gap-2">
        {#if isViewing}
          <button
            class="btn btn-outline btn-error btn-sm"
            on:click={() => dispatch("removeRule")}
          >
            Exit
          </button>
        {:else}
          {#if !isParentConditional}
            <button
              class="btn btn-outline btn-error btn-sm"
              on:click={() => dispatch("removeRule")}
            >
              <Icon icon="iconoir:trash" class="text-lg" />
              {isViewing ? "Exit" : isChildConditional ? "Delete" : "Cancel"}
            </button>
          {/if}
          <button
            class="btn btn-outline btn-primary btn-sm"
            on:click={() => dispatch("changeRuleType", index)}
          >
            Change Rule Type
          </button>
        {/if}
        {#if !isChildConditional && !isParentConditional}
          {#if isInvalidRuleInput}
            <div
              class="tooltip"
              data-tip="Make sure Rule has a name, description."
            >
              <button class="btn btn-primary btn-sm" disabled>
                <Icon icon="iconoir:save-floppy-disk" class="text-lg" /> Save Rule
              </button>
            </div>
            {#if saveAndCreateButton}
              <label
                class="flex cursor-pointer items-center"
                title="Save and create new rules"
              >
                <input
                  type="checkbox"
                  class="toggle toggle-sm toggle-primary"
                  bind:checked={saveAndCreateMode}
                />
                <span class="ml-2 text-sm font-light">Save and create more</span
                >
              </label>
            {/if}
          {:else if rule.search_type === RuleSearchType.MANUAL && invalidManualRuleCheck(rule)}
            <div
              class="tooltip"
              data-tip="There must be at least one sub-task for a manual task."
            >
              <button class="btn btn-primary btn-sm" disabled>
                <Icon icon="iconoir:save-floppy-disk" class="text-lg" /> Save Rule
              </button>
            </div>
            {#if saveAndCreateButton}
              <label
                class="flex cursor-pointer items-center"
                title="Save and create new rules"
              >
                <input
                  type="checkbox"
                  class="toggle toggle-sm toggle-primary"
                  bind:checked={saveAndCreateMode}
                />
                <span class="text-base-content/70 ml-2 text-sm font-light"
                  >Save and create more</span
                >
              </label>
            {/if}
          {:else if !isViewing}
            <button
              class="btn btn-primary btn-sm"
              on:click={handleSaveClick}
              disabled={savingRule}
            >
              {#if savingRule}
                <div class="loading loading-sm" />
              {:else}
                <Icon icon="iconoir:save-floppy-disk" class="text-lg" />
              {/if}
              Save Rule
            </button>
            {#if saveAndCreateButton}
              <label
                class="flex cursor-pointer items-center"
                title="Save and create new rules"
              >
                <input
                  type="checkbox"
                  class="toggle toggle-sm toggle-primary"
                  bind:checked={saveAndCreateMode}
                />
                <span class="ml-2 text-sm font-light">Save and create more</span
                >
              </label>
            {/if}
          {/if}
        {/if}
      </div>

      <button
        class="btn btn-outline btn-primary btn-sm"
        on:click={() => (advanced = !advanced)}
      >
        <Icon icon="iconoir:more-vert" />
        {advanced ? "Hide" : "Show"} Advanced Options
      </button>
    </div>
    {#if advanced}
      <div class="mt-4 flex flex-col gap-4" transition:slide>
        <div class="text-xl font-medium">Advanced Settings</div>
        <div class="form-control mb-2 grow-[3]">
          <label class="label" for="remediation_step_{index}">
            <span class="label-text">Remediation Step</span>
          </label>
          <textarea
            class="textarea textarea-bordered w-full"
            id="remediation_step_{index}"
            placeholder="Add a remediation step, providing more detail on how to fix a flagged issue"
            bind:value={rule.remediation_step}
            rows="1"
            required
          />
        </div>

        <div class="flex gap-2">
          <div class="form-control w-full">
            <label class="label" for={`doc_query_${index}`}>
              <span class="label-text">Document Query</span>
            </label>
            <input
              id={`doc_query_${index}`}
              type="text"
              class="input input-bordered"
              bind:value={rule.doc_query}
              placeholder="Enter document query"
              disabled={isViewing}
            />
          </div>
          <div class="form-control w-full">
            <label class="label" for={`section_query_${index}`}>
              <span class="label-text">Section Query</span>
            </label>
            <input
              id={`section_query_${index}`}
              type="text"
              class="input input-bordered"
              bind:value={rule.section_query}
              placeholder="Enter section query"
              disabled={isViewing}
            />
          </div>
          {#if get(org_name) === "testbench" || get(org_name) === "haast"}
            {#if rule.search_type == RuleSearchType.TENSOR}
              <div class="form-control w-full">
                <label class="label" for={`semantic_split_${index}`}>
                  <span class="label-text">Multiple Semantic Queries</span>
                </label>
                <input
                  id={`semantic_split_${index}`}
                  type="text"
                  class="input input-bordered"
                  bind:value={rule.example_string}
                  placeholder="Enter semantic queries separated by semicolons"
                />
              </div>
            {:else if rule.search_type == RuleSearchType.LEXICAL}
              <div class="form-control w-full">
                <label class="label" for={`exact_split_${index}`}>
                  <span class="label-text">Multiple Exact Queries</span>
                </label>
                <input
                  id={`exact_split_${index}`}
                  type="text"
                  class="input input-bordered"
                  bind:value={rule.lexical}
                  placeholder="Enter exact queries separated by semicolons"
                />
              </div>
            {/if}
          {/if}
        </div>

        {#if rule.search_type == RuleSearchType.TENSOR}
          <div class="flex w-full flex-col">
            <label class="label mr-2" for={`no-match_${index}`}>
              <span class="w-1/2 shrink-0">
                No Match:
                <input
                  type="number"
                  class="input input-sm input-bordered"
                  min="0"
                  max="1"
                  step="0.01"
                  disabled={isViewing}
                  bind:value={noMatchNumericInput}
                  on:keydown={(event) =>
                    handleKeyDownOnThresholdField(event, "no_match")}
                  on:blur={handleNoMatchThresholdChange}
                />
              </span>
              <input
                type="range"
                class="range range-error"
                style="background:orange;"
                id={`no-match_${index}`}
                min="0"
                max="1"
                step="0.01"
                disabled={isViewing}
                bind:value={rule.no_match_threshold}
                on:input={() => adjustThresholds(rule, "no_match")}
              />
            </label>

            <label class="label mr-2" for={`strong-match_${index}`}>
              <span class="w-1/2 shrink-0">
                Strong Match:
                <input
                  type="number"
                  class="input input-sm input-bordered"
                  min="0"
                  max="1"
                  step="0.01"
                  disabled={isViewing}
                  bind:value={strongMatchNumericInput}
                  on:keydown={(event) =>
                    handleKeyDownOnThresholdField(event, "strong_match")}
                  on:blur={handleStrongMatchThresholdChange}
                />
              </span>
              <input
                type="range"
                class="range range-warning"
                style="background:green;"
                id={`strong-match_${index}`}
                min="0"
                max="1"
                step="0.01"
                disabled={isViewing}
                bind:value={rule.strong_match_threshold}
                on:input={() => adjustThresholds(rule, "strong_match")}
              />
            </label>

            <label class="label mr-2">
              <span class="w-1/2 shrink-0"> Maximum number of results: </span>
              <input
                type="number"
                class="input input-sm input-bordered"
                min="5"
                max="1000"
                disabled={isViewing}
                bind:value={rule.limit}
              />
            </label>
          </div>
        {/if}
        {#if rule.search_type === RuleSearchType.BOOLEAN}
          <div class="mt-4 flex gap-4" transition:slide>
            <button
              class="btn btn-primary btn-outline btn-sm"
              on:click={converter}
            >
              <Icon icon="iconoir:coins-swap" /> Switch to
              {dataType === RuleDataType.BUILDER ? "Classic" : "Visual"} Rule Builder
            </button>
          </div>
        {/if}
      </div>
    {/if}
  </div>
</article>
