<script>
  import Icon from "@iconify/svelte";
  import { onMount, tick } from "svelte";
  import { toast } from "svelte-sonner";
  import { get } from "svelte/store";
  import { slide } from "svelte/transition";
  import { fetchGet, fetchPatch } from "../../helpers";
  import { HitColour } from "../../lib/interfaces/Hit.interface";
  import {
    ConditionalRuleType,
    RuleStatus,
  } from "../../lib/interfaces/Rule.interface";
  import { isEmpty } from "../../lib/utils/GenericUtils";
  import {
    getRuleColourCounts,
    getRuleColourDropDownOptions,
    isParentConditionalRule,
    ruleColourToLabel,
    ruleColourToStyle,
  } from "../../lib/utils/RuleUtils";
  import { actions, org_name } from "../../stores";
  import ActionConfirmation from "../ActionConfirmation.svelte";
  import ChecklistInfoModal from "../ChecklistInfoModal.svelte";
  import Comments from "../comments/Comments.svelte";
  import LogoLoader from "../LogoLoader.svelte";
  import AssetReviewHit from "./AssetReviewHit.svelte";
  import AssetRuleInfoDialog from "./AssetRuleInfoDialog.svelte";
  import AssignUsers from "./AssignUsers.svelte";
  import ConditionalRuleChildren from "./ConditionalRules/ConditionalRuleChildren.svelte";
  import ReportAssetPerformance from "./ReportAssetPerformance.svelte";
  import Badge from "./WorkflowBoard/Badge.svelte";

  export let activeVersion;
  export let activeAsset;
  export let groupedRules = [];
  export let currentStreamPerm;
  export let previewSearchText;
  export let boundingBox;
  export let reportId;
  export let activeAssetId;
  export let currentAssetApprovingPermission = false;
  export let rulesLoading = false;
  export let versionUpdated = false;
  export let assetStatus = {
    status: "",
    assetRuleStatuses: {
      ready: 0,
      processing: 0,
      failed: 0,
      total: 0,
    },
    percentageProgress: 0,
  };

  let ruleComment = "";
  let activeRuleGroup = 0;
  let activeRule = -1;
  let activeRuleId = -1;
  let activeRuleResults = [];
  let activeRuleResultsLoading = false;
  let actionTexts = $actions.map(function (elem) {
    return elem.action_text;
  });
  let rulePerformanceIndexMap = { groupIdx: -1, ruleIdx: -1 };
  let searchActionToggled = false;
  let forceSearchDetails = {
    ruleGroupIndex: -1,
    ruleIndex: -1,
    ruleId: -1,
    name: "",
    colour: HitColour.UNKNOWN,
    loading: false,
  };
  let workflowStep = activeAsset?.asset?.workflow_step.name;

  async function updateColour(ruleId, assetId, colour, isConditional = false) {
    try {
      const updateColourResponse = await fetchPatch(
        `/asset-rule/status/${assetId}/${ruleId}`,
        {
          colour: colour,
          is_conditional_rule: isConditional,
        },
      );

      if (!updateColourResponse.success) {
        throw Error("Unable to update Rule colour.");
      }

      if (isConditional && colour === HitColour.PASSED) {
        activeAsset.asset.status = "Searching";
        activeAsset = activeAsset;
      }

      return updateColourResponse;
    } catch (e) {
      toast.error(
        `Something went wrong updating the colour of Rule(${ruleId}).`,
      );
    }
  }

  const updateRuleComments = async (assetId, ruleId, ruleGroupIndex) => {
    const res = await fetchGet(`/asset-rule/comment/${assetId}/${ruleId}`);

    if (!res?.success) {
      console.error("Error fetching rule comments");
      return;
    }

    groupedRules[ruleGroupIndex].rules = groupedRules[ruleGroupIndex].rules.map(
      (r) => {
        if (r.rule.id === ruleId) r.comments = res.data;
        return r;
      },
    );

    // and now updated GroupedRules to reflect the changes
  };

  async function updateActiveRuleHits() {
    activeRuleResultsLoading = true;

    if (activeRule !== -1 && activeRuleGroup !== -1) {
      const activeRuleId =
        groupedRules[activeRuleGroup].rules[activeRule].rule.id;

      try {
        const res = await fetchGet(
          `/hit/asset/${activeAssetId}/rule/${activeRuleId}`,
        );

        if (!res.success) {
          throw new Error(res.message);
        }

        activeRuleResults = res.results.filter(
          (r) => r.version === activeVersion.version,
        );
        activeRuleResults = [...activeRuleResults];
      } catch (e) {
        toast.error(
          `Something went wrong loading results for Asset(${activeAssetId}), Rule(${activeRuleId}).`,
        );
      }
    } else {
      activeRuleResults = [];
    }
  }

  $: updateActiveRuleHits().then((res) => {
    activeRuleResultsLoading = false;
  }),
    [activeRule];

  let ruleId;
  let hitId;

  onMount(async () => {
    const hashParams = new URLSearchParams(
      new URL(window.location.href).hash.split("?")[1],
    );
    ruleId = Number(hashParams.get("ruleId"));
    hitId = Number(hashParams.get("hitId"));

    activeRuleGroup = groupedRules.findIndex((c) =>
      c.rules.some((r) => r.rule.id === ruleId),
    );

    if (activeRuleGroup === -1) activeRuleGroup = 0;
    else {
      activeRule = groupedRules[activeRuleGroup].rules.findIndex(
        (r) => r.rule.id === ruleId,
      );
    }
  });

  function handleRuleOrderUpdate() {
    if (activeRuleGroup !== -1 && activeRuleId !== -1 && activeRule !== -1) {
      activeRule = groupedRules[activeRuleGroup].rules.findIndex(
        (r) => r.rule.id === activeRuleId,
      );
      activeRule = activeRule;
    }
  }

  function getActiveAssetRuleIdx(ruleId) {
    return activeAsset.rules.findIndex((r) => r.rule.id === ruleId);
  }

  async function refreshRule(ruleId, ruleGroupIndex) {
    const activeAssetRuleIdx = getActiveAssetRuleIdx(ruleId);

    try {
      await updateRuleComments(activeAssetId, ruleId, ruleGroupIndex);
    } catch (e) {
      console.error(e);
      toast.error(
        `Something went wrong updating the status of Task(${activeAsset.rules[activeAssetRuleIdx].rule.name})`,
      );
    }

    activeAsset = activeAsset;
  }

  async function handleVersionUpdated() {
    if (versionUpdated) {
      await updateActiveRuleHits().then((res) => {
        activeRuleResultsLoading = false;
      });
    }
  }

  async function handleForceSearchConditional() {
    const updateColourResponse = await updateColour(
      forceSearchDetails.ruleId,
      activeAssetId,
      forceSearchDetails.colour,
      true,
    );
    await updateRuleComments(
      activeAssetId,
      forceSearchDetails.ruleId,
      forceSearchDetails.ruleGroupIndex,
    );

    groupedRules[forceSearchDetails.ruleGroupIndex].rules[
      forceSearchDetails.ruleIndex
    ].change_comment = updateColourResponse.change_comment;
    groupedRules[forceSearchDetails.ruleGroupIndex].rules[
      forceSearchDetails.ruleIndex
    ].changed = true;
    groupedRules[forceSearchDetails.ruleGroupIndex].rules[
      forceSearchDetails.ruleIndex
    ] =
      groupedRules[forceSearchDetails.ruleGroupIndex].rules[
        forceSearchDetails.ruleIndex
      ];
  }

  function resetForceSearchDetails() {
    forceSearchDetails = {
      ruleGroupIndex: -1,
      ruleIndex: -1,
      ruleId: -1,
      name: "",
      colour: HitColour.UNKNOWN,
      loading: false,
    };
  }

  $: handleRuleOrderUpdate(), [groupedRules];
  $: handleVersionUpdated(), [versionUpdated];
</script>

<div
  class="relative {activeAsset?.asset?.status !== 'Ready'
    ? 'overflow-hidden'
    : 'overflow-auto'} rounded border"
  style="height: calc(100vh - 350px);"
>
  {#if !rulesLoading}
    {#each groupedRules as ruleGroup, index}
      <div class="flex flex-col border-b" in:slide>
        <button
          class="bg-base-200 flex w-full cursor-pointer flex-col gap-2 px-4 py-2 text-start"
          on:click={() =>
            (activeRuleGroup = activeRuleGroup === index ? -1 : index)}
        >
          <div class="flex w-full items-center justify-between">
            <span class="flex h-fit flex-wrap items-center gap-2 font-semibold">
              {ruleGroup.setName}
              <ChecklistInfoModal
                data={{
                  name: ruleGroup.setName,
                  id: ruleGroup.setId,
                  description: ruleGroup.setDescription,
                }}
              />
            </span>
            <div class="flex items-center gap-4">
              <div class="flex items-center gap-2">
                <div
                  class="flex h-6 w-6 items-center justify-center rounded-full bg-[#ff4e4e5e] text-sm text-[#FC0000]"
                >
                  {getRuleColourCounts(ruleGroup.rules, "red")}
                </div>
                <div
                  class="flex h-6 w-6 items-center justify-center rounded-full bg-[#fbe5b8] text-sm text-[#FF8D00]"
                >
                  {getRuleColourCounts(ruleGroup.rules, "amber")}
                </div>
                <div
                  class="flex h-6 w-6 items-center justify-center rounded-full bg-[#c4d4bd] text-sm text-[#336A1D]"
                >
                  {getRuleColourCounts(ruleGroup.rules, "green")}
                </div>
              </div>
              <button class="btn btn-square btn-ghost btn-sm">
                <Icon
                  icon="iconoir:nav-arrow-down"
                  class="text-lg transition-transform
                    {activeRuleGroup === index ? 'rotate-180' : ''}"
                />
              </button>
            </div>
          </div>
        </button>
        {#if activeRuleGroup === index}
          {#each ruleGroup.rules as rule, idx}
            <div
              class="flex w-full flex-col gap-3 py-2 {rule?.changed
                ? 'pl-4'
                : 'pl-10'} pr-4"
              class:border-t={idx !== 0}
              in:slide
            >
              <button
                class="flex cursor-pointer items-center justify-between gap-4 text-start"
                on:click|stopPropagation={() => {
                  activeRule = activeRule === idx ? -1 : idx;
                  activeRuleId =
                    activeRuleId === rule.rule.id ? -1 : rule.rule.id;
                  ruleComment = "";
                }}
              >
                <div
                  class="flex w-64 flex-row items-center justify-between gap-2"
                >
                  <div class="flex flex-row items-center gap-2">
                    {#if rule?.changed}
                      <div
                        class="tooltip tooltip-right flex items-center justify-center"
                        data-tip={!isEmpty(rule.change_comment)
                          ? rule.change_comment
                          : "This rule has been modified by an unknown user."}
                      >
                        <Icon
                          icon="material-symbols:circle"
                          class="text-primary text-sm"
                        />
                      </div>
                    {/if}
                    <span class="text-base leading-6">{rule.rule.name}</span>
                  </div>

                  <div class="flex flex-row items-center gap-2">
                    <button
                      class="btn btn-circle btn-ghost btn-xs ml-1"
                      on:click|stopPropagation={() =>
                        document
                          .getElementById("rule_info_modal_" + rule.rule.id)
                          .showModal()}
                    >
                      <Icon icon="iconoir:info-circle" class="text-lg" />
                    </button>

                    {#if get(org_name) === "testbench" || get(org_name) === "haast"}
                      <button
                        class="btn btn-circle btn-ghost btn-xs ml-1"
                        on:click|stopPropagation={() => {
                          rulePerformanceIndexMap.groupIdx = index;
                          rulePerformanceIndexMap.ruleIdx = idx;
                          document
                            .querySelector(
                              `rule_report_example_${rule.rule.id}`,
                            )
                            ?.showModal();
                        }}
                      >
                        <Icon icon="material-symbols:flag" class="text-lg" />
                      </button>
                    {/if}
                  </div>
                </div>

                <div class="flex w-80 shrink-0 items-center gap-2">
                  <!-- {#if rule.rule.critical_rule}
                      <div class="tooltip" data-tip="Critical Rules">
                        <Icon
                          icon="iconoir:priority-high"
                          class="text-xl"
                        />
                      </div>
                    {/if} -->

                  {#key rule.colour}
                    <div
                      class={!currentAssetApprovingPermission ||
                      currentStreamPerm !== "edit"
                        ? "tooltip tooltip-bottom"
                        : ""}
                      data-tip={currentStreamPerm === "edit" &&
                      workflowStep === "Uploaded"
                        ? "You don't have permission to change this risk rating. Please submit the asset for approval."
                        : "You don't have permission to change this risk rating."}
                    >
                      <div class="dropdown dropdown-end">
                        <!--svelte-ignore a11y-no-noninteractive-tabindex-->
                        <!--svelte-ignore a11y-label-has-associated-control-->
                        <!-- svelte-ignore a11y-click-events-have-key-events -->
                        <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
                        <label
                          tabindex="0"
                          class="btn btn-ghost btn-xs w-[116px] flex-nowrap justify-between whitespace-nowrap
                                {ruleColourToStyle(
                            rule.colour,
                            isParentConditionalRule(rule.rule),
                          )}"
                          on:click|stopPropagation
                        >
                          <span class="flex flex-row items-center gap-2">
                            <Icon
                              icon="material-symbols:circle"
                              class="h-2 w-2"
                            />
                            {ruleColourToLabel(
                              rule.colour,
                              isParentConditionalRule(rule.rule),
                            )}
                          </span>
                          {#if currentStreamPerm == "edit"}
                            <Icon
                              icon="iconoir:nav-arrow-down"
                              class="text-base"
                            />
                          {/if}
                        </label>
                        <!--svelte-ignore a11y-no-noninteractive-tabindex-->
                        {#if currentStreamPerm == "edit" && currentAssetApprovingPermission}
                          <ul
                            tabindex="0"
                            class="menu dropdown-content menu-xs bg-base-100 z-[1] mt-1 flex w-full min-w-max flex-col gap-1 rounded border p-1"
                          >
                            {#each getRuleColourDropDownOptions(rule.rule) as item}
                              <li>
                                <button
                                  style:color={item.hex}
                                  on:click|stopPropagation={async () => {
                                    if (
                                      rule.rule?.is_conditional &&
                                      item.color === HitColour.PASSED
                                    ) {
                                      searchActionToggled = true;
                                      forceSearchDetails = {
                                        ruleGroupIndex: index,
                                        ruleIndex: idx,
                                        ruleId: rule.rule.id,
                                        name: rule.rule.name,
                                        colour: item.color,
                                      };
                                      document
                                        .getElementById(
                                          `force-conditional-search`,
                                        )
                                        .showModal();
                                    } else {
                                      rule.colour = item.color;
                                      const updateColourResponse =
                                        await updateColour(
                                          rule.rule.id,
                                          activeAssetId,
                                          item.color,
                                          false,
                                        );
                                      rule.change_comment =
                                        updateColourResponse.change_comment;
                                      rule.changed = true;

                                      await updateRuleComments(
                                        activeAssetId,
                                        rule.rule.id,
                                        activeRuleGroup,
                                      );
                                      rule = rule;
                                    }
                                  }}
                                >
                                  <input
                                    type="radio"
                                    class="radio-primary radio radio-xs"
                                    checked={rule.colour === item.color}
                                  />
                                  {item.text}
                                </button>
                              </li>
                            {/each}
                          </ul>
                        {/if}
                      </div>
                    </div>
                  {/key}

                  <button
                    class="btn btn-ghost btn-xs"
                    on:click|stopPropagation={async () => {
                      activeRule = idx;
                      activeRuleId = rule.rule.id;
                      ruleComment = "";
                      await tick();

                      const ctxtarea = document.getElementById("ruleComment");
                      if (ctxtarea) {
                        ctxtarea.scrollIntoView({ behavior: "smooth" });
                        ctxtarea.focus();
                      } else console.log("Comment textarea not found");
                    }}
                  >
                    <Icon icon="iconoir:chat-lines" class="text-base" />
                    {rule.comments ? rule.comments.length : 0}
                  </button>

                  <AssignUsers
                    containerClass="ml-auto"
                    rule_id={rule.rule.id}
                    asset_id={activeAssetId}
                    assignees={rule.assigned_users}
                    disableEditing={currentStreamPerm !== "edit"}
                    isCompact
                    on:change={(e) => {
                      rule.assigned_users = e.detail;
                      rule = rule;

                      updateRuleComments(activeAssetId, rule.rule.id, index);
                    }}
                  />

                  <button class="btn btn-square btn-ghost btn-sm">
                    <Icon
                      icon="iconoir:nav-arrow-down"
                      class="text-lg transition-transform
                    {activeRule === idx ? 'rotate-180' : ''}"
                    />
                  </button>
                </div>
              </button>
              {#if activeRule === idx}
                <div class="flex flex-col gap-2 {rule?.changed ? 'ml-6' : ''}">
                  {#if activeRuleResultsLoading}
                    <div class="flex self-center align-middle">
                      <LogoLoader size="2.5rem" />
                    </div>
                  {:else}
                    <div
                      class="border-l-asset-hit-border flex flex-col gap-2 border-l-2 pl-1"
                    >
                      {#each activeRuleResults as hit}
                        <AssetReviewHit
                          bind:boundingBox
                          bind:hit
                          bind:activeAsset={activeAsset.asset}
                          bind:previewSearchText
                          bind:currentAssetApprovingPermission
                          {reportId}
                          {actionTexts}
                          bind:rule={rule.rule}
                          {currentStreamPerm}
                          bind:asset_rule={rule}
                          expanded={hit.hit_id === hitId}
                          on:refresh={async () => {
                            await refreshRule(rule.rule.id, index);
                          }}
                        />
                      {:else}
                        <p>No results found</p>
                      {/each}
                    </div>
                  {/if}
                </div>
              {/if}

              {#if rule.rule.is_conditional}
                <div class="flex flex-col gap-2">
                  <div class="items-left flex w-full flex-col">
                    <span class="{rule?.changed ? 'ml-9' : 'ml-3'} h-fit">
                      Because this
                      <Badge bgColor="#3889FF" textColor="white">
                        {ConditionalRuleType.IF}
                      </Badge>
                      rule is marked
                      {#if rule.colour === HitColour.PASSED}
                        <Badge bgColor="#929292" textColor="white">
                          {RuleStatus.TRUE}
                        </Badge>
                      {:else if rule.colour === HitColour.RISK}
                        <Badge bgColor="#929292" textColor="white">
                          {RuleStatus.FALSE}
                        </Badge>
                      {:else if rule.colour === HitColour.REVIEW}
                        <Badge bgColor="#929292" textColor="white">
                          {RuleStatus.REVIEW}
                        </Badge>
                      {:else}
                        <Badge bgColor="#929292" textColor="white">
                          {RuleStatus.UNKNOWN}
                        </Badge>
                      {/if}
                      , the following
                      <Badge bgColor="#A712ED" textColor="white">
                        {ConditionalRuleType.THEN}
                      </Badge>
                      rules were {rule.colour !== HitColour.PASSED ? "not" : ""}
                      run:
                    </span>
                  </div>

                  <ConditionalRuleChildren
                    bind:activeAssetId
                    bind:activeAsset
                    bind:activeVersion
                    bind:previewSearchText
                    bind:reportId
                    bind:currentStreamPerm
                    bind:currentAssetApprovingPermission
                    bind:actionTexts
                    bind:parentRuleColour={rule.colour}
                    parentChanged={rule?.changed}
                    bind:rules={rule.rule.children}
                    bind:versionUpdated
                    parentRuleId={rule.rule.id}
                  />
                </div>
              {/if}

              {#if activeRule === idx}
                <Comments
                  commentInputId="ruleComment"
                  comments={rule.comments}
                  apiEndpoint={`/asset-rule/comment/${activeAssetId}/${rule.rule.id}`}
                  type="asset"
                  isParentConditional={rule.rule.is_conditional}
                  xs
                  on:updateComments={() =>
                    updateRuleComments(activeAssetId, rule.rule.id, index)}
                />
              {/if}
            </div>
            <AssetRuleInfoDialog rule={rule.rule} {activeAssetId} />
          {/each}
        {/if}
      </div>
    {:else}
      <div
        class="flex flex-col justify-center items-center p-4 text-base-content/50 h-full"
      >
        <Icon icon="iconoir:task-list" class="text-8xl" />
        <p class="text-sm text-center">No checklist rules yet!</p>
      </div>
    {/each}

    {#if activeAsset?.asset?.status === "Searching"}
      <div
        class="absolute inset-0 z-10 flex min-h-screen items-center justify-center backdrop-blur"
      ></div>
      <div
        class="absolute inset-0 z-20 flex items-center justify-center text-xl"
      >
        <div class="flex flex-col items-center justify-center gap-4 text-xl">
          <div class="flex w-full flex-col items-center gap-2">
            <span class="text-l min-w-8 text-start">
              {assetStatus.percentageProgress}&#37;
            </span>
            <progress
              class="progress progress-primary w-full"
              value={assetStatus.assetRuleStatuses.ready}
              max={assetStatus.assetRuleStatuses.total}
            />
          </div>
          The asset is currently being searched.
        </div>
      </div>
    {:else if activeAsset?.asset?.status === "Processing"}
      <div
        class="absolute inset-0 z-10 flex min-h-screen items-center justify-center backdrop-blur"
      ></div>
      <div
        class="absolute inset-0 z-20 flex items-center justify-center text-xl"
      >
        <div class="flex flex-col items-center justify-center gap-4 text-xl">
          <div class="flex w-full flex-col items-center gap-2">
            <span class="text-l min-w-8 text-start"> 0&#37; </span>
            <progress
              class="progress progress-primary w-full"
              value="0"
              max="0"
            />
          </div>
          The asset is currently processing.
        </div>
      </div>
    {:else if activeAsset?.asset?.status === "Upload Failed"}
      <div
        class="absolute inset-0 z-10 flex min-h-screen items-center justify-center backdrop-blur"
      ></div>
      <div
        class="absolute inset-0 z-20 flex items-center justify-center text-xl"
      >
        The asset has failed to upload.
      </div>
    {:else if activeAsset?.asset?.status === ("Searching Failed" || "Searching Error")}
      <div
        class="absolute inset-0 z-10 flex min-h-screen items-center justify-center backdrop-blur"
      ></div>
      <div
        class="absolute inset-0 z-20 flex items-center justify-center text-xl"
      >
        The asset has failed to search.
      </div>
    {:else if activeAsset?.asset?.status !== "Ready"}
      <div
        class="absolute inset-0 flex items-center justify-center text-xl backdrop-blur"
      >
        The asset is still {activeAsset?.asset?.status.toLowerCase()}.
      </div>
    {/if}
  {:else}
    <div
      class="absolute inset-0 flex items-center justify-center text-xl backdrop-blur"
    >
      <LogoLoader size="3rem" />
    </div>
  {/if}
</div>

{#if Object.values(rulePerformanceIndexMap).every((v) => v > -1)}
  <ReportAssetPerformance
    rule={groupedRules[rulePerformanceIndexMap.groupIdx].rules[
      rulePerformanceIndexMap.ruleIdx
    ]}
    asset={activeAsset.asset}
    activeVersion={activeVersion?.version}
    report_id={reportId}
  />
{/if}

<!-- svelte-ignore missing-declaration -->
<ActionConfirmation
  message="Changing this to “True” will cause the Rule({forceSearchDetails.name}) to refresh. Are you sure you want to proceed?"
  modalId="force-conditional-search"
  showLoadingSpinner={true}
  loading={forceSearchDetails.loading}
  onConfirm={async () => {
    forceSearchDetails.loading = true;
    await handleForceSearchConditional();
    forceSearchDetails.loading = false;
    document.getElementById(`force-conditional-search`).close();
    resetForceSearchDetails();
  }}
  onCancel={() => {
    document.getElementById(`force-conditional-search`).close();
    resetForceSearchDetails();
  }}
/>
