<script>
  import { onMount } from "svelte";
  import { toast } from "svelte-sonner";
  import { fetchDelete, fetchGet, fetchPatch } from "../../helpers";
  import { org_users, org_teams } from "../../stores";
  import ActionConfirmation from "../ActionConfirmation.svelte";
  import MultiSelect from "../MultiSelect.svelte";
  import Pagination from "../table/Pagination.svelte";

  const maximumStringLength = 64;

  let teams = [];
  let editTeamName = "";
  let editTeamDescription = "";
  let editSelectedUsers = [];
  let users = $org_users;
  let displayUsers = users
    .filter((u) => !u.is_deleted)
    .map((user) => ({
      label: user.first_name + " " + user.last_name,
      value: user.id,
    }));
  let teamToEdit = 0;
  let teamToDelete;
  let currentPage = 1;
  let itemsPerPage = 5;
  let loading = false;

  async function setTeamToEdit(teamId) {
    teamToEdit = teamId;
    const team = teams.find((team) => team.id === teamToEdit);

    editTeamName = team.name;
    editTeamDescription = team.description;
    editSelectedUsers = [];

    try {
      const response = await fetchGet(`/team/${teamId}/members`);

      if (!response.success) throw new Error(response.error);

      editSelectedUsers = response.team_members.map((m) => m.value);
    } catch (error) {
      console.error(error);
    }
  }

  async function editTeam() {
    if (!teamToEdit) return;

    if (editTeamName.trim().length === 0) {
      toast.warning("Please enter a team name");
      return;
    }

    loading = true;

    try {
      const response = await fetchPatch(`/team/${teamToEdit}`, {
        name: editTeamName,
        description: editTeamDescription,
        users: editSelectedUsers,
      });

      if (!response.success) {
        throw new Error(response.error);
      }

      teams = teams.map((team) => {
        if (team.id === teamToEdit) return response.team;

        return team;
      });

      teamToEdit = 0;
    } catch (error) {
      toast.error(`Failed to save changes`);
      console.error(error);
    }
    toast.success(`Changes saved.`);
    loading = false;
  }

  async function deleteTeam(teamId) {
    if (!teamId) return;

    loading = true;

    try {
      const response = await fetchDelete(`/team/${teamId}`);

      if (!response.success) {
        toast.error(`Failed to delete team. Team may be active.`);
        loading = false;
        return;
      }

      teams = teams.filter((team) => team.id !== teamId);
    } catch (error) {
      toast.error(`Failed to delete team. Team may be active.`);
      console.error(error);
      loading = false;

      return;
    }

    toast.success("Item deleted.");
    loading = false;
  }

  async function getTeams() {
    try {
      const response = await fetchGet("/team");

      if (!response.success) throw new Error(response.error);

      teams = [...response.teams];
    } catch (error) {
      console.error(error);
    }
  }

  function updateUsersToDisplay() {
    if (teamToEdit !== 0) {
      displayUsers = [
        ...$org_users
          .filter(
            (user) =>
              !user.is_deleted ||
              teams
                .find((t) => t.id === teamToEdit)
                ?.users.some((u) => u.id === user.id),
          )
          .map((user) => ({
            label: user.first_name + " " + user.last_name,
            value: user.id,
          })),
      ];
    }
  }

  function handleOrganisationUsersUpdate() {
    updateUsersToDisplay();
    getTeams();
  }

  onMount(async () => {
    await getTeams();
  });

  $: updateUsersToDisplay(), [teamToEdit];
  $: handleOrganisationUsersUpdate(), [$org_users];
  $: getTeams(), [$org_teams];
</script>

<!-- svelte-ignore missing-declaration -->
<ActionConfirmation
  modalId="deleteTeamModal"
  message="Are you sure you want to delete this team?"
  showLoadingSpinner={true}
  {loading}
  onConfirm={async () => {
    await deleteTeam(teamToDelete);
    deleteTeamModal.close();
  }}
  onCancel={() => deleteTeamModal.close()}
/>

<div class="overflow-auto">
  <table
    class="bg-base-200 text-base-content/80 table border-collapse text-sm *:h-12"
  >
    <thead>
      <tr
        class="text-base-content/90 border-gray-200 *:text-sm *:font-semibold"
      >
        <th class="max-w-max">Team Name</th>
        <th class="max-w-max">Team Description</th>
        <th class="min-w-60 whitespace-normal">Users</th>
        <th class="min-w-32 whitespace-normal" />
      </tr>
    </thead>
    <tbody>
      {#each teams.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) as team}
        {#if teamToEdit === team.id}
          <tr>
            <td>
              <input
                class="input input-sm input-bordered"
                type="text"
                maxlength={maximumStringLength}
                bind:value={editTeamName}
                placeholder="Add Team Name"
              />
            </td>
            <td>
              <input
                class="input input-sm input-bordered"
                type="text"
                maxlength={maximumStringLength}
                bind:value={editTeamDescription}
                placeholder="Add Team Description"
              />
            </td>
            <td>
              <MultiSelect
                items={displayUsers}
                placeholder="Select Team Members"
                bind:selectedValues={editSelectedUsers}
              />
            </td>
            <td class="w-44">
              <div class="m-auto flex w-full flex-wrap gap-2">
                <button
                  class="btn btn-outline btn-primary btn-xs"
                  disblaed={loading}
                  on:click={async () => {
                    await editTeam();
                  }}
                >
                  Save
                </button>

                <button
                  class="btn btn-outline btn-error btn-xs"
                  on:click={() => setTeamToEdit(0)}
                >
                  Cancel
                </button>
              </div>
            </td>
          </tr>
        {:else}
          <tr>
            <td>{team.name}</td>
            <td>{team.description}</td>
            <td>
              <div>
                {(team?.users || [])
                  .map((u) => u.first_name + " " + u.last_name)
                  .join(", ")}
              </div>
            </td>
            <td class="w-44">
              <div class="m-auto flex flex-wrap gap-2">
                <button
                  class="btn btn-outline btn-primary btn-xs"
                  disabled={loading}
                  on:click={() => setTeamToEdit(team.id)}
                >
                  Edit
                </button>

                <!-- svelte-ignore missing-declaration -->
                <button
                  class="btn btn-outline {loading
                    ? 'btn-disabled'
                    : 'btn-error'} btn-xs"
                  disblaed={loading}
                  on:click={() => {
                    teamToDelete = team.id;
                    deleteTeamModal.showModal();
                  }}
                >
                  Delete
                </button>
              </div>
            </td>
          </tr>
        {/if}
      {/each}
    </tbody>
  </table>
</div>

<Pagination bind:currentPage bind:itemsPerPage totalHits={teams.length} />
