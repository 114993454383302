<script>
  import Icon from "@iconify/svelte";
  import { toast } from "svelte-sonner";
  import { slide } from "svelte/transition";
  import { fetchGet, saveRuleToMyRules } from "../../helpers.js";
  import { isEmpty } from "../../lib/utils/GenericUtils.js";
  import {
    isParentConditionalRule,
    ruleSearchTypeToLabel,
  } from "../../lib/utils/RuleUtils.js";
  import Modal from "../Modals/Modal.svelte";
  import {
    ConditionalRuleType,
    RuleSearchType,
    RuleStatus,
  } from "./../../lib/interfaces/Rule.interface.js";
  import ContextualRuleRow from "./ContextualRules/ContextualRuleRow.svelte";
  import QueryPrettyPrint from "./QueryPrettyPrint.svelte";
  import Badge from "./WorkflowBoard/Badge.svelte";

  export let rule;
  export let activeAssetId;

  let saved_rules = [];

  let shortInstruction = "";
  let detailedPlan = "";
  let context = "";
  let definitions = "";
  let isLoading = false;
  let showMoreInfo = false;
  let activeChildRuleId = -1;
  let showSuggestedFix = false;

  async function suggestFix() {
    isLoading = true;
    try {
      const response = await fetchGet(
        `/asset-rule/fix/${activeAssetId}/${rule.id}`,
      );
      if (response.success) {
        shortInstruction = response.short_instruction.trim();
        detailedPlan = response.detailed_plan.trim();
        showSuggestedFix = true; // Show the suggested fix content
      } else {
        console.error("Failed to get fix suggestion:", response.reason);
      }
    } catch (error) {
      console.error("Error suggesting fix:", error);
    } finally {
      isLoading = false;
    }
  }

  function toggleMoreInfo() {
    showMoreInfo = !showMoreInfo;
  }

  $: if (rule && rule.rule_type === RuleSearchType.CONTEXTUAL && rule?.query) {
    const query = JSON.parse(rule.query);
    context = query?.context;
    definitions = query.definitions.length > 0 ? query.definitions : [];
  }
</script>

<Modal
  modalId="rule_info_modal_{rule.id}"
  size={rule && rule?.is_conditional ? "xl" : "sm"}
>
  <h2 class="max-w-m truncate text-xl font-semibold">
    {rule.name}
  </h2>

  <p class="text-base-content/80 mt-4 text-sm">
    ID: #{rule.id}
  </p>

  {#if rule.description}
    <div class="mt-4 space-y-2">
      <p class="text-sm font-bold">Description</p>
      <p class="text-base-content/80 text-sm">{rule.description}</p>
    </div>
  {/if}

  {#if rule.remediation_step}
    <div class="mt-4 space-y-2">
      <p class="text-sm font-bold">Remediation Step</p>
      <p class="text-base-content/80 text-sm">{rule.remediation_step}</p>
    </div>
  {/if}

  {#if showMoreInfo}
    <div transition:slide>
      {#if rule.rule_type === RuleSearchType.CONTEXTUAL}
        <div class="mt-4 space-y-2">
          <p class="mt-1 text-sm font-bold">Context</p>
          <p class="text-base-content/80 text-sm">
            {context}
          </p>
        </div>

        <div class="mt-4 space-y-2">
          <p class="mt-1 text-sm font-bold">Definitions</p>
          <div class="grid grid-cols-2">
            <p class="text-sm font-medium">Name</p>
            <p class="text-sm font-medium">Description</p>

            {#each definitions as def, idx}
              <span class="badge text-base-content/80 h-auto text-sm">
                {def.name}
              </span>

              <p class="text-base-content/80 text-sm">{def.desc}</p>
            {:else}
              <p class="col-span-2 text-base-content/80 text-sm">
                No definitions found.
              </p>
            {/each}
          </div>
        </div>
      {/if}

      <div
        class="mt-4 space-y-2 {rule && rule?.is_conditional ? 'w-full' : ''}"
      >
        <p class="text-sm font-bold">
          {rule.rule_type === RuleSearchType.CONTEXTUAL ? "Examples" : "Query"}
        </p>
        {#if rule.is_conditional && rule.condition_type === ConditionalRuleType.IF}
          <div class="flex w-full flex-col gap-6">
            <div class="flex flex-col">
              <div class="flex gap-4">
                <button class="btn btn-xs btn-primary w-20 text-start"
                  >IF</button
                >
                <QueryPrettyPrint
                  {rule}
                  isConditionalDialog={rule && rule?.is_conditional}
                  isParentConditional={isParentConditionalRule(rule)}
                />
                {#if rule.rule_type === RuleSearchType.MANUAL}
                  <div class="col-span-2 col-start-5 flex flex-col gap-1">
                    <p class="mt-1 text-sm font-medium">Sub-Tasks</p>
                    <ul class="rounded-box list-disc">
                      {#each rule.examples.split(" AND ") as example}
                        <li class="ml-6">
                          <span class="badge h-auto"
                            >{example.slice(1, -1)}</span
                          >
                        </li>
                      {/each}
                    </ul>
                  </div>
                {/if}
              </div>
            </div>

            <div class="flex flex-col">
              <div class="flex flex-col gap-4">
                <div class="flex flex-row gap-4">
                  <button
                    class="btn btn-xs bg-child-rule-border border-child-rule-border w-20 text-start text-white"
                  >
                    THEN
                  </button>
                  <p class="text-base-content/80 mb-2 text-sm">
                    the following rules will be run on the asset:
                  </p>
                </div>
                <div class="ml-24 flex flex-col gap-1 pt-0" transition:slide>
                  {#each rule.children.map((child) => child.rule) as child}
                    <div class="bg-base-100 w-full rounded border">
                      <!-- svelte-ignore a11y-click-events-have-key-events -->
                      <!-- svelte-ignore a11y-no-static-element-interactions -->
                      <div
                        class="grid grid-cols-3 items-center justify-start gap-4 px-4 py-2"
                        on:click={() =>
                          (activeChildRuleId =
                            activeChildRuleId === child.id ? -1 : child.id)}
                      >
                        <div class="col-span-1 flex items-center gap-4">
                          <span class="break-words">{child.name}</span>
                        </div>

                        <div
                          class="col-span-2 flex items-center justify-between gap-4"
                        >
                          <div class="flex flex-wrap items-center gap-2">
                            <Badge bgColor="#929292" textColor="white">
                              {ruleSearchTypeToLabel(child.rule_type)}
                            </Badge>

                            {#if child.rule_type === RuleSearchType.MANUAL}
                              {#if child.affirmative_rule}
                                <Badge bgColor="#929292" textColor="white">
                                  {RuleStatus.PASSED} if Marked Complete
                                </Badge>
                              {:else}
                                <Badge bgColor="#929292" textColor="white">
                                  {RuleStatus.RISK} if Not Marked Complete
                                </Badge>
                              {/if}
                            {:else if child.affirmative_rule}
                              <Badge bgColor="#929292" textColor="white">
                                Passes if Matched
                              </Badge>
                            {:else}
                              <Badge bgColor="#929292" textColor="white">
                                Failed if Matched
                              </Badge>
                            {/if}
                          </div>

                          <button
                            class="btn btn-square btn-ghost btn-sm ml-auto"
                          >
                            <Icon
                              icon="iconoir:nav-arrow-{activeChildRuleId ===
                              child.id
                                ? 'up'
                                : 'down'}"
                              class="text-lg"
                            />
                          </button>
                        </div>
                      </div>

                      {#if activeChildRuleId === child.id}
                        <main
                          class="grid grid-cols-3 gap-4 px-4 py-2 pt-0"
                          transition:slide
                        >
                          {#if child.rule_type === RuleSearchType.MANUAL}
                            <div>
                              <p class="text-sm font-medium">ID</p>
                              <p class="text-sm font-light">
                                #{child.id}
                              </p>
                            </div>
                            <div>
                              <p class="text-sm font-medium">Description</p>
                              <p class="text-sm font-light">
                                {child.description}
                              </p>
                            </div>
                            <div>
                              <p class="text-sm font-medium">
                                Remediation Step
                              </p>
                              <p class="text-sm font-light">
                                {isEmpty(child.remediation_step)
                                  ? "No remediation step."
                                  : child.remediation_step}
                              </p>
                            </div>
                            <div class="col-span-3 grid grid-cols-6 gap-4">
                              <div
                                class="col-span-3 mr-[36px] flex flex-col gap-1"
                              >
                                <p class="mt-1 text-sm font-medium">Query</p>
                                <QueryPrettyPrint rule={child} />
                              </div>
                              <div
                                class="col-span-2 col-start-5 flex flex-col gap-1"
                              >
                                <p class="mt-1 text-sm font-medium">
                                  Sub-Tasks
                                </p>
                                <ul class="rounded-box list-disc">
                                  {#each child.examples.split(" AND ") as example}
                                    <li class="ml-6">
                                      <span class="badge h-auto"
                                        >{example.slice(1, -1)}</span
                                      >
                                    </li>
                                  {/each}
                                </ul>
                              </div>
                            </div>
                          {:else if child.rule_type === RuleSearchType.CONTEXTUAL}
                            <ContextualRuleRow
                              bind:rule={child}
                              isSavedRulesTable={true}
                              isConditionalChild={true}
                            />
                          {:else}
                            <div>
                              <p class="text-sm font-medium">ID</p>
                              <p class="text-sm font-light">
                                #{child.id}
                              </p>
                            </div>
                            <div>
                              <p class="text-sm font-medium">Description</p>
                              <p class="text-sm font-light">
                                {child.description}
                              </p>
                            </div>
                            <div>
                              <p class="text-sm font-medium">
                                Remediation Step
                              </p>
                              <p class="text-sm font-light">
                                {isEmpty(child.remediation_step)
                                  ? "No remediation step."
                                  : child.remediation_step}
                              </p>
                            </div>

                            <div class="col-span-3 grid grid-cols-2">
                              <div
                                class="col-span-1 mr-[36px] flex flex-col gap-1"
                              >
                                <p class="mt-1 text-sm font-medium">Query</p>
                                <QueryPrettyPrint rule={child} />
                              </div>
                            </div>
                          {/if}
                        </main>
                      {/if}
                    </div>
                  {/each}
                </div>
              </div>
            </div>
          </div>
        {:else}
          <QueryPrettyPrint
            {rule}
            isConditionalDialog={rule && rule?.is_conditional}
            isParentConditional={isParentConditionalRule(rule)}
          />
          {#if rule.rule_type === RuleSearchType.MANUAL}
            <div class="col-span-2 col-start-5 flex flex-col gap-1">
              <p class="mt-1 text-sm font-medium">Sub-Tasks</p>
              <ul class="rounded-box list-disc">
                {#each rule.examples.split(" AND ") as example}
                  <li class="ml-6">
                    <span class="badge h-auto">{example.slice(1, -1)}</span>
                  </li>
                {/each}
              </ul>
            </div>
          {/if}
        {/if}
      </div>

      {#if rule.condition_type !== ConditionalRuleType.THEN}
        <button
          class="btn btn-outline btn-primary btn-xs mt-2"
          on:click={async () => {
            saved_rules = saveRuleToMyRules(rule.id, saved_rules);
            toast.success("Rule has been saved to My Rules");
          }}
        >
          Save to My Rules
        </button>
      {/if}
    </div>
    <!-- <div class="mt-4">
      <button
        class="btn btn-primary btn-sm"
        on:click={suggestFix}
        disabled={isLoading}
      >
        {#if isLoading}
          <span class="loading loading-spinner"></span>
          Loading...
        {:else}
          Suggest a Fix
        {/if}
      </button>
    </div>
    
    {#if showSuggestedFix}
      <div class="mt-4 space-y-2">
        <p class="text-sm font-bold">Suggested Fix</p>
        <div class="rounded border bg-base-200 p-4">
          <div class="text-sm text-base-content/80">{shortInstruction}</div>
        </div>
      </div>
    
      {#if detailedPlan}
        <div class="mt-4 space-y-2">
          <button class="btn btn-sm" on:click={toggleMoreInfo}>
            {#if showMoreInfo}
              Hide More Information
            {:else}
              More Information
            {/if}
          </button>
          {#if showMoreInfo}
            <div class="rounded border bg-base-200 p-4">
              <div
                class="text-base-content/80 text-sm"
                innerHTML={marked(detailedPlan)}
              ></div>
            </div>
          {/if}
        </div>
      {/if} 
    {/if} -->
  {/if}

  <button class="btn btn-sm btn-primary mt-5 w-full" on:click={toggleMoreInfo}>
    {#if showMoreInfo}
      Show less
      <Icon icon="iconoir:nav-arrow-up" />
    {:else}
      Show more
      <Icon icon="iconoir:nav-arrow-down" />
    {/if}
  </button>
</Modal>
