<script>
  import { onMount } from "svelte";
  import { toast } from "svelte-sonner";
  import { fetchGet, fetchPost } from "../../helpers";
  import NewWorkflow from "../Workflow/NewWorkflow.svelte";
  import ViewWorkflow from "../Workflow/ViewWorkflow.svelte";
  import GoToFolderButton from "./GoToFolderButton.svelte";
  import WorkflowTemplateModal from "./WorkflowTemplateModal.svelte";

  export let report_id;
  export let step;
  export let stepsCompletedTill;
  export let currentStreamPerm;
  export let uploadedFiles = [];
  export let ran_reports = [];
  export let isNewWorkflow = true;
  export let selectedWorkflow = {
    id: 10,
    name: "",
    description: "",
    workflow_steps: [
      {
        id: 10,
        name: "",
        description: "",
        position: 1,
        teams: [],
        users: [],
      },
    ],
  };

  let all_workflows = [];
  //users and teams to add
  let associatedUsers = [];
  let associatedTeams = [];

  const getStreamAccessDetails = async () => {
    const usersResponse = await fetchGet(`/report/${report_id}/users`);
    if (usersResponse.success) associatedUsers = usersResponse.users;

    const teamsResponse = await fetchGet(`/report/${report_id}/teams`);
    if (teamsResponse.success) associatedTeams = teamsResponse.teams;
  };
  /// null if not chosen so "build with template" and "create from scratch should appear"
  // make empty workflow if "create from scratch" is chosen
  // make the template if build with a template is selected
  let building_workflow = null;

  //template select id
  let templateSelectedId = "";

  const checkForStepCompletion = async () => {
    // TODO: need to add check for there to be atleast one document in upload?
    if (stepsCompletedTill < step) stepsCompletedTill = step;

    if (isNewWorkflow) {
      // <!-- each workflow_steps .users or team .length should not be empty -->
      const checkEveryStepAssigned = building_workflow.workflow_steps?.every(
        (ws) => ws.teams.length || ws.users.length,
      );

      if (!checkEveryStepAssigned) {
        toast.error("Please assign users or teams to each step");
        return;
      }

      const toastId = toast.loading("Saving Workflow...");

      let stepCount = 1;
      const requestWorkflow = {
        ...building_workflow,
        workflow_steps: building_workflow.workflow_steps
          .sort((a, b) => a.position - b.position)
          .map((step) => {
            const orderedStep = {
              ...step,
              position: stepCount,
            };
            stepCount++;
            return orderedStep;
          }),
      };
      const res = await fetchPost(`/workflow/${report_id}`, {
        workflow: requestWorkflow,
      });

      if (res.success) {
        toast.success("Workflow Added!");

        fetchGet(`/workflow/${report_id}`).then((r) => {
          isNewWorkflow = r.new_workflow;
          selectedWorkflow = r.data;
        });

        step += 1;
      } else toast.error("Error adding workflow");
    } else step += 1;
  };

  const checkForCompletionAndViewStream = async () => {
    // TODO: need to add check for there to be atleast one document in upload?
    if (stepsCompletedTill < step) stepsCompletedTill = step;

    toast.loading("Uploading documents...");
  };
  let loaded = false;

  onMount(async () => {
    try {
      const res = await fetchGet("/workflow");

      if (res.success) all_workflows = res.data;
      else console.error("Error fetching uploaded files:", res.message);

      if (isNewWorkflow) {
        building_workflow = selectedWorkflow;
        templateSelectedId = selectedWorkflow.id;
      }

      loaded = true;
    } catch (error) {
      console.error("Error fetching uploaded files:", error);
    }
    getStreamAccessDetails();
  });

  function updateBuildingWorkflow(e) {
    templateSelectedId = e.detail;
    building_workflow = all_workflows.find((wf) => wf.id == templateSelectedId);
  }
</script>

<!-- svelte-ignore missing-declaration -->

{#if loaded}
  {#if isNewWorkflow}
    {#if building_workflow}
      <button
        class="btn btn-sm btn-primary"
        on:click={() => workflowTemplateModal.showModal()}
      >
        Select Workflow Template
      </button>

      <WorkflowTemplateModal
        {all_workflows}
        selectedValue={templateSelectedId}
        on:confirm={updateBuildingWorkflow}
      />

      <!-- key block required here so viewport is adjusted and all the cards are visible -->
      {#key templateSelectedId}
        <NewWorkflow {report_id} bind:selectedWorkflow={building_workflow} />
      {/key}
    {/if}
  {:else}
    <ViewWorkflow {selectedWorkflow} {report_id} />
  {/if}
{/if}

{#if currentStreamPerm == "upload"}
  <div class="mt-4 flex gap-2">
    <button
      class="btn btn-primary btn-sm"
      on:click={checkForCompletionAndViewStream}
    >
      View Folder
    </button>
  </div>
{:else}
  <div class="mt-4 flex gap-2">
    <button
      class="btn btn-sm"
      on:click={() => {
        if (step === 0) return;
        step -= 1;
      }}
    >
      Back
    </button>

    <!-- workflow already added or if new then being created -->
    {#if !isNewWorkflow || (isNewWorkflow && building_workflow && building_workflow.workflow_steps?.every((step) => step.name.length && (step.teams.length || step.users.length)))}
      <button class="btn btn-primary btn-sm" on:click={checkForStepCompletion}>
        Next Step
      </button>
    {:else}
      <div
        class="tooltip"
        data-tip="Please select/create a workflow first and make sure it has users assigned to it."
      >
        <button class="btn btn-primary btn-sm" disabled> Next Step </button>
      </div>
    {/if}

    <GoToFolderButton
      {report_id}
      {isNewWorkflow}
      {uploadedFiles}
      {ran_reports}
      on:click={checkForStepCompletion}
    />
  </div>
{/if}
