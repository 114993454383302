<script>
  import Icon from "@iconify/svelte";
  import { onMount, tick } from "svelte";
  import { toast } from "svelte-sonner";
  import { push } from "svelte-spa-router";
  import { fly, slide } from "svelte/transition";
  import { fetchDelete, fetchGet, fetchPatch, mutateURL } from "../../helpers";
  import { retrieveVersions } from "../../lib/utils/AssetReviewUtils";
  import { org_name } from "../../stores";
  import ActionConfirmation from "../ActionConfirmation.svelte";
  import UploadNewVersion from "../AssetReview/UploadNewVersion.svelte";
  import LogoLoader from "../LogoLoader.svelte";
  import Modal from "../Modals/Modal.svelte";
  import DisplayPdf from "../PDF/DisplayPDF.svelte";
  import GoToFolderButton from "./GoToFolderButton.svelte";
  import Number from "../RuleBuilder/Number.svelte";
  import { isValidUUID } from "../../lib/utils/GenericUtils";

  export let report_id;
  export let step;
  export let stepsCompletedTill;
  export let currentStreamPerm;
  export let isNewWorkflow = false;
  export let ran_reports = [];
  export let uploadedFiles = [];

  let isUploadEnabled =
    currentStreamPerm === "upload" ||
    currentStreamPerm === "edit" ||
    currentStreamPerm === "manage";
  let goToFolderClicked = false;
  let nextClicked = false;
  let loaded = false;
  let accumulatedFiles = []; // Array to store accumulated files
  let filePreviews = [];
  let versions = [];
  let accumulatedFileDates = new Map();
  let previewOpen = false;
  let previewUrl = "";
  let previewName = "";
  let previewFileType = "";
  let activeAssetId = 0;
  let uploadingFiles = [];
  let fileUploadStatusMap = new Map();
  let failedUploadMap = new Map();

  $: filePreviews = Array.isArray(accumulatedFiles)
    ? accumulatedFiles.map((file) => ({ name: file.name, status: "" }))
    : [];

  function formatDateForInput(dateString) {
    if (!dateString) return "";

    const date = new Date(dateString);

    if (isNaN(date.getTime())) return "";

    return date.toISOString().split("T")[0];
  }

  async function uploadFile(file) {
    let dueDate = accumulatedFileDates.get(file.name);
    const tempId = crypto.randomUUID();

    uploadedFiles = [
      ...uploadedFiles,
      {
        name: file.name,
        id: tempId,
        due_date: dueDate,
        file_type: null,
        description: null,
        asset_assigned_users: null,
        approval_status: "Pending",
        priority: null,
        report_id: null,
        status: null,
        workflow_step: null,
      },
    ];
    const formData = new FormData();
    let toastId = toast.loading(`Uploading ${file.name}...`, {
      duration: Number.POSITIVE_INFINITY,
    });
    uploadingFiles = [...uploadingFiles, file];
    fileUploadStatusMap.set(file.name, true);
    fileUploadStatusMap = fileUploadStatusMap;

    if (dueDate) {
      formData.append(file.name, dueDate);
    }

    formData.append("files", file);
    formData.append("message", "");

    try {
      const url = mutateURL(`/report/upload/${report_id}`);
      const response = await fetch(url, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      const responseData = await response.json();

      if (!response.ok || !responseData.success) {
        failedUploadMap.set(tempId, true);
        failedUploadMap = failedUploadMap;
        const errorMessage =
          responseData.message ?? `Upload failed for File(${file.name})`;

        throw new Error(errorMessage);
      }

      const assetId = responseData.asset_id;
      uploadingFiles = [...uploadingFiles.filter((f) => f.name !== file.name)];
      accumulatedFiles = accumulatedFiles.filter((f) => f.name !== file.name);
      await updateFileList(tempId, assetId);
      toast.dismiss(toastId);
      toast.success(`${file.name} uploaded successfully`);

      return true;
    } catch (e) {
      toast.error(`Error uploading ${file.name}`);
      console.error(`Error uploading ${file.name}: ${e}`);

      return false;
    }
  }

  const deleteAsset = (fileName, fileId) => {
    accumulatedFiles = accumulatedFiles.filter(
      (file) => file.name !== fileName,
    );
    filePreviews = filePreviews.filter((preview) => preview.name !== fileName);

    if (fileId && failedUploadMap.has(fileId)) {
      failedUploadMap.delete(fileId);
      failedUploadMap = failedUploadMap;
    }
  };

  async function deleteUploadedFile(assetId) {
    if (isValidUUID(assetId)) {
      const file = uploadedFiles.find((f) => f.id === assetId);
      deleteAsset(file.name, assetId);
      uploadedFiles = [...uploadedFiles.filter((file) => file.id !== assetId)];
    } else {
      try {
        const response = await fetchDelete(`/asset/${assetId}`);

        if (response.success)
          uploadedFiles = uploadedFiles.filter((file) => file.id !== assetId);
        else throw new Error(`Error uploading files: ${response.message}`);
      } catch (error) {
        throw new Error(`Error uploading files: ${error}`);
      }
    }
  }

  function previewAsset(file) {
    previewUrl =
      "https://preex-staging.s3.ap-southeast-2.amazonaws.com/" +
      $org_name +
      "/" +
      report_id +
      "/" +
      file.id;

    previewName = file.name;
    previewFileType = file.file_type;
    previewOpen = true;
  }

  const checkForStepCompletion = async () => {
    nextClicked = true;

    if (stepsCompletedTill < step) stepsCompletedTill = step;

    nextClicked = false;
    step += 1;
  };

  const checkForCompletionAndViewStream = async () => {
    if (stepsCompletedTill < step) stepsCompletedTill = step;

    push(`/review/${report_id}`);
  };

  /**
   * Helper method to retrieve the default date for an asset on upload.
   * Note: We're using the millisecond approach for browser compatibility.
   *
   * @returns {str} A string representing the date for one week from the current time,
   *  e.g. 2024-07-30 00:00:00.000000.
   */
  function getOneWeekFromNowStr() {
    const today = new Date();
    const weekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
    return (
      new Date(today.getTime() + weekInMilliseconds)
        .toISOString()
        .split("T")[0] + " 00:00:00.000000"
    );
  }

  async function handleUpdate(files) {
    const defaultDate = getOneWeekFromNowStr();
    const newFiles = Array.from(files);
    const allowedExtensions = [
      ".pdf",
      ".doc",
      ".docx",
      ".png",
      ".jpeg",
      ".jpg",
      // ".eml", // Removed until can support viewing via PSPDFKit - J. Kelly 2024-10-16
      // ".msg", // Removed until can support viewing via PSPDFKit - J. Kelly 2024-10-16
    ];
    const filteredFiles = newFiles.filter((file) => {
      const fileExtension = file.name.toLowerCase().split(".").pop();
      if (!allowedExtensions.includes(`.${fileExtension}`)) {
        toast.error(
          `File ${file.name} is not supported. Please upload a PDF, DOC, or DOCX file.`,
        );
        return false;
      }
      return true;
    });

    filteredFiles.forEach((f) => {
      accumulatedFileDates.set(f.name, defaultDate);
    });
    accumulatedFiles = [...accumulatedFiles, ...filteredFiles];
    accumulatedFileDates = accumulatedFileDates;

    try {
      await Promise.all(filteredFiles.map((file) => uploadFile(file)));
    } catch (e) {
      toast.error("Something went wrong uploading one or more files.");
      console.error(e);
    }

    const fileInput = document.querySelector('input[type="file"]');

    if (fileInput) {
      fileInput.value = "";
    }
  }

  async function updateFileList(tempId = null, assetId) {
    const searchId = tempId ?? assetId;

    try {
      const assetResponse = await fetchGet(
        `/asset/report/${report_id}/document/${assetId}?info=true`,
      );

      if (!assetResponse?.success) {
        throw new Error(
          `Something went wrong retrieving details for Asset(${assetId})`,
        );
      }

      const fileIdx = uploadedFiles.findIndex((file) => file.id === searchId);

      if (fileIdx === -1) {
        throw new Error(
          `Key error attempting to update details for Asset(${assetId})`,
        );
      }

      if (tempId) {
        fileUploadStatusMap.delete(tempId);
        fileUploadStatusMap = fileUploadStatusMap;
      }

      uploadedFiles[fileIdx] = assetResponse.asset;
      uploadedFiles[fileIdx] = uploadedFiles[fileIdx];
    } catch (e) {
      toast.error(
        `Something went wrong retrieving details for Asset(${assetId})`,
      );
      console.error(e);
    }
  }

  async function updateQueuedFileDueDate(fileName, newDueDate) {
    try {
      let updatedDueDate = newDueDate;

      if (newDueDate) {
        const existingDueDate = accumulatedFileDates.get(fileName);

        if (existingDueDate) {
          const originalTime = existingDueDate.split(" ")[1];
          updatedDueDate = `${newDueDate} ${originalTime || "00:00:00.000000"}`;
        } else {
          updatedDueDate = `${newDueDate} 00:00:00.000000`;
        }

        accumulatedFileDates.set(fileName, updatedDueDate);
        accumulatedFileDates = accumulatedFileDates;
      }
    } catch (e) {
      toast.error(
        `Something went wrong updating the due date for file: ${fileName}.`,
      );
      console.error(e);
    }
  }

  async function updateDueDate(assetId, newDueDate) {
    try {
      let updatedDueDate = newDueDate;
      if (newDueDate) {
        const file = uploadedFiles.find((f) => f.id === assetId);
        if (file && file.due_date) {
          const originalTime = file.due_date.split(" ")[1];
          updatedDueDate = `${newDueDate} ${originalTime || "00:00:00.000000"}`;
        } else {
          updatedDueDate = `${newDueDate} 00:00:00.000000`;
        }
      }

      const response = await fetchPatch(`/asset/${assetId}/due`, {
        due_date: updatedDueDate,
      });
      if (response.success) {
        toast.success("Due date updated successfully");
        await updateFileList(null, assetId);
      } else {
        toast.error("Failed to update due date");
      }
    } catch (error) {
      console.error("Error updating due date:", error);
      toast.error("Error updating due date");
    }
  }

  function handleCurrentStreamPermissionChange() {
    if (
      currentStreamPerm === "upload" ||
      currentStreamPerm === "edit" ||
      currentStreamPerm === "manage"
    ) {
      isUploadEnabled = true;
    } else {
      isUploadEnabled = false;
    }

    isUploadEnabled = isUploadEnabled;
  }

  async function updateFilesAndGoToFolder() {
    goToFolderClicked = true;

    if (stepsCompletedTill < step) stepsCompletedTill = step;

    push(`/review/${report_id}`);
    goToFolderClicked = false;
  }

  onMount(async () => {
    loaded = true;
  });

  $: handleCurrentStreamPermissionChange(), [currentStreamPerm];
</script>

{#if isUploadEnabled}
  <label
    class="border-base-content/30 hover:border-primary my-8 flex w-full max-w-screen-lg cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed p-10 text-center"
    on:drop|preventDefault={async (e) => {
      await handleUpdate(e.dataTransfer.files);
      e.target.classList.remove("border-primary");
    }}
    on:dragover|preventDefault
    on:dragenter|preventDefault={(e) =>
      e.target.classList.add("border-primary")}
    on:dragleave|preventDefault={(e) =>
      e.target.classList.remove("border-primary")}
  >
    <Icon icon="iconoir:cloud-upload" width="32px" />
    <p class="my-2 text-base font-semibold text-gray-500 dark:text-gray-400">
      Click (or Drag & Drop) a .pdf, .doc, .docx, .png, .jpg or .jpeg
      <br /> file to upload it for review
    </p>

    <input
      type="file"
      class="hidden"
      multiple
      on:change={async (e) => await handleUpdate(e.target.files)}
      accept=".pdf, .doc, .docx, .png, .jpg, .jpeg, .eml, .msg"
      disabled={isNewWorkflow}
    />
  </label>

  {#if isNewWorkflow}
    <div
      role="alert"
      class="alert alert-warning mb-8 flex w-full max-w-screen-lg"
    >
      <Icon icon="iconoir:warning-circle-solid" width="24px" />
      <span>
        Warning: Please apply a valid stream workflow before uploading assets.
      </span>
    </div>
  {/if}
{:else}
  <p class="my-2 text-base font-semibold text-gray-500 dark:text-gray-400">
    You do not have upload permissions for this report
  </p>
{/if}

{#if loaded && uploadedFiles.length > 0}
  <div class="flex max-w-screen-lg flex-col gap-2">
    <div
      class="grid grid-cols-[2fr_1fr_1fr_minmax(190px,1fr)] gap-2 border-b px-2 font-semibold"
    >
      <span class="col-span-1">Asset</span>
      <span class="col-span-1">Upload Status</span>
      <span class="col-span-1">Due Date</span>
      <span class="col-span-1">Actions</span>
    </div>
    <div>
      {#each uploadedFiles as file}
        <div
          class="bg-base-200 mb-1 grid grid-cols-[minmax(0px,2fr)_1fr_1fr_minmax(190px,1fr)] gap-2 rounded border px-4 py-2"
          in:fly={{ y: 50 }}
          out:slide
        >
          <div
            class="tooltip col-span-1 max-w-fit break-words"
            data-tip={file.name}
          >
            <p
              class="w-full overflow-hidden text-ellipsis whitespace-nowrap text-start text-lg font-medium"
            >
              {file.name}
            </p>
          </div>
          <div class="col-span-1 flex items-center">
            <div class="mr-5 flex items-center gap-2">
              {#if failedUploadMap.has(file.id)}
                <div class="flex items-center gap-2">
                  <Icon
                    icon="iconoir:xmark-circle-solid"
                    class="text-error text-lg"
                  />
                  <span class="text-sm">Upload Failed</span>
                </div>
              {:else if fileUploadStatusMap.has(file.name) && !file.id}
                <span class="text-sm">Queued</span>
              {:else if fileUploadStatusMap.has(file.name) && isValidUUID(file.id)}
                <div class="flex items-center gap-2">
                  <LogoLoader size="1.25rem" />
                  <span class="text-sm">Uploading</span>
                </div>
              {:else}
                <Icon
                  icon="iconoir:check-circle-solid"
                  class="text-primary text-lg"
                />
                <span class="text-sm">Uploaded</span>
              {/if}
            </div>
          </div>
          <div class="col-span-1">
            <input
              type="date"
              value={formatDateForInput(`${file.due_date} UTC`)}
              on:change={(e) => updateDueDate(file.id, e.target.value || null)}
              class="input input-xs input-bordered w-32"
              title="Due Date"
              disabled={isValidUUID(file.id)}
            />
          </div>
          <div class="col-span-1 flex flex-wrap items-center justify-end gap-1">
            <!-- svelte-ignore missing-declaration -->
            <button
              class="btn btn-outline btn-primary btn-xs"
              disabled={!isUploadEnabled || isValidUUID(file.id)}
              on:click={async () => {
                uploadNewVersionModal.showModal();
                activeAssetId = file.id;
                versions = await new Promise(function (resolve) {
                  retrieveVersions(activeAssetId)
                    .then((versions) => {
                      if (versions != null) {
                        resolve(versions);
                      } else {
                        resolve([]);
                      }
                    })
                    .catch((error) => {
                      toast.error(
                        `Error retrieving older versions of the asset ${error}`,
                      );
                      resolve([]);
                    });
                });
              }}
            >
              New Version
            </button>
            <!-- svelte-ignore missing-declaration -->
            <button
              class="btn btn-primary btn-xs"
              disabled={isValidUUID(file.id)}
              on:click={() => {
                previewAsset(file);
                preview_modal.showModal();
              }}
            >
              Preview
            </button>
            <div class="tooltip" data-tip="Delete">
              <button
                class="btn btn-square btn-error btn-xs"
                disabled={!isUploadEnabled}
                on:click={() => {
                  if (isValidUUID(file.id)) {
                    deleteUploadedFile(file.id);
                  } else {
                    document
                      .querySelector(`#delUploadedAsset-${file.id}`)
                      .showModal();
                  }
                }}
              >
                <Icon icon="iconoir:trash" />
              </button>
            </div>
            <ActionConfirmation
              modalId="delUploadedAsset-{file.id}"
              message="Are you sure you want to delete this asset?"
              onConfirm={() => {
                deleteUploadedFile(file.id);
                document.querySelector(`#delUploadedAsset-${file.id}`).close();
              }}
              onCancel={() =>
                document.querySelector(`#delUploadedAsset-${file.id}`).close()}
            />
          </div>
        </div>
      {/each}
    </div>
  </div>
{/if}

{#if currentStreamPerm == "upload"}
  <div class="mt-4 flex gap-2">
    <button
      class="btn btn-primary btn-sm"
      on:click={checkForCompletionAndViewStream}
    >
      View Folder
    </button>
  </div>
{:else}
  <div class="mt-4 flex gap-2">
    <button
      class="btn btn-sm"
      on:click={() => {
        if (step === 0) return;
        step -= 1;
      }}
    >
      Back
    </button>
    {#if isNewWorkflow || uploadingFiles.length > 0 || uploadedFiles.length + filePreviews.length === 0 || failedUploadMap.size > 0}
      {@const tooltip = isNewWorkflow
        ? "Please add a valid stream workflow"
        : uploadedFiles.length + filePreviews.length === 0
          ? "Upload at least one asset"
          : failedUploadMap.size > 0
            ? "Please remove any files that failed to upload"
            : "Please wait while the files are being uploaded"}
      <div class="tooltip" data-tip={tooltip}>
        <button class="btn btn-primary btn-sm" disabled>
          {#if !goToFolderClicked && nextClicked}
            <LogoLoader size="1.25rem" />
          {/if}
          Next Step
        </button>
      </div>
    {:else}
      <button class="btn btn-primary btn-sm" on:click={checkForStepCompletion}>
        Next Step
      </button>
    {/if}

    {#if (filePreviews.length > 0 && !isNewWorkflow) || uploadingFiles.length > 0 || failedUploadMap.size > 0}
      {@const tooltip =
        uploadingFiles.length > 0
          ? "Please wait for all uploads to complete"
          : failedUploadMap.size > 0
            ? "Please remove any files that failed to upload"
            : ""}
      <div class="tooltip" data-tip={tooltip}>
        <button
          class="btn btn-primary btn-sm"
          disabled={(uploadingFiles.length > 0 &&
            (goToFolderClicked || nextClicked)) ||
            uploadingFiles.length > 0 ||
            failedUploadMap.size > 0}
          on:click={async () => {
            await updateFilesAndGoToFolder();
          }}
        >
          {#if uploadingFiles.length > 0 && goToFolderClicked}
            <LogoLoader size="1.25rem" />
          {/if}
          Go to Folder
        </button>
      </div>
    {:else}
      <GoToFolderButton
        {report_id}
        {isNewWorkflow}
        {uploadedFiles}
        {ran_reports}
        on:click={checkForStepCompletion}
      />
    {/if}
  </div>
{/if}

<UploadNewVersion
  assetId={activeAssetId}
  bind:currentStreamPerm
  {versions}
  {report_id}
  {isNewWorkflow}
/>

<Modal modalId="preview_modal" size="md">
  <div class="flex gap-2">
    <Icon
      icon={["png", "jpeg", "jpg"].includes(previewFileType.split("/").pop())
        ? "iconoir:media-image"
        : "iconoir:page"}
      class="shrink-0 text-3xl"
    />

    <h3 class="mb-2 truncate text-lg font-bold">
      {previewName}
    </h3>
  </div>

  {#key previewUrl}
    {#if previewOpen}
      <DisplayPdf
        documentURL={previewUrl}
        documentType="proxy"
        bind:open={previewOpen}
      />
    {/if}
  {/key}
</Modal>
