<script>
  import Icon from "@iconify/svelte";
  import { onMount } from "svelte";
  import { toast } from "svelte-sonner";
  import { slide } from "svelte/transition";
  import Select from "../../components/Select.svelte";
  import { fetchDelete, fetchGet, fetchPatch, fetchPost } from "../../helpers";
  import { currentUser, org_users } from "../../stores";
  import LabelSearch from "../Labels/LabelSearch.svelte";
  import ListOfBeans from "../Labels/ListOfBeans.svelte";
  import GoToFolderButton from "./GoToFolderButton.svelte";

  export let type = "stream";
  export let classification = "live";

  export let step;
  export let stepsCompletedTill;
  export let report_id;
  export let streamName = "";
  export let streamDesc = "";
  export let streamStatus = "";
  export let currentStreamPerm = "none";
  export let isNewWorkflow = true;
  export let uploadedFiles = [];
  export let ran_reports = [];

  let associatedUsers = [];
  let associatedTeams = [];
  let streamAccess = "Specific people or teams";
  let streamPermissions = [
    { value: "view", label: "View" },
    { value: "upload", label: "Upload Assets" },
    { value: "edit", label: "Manage" },
  ];
  let selectedFrequency = "Never";
  let allLabels = [];
  let labels = [];

  onMount(async () => {
    await getOrganizationTeams();
    await getOrganizationPermission();
    const labelResponse = await fetchGet("/label");
    if (labelResponse.success) allLabels = await labelResponse.labels;

    await setStreamDetails();

    if (streamName.trim().length === 0) {
      await saveDetails();
      await addCurrentUserToStream();
    }

    await setStreamAccessDetails();
  });

  async function setStreamDetails() {
    const res = await fetchGet(`/report/details/${report_id}`);
    if (res.success === false) return;

    streamName = res.report.name;
    streamDesc = res.report.description;
    streamStatus = res.report.status;

    if (res.report.rule_run_schedule == 167) {
      selectedFrequency = "Weekly";
      /*else if (res.report.rule_run_schedule == 24) { //to enable if daily is supported in future
        selectedFrequency = "Daily";
      }*/
    } else {
      //default to never
      selectedFrequency = "Never";
    }

    labels = allLabels.filter((l) => res.report.label_ids.includes(l.id));
  }

  const setStreamAccessDetails = async () => {
    const usersResponse = await fetchGet(`/report/${report_id}/users`);
    if (usersResponse.success) associatedUsers = usersResponse.users;

    const teamsResponse = await fetchGet(`/report/${report_id}/teams`);
    if (teamsResponse.success) associatedTeams = teamsResponse.teams;

    const res3 = await fetchGet(`/report/get-all-subs/${report_id}`);
    if (res3.success) {
      const subs = res3.subscribers;

      if (subs.length > 0) {
        associatedUsers = associatedUsers.map((user) => {
          const sub = subs.find((sub) => sub.id === user.id);
          user.subscribed = sub ? true : false;
          return user;
        });
      }
    }
  };

  const saveDetails = async () => {
    //TODO: refactor this to use the reportFunctions saveDetails.
    await fetchPost("/report/1", {
      report_id,
      name:
        streamName.trim().length > 0
          ? streamName
          : type === "asset"
            ? "Untitled Folder"
            : "Untitled Stream",
      description: streamDesc.trim().length > 0 ? streamDesc : "",
      status: type == "asset" && streamStatus === "" ? "Uploads" : streamStatus,
      classification: classification,
    });
  };

  const addCurrentUserToStream = async () => {
    const currentUserId = $currentUser.id;

    await fetchPost(`/user/${currentUserId}/report/${report_id}`, {
      permission: "edit",
    }).catch((error) => {
      toast.error(
        `Something went wrong adding User with ID(${currentUserId}) to Report(${report_id})`,
      );
      console.error("Failed to add current user to stream:", error);
    });
    associatedUsers = [
      ...associatedUsers,
      { ...$currentUser, permission: "manage" },
    ];
    associatedUsers = associatedUsers;
    currentStreamPerm = "manage";
  };

  const checkForStepCompletion = async () => {
    let frequencyValue = -1;
    if (selectedFrequency === "Never") frequencyValue = -1;
    else if (selectedFrequency === "Weekly") frequencyValue = 167;
    // else if (selectedFrequency === "Daily") frequencyValue = 24;

    await fetchPost("/report/schedule/" + report_id, {
      schedule: frequencyValue,
    });

    if (streamName.trim().length >= 3) {
      await saveDetails();
      if (stepsCompletedTill < step) stepsCompletedTill = step;
    } else {
      stepsCompletedTill = -1;
      toast.warning("Please enter a valid name");
      return false;
    }

    if (labels.length > 0) {
      labels.forEach((l) => {
        fetchPost(`/label/assign/${l.id}`, {
          report_id,
        });
      });
    }

    step += 1;
  };

  // for stream access/ alerts
  let orgPermission = "none";

  let allUsers = $org_users;
  let allTeams = [];

  let entityToEditId = -1;
  let entityToEditType = "";

  let warningMessage = "";

  async function getOrganizationTeams() {
    try {
      const response = await fetchGet("/team");

      if (!response.success) {
        throw new Error(response.message);
      }

      allTeams = response.teams;
    } catch (error) {
      console.error(error);
    }
  }
  async function getOrganizationPermission() {
    try {
      const response = await fetchGet(
        `/report/${report_id}/get-org-permission`,
      );

      if (response.success) orgPermission = response.permission;
    } catch (error) {
      console.error(error);
    }
  }

  let selectedPermission = null;
  let selectedEntity = null;

  let selectableEntities = getSelectableEntities();

  $: if (orgPermission || associatedTeams || associatedUsers) {
    selectableEntities = getSelectableEntities();
  }

  function getSelectableEntities() {
    let output = [
      ...allUsers
        .filter((user) => !user.is_deleted)
        .map((user) => ({
          value: `user:${user.id}`,
          label: user.first_name + " " + user.last_name,
          group: "Users",
        })),

      ...allTeams.map((team) => ({
        value: `team:${team.id}`,
        label: team.name,
        group: "Teams",
      })),
    ];

    if (orgPermission === "none") {
      output = [
        ...output,
        {
          value: "org",
          label: "All Users",
          group: "Organisation",
        },
      ];
    }

    output = output.filter((entity) => {
      if (entity.group === "Teams") {
        return !isEntityAssociated(entity.value.split(":")[1], associatedTeams);
      } else if (entity.group === "Users") {
        return !isEntityAssociated(entity.value.split(":")[1], associatedUsers);
      }
      return true;
    });
    return output;
  }

  const groupBy = (entity) => entity.group;

  function isEntityAssociated(entityId, associatedEntities) {
    return associatedEntities.some((entity) => entity.id == entityId);
  }

  async function sharePermissionWithEntity() {
    if (selectedPermission === "none" || selectedEntity === "none") {
      warningMessage = "* Please select a permission and entity to share with.";
      return;
    }

    const [entityType, entityId] = selectedEntity.split(":");

    try {
      if (entityType != "org") {
        const response = await fetchPost(
          `/${entityType}/${entityId}/report/${report_id}`,
          {
            permission: selectedPermission,
          },
        );

        if (!response.success) {
          warningMessage = response.message;
          return;
        }
      } else {
        const response = await fetchPost(
          `/report/${report_id}/change-org-permission`,
          {
            permission: selectedPermission,
          },
        );

        if (!response.success) {
          warningMessage = response.message;
          return;
        }
      }
    } catch (error) {
      warningMessage = error.message;
      return;
    }
  }

  async function deletePermissionFromEntity() {
    try {
      if (entityToEditType === "org") {
        const response = await fetchPost(
          `/report/${report_id}/change-org-permission`,
          {
            permission: "none",
          },
        );

        if (!response.success) {
          warningMessage = response.message;
          return;
        }
      } else {
        const response = await fetchDelete(
          `/${entityToEditType}/${entityToEditId}/report/${report_id}`,
        );

        if (!response.success) {
          warningMessage = response.message;
          return;
        }
      }
    } catch (error) {
      warningMessage = error.message;
      return;
    }
  }

  async function updatePermissionForEntity() {
    try {
      if (entityToEditType === "org") {
        const response = await fetchPost(
          `/report/${report_id}/change-org-permission`,
          {
            permission: selectedPermission,
          },
        );

        if (!response.success) {
          warningMessage = response.message;
          return;
        }
      } else {
        const response = await fetchPatch(
          `/${entityToEditType}/${entityToEditId}/report/${report_id}`,
          {
            permission: selectedPermission,
          },
        );

        if (!response.success) {
          warningMessage = response.message;
          return;
        }
      }
    } catch (error) {
      warningMessage = error.message;
      return;
    }
  }

  async function subscribeToReport(userId) {
    try {
      const response = await fetchPost(`/report/subscribe/${report_id}`, {
        user_id: userId,
      });

      if (!response.success) {
        warningMessage = response.message;
        return;
      }
    } catch (error) {
      warningMessage = error.message;
      return;
    }
  }

  async function unsubscribeFromReport(userId) {
    try {
      const response = await fetchDelete(`/report/subscribe/${report_id}`, {
        user_id: userId,
      });

      if (!response.success) {
        warningMessage = response.message;
        return;
      }
    } catch (error) {
      warningMessage = error.message;
      return;
    }
  }
</script>

<label class="label max-w-xl">
  <span class="label-text">Folder Name</span>
  <input
    type="text"
    placeholder="Type here"
    class="input input-bordered w-full"
    minlength="4"
    maxlength="60"
    bind:value={streamName}
  />
</label>

<label class="label max-w-xl">
  <span class="label-text">Folder Description</span>
  <textarea
    class="textarea textarea-bordered h-24 w-full"
    placeholder="Add a description, outlining the {type == 'asset'
      ? 'type of assets this folder contains'
      : type == 'spelling'
        ? 'spelling and grammar issues this report has been set up to monitor'
        : 'topics or issues this stream has been set up to monitor'}"
    bind:value={streamDesc}
  />
</label>

<label class="label max-w-xl" for="">
  <span class="label-text">Folder Labels</span>
  <div class="z-20 flex flex-wrap items-center gap-2">
    <LabelSearch {allLabels} bind:selectedLabels={labels} />

    {#if labels.length > 0}
      <ListOfBeans
        {labels}
        on:labelBeanClicked={(e) =>
          (labels = labels.filter((l) => l.id !== e.detail.id))}
        closeIcon={true}
      />
    {/if}
  </div>
</label>

<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<label class="label max-w-xl" for="">
  <span class="label-text">
    Document Owners

    <div class="dropdown dropdown-end dropdown-top dropdown-hover">
      <div tabindex="0" role="button" class="btn btn-circle btn-ghost btn-xs">
        <Icon icon="iconoir:info-circle" class="text-base" />
      </div>
      <div
        tabindex="0"
        class="card dropdown-content compact bg-base-100 left-[-170px] z-[1] w-96 rounded border shadow"
      >
        <div tabindex="0" class="card-body text-sm">
          <h2 class="font-semibold">There are 3 levels of permissions:</h2>
          <p>View: See assets in the report, comment, and track progress.</p>
          <p>Upload: Add new assets and upload new versions.</p>
          <p>
            Manage: Configure folder settings, step-level approval permissions
            (at the next page).
          </p>
        </div>
      </div>
    </div>
  </span>
</label>

{#if streamAccess === "Specific people or teams"}
  <div class="max-w-xl" transition:slide>
    <Select
      classes="w-full"
      items={selectableEntities}
      bind:selectedValue={selectedEntity}
      placeholder="Add Users or Teams"
      on:change={(e) => {
        selectedPermission = "view";
        selectedEntity = e.detail;

        if (selectedEntity.startsWith("team")) {
          const teamId = selectedEntity.split(":")[1];
          const team = allTeams.find((team) => team.id == teamId);

          if (!isEntityAssociated(teamId, associatedTeams)) {
            associatedTeams = [
              ...associatedTeams,
              { ...team, permission: "view" },
            ];
          }
        } else if (selectedEntity.startsWith("user")) {
          const userId = selectedEntity.split(":")[1];
          const user = allUsers.find((user) => user.id == userId);

          if (!isEntityAssociated(userId, associatedUsers)) {
            associatedUsers = [
              ...associatedUsers,
              { ...user, permission: "view" },
            ];
          }
        } else orgPermission = "view";

        sharePermissionWithEntity();

        selectedPermission = null;
        selectedEntity = null;
      }}
    />

    <table class="mt-2 table">
      <thead>
        <tr>
          <th>People/Teams</th>
          <th>
            <div
              class="tooltip tooltip-bottom"
              data-tip="Permission to view / edit this stream"
            >
              Permissions
            </div>
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        {#each associatedUsers as user (user.id)}
          <tr>
            <td>
              <div class="flex items-center gap-1">
                <Icon icon="iconoir:user" />
                {user.first_name
                  ? `${user.first_name} ${user.last_name}`
                  : "All Users"}
              </div>
            </td>
            <td>
              <div
                class={user.id === $currentUser.id && user.role !== "admin"
                  ? "tooltip"
                  : ""}
                data-tip="You cannot modify your own access permissions. Please request an administrator perform this action for you."
              >
                <Select
                  selectedValue={user.permission}
                  disabled={user.id === $currentUser.id &&
                    user.role !== "admin"}
                  on:change={(e) => {
                    entityToEditId = user.id;
                    entityToEditType = "user";
                    selectedPermission = e.detail;
                    updatePermissionForEntity();
                  }}
                  items={streamPermissions}
                />
              </div>
            </td>
            <td>
              <div
                class="{user.id === $currentUser.id && user.role !== 'admin'
                  ? 'tooltip'
                  : ''} float-right"
                data-tip="You cannot remove yourself from a report. Please request an administrator perform this action for you."
              >
                <button
                  class="btn btn-square btn-ghost btn-sm hover:btn-error float-right"
                  disabled={user.id === $currentUser.id &&
                    user.role !== "admin"}
                  on:click={() => {
                    entityToEditId = user.id;
                    entityToEditType = "user";
                    associatedUsers = associatedUsers.filter(
                      (user) => user.id !== entityToEditId,
                    );
                    deletePermissionFromEntity();
                  }}
                >
                  <Icon icon="iconoir:cancel" />
                </button>
              </div>
            </td>
          </tr>
        {/each}
        {#each associatedTeams as team (team.id)}
          <tr>
            {#if team.name !== "All Users"}
              <td>
                <div class="flex items-center gap-1">
                  <Icon icon="iconoir:community" />
                  {team.name}
                </div>
              </td>
              {#if !type == "asset"}<td />{/if}
              <td>
                <Select
                  selectedValue={team.permission}
                  on:change={(e) => {
                    entityToEditId = team.id;
                    entityToEditType = "team";
                    selectedPermission = e.detail;
                    updatePermissionForEntity();
                  }}
                  items={streamPermissions}
                />
              </td>
              <td>
                <button
                  class="btn btn-square btn-ghost btn-sm hover:btn-error float-right"
                  on:click={() => {
                    entityToEditId = team.id;
                    entityToEditType = "team";
                    associatedTeams = associatedTeams.filter(
                      (team) => team.id !== entityToEditId,
                    );
                    deletePermissionFromEntity();
                  }}
                >
                  <Icon icon="iconoir:cancel" />
                </button>
              </td>
            {/if}
          </tr>
        {/each}
        {#if orgPermission != "none"}
          <tr>
            <td>
              <div class="flex items-center gap-1">
                <Icon icon="iconoir:community" /> All Users
              </div>
            </td>
            {#if !type == "asset"}<td />{/if}
            <td>
              <Select
                selectedValue={orgPermission}
                on:change={(e) => {
                  entityToEditId = "org";
                  entityToEditType = "org";
                  selectedPermission = e.detail.value;
                  updatePermissionForEntity();
                }}
                items={streamPermissions}
              />
            </td>
            <td>
              <button
                class="btn btn-square btn-ghost btn-sm hover:btn-error float-right"
                on:click={() => {
                  entityToEditId = "org";
                  entityToEditType = "org";
                  orgPermission = "none";
                  deletePermissionFromEntity();
                }}
              >
                <Icon icon="iconoir:cancel" />
              </button>
            </td>
          </tr>
        {/if}
      </tbody>
    </table>
  </div>
{/if}

<div class="mt-4 flex max-w-xl gap-2">
  <div class="tooltip" data-tip="You are on the first page">
    <button class="btn btn-sm" disabled>Back</button>
  </div>

  {#if streamName.length < 3}
    <div
      class="tooltip"
      data-tip="{type == 'asset'
        ? 'Folder'
        : type == 'spelling'
          ? 'Report'
          : 'Stream'} Name should at least have 3 letters"
    >
      <button class="btn btn-primary btn-sm" disabled>Next Step</button>
    </div>
  {:else}
    <button class="btn btn-primary btn-sm" on:click={checkForStepCompletion}>
      Next Step
    </button>

    <GoToFolderButton
      {report_id}
      {isNewWorkflow}
      {uploadedFiles}
      {ran_reports}
      on:click={checkForStepCompletion}
    />
  {/if}
</div>

<style lang="postcss">
  .label {
    @apply flex-col items-start p-0;
  }
  .label-text {
    @apply py-1;
  }
</style>
