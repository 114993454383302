<script>
  import PhraseItem from "./PhraseItem.svelte";
  import Icon from "@iconify/svelte";

  export let phrases;
  let isCollapsed = phrases.length > 5; // Set isCollapsed to true if there are more than 10 phrases

  const toggleCollapse = () => {
    isCollapsed = !isCollapsed;
  };
</script>

<div class="my-4 flex">
  {#if phrases.length > 5}
    <button class="pl group z-10 my-4" on:click={toggleCollapse}>
      <!-- Makes the target area larger -->
      <div
        class="border-primary ml-2 h-full rounded-l-md border-b border-l border-t border-opacity-20 pr-2 group-hover:border-opacity-100"
      />
    </button>
  {/if}
  <div class="tree flex w-full flex-col gap-4">
    {#each phrases as phrase, index}
      {#if index === 0}
        <PhraseItem {phrase} />
      {:else if index === phrases.length - 1}
        <PhraseItem {phrase} />
      {:else}
        {#if index === 1 && isCollapsed}
          <div class="flex" class:leaf={phrases.length > 5}>
            <button
              class="btn btn-primary btn-sm btn-outline"
              on:click={toggleCollapse}
            >
              <Icon icon="iconoir:plus" /> Show {phrases.length - 2} more phrases
            </button>
          </div>
        {/if}
        {#if !isCollapsed}
          <div class:leaf={phrases.length > 5}>
            <PhraseItem {phrase} />
          </div>
        {/if}
      {/if}
    {/each}
  </div>
</div>

<style>
  .tree .leaf::before {
    content: "–";
    position: absolute;
    transform: translate(-8px, 4px);
    color: #0064bc;
    opacity: 0.2;
  }
</style>
