<script>
  import PSPDFKit from "pspdfkit";
  import { afterUpdate, onDestroy, onMount } from "svelte";

  const API_VERSION = import.meta.env.VITE_API_VERSION;
  let currentDocument;
  let container = "#pspdfkit";
  let instance;

  export let documentA;
  export let documentB;

  async function load() {
    const licenseKey = import.meta.env.VITE_APP_PSPDFKIT_LICENSE;
    currentDocument = documentA;
    instance = await PSPDFKit.load({
      baseUrl: `${window.location.protocol}//${window.location.host}/`,
      licenseKey: licenseKey,
      container: container,
      document: documentA,

      toolbarItems: [
        { type: "sidebar-document-outline" },
        { type: "search" },
        // { type: "comment" }, // Temporarily removed as comments are currently non-persistent. | 02/08/2024
      ],
      treatImportedDocumentAsOnePage: true,
    });

    // Use this for visual overlay comparison, can have different blending modes
    // instance.setDocumentComparisonMode({
    //   documentA: {
    //     source: PSPDFKit.DocumentComparisonSourceType.USE_OPEN_DOCUMENT,
    //   },
    //   documentB: { source: documentB },
    //   autoCompare: true,
    // });

    try {
      // TODO: Add methodology to verify the file types and appropriately handle them
      const originalDocument = new PSPDFKit.DocumentDescriptor({
        filePath: documentA,
        pageIndexes: Array.from(
          { length: instance.totalPageCount },
          (_, i) => i,
        ),
      });
      const changedDocument = new PSPDFKit.DocumentDescriptor({
        filePath: documentB,
        pageIndexes: Array.from(
          { length: instance.totalPageCount },
          (_, i) => i,
        ),
      });
      const textComparisonOperation = new PSPDFKit.ComparisonOperation(
        PSPDFKit.ComparisonOperationType.TEXT,
        { numberOfContextWords: 2 },
      );
      const comparisonResult = await instance.compareDocuments(
        { originalDocument, changedDocument },
        textComparisonOperation,
      );

      //console.log("comparisonResult", comparisonResult);

      comparisonResult.forEach((cr) => {
        cr.documentComparisonResults.forEach((result) => {
          result.comparisonResults.forEach((comparison) => {
            comparison.hunks.forEach((hunk) => {
              hunk.operations
                .filter((ops) => ops.type !== "equal")
                .forEach((ops) => {
                  const rects = PSPDFKit.Immutable.List(
                    ops.originalTextBlocks
                      .map((block) => ({
                        left: block.rect[0],
                        top:
                          instance.pageInfoForIndex(result.originalPageIndex)
                            .height -
                          block.rect[1] -
                          block.rect[3],
                        width: block.rect[2],
                        height: block.rect[3],
                      }))
                      .map((rect) => new PSPDFKit.Geometry.Rect(rect)),
                  );

                  const annotation =
                    new PSPDFKit.Annotations.HighlightAnnotation({
                      pageIndex: result.originalPageIndex,
                      rects: rects,
                      boundingBox: PSPDFKit.Geometry.Rect.union(rects),
                      note: `"${ops.text}" was ${ops.type === "insert" ? "added" : ops.type === "delete" ? "removed" : "modified"}.`,
                      creatorName: "Haast Compare Bot",
                    });

                  instance.create(annotation);
                });
            });
          });
        });
      });
    } catch (e) {
      console.error(e);
    }
  }

  function unload() {
    if (instance) {
      PSPDFKit.unload(instance);
      instance = null;
    }
  }

  onMount(() => {
    documentA = `${window.location.protocol}//${window.location.host}/api/${API_VERSION}/pdf/proxy_pdf?url=${documentA}`;
    documentB = `${window.location.protocol}//${window.location.host}/api/${API_VERSION}/pdf/proxy_pdf?url=${documentB}`;
    load();
  });
  afterUpdate(() => {
    if (documentA !== currentDocument) {
      unload();
      load();
    }
  });
  onDestroy(unload);
</script>

<div
  bind:this={container}
  class="bg-base-200 mt-2 h-[calc(100vh-168px)] w-full rounded border p-2"
/>
