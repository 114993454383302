<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher, onMount } from "svelte";
  import { toast } from "svelte-sonner";
  import { link } from "svelte-spa-router";
  import { fly } from "svelte/transition";
  import { fetchDelete, fetchGet, fetchPost } from "../helpers";
  import { currentUser } from "../stores";
  import LabelSearch from "./Labels/LabelSearch.svelte";
  import ListOfBeans from "./Labels/ListOfBeans.svelte";

  const dispatch = createEventDispatcher();

  export let report;
  export let spellingReport = false;

  export let labels = [];
  export let allLabels = [];

  export let activeTabLabel = "Live";

  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  }

  function labelSelected(event) {
    const selectedLabel = event.detail;

    if (!labels.some((label) => label.id === selectedLabel.id)) {
      fetchPost(`/label/assign/${selectedLabel.id}`, {
        report_id: report.id,
      });

      labels = [...labels, selectedLabel];
    }

    dispatch("labelAdded", selectedLabel);
  }

  function deleteLabel(event) {
    const label = event.detail;

    fetchDelete(`/label/assign/${label.id}`, {
      report_id: report.id,
    });

    labels = labels.filter((l) => l.id !== label.id);

    dispatch("labelRemoved", label);
  }

  let canEditThisStream = false;

  onMount(async () => {
    if ($currentUser.role == "admin") canEditThisStream = true;
    else {
      const response = await fetchGet(
        `/report/${report_id}/get-user-permission-to-report`,
      );

      const orgPermissionsResponse = await fetchGet(
        `/report/${report_id}/get-org-permission`,
      );

      // if (!response.permission || response.permission === "none") {
      //   if (
      //     !orgPermissionsResponse.permission ||
      //     orgPermissionsResponse.permission === "none"
      //   )
      // push("/streams");
      // }
      if (
        response.permission === "edit" ||
        orgPermissionsResponse.permission === "edit"
      )
        canEditThisStream = true;
      else canEditThisStream = false;
    }

    //console.log("canEditThisStream of onMount check", canEditThisStream);
  });

  const duplicateStream = async () => {
    const res = await fetchGet(`/report/clone/${report.id}`);
    if (res.success) dispatch("reportCloned", res.report_id);
  };
</script>

<article
  class="bg-base-100 relative flex min-w-80 flex-col justify-between rounded border p-4"
  in:fly={{ y: 50 }}
>
  <div>
    <div
      class="flex items-center {!(
        report.description && report.description.trim().length > 0
      )
        ? 'pb-1'
        : ''}"
    >
      <div class="tooltip max-w-[calc(100%-96px)]" data-tip={report.name}>
        <a
          href="/{spellingReport ? 'spelling' : 'stream'}/{activeTabLabel ===
          'Draft'
            ? 'edit/'
            : ''}{report.id}"
          use:link
          class="block overflow-hidden text-ellipsis whitespace-nowrap text-start text-lg font-semibold"
        >
          {report.name}
        </a>
      </div>
      <div class="ml-auto inline-flex">
        <div
          class="tooltip"
          data-tip={report.permission === "view"
            ? "Can view"
            : report.permission === "edit" || report.permission === "admin"
              ? "Can edit"
              : ""}
        >
          <a
            href="/{spellingReport ? 'spelling' : 'stream'}/{activeTabLabel ===
            'Draft'
              ? 'edit/'
              : ''}{report.id}"
            use:link
            class="btn btn-square btn-ghost btn-sm"
          >
            {#if report.permission === "view"}
              <Icon icon="iconoir:eye-empty" />
            {:else if report.permission === "edit" || report.permission === "admin"}
              <Icon icon="iconoir:edit-pencil" />
            {/if}
          </a>
        </div>
        <div class="tooltip" data-tip="Duplicate Stream">
          <button
            class="btn btn-square btn-ghost btn-sm"
            on:click={async () =>
              toast.promise(duplicateStream(), {
                loading: "Creating duplicate stream...",
                // success: (data) => `${data.name} toast has been added`,
                success: "Duplicate stream created",
                error: "Error creating duplicate stream",
              })}
          >
            <Icon icon="iconoir:copy" />
          </button>
        </div>
        <div class="tooltip tooltip-left" data-tip="Open stream in new tab">
          <a
            href="/#/{spellingReport
              ? 'spelling'
              : 'stream'}/{activeTabLabel === 'Draft'
              ? 'edit/'
              : ''}{report.id}"
            target="_blank"
            class="btn btn-square btn-ghost btn-sm"
          >
            <Icon icon="iconoir:share-ios" />
          </a>
        </div>
      </div>
    </div>

    {#if report.description && report.description.trim().length > 0}
      <p class="text-base-content/70 py-2 text-sm">{report.description}</p>
    {/if}

    <div class="z-20 flex flex-wrap items-center gap-2">
      <LabelSearch
        {allLabels}
        selectedLabels={labels}
        on:labelSelected={labelSelected}
        on:labelUnselected={deleteLabel}
        disabled={!canEditThisStream || activeTabLabel === "Archived"}
      />
      {#if labels.length > 0}
        <ListOfBeans
          {labels}
          on:labelBeanClicked={deleteLabel}
          closeIcon={true}
          disabled={!canEditThisStream || activeTabLabel === "Archived"}
        />
      {/if}
    </div>

    <div class="text-base-content/80 m-4 space-y-2 text-sm">
      <div class="flex items-center justify-between gap-2">
        <p class="inline-flex">Total hits</p>
        <span
          class="bg-base-200 w-fit-content min-w-16 items-center rounded-full px-4 py-1 text-right"
        >
          {report.hits_count}
        </span>
      </div>
      <div class="flex items-center justify-between gap-2">
        <p class="inline-flex">Flagged hits</p>
        <span
          class="bg-base-200 w-fit-content min-w-16 items-center rounded-full px-4 py-1 text-right"
        >
          {report.flagged_hits_count}
        </span>
      </div>
      <div class="flex items-center justify-between gap-2">
        <p class="inline-flex">Open hits</p>
        <span
          class="bg-base-200 w-fit-content min-w-16 items-center rounded-full px-4 py-1 text-right"
        >
          {report.open_hits_count}
        </span>
      </div>
    </div>
  </div>
  <div
    class="text-base-content/60 flex justify-between border-t-2 pt-2 text-xs"
  >
    <span>Date Created {formatDate(report.created_date)}</span>
    <span>Last Updated {formatDate(report.last_refreshed)}</span>
  </div>
</article>
