<!-- WorkflowStepNode.svelte -->
<script>
  import Icon from "@iconify/svelte";
  import { Handle, Position } from "@xyflow/svelte";
  import Select from "../../components/Select.svelte";
  import { fetchPost } from "../../helpers";
  import { org_teams, org_users } from "../../stores";
  import Modal from "../Modals/Modal.svelte";
  import { saveStep } from "./workflowAction";
  import { onMount } from "svelte";

  export let isConnectable = false;
  export let data;

  let report_id = data.report_id;
  let associatedTeams = [];
  let associatedUsers = [];

  let allUsers = [];
  let allTeams = [];

  onMount(async () => {
    allUsers = $org_users.filter((ou) => !ou.is_deleted);
    allTeams = $org_teams;

    // Reconstruct associatedUsers
    associatedUsers = data.users.map(user => {
      const fullUser = allUsers.find(u => u.id == user.user_id);
      return {
        id: user.user_id,
        first_name: fullUser ? fullUser.first_name : '',
        last_name: fullUser ? fullUser.last_name : '',
        permission: user.permission
      };
    });

    // Reconstruct associatedTeams
    associatedTeams = data.teams.map(team => {
      const fullTeam = allTeams.find(t => t.id == team.team_id);
      return {
        id: team.team_id,
        name: fullTeam ? fullTeam.name : '',
        permission: team.permission
      };
    });

    console.log("Reconstructed Users:", associatedUsers);
    console.log("Reconstructed Teams:", associatedTeams);
  });

  function save() {
    saveStep.set({
      id: data.id,
      name: data.name,
      description: data.description,
      users: associatedUsers,
      teams: associatedTeams,
      type: "save",
    });
  }

  function remove() {
    // console.log("Save", {
    //   id: data.id,
    //   name: data.name,
    //   description: data.description,
    //   users: associatedUsers,
    //   teams:associatedTeams
    // });
    saveStep.set({
      id: data.id,
      name: data.name,
      description: data.description,
      users: associatedUsers,
      teams: associatedTeams,
      type: "delete",
    });
  }

  // for stream access/ alerts

  let orgPermission = "none";

  // let allUsers = $org_users.filter((ou) => !ou.is_deleted);
  // let allTeams = $org_teams;

  let entityToEditId = -1;
  let entityToEditType = "";

  let warningMessage = "";

  let selectedPermission = null;
  let selectedEntity = null;

  let selectableEntities = getSelectableEntities();

  $: if (orgPermission || associatedTeams || associatedUsers) {
    selectableEntities = getSelectableEntities();
  }

  function getSelectableEntities() {
    let output = [
      ...allUsers.map((user) => ({
        value: `user:${user.id}`,
        label: user.first_name + " " + user.last_name,
        group: "Users",
      })),

      ...allTeams.map((team) => ({
        value: `team:${team.id}`,
        label: team.name,
        group: "Teams",
      })),
    ];

    output = output.filter((entity) => {
      if (entity.group === "Teams") {
        return !isEntityAssociated(entity.value.split(":")[1], associatedTeams);
      } else if (entity.group === "Users") {
        return !isEntityAssociated(entity.value.split(":")[1], associatedUsers);
      }
      return true;
    });
    return output;
  }

  function isEntityAssociated(entityId, associatedEntities) {
    return associatedEntities.some((entity) => entity.id == entityId);
  }

  async function sharePermissionWithEntity() {
    if (selectedPermission === "none" || selectedEntity === "none") {
      warningMessage = "* Please select a permission and entity to share with.";
      return;
    }

    const [entityType, entityId] = selectedEntity.split(":");

    try {
      if (entityType != "org") {
        const response = await fetchPost(
          `/${entityType}/${entityId}/report/${report_id}`,
          { permission: selectedPermission },
        );

        if (!response.success) {
          warningMessage = response.message;
          return;
        }
      } else {
        const response = await fetchPost(
          `/report/${report_id}/change-org-permission`,
          { permission: selectedPermission },
        );

        if (!response.success) {
          warningMessage = response.message;
          return;
        }
      }
    } catch (error) {
      warningMessage = error.message;
      return;
    }
  }
</script>

<div class="workflow-step-node">
  <Handle type="target" position={Position.Left} {isConnectable} />

  <div class="flex w-64 flex-col gap-2 rounded border bg-white p-4 shadow-lg">
    <label class="form-control">
      <p class="text-sm font-medium">Review Group</p>
      <input
        type="text"
        bind:value={data.name}
        on:change={save}
        placeholder="Name"
        class="input input-sm input-bordered"
        id="name"
      />
    </label>

    <label class="form-control">
      <p class="text-sm font-medium">Description</p>
      <textarea
        class="textarea textarea-bordered textarea-sm"
        id="description"
        placeholder="Description"
        bind:value={data.description}
        on:change={save}
      ></textarea>
    </label>

    <div>
      <p class="text-sm font-medium">Approvers</p>

      <button
        class="btn btn-outline btn-primary btn-xs w-full"
        on:click|stopPropagation={() =>
          document.getElementById(`assign-user-${data.id}`).showModal()}
      >
        Add Approvers
      </button>

      <Modal modalId="assign-user-{data.id}">
        <h3 class="text-lg font-bold">Assigned Users</h3>

        <Select
          classes="w-full mt-2"
          items={selectableEntities}
          bind:selectedValue={selectedEntity}
          on:change={(e) => {
            selectedPermission = "view";
            selectedEntity = e.detail;

            if (selectedEntity.startsWith("team")) {
              const teamId = selectedEntity.split(":")[1];
              const team = allTeams.find((team) => team.id == teamId);

              if (!isEntityAssociated(teamId, associatedTeams)) {
                associatedTeams = [
                  ...associatedTeams,
                  { ...team, permission: "approve" },
                ];
              }
            } else if (selectedEntity.startsWith("user")) {
              const userId = selectedEntity.split(":")[1];
              const user = allUsers.find((user) => user.id == userId);

              if (!isEntityAssociated(userId, associatedUsers)) {
                associatedUsers = [
                  ...associatedUsers,
                  { ...user, permission: "approve" },
                ];
              }
            } else orgPermission = "view";

            sharePermissionWithEntity();

            selectedPermission = null;
            selectedEntity = null;
            save();
          }}
        />

        <table class="table">
          <thead>
            <tr>
              <th class="w-full">People/Team</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {#each associatedUsers as user (user.id)}
              <tr>
                <td>{user.first_name} {user.last_name}</td>
                <td>
                  <button
                    class="btn btn-outline btn-error btn-xs"
                    on:click={() => {
                      entityToEditId = user.id;
                      entityToEditType = "user";
                      associatedUsers = associatedUsers.filter(
                        (u) => u.id !== entityToEditId,
                      );
                      save();
                    }}
                  >
                    Remove
                  </button>
                </td>
              </tr>
            {/each}
            {#each associatedTeams as team (team.id)}
              <tr>
                {#if team.name !== "All Users"}
                  <td>{team.name}</td>
                  <td>
                    <button
                      class="btn btn-outline btn-error btn-xs"
                      on:click={() => {
                        entityToEditId = team.id;
                        entityToEditType = "team";
                        associatedTeams = associatedTeams.filter(
                          (t) => t.id !== entityToEditId,
                        );
                        save();
                        //deletePermissionFromEntity();
                      }}
                    >
                      Remove
                    </button>
                  </td>
                {/if}
              </tr>
            {/each}
          </tbody>
        </table>
      </Modal>

      <table class="table-xs bg-base-200 mt-2 table rounded">
        <thead>
          <tr class="border-b-base-content/10 border-b">
            <th class="w-full">Approver</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {#each associatedUsers as user (user.id)}
            <tr>
              <td>
                <div class="flex items-center gap-1">
                  <Icon icon="iconoir:user" class="shrink-0" />
                  {user.first_name}
                  {user.last_name}
                </div>
              </td>
              <td>
                <button
                  class="btn btn-square btn-ghost btn-sm hover:btn-error"
                  on:click={() => {
                    entityToEditId = user.id;
                    entityToEditType = "user";
                    associatedUsers = associatedUsers.filter(
                      (u) => u.id !== entityToEditId,
                    );
                    save();
                  }}
                >
                  <Icon icon="iconoir:cancel" />
                </button>
              </td>
            </tr>
          {/each}
          {#each associatedTeams as team (team.id)}
            <tr>
              {#if team.name !== "All Users"}
                <td>
                  <div class="flex items-center gap-1">
                    <Icon icon="iconoir:community" />
                    {team.name}
                  </div>
                </td>
                <td>
                  <button
                    class="btn btn-square btn-ghost btn-sm hover:btn-error"
                    on:click={() => {
                      entityToEditId = team.id;
                      entityToEditType = "team";
                      associatedTeams = associatedTeams.filter(
                        (t) => t.id !== entityToEditId,
                      );
                      save();
                      //deletePermissionFromEntity();
                    }}
                  >
                    <Icon icon="iconoir:cancel" />
                  </button>
                </td>
              {/if}
            </tr>
          {/each}
        </tbody>
      </table>
    </div>

    <div class="bg-base-200 -m-4 mt-2 flex flex-col gap-1 px-4 py-2">
      <button class="btn btn-sm btn-ghost text-primary" on:click={remove}>
        Delete
      </button>
    </div>
  </div>

  <Handle type="source" position={Position.Right} {isConnectable} />
</div>

<style>
  :global(.svelte-flow .handle) {
    background-color: transparent;
    pointer-events: none;
    min-width: 0;
    min-height: 0;
    width: 0;
    height: 0;
    background-color: var(
      --xy-handle-background-color,
      var(--xy-handle-background-color-default)
    );
    border: 0;
    border-radius: 0;
  }
</style>
