<script>
  //TODO: separate out checklist generation into a sub component
  //TODO: add visual rule builder to boolean rule generation option
  import { ConditionalRuleType } from "./../../lib/interfaces/Rule.interface.js";
  import Icon from "@iconify/svelte";
  import { onMount } from "svelte";
  import { toast } from "svelte-sonner";
  import { link } from "svelte-spa-router";
  import { slide } from "svelte/transition";
  import {
    buildExamples,
    buildSearchSources,
    fetchDelete,
    fetchGet,
    fetchPatch,
    fetchPost,
    saveAssetRule,
    saveRuleToMyRules,
  } from "../../helpers";
  import {
    RuleSearchType,
    RuleStatus,
  } from "../../lib/interfaces/Rule.interface";
  import { NoRuleSetDefaults } from "../../lib/interfaces/RuleSet.interface";
  import { SharedRuleStatus } from "../../lib/interfaces/SharedRule.interface";
  import { deepCopy, isEmpty } from "../../lib/utils/GenericUtils";
  import {
    groupConditionalRules,
    isParentConditionalRule,
    ruleSearchTypeToLabel,
  } from "../../lib/utils/RuleUtils";
  import {
    convertBoolToBuilder,
    convertBuilderToBoolean,
  } from "../../ruleBuilderConverter";
  import LoadRules from "..//Reports/LoadRules.svelte";
  import ActionConfirmation from "../ActionConfirmation.svelte";
  import AssetRuleBuilder from "../AssetReview/AssetRuleBuilder.svelte";
  import AssetRuleTypeSelector from "../AssetReview/AssetRuleTypeSelector.svelte";
  import ConditionalAssetRuleBuilder from "../AssetReview/ConditionalAssetRuleBuilder.svelte";
  import ContextualRuleRow from "../AssetReview/ContextualRules/ContextualRuleRow.svelte";
  import QueryPrettyPrint from "../AssetReview/QueryPrettyPrint.svelte";
  import Badge from "../AssetReview/WorkflowBoard/Badge.svelte";
  import ChecklistInfoModal from "../ChecklistInfoModal.svelte";
  import LogoLoader from "../LogoLoader.svelte";
  import Modal from "../Modals/Modal.svelte";
  import Select from "../Select.svelte";
  import SavedRulesTableRowConditionalRules from "../TableViews/SavedRulesTable/SavedRulesTableRowConditionalRules.svelte";

  export let step;
  export let report_id;
  export let activeTab = 0;
  export let ran_reports = [];
  export let runAddRuleinApplyChecklist = false;
  export let activeNewRuleSubSection = 0;

  const maximumChecklistNameLength = 64;

  let ruleSets = [];
  let allRuleSets = [];
  let report_rules = [];
  let activeRuleId = -1;
  let activeChecklistIdx = -1;
  let reportClassification;
  let sharedWith = [];
  let exceedsMaxLength = false;
  let loadingRules = true;
  let saveAndCreateMode = false;
  let saveAndCreateButton = true;

  let rules = [];

  let savingRule = false;

  /**
   * TODO: We need to add validation on the rule parsed via the index.
   *
   * Note: Only when a Rule entity is edited will it have an ID.
   * @param indexDetail
   */
  async function saveRule(indexDetail = { index: -1 }) {
    try {
      if (indexDetail?.index === undefined || indexDetail?.index === null) {
        throw new Error(
          `No Rule index provided. Received: "${JSON.stringify(indexDetail)}."`,
        );
      }

      savingRule = true;
      let ruleResponse;

      if (rules[indexDetail.index]?.id)
        ruleResponse = await edit(rules[indexDetail.index]);
      else ruleResponse = await saveAssetRule(rules[0], report_id);

      const ruleSetResponse = await fetchPost("/ruleset/change-ruleset", {
        rule_set_id: rules[indexDetail.index].selectedChecklistId
          ? rules[indexDetail.index].selectedChecklistId
          : -1,
        rule_id: ruleResponse.rule?.id || rules[indexDetail.index].id,
      });

      if (ruleResponse?.success) {
        toast.success("Checklists saved successfully");
        await updateReportRules();

        if (saveAndCreateMode) {
          addRule();
        } else {
          removeRule();
        }
      } else
        throw new Error(
          `Something went wrong saving Rule(ID: ${rules[indexDetail.index]?.id})`,
        );
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong while saving the rule.");
    }

    savingRule = false;
    activeTab = 0;
  }

  const handleSaveConditionalRule = async (event, index = -1) => {
    savingRule = true;

    try {
      const conditionalRule = event.detail;

      if (conditionalRule.ifRule.id) {
        await Promise.all(
          [conditionalRule.ifRule, ...conditionalRule.thenRules].map((r) =>
            edit(r),
          ),
        );

        // If all requests were successful, show a success toast
        toast.success("All rules edited successfully!");

        const ruleSetResponse = await fetchPost("/ruleset/change-ruleset", {
          rule_set_id: rules[index].selectedChecklistId
            ? rules[index].selectedChecklistId
            : -1,
          rule_id: conditionalRule.ifRule.id,
        });
        conditionalRuleData = undefined;
      } else {
        const rulesWithExamples = [
          conditionalRule.ifRule,
          ...conditionalRule.thenRules,
        ].map((r) => ({
          ...r,
          examples: buildExamples(r),
          search_sources: buildSearchSources(r.search_sources),
          report_id,
        }));

        const saveResponse = await fetchPost("/rule", {
          rules: rulesWithExamples,
          report_id,
        });

        if (!saveResponse.success) {
          const errMsg = "Something went wrong while saving the rule.";
          toast.error(errMsg);

          throw new Error(errMsg);
        }

        const ifRule = saveResponse.rules.find((r) =>
          isParentConditionalRule(r),
        );
        const ruleSetResponse = await fetchPost("/ruleset/change-ruleset", {
          rule_set_id: rules[index].selectedChecklistId
            ? rules[index].selectedChecklistId
            : -1,
          rule_id: ifRule.id,
        });
      }

      await updateReportRules();
    } catch (e) {
      console.error(e);
    }

    await updateReportRules();
    removeRule();
    savingRule = false;
    activeTab = 0;
  };

  let checklistName = "";
  let checklistDescription = "";
  let checklistId = -1;
  let duplicateChecklistDetected = false;

  let conditionalRuleData;

  function handleDuplicateChecklistDetected() {
    if (duplicateChecklistDetected) {
      duplicateChecklistDetected != duplicateChecklistDetected;
    }
  }

  async function addChecklist() {
    if (
      ruleSets.some(
        (rs) =>
          rs.name === checklistName && rs.description === checklistDescription,
      )
    ) {
      toast.error(
        "Could not create a new checklist, one already exists with the same name and description.",
      );

      duplicateChecklistDetected = true;
      return;
    }

    let res = await fetchPost("/ruleset/create", {
      name: checklistName,
      description: checklistDescription,
      report_id: report_id,
      sharing: false,
    });

    if (!res.success) {
      toast.error("Something went wrong while adding the checklist.");
      return;
    }

    await updateReportRules();
    toast.success("Checklist has been added successfully.");

    checklistName = "";
    checklistDescription = "";
    checklistId = -1;
    newChecklistModal?.close();
  }

  const editChecklist = async (id) => {
    if (!id) {
      toast.error("Something went wrong while editing the checklist");
    }

    if (
      ruleSets.some(
        (rs) =>
          rs.name === checklistName &&
          rs.description === checklistDescription &&
          rs.id !== id,
      )
    ) {
      toast.error(
        "Could not edit checklist details, one already exists with the same name and description.",
      );

      duplicateChecklistDetected = true;
      return;
    }

    const ruleSetToEdit = ruleSets.find((rs) => rs.id === id);
    const rulesForRuleSet = report_rules.flatMap((rs) => {
      if (rs[0].rule_set && rs[0].rule_set.id && rs[0].rule_set.id === id) {
        return rs.flatMap((rsr) => rsr.rule);
      }

      return [];
    });

    if (isEmpty(ruleSetToEdit)) {
      toast.error(
        "Cannot edit a Saved Checklist here. Please navigate to the Saved Rules page to edit this rule.",
      );

      document.getElementById(`edit-checklist-${id}`).close();

      return;
    }

    try {
      const res = await fetchPatch("/ruleset/edit", {
        id: ruleSetToEdit.id,
        name: checklistName,
        description: checklistDescription,
        rules: rulesForRuleSet,
      });

      // Need to revisit this and add handlers for different HTTP codes
      if (!res.success) {
        throw new Error("API request did not succeed.");
      }

      await updateReportRules();

      toast.success("Checklist has been edited successfully.");

      checklistName = "";
      checklistDescription = "";
      checklistId = -1;
      document.getElementById(`edit-checklist-${id}`).close();
    } catch (e) {
      toast.error("Something went wrong while editing the checklist.");
      return;
    }
  };

  const deleteChecklist = async (id) => {
    if (!id) {
      toast.error("Something went wrong while deleting the checklist");
    }

    const ruleSetToEdit = ruleSets.find((rs) => rs.id === id);

    if (isEmpty(ruleSetToEdit)) {
      toast.error(
        "Cannot delete a Saved Checklist here. Please navigate to the Saved Rules page to edit this rule.",
      );

      document.getElementById(`edit-checklist-${id}`).close();

      return;
    }

    const res = await fetchDelete("/ruleset/delete", { id });

    if (!res.success) {
      toast.error("Something went wrong while deleting the checklist.");
      return;
    }

    document.getElementById(`delete-checklist-${id}`).close();
    await updateReportRules();

    toast.success("Checklist has been deleted successfully.");
  };

  const addRule = () => {
    rules = [
      {
        name: "",
        description: "",
        examples: [{ text: "", score: 1 }],
        operator: "OR",
        boolean: "",
        doc_query: "",
        section_query: "",
        affirmative_rule: true,
        remediation_step: "",
        limit: 5,
        search_sources: {
          DOCUMENT: true,
          IMAGE_TEXT: false,
          IMAGE_FEATURE: false, // Initialize according to your default needs
        },
        index: "vector-db-index",
        strong_match_threshold: 0.95,
        no_match_threshold: 0.92,
        search_type: undefined,
        type: "asset",
        lexical: "",
      },
    ];

    activeTab = 1;
    activeNewRuleSubSection = 4.1;
  };

  $: if (runAddRuleinApplyChecklist) {
    addRule();
    runAddRuleinApplyChecklist = false;
  }

  const duplicateRule = (id) => {
    let rule;

    if (reportClassification === "asset")
      rule = ran_reports.find((f) => f.id === id);
    else rule = ran_reports.find((f) => f.rule.id == id).rule;

    let query;

    if (typeof rule.query === "string") query = JSON.parse(rule.query);
    else query = rule.query;

    rules = [
      {
        name: `Copy of ${rule.name}`,
        description: rule.description,
        remediation_step: rule.remediation_step,
        examples:
          rule.rule_type === RuleSearchType.TENSOR
            ? Object.entries(query.examples).map((e) => ({
                text: e[0],
                score: e[1],
              }))
            : rule.rule_type === RuleSearchType.MANUAL
              ? rule.examples
              : rule.rule_type === RuleSearchType.LEXICAL
                ? rule.lexical
                : [{ text: "", score: 1 }],
        builder:
          rule.rule_type === RuleSearchType.BOOLEAN
            ? convertBoolToBuilder(JSON.parse(rule?.query)?.doc_query)
            : undefined,
        lexical: rule.examples.substring(1, rule.examples.length - 1),
        operator: query.operator,
        doc_query: rule.doc_query,
        query: query,
        section_query: query?.section_query ?? "",
        affirmative_rule: rule.affirmative_rule,
        limit: query.limit,
        search_sources: {
          DOCUMENT: true,
          IMAGE_TEXT: false,
          IMAGE_FEATURE: false,
        },
        index: query?.index ?? rule.data_type ?? "vector-db-index", // Default to vector-db-index if no index nor data type
        strong_match_threshold: rule.strong_match_threshold,
        no_match_threshold: rule.no_match_threshold,
        search_type: rule.rule_type,
        type: rule.type,
        dataIfRuleToBeEdited: { id: rule.id, name: rule.name },
      },
    ];

    if (rule.rule_set_details.length > 0)
      rules[0].selectedChecklistId = rule.rule_set_details[0].id;
    else rules[0].selectedChecklistId = -1;

    // for conditional rules
    if (
      !isEmpty(rule.conditional_id) &&
      rule.condition_type === ConditionalRuleType.IF
    ) {
      rules[0] = {
        ...rules[0],
        condition_type: "IF",
        conditional_id: null,
        sequence_order: 0,
      };

      const ifRule = deepCopy(rules[0]);
      let thenRules = [];

      ran_reports
        .filter(
          (r) =>
            r.conditional_id === rule.conditional_id &&
            r.condition_type === ConditionalRuleType.THEN,
        )
        .map((r) => {
          duplicateRule(r.id);
          thenRules = [
            ...thenRules,
            {
              ...rules[0],
              conditional_id: null,
              condition_type: "THEN",
              sequence_order: 1,
            },
          ];
        });

      conditionalRuleData = { ifRule, thenRules };

      rules = [{ ...ifRule, search_type: RuleSearchType.CONDITIONAL }];
    }

    activeNewRuleSubSection = 4.2;
    activeTab = 1;
  };

  const editRule = (id, rule) => {
    duplicateRule(id);

    rules[0].id = id;
    rules[0].name = rule.name;

    if (rule.rule_set_details.length > 0)
      rules[0].selectedChecklistId = rule.rule_set_details[0].id;
    else rules[0].selectedChecklistId = -1;

    if (conditionalRuleData) {
      conditionalRuleData.ifRule.id = id;
      conditionalRuleData.ifRule.name = rule.name;

      conditionalRuleData.thenRules = conditionalRuleData.thenRules.map((r) => {
        r.id = r.dataIfRuleToBeEdited.id;
        r.name = r.dataIfRuleToBeEdited.name;
        return r;
      });
    }
  };

  /**
   * TODO: For the construction of the `examples` properties - we need to verify if we can
   * just use the `buildExamples` function given it's very functionally similar.
   *  - Jim K. - 09/10/24
   * @param {Object} rule
   */
  const edit = async (rule) => {
    const res = await fetchPatch(`/asset/rule/${rule.id}`, {
      rule: {
        examples: [
          RuleSearchType.MANUAL,
          RuleSearchType.CONTEXTUAL,
          RuleSearchType.IMAGE,
          RuleSearchType.VISUAL,
        ].includes(rule.search_type)
          ? rule.examples
          : rule.search_type === RuleSearchType.LEXICAL
            ? rule.lexical.includes('" OR "')
              ? rule.lexical
              : rule.lexical //If Lexical just search the string without all the queried paramterisation
            : rule.search_type === RuleSearchType.BOOLEAN
              ? "*"
              : // : rule.search_type === RuleSearchType.CONTEXTUAL
                // ? rule.examples
                rule.examples
                  .filter((ex) => ex.text.trim() !== "")
                  .reduce((examples, ex) => {
                    examples[ex.text] = ex.score;
                    return examples;
                  }, {}),
        operator: rule.operator,
        boolean:
          rule.search_type === RuleSearchType.BOOLEAN ? rule.queryBoolean : "",
        doc_query:
          rule.search_type === RuleSearchType.BOOLEAN
            ? rule.queryBoolean
            : rule.doc_query,
        section_query:
          rule.search_type === RuleSearchType.BOOLEAN
            ? rule.section_query.length === 0
              ? rule.lexical
              : rule.section_query + " AND " + rule.lexical
            : rule.section_query,
        index: rule.index,
        name: rule.name,
        description: rule.description,
        remediation_step: rule.remediation_step,
        strong_match_threshold: rule.strong_match_threshold,
        no_match_threshold: rule.no_match_threshold,
        affirmative_rule: rule.affirmative_rule,
        search_type: rule.search_type,
        search_sources: Object.entries(rule.search_sources)
          .filter(([key, value]) => value)
          .map(([key, _]) => key),
        limit: rule.limit,
        type: rule.type,
        context: rule.context,
        definitions: rule.definitions,
      },
      stream_id: report_id,
    });

    return res;
  };

  function removeRule() {
    saveAndCreateMode = false;
    rules = [];
    activeTab = 0;
    activeNewRuleSubSection = 0;
    conditionalRuleData = undefined;
  }

  async function deleteRule(id) {
    try {
      const res = await fetchDelete("/rule/" + id);
      if (res.success) await updateReportRules();

      toast.success("Rule has been deleted along with all the hits.");
    } catch (error) {
      console.warn("Error while deleting the rule", error);

      toast.error("Something went wrong while deleting the rule.");
    }
  }

  const updateReportRules = async () => {
    loadingRules = true;
    let used_rule_set_ids = [];
    let noRuleSetGroup = [];
    const rsRes = await fetchGet(`/ruleset/report/${report_id}`);
    ruleSets = rsRes.rule_sets;

    const res2 = await fetchGet("/ruleset");
    allRuleSets = res2.rule_sets;
    if (res2.shared_with) {
      sharedWith = [...res2.shared_with];
    }

    const res = await fetchGet(`/report/${report_id}`);

    reportClassification = res.report.classification;

    if (res.report.classification === "asset") {
      ran_reports = groupConditionalRules(res.rules);
      used_rule_set_ids = [
        ...new Set(
          ran_reports
            .map((r) => r.rule_sets)
            .filter((rs) => rs.length > 0)
            .flat(),
        ),
      ];

      report_rules = used_rule_set_ids.map((rs_id) => {
        return ran_reports
          .filter((r) => r.rule_sets.includes(rs_id))
          .map((r) => {
            return {
              rule_set:
                ruleSets.find((rs) => rs.id === rs_id) ||
                allRuleSets.find((rs) => rs.id === rs_id),
              rule: r,
            };
          })
          .sort((a, b) => a.rule.name.localeCompare(b.rule.name));
      });

      noRuleSetGroup = ran_reports
        .filter((r) => r.rule_sets.length === 0)
        .map((r) => ({
          rule_set: { name: NoRuleSetDefaults.NAME, id: NoRuleSetDefaults.ID },
          rule: r,
        }));

      ran_reports = res.rules;
    } else {
      ran_reports = res.data;
      used_rule_set_ids = [
        ...new Set(ran_reports.map((r) => r.rule.rule_sets).flat()),
      ];

      report_rules = used_rule_set_ids.map((rs_id) => {
        return ran_reports
          .filter((r) => r.rule.rule_sets?.includes(rs_id))
          .map((r) => {
            return {
              rule_set:
                ruleSets.find((rs) => rs.id === rs_id) ||
                allRuleSets.find((rs) => rs.id === rs_id),
              rule: r.rule,
            };
          })
          .sort((a, b) => a.rule.name.localeCompare(b.rule.name));
      });

      noRuleSetGroup = ran_reports
        .filter((r) => r.rule.rule_sets.length === 0)
        .map((r) => ({
          rule_set: {
            name: NoRuleSetDefaults.NAME,
            id: NoRuleSetDefaults.ID,
            description: NoRuleSetDefaults.DESCRIPTION,
          },
          rule: r.rule,
        }));
    }

    // If there are any rules in the noRuleSetGroup, add them as a separate group
    if (noRuleSetGroup.length > 0) {
      report_rules.unshift(noRuleSetGroup); // Prepend to ensure it appears first, or use push to add it to the end
    }

    // Ensure all rule sets are included, even those without any rules
    // (This is the new part added for ensuring every rule set is included)
    const allRuleSetIds = ruleSets.map((rs) => rs.id);
    const missingRuleSetIds = allRuleSetIds.filter(
      (id) => !used_rule_set_ids.includes(id),
    );
    missingRuleSetIds.forEach((id) => {
      // Find the rule set by its id and add it with an empty rules array
      const ruleSet = ruleSets.find((rs) => rs.id === id);
      if (ruleSet) {
        report_rules.push([
          {
            rule_set: ruleSet,
            rule: {}, // Indicates no rules associated with this rule set
          },
        ]);
      }
    });
    report_rules = report_rules;

    if (report_rules.length === 1) activeChecklistIdx = 0;

    loadingRules = false;
  };

  let saved_rules = [];

  let newRuleSubSections = [
    {
      id: 0,
      name: "Apply Checklists",
      desc: "Add and optimise your asset evaluations effortlessly. On this page, you can apply, customize, and fine-tune your rule-based checklists.",
    },
    {
      id: 4.1,
      name: "Choose Rule Type",
      desc: "Each rule type defines how the system evaluates and processes your conditions, allowing you to tailor the behaviour precisely.",
    },
    {
      id: 4.2,
      name: "Build Rule",
      desc: "This section empowers you to build and fine-tune the rule's behavior according to your specific needs.",
    },
  ];
  // if 0 no rule is selected else 4.1 or 4.2

  // Utility function to check if an object is empty
  function isEmptyObject(obj) {
    return Object.keys(obj).length === 0;
  }

  onMount(async () => {
    loadingRules = true;
    await updateReportRules();

    loadingRules = false;
  });

  $: if (rules[0] && rules[0].builder)
    rules[0].queryBoolean = convertBuilderToBoolean(rules[0].builder);

  $: handleDuplicateChecklistDetected(), [checklistName, checklistDescription];
</script>

<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<!-- svelte-ignore missing-declaration -->

{#if activeTab == 1}
  <div class="py-4">
    {#each rules as rule, index}
      {#if activeNewRuleSubSection === 4.1}
        <AssetRuleTypeSelector bind:value={rule.search_type} bind:rule />

        <div class="mt-4 flex gap-2">
          <button
            class="btn btn-sm"
            on:click={() => {
              if (step === 0) return;
              step -= 1;
              activeNewRuleSubSection = 0;
            }}
          >
            Back
          </button>

          <button
            class="btn btn-sm"
            on:click={() => cancelRuleModal.showModal()}
          >
            Cancel & Return to Apply Checklists
          </button>

          {#if !rules[0].search_type}
            <div
              class="tooltip"
              data-tip="You need to select a rule type to proceed."
            >
              <button class="btn btn-primary btn-sm" disabled>Select</button>
            </div>
          {:else}
            <button
              class="btn btn-primary btn-sm"
              on:click={() => {
                activeNewRuleSubSection = 4.2;
                saveAndCreateButton = true;
              }}
            >
              Select
            </button>
          {/if}
        </div>
      {:else if activeNewRuleSubSection === 4.2}
        <article class="my-4 rounded border p-6">
          <div>
            <div class="form-control grow">
              <label class="label" for="rule_checklist_{index}">
                <span class="label-text">
                  Select which checklist this rule should be part of
                </span>
              </label>
              <Select
                bind:selectedValue={rules[index].selectedChecklistId}
                items={[
                  { label: NoRuleSetDefaults.NAME, value: -1 },
                  ...ruleSets.map((rs) => ({ label: rs.name, value: rs.id })),
                ]}
                size="md"
              />
            </div>
          </div>
        </article>

        {#if rule.search_type === RuleSearchType.CONDITIONAL}
          <div class="my-4 rounded border p-6">
            <ConditionalAssetRuleBuilder
              on:removeRule={() => removeRule()}
              on:saveConditionalRule={async (e) =>
                await handleSaveConditionalRule(e, index)}
              bind:conditionalRuleData
              loading={savingRule}
            />
          </div>
        {:else}
          <AssetRuleBuilder
            bind:rule
            {index}
            {savingRule}
            on:removeRule={() => removeRule()}
            on:saveRule={(e) => saveRule(e.detail)}
            on:back={() => (activeNewRuleSubSection = 4.1)}
            on:changeRuleType={() => confirmRuleTypeChange?.showModal()}
          />

          <Modal
            modalId="confirmRuleTypeChange"
            bottomButtons={{
              show: true,
              primaryText: "Switch rule type",
              secondaryText: "Cancel",
              primaryAction: () => {
                activeNewRuleSubSection = 4.1;
                confirmRuleTypeChange.close();
              },
              secondaryAction: () => confirmRuleTypeChange.close(),
            }}
            containerClasses="flex flex-col gap-4 items-center"
            cornerCloseButton={false}
          >
            <span
              class="bg-error flex h-12 w-12 items-center justify-center rounded-full"
            >
              <Icon
                icon="iconoir:warning-triangle"
                class="text-xl text-white"
              />
            </span>

            <h3 class="font-semibold">
              Are you sure you want to switch rule type?
            </h3>
            <p class="text-base-content/70 text-center text-sm">
              If you switch rule type,
              <span class="font-semibold">you will lose your progress</span>
              with the current rule type.
            </p>
          </Modal>
        {/if}
      {/if}
    {/each}
  </div>
{:else if activeTab == 2}
  <LoadRules
    reportId={report_id}
    reportRules={report_rules}
    {allRuleSets}
    sharedRules={sharedWith}
    showAssetRules={true}
    on:ruleDuplicated={async () => {
      await updateReportRules();
      activeTab = 0;
    }}
    on:back={() => {
      activeNewRuleSubSection = 0;
      activeTab = 0;
    }}
  />
{/if}

<div
  class="collapse mt-4 overflow-x-auto rounded border"
  class:collapse-open={![1, 2].includes(activeTab)}
  class:collapse-arrow={[1, 2].includes(activeTab)}
  transition:slide
>
  <input type="checkbox" />
  <div class="collapse-title text-xl font-medium">Checklist Rules</div>
  <div class="collapse-content flex flex-col gap-2">
    <div class="grid min-w-[750px] grid-cols-2 border-b px-6 font-semibold">
      <span class="col-span-1">Checklist Name</span>
      <!-- <span class="col-span-1 text-end">Actions</span> -->
    </div>

    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <!-- svelte-ignore a11y-no-static-element-interactions -->
    {#each report_rules as checklist, i}
      <div class="rounded border">
        <div
          class="flex cursor-pointer items-center justify-between gap-2 px-6 py-2"
          on:click={() => {
            activeChecklistIdx = activeChecklistIdx === i ? -1 : i;
            activeRuleId = -1;
          }}
        >
          <span class="flex items-center">
            <span class="mr-1">
              {checklist[0]?.rule_set?.name ??
                checklist[0][0]?.rule_set_details?.name}
            </span>
            <ChecklistInfoModal data={checklist[0].rule_set} />
          </span>

          <div class="flex items-center gap-1">
            <!-- {#if checklist[0].rule_set?.id !== "no_rule_set" && !["Consumer Checklist", "Legal Checklist", "Technical Checklist"].includes(checklist[0]?.rule_set?.name)} -->
            {#if checklist[0].rule_set?.id !== "no_rule_set"}
              <button
                class="btn btn-outline btn-primary btn-xs"
                on:click|stopPropagation={() => {
                  checklistName =
                    checklist[0]?.rule_set?.name ??
                    checklist[0][0]?.rule_set_details?.name;
                  checklistDescription =
                    checklist[0].rule_set?.description ?? "";
                  checklistId =
                    checklist[0]?.rule_set?.id ??
                    checklist[0][0]?.rule_set_details?.id ??
                    -1;
                  document
                    .getElementById(
                      `edit-checklist-${checklist[0].rule_set?.id}`,
                    )
                    .showModal();
                }}
              >
                Edit
              </button>

              <Modal
                modalId="edit-checklist-{checklist[0].rule_set?.id}"
                on:close={() => {
                  checklistName = "";
                  checklistDescription = "";
                  checklistId = -1;
                }}
              >
                <h3 class="text-center font-bold">Edit Checklist</h3>

                <div>
                  <label class="label" for="checklist_name">
                    <span class="label-text">Checklist name</span>
                  </label>
                  <input
                    type="text"
                    bind:value={checklistName}
                    placeholder="Checklist Name"
                    class="input input-bordered w-full {duplicateChecklistDetected ||
                    checklistName.length < 1
                      ? 'input-error'
                      : ''}"
                    id="checklist_name"
                    maxlength={maximumChecklistNameLength}
                    required
                    on:change={() => {
                      ruleSets.some(
                        (rs) =>
                          rs.name === checklistName &&
                          rs.description === checklistDescription &&
                          rs.id !== checklistId,
                      );
                    }}
                  />
                </div>
                <div>
                  <label class="label" for="checklist_description">
                    <span class="label-text">Checklist description</span>
                  </label>
                  <input
                    type="text"
                    bind:value={checklistDescription}
                    placeholder="Checklist Description"
                    class="input input-bordered w-full {duplicateChecklistDetected
                      ? 'input-error'
                      : ''}"
                    id="checklist_description"
                    on:change={() => {
                      ruleSets.some(
                        (rs) =>
                          rs.name === checklistName &&
                          rs.description === checklistDescription &&
                          rs.id !== checklistId,
                      );
                    }}
                  />
                </div>

                <div
                  class="mt-4 w-full"
                  class:tooltip={checklistName.length < 1 ||
                    duplicateChecklistDetected}
                  data-tip={checklistName.length < 1
                    ? "The checklist cannot have an empty name."
                    : duplicateChecklistDetected
                      ? "A checklist already exists with the same name and description"
                      : ""}
                >
                  <button
                    on:click={async () =>
                      await editChecklist(checklist[0].rule_set?.id)}
                    class="btn btn-primary w-full"
                    disabled={checklistName.length < 1}
                  >
                    Save
                  </button>
                </div>
              </Modal>

              <button
                class="btn btn-outline btn-error btn-xs"
                on:click|stopPropagation={() =>
                  document
                    .querySelector(
                      `#delete-checklist-${checklist[0].rule_set?.id}`,
                    )
                    .showModal()}
              >
                Delete
              </button>
              <ActionConfirmation
                modalId="delete-checklist-{checklist[0].rule_set?.id}"
                message="Are you sure you want to delete this checklist?"
                onConfirm={() => deleteChecklist(checklist[0].rule_set?.id)}
                onCancel={() =>
                  document
                    .querySelector(
                      `#delete-checklist-${checklist[0].rule_set?.id}`,
                    )
                    .close()}
              />
            {/if}

            <button class="btn btn-square btn-ghost btn-sm">
              <Icon
                icon="iconoir:nav-arrow-{activeChecklistIdx === i
                  ? 'up'
                  : 'down'}"
                class="text-lg"
              />
            </button>
          </div>
        </div>

        {#if activeChecklistIdx === i}
          <div class="flex flex-col gap-1 p-2 pt-0" transition:slide>
            {#each checklist as e}
              {#if !isEmptyObject(e.rule)}
                <div class="bg-base-200 min-w-[750px] rounded border">
                  <div
                    class="grid grid-cols-3 items-center justify-between gap-4 px-4 py-2"
                    on:click={() =>
                      (activeRuleId =
                        activeRuleId === e.rule.id ? -1 : e.rule.id)}
                  >
                    <span class="col-span-1 break-words">{e.rule.name}</span>
                    <div
                      class="col-span-2 flex flex-nowrap justify-between gap-4"
                    >
                      <div class="flex flex-wrap items-center gap-2">
                        <Badge bgColor="#929292" textColor="white">
                          {ruleSearchTypeToLabel(e.rule.rule_type)}
                        </Badge>
                        {#if isParentConditionalRule(e.rule)}
                          {#if e.rule.rule_type === RuleSearchType.MANUAL}
                            {#if e.rule.affirmative_rule}
                              <Badge bgColor="#929292" textColor="white">
                                {RuleStatus.TRUE} if Marked Complete
                              </Badge>
                            {:else}
                              <Badge bgColor="#929292" textColor="white">
                                {RuleStatus.FALSE} if Not Marked Complete
                              </Badge>
                            {/if}
                          {:else if e.rule.affirmative_rule}
                            <Badge bgColor="#929292" textColor="white">
                              {RuleStatus.TRUE} if Matched
                            </Badge>
                          {:else}
                            <Badge bgColor="#929292" textColor="white">
                              {RuleStatus.FALSE} if Matched
                            </Badge>
                          {/if}
                        {:else if e.rule.rule_type === RuleSearchType.MANUAL}
                          {#if e.rule.affirmative_rule}
                            <Badge bgColor="#929292" textColor="white">
                              {RuleStatus.PASSED} if Marked Complete
                            </Badge>
                          {:else}
                            <Badge bgColor="#929292" textColor="white">
                              {RuleStatus.RISK} if Not Marked Complete
                            </Badge>
                          {/if}
                        {:else if e.rule.affirmative_rule}
                          <Badge bgColor="#929292" textColor="white">
                            {RuleStatus.PASSED} if Matched
                          </Badge>
                        {:else}
                          <Badge bgColor="#929292" textColor="white">
                            {RuleStatus.RISK} if Matched
                          </Badge>
                        {/if}

                        {#if !isEmpty(e.rule?.conditional_id) && e.rule?.condition_type === ConditionalRuleType.IF}
                          <Badge bgColor="#929292" textColor="white">
                            Conditional
                          </Badge>
                        {/if}

                        {#if sharedWith.some((sr) => sr.rule_id === e.rule.id && sr.status === SharedRuleStatus.ACTIVE)}
                          <div
                            class="tooltip"
                            data-tip="This rule was created from a shared rule and is up to date with the original rule."
                          >
                            <Badge bgColor="#336A1D" textColor="white">
                              Shared Rule Current
                            </Badge>
                          </div>
                        {:else if sharedWith.some((sr) => sr.rule_id === e.rule.id && sr.status === SharedRuleStatus.UPDATED)}
                          <div
                            class="tooltip"
                            data-tip="This rule was created from a shared rule and is not up to date with the original rule."
                          >
                            <Badge bgColor="#FB8C00" textColor="white">
                              Shared Rule Updated
                            </Badge>
                          </div>
                        {:else if sharedWith.some((sr) => sr.rule_id === e.rule.id && sr.status === SharedRuleStatus.DELETED)}
                          <div
                            class="tooltip"
                            data-tip="This rule was created from a shared rule and the original rule has been deleted."
                          >
                            <Badge bgColor="#FF4E4E" textColor="white">
                              Shared Rule Deleted
                            </Badge>
                          </div>
                        {/if}
                      </div>

                      <div class="ml-auto flex items-center gap-1">
                        <button
                          class="btn btn-outline btn-primary btn-xs"
                          on:click|stopPropagation={() => {
                            editRule(e.rule.id, e.rule);
                            saveAndCreateButton = false;
                          }}
                        >
                          Edit
                        </button>
                        <button
                          class="btn btn-outline btn-primary btn-xs"
                          on:click|stopPropagation={() =>
                            duplicateRule(e.rule.id)}
                        >
                          Duplicate
                        </button>
                        <button
                          class="btn btn-outline btn-error btn-xs"
                          on:click|stopPropagation={() => {
                            const delRule = document.querySelector(
                              `#delRule-${e.rule.id}`,
                            );
                            delRule.showModal();
                          }}
                        >
                          Delete
                        </button>
                        <ActionConfirmation
                          modalId="delRule-{e.rule.id}"
                          message="Are you sure you want to delete this rule?"
                          onConfirm={async () => {
                            e.deleting = true;
                            const delRule = document.querySelector(
                              `#delRule-${e.rule.id}`,
                            );
                            await deleteRule(e.rule.id);
                            delRule.close();
                          }}
                          onCancel={() => {
                            const delRule = document.querySelector(
                              `#delRule-${e.rule.id}`,
                            );
                            delRule.close();
                          }}
                          showLoadingSpinner={true}
                          loading={e.deleting}
                        />

                        <button class="btn btn-square btn-ghost btn-sm">
                          <Icon
                            icon="iconoir:nav-arrow-{activeRuleId === e.rule.id
                              ? 'up'
                              : 'down'}"
                            class="text-lg"
                          />
                        </button>
                      </div>
                    </div>
                  </div>

                  {#if activeRuleId === e.rule.id}
                    <main
                      class="grid grid-cols-3 gap-4 px-4 py-2 pt-0"
                      transition:slide
                    >
                      {#if e.rule.rule_type === RuleSearchType.MANUAL}
                        <div>
                          <p class="text-sm font-medium">ID</p>
                          <p class="text-sm font-light">
                            #{e.rule.id}
                          </p>
                        </div>
                        <div>
                          <p class="text-sm font-medium">Description</p>
                          <p class="text-sm font-light">
                            {e.rule.description}
                          </p>
                        </div>
                        <div>
                          <p class="text-sm font-medium">Remediation Step</p>
                          <p class="text-sm font-light">
                            {isEmpty(e.rule.remediation_step)
                              ? "No remediation step."
                              : e.rule.remediation_step}
                          </p>
                        </div>
                        <div class="col-span-3 grid grid-cols-6 gap-4">
                          <div class="col-span-3 mr-[36px] flex flex-col gap-1">
                            <p class="mt-1 text-sm font-medium">Query</p>
                            <QueryPrettyPrint
                              rule={e.rule}
                              isParentConditional={isParentConditionalRule(
                                e.rule,
                              )}
                            />
                          </div>
                          <div
                            class="col-span-2 col-start-5 flex flex-col gap-1"
                          >
                            <p class="mt-1 text-sm font-medium">Sub-Tasks</p>
                            <ul class="rounded-box list-disc">
                              {#each e.rule.examples.split(" AND ") as example}
                                <li class="ml-6">
                                  <span class="badge h-auto"
                                    >{example.slice(1, -1)}</span
                                  >
                                </li>
                              {/each}
                            </ul>
                          </div>
                        </div>
                        <div
                          class="col-span-3 mb-1 ml-auto mr-[36px] flex items-center gap-1"
                        >
                          <button
                            class="btn btn-outline btn-primary btn-xs ml-auto"
                            on:click={async () => {
                              saved_rules = saveRuleToMyRules(
                                e.rule.id,
                                saved_rules,
                                "asset",
                              );
                              toast.success("Rule has been saved to My Rules");
                            }}
                          >
                            Save to My Rules
                          </button>
                        </div>

                        {#if !isEmpty(e.rule?.conditional_id) && e.rule?.condition_type === ConditionalRuleType.IF}
                          <div class="col-span-3 flex flex-col">
                            <div class="mb-2 gap-1">
                              <p class="text-sm font-medium">
                                Conditional Child Rules
                              </p>
                              <p class="text-sm font-light">
                                If this rule is
                                <Badge bgColor="#336A1D" textColor="white"
                                  >{RuleStatus.TRUE}</Badge
                                >, then the following rules will be ran.
                              </p>
                            </div>

                            <SavedRulesTableRowConditionalRules
                              bind:rules={e.rule.children}
                              assetRulesTable={true}
                              on:editRule={(event) => {
                                const ruleToEdit = e.rule.children.find(
                                  (r) => r.id === event.detail,
                                );
                                editRule(ruleToEdit.id, ruleToEdit);
                              }}
                            />
                          </div>
                        {/if}
                      {:else if e.rule.rule_type === RuleSearchType.CONTEXTUAL}
                        <ContextualRuleRow
                          bind:rule={e.rule}
                          bind:savedRules={saved_rules}
                          on:editRule={(event) => {
                            const ruleToEdit = e.rule.children.find(
                              (r) => r.id === event.detail,
                            );
                            editRule(ruleToEdit.id, ruleToEdit);
                          }}
                        />
                      {:else}
                        <div>
                          <p class="text-sm font-medium">ID</p>
                          <p class="text-sm font-light">
                            #{e.rule.id}
                          </p>
                        </div>
                        <div>
                          <p class="text-sm font-medium">Description</p>
                          <p class="text-sm font-light">
                            {e.rule.description}
                          </p>
                        </div>
                        <div>
                          <p class="text-sm font-medium">Remediation Step</p>
                          <p class="text-sm font-light">
                            {isEmpty(e.rule.remediation_step)
                              ? "No remediation step."
                              : e.rule.remediation_step}
                          </p>
                        </div>
                        <div class="col-span-3 grid grid-cols-2">
                          <div class="col-span-1 mr-[36px] flex flex-col gap-1">
                            <p class="mt-1 text-sm font-medium">Query</p>
                            <QueryPrettyPrint
                              rule={e.rule}
                              isParentConditional={isParentConditionalRule(
                                e.rule,
                              )}
                            />
                          </div>

                          <div
                            class="col-span-1 ml-auto mr-[36px] flex place-items-end gap-1"
                          >
                            <button
                              class="btn btn-outline btn-primary btn-xs ml-auto"
                              on:click={async () => {
                                saved_rules = saveRuleToMyRules(
                                  e.rule.id,
                                  saved_rules,
                                  "asset",
                                );
                                toast.success(
                                  "Rule has been saved to My Rules",
                                );
                              }}
                            >
                              Save to My Rules
                            </button>
                          </div>
                        </div>

                        {#if !isEmpty(e.rule?.conditional_id) && e.rule?.condition_type === ConditionalRuleType.IF}
                          <div class="col-span-3 flex flex-col">
                            <div class="mb-2 gap-1">
                              <p class="text-sm font-medium">
                                Conditional Child Rules
                              </p>
                              <p class="text-sm font-light">
                                If this rule is
                                <Badge bgColor="#336A1D" textColor="white"
                                  >{RuleStatus.TRUE}</Badge
                                >, then the following rules will be ran.
                              </p>
                            </div>

                            <SavedRulesTableRowConditionalRules
                              bind:rules={e.rule.children}
                              assetRulesTable={true}
                              on:editRule={(event) => {
                                const ruleToEdit = e.rule.children.find(
                                  (r) => r.id === event.detail,
                                );
                                editRule(ruleToEdit.id, ruleToEdit);
                              }}
                            />
                          </div>
                        {/if}
                      {/if}
                    </main>
                  {/if}
                </div>
              {/if}
            {/each}

            {#if checklist.every((e) => isEmptyObject(e.rule))}
              <div
                class="text-base-content/50 flex flex-col items-center justify-center p-4"
              >
                <Icon icon="iconoir:folder" class="text-8xl" />
                <p class="text-center text-sm">
                  No rules yet! <br />
                  We're on a clean slate waiting for your brilliance.
                </p>
              </div>
            {/if}
          </div>
        {/if}
      </div>
    {:else}
      <div
        class="flex flex-col justify-center items-center p-4 text-base-content/50"
      >
        {#if loadingRules}
          <LogoLoader size="100px" />
        {:else}
          <Icon icon="iconoir:folder" class="text-8xl" />
          <p class="text-sm text-center">
            No checklist rules yet! <br />
            We're on a clean slate waiting for your brilliance.
          </p>
        {/if}
      </div>
    {/each}
  </div>
</div>

{#if ![1, 2].includes(activeTab)}
  <div class="mt-4 flex gap-2">
    <button
      class="btn btn-sm"
      on:click={() => {
        if (step === 0) return;
        step -= 1;
        activeNewRuleSubSection = 0;
      }}
    >
      Back
    </button>

    {#if ran_reports.length === 0}
      <div class="tooltip" data-tip="You need at least one rule to proceed.">
        <button class="btn btn-primary btn-sm" disabled>Review Assets</button>
      </div>
    {:else}
      <a href="/review/{report_id}" class="btn btn-primary btn-sm" use:link>
        Review Assets
      </a>
    {/if}
  </div>
{/if}

<!-- TODO: Modal - move to confirmation modal  -->
<Modal modalId="cancelRuleModal" cornerCloseButton={false}>
  <div class="flex flex-col gap-4">
    <Icon
      icon="iconoir:warning-triangle"
      class="text-warning mx-auto text-4xl"
    />
    <h3 class="text-center font-bold">
      Are you sure you want to cancel <br /> building the rule?
    </h3>
    <p class="text-base-content/70 text-sm">
      If you choose to cancel, all progress made on the current rule will be
      lost. Please note that this action is irreversible.
    </p>

    <!-- svelte-ignore missing-declaration -->
    <button
      on:click={() => cancelRuleModal.close()}
      class="btn btn-primary w-full"
    >
      Continue Building Rule
    </button>
    <!-- svelte-ignore missing-declaration -->
    <button
      class="link-hover link link-primary w-full text-xs"
      on:click={() => {
        saveAndCreateMode = false;
        removeRule();
        cancelRuleModal.close();
      }}
    >
      Cancel & Return to 'Apply Checklists' Page
    </button>
  </div>
</Modal>

<Modal
  modalId="newChecklistModal"
  on:close={() => {
    checklistName = "";
    checklistDescription = "";
    checklistId = -1;
  }}
>
  <h3 class="text-center font-bold">Create New Checklist</h3>

  <div>
    <label class="label" for="checklist_name">
      <span class="label-text">Checklist name</span>
    </label>
    <input
      type="text"
      bind:value={checklistName}
      placeholder="Checklist Name"
      class="input input-bordered w-full {duplicateChecklistDetected ||
      checklistName.length < 1
        ? 'input-error'
        : ''}"
      id="checklist_description"
      maxlength={maximumChecklistNameLength}
      required
      on:change={() => {
        ruleSets.some(
          (rs) =>
            rs.name === checklistName &&
            rs.description === checklistDescription &&
            rs.id !== checklistId,
        );
      }}
    />
  </div>

  <div>
    <label class="label" for="checklist_description">
      <span class="label-text">Checklist description</span>
    </label>
    <input
      type="text"
      bind:value={checklistDescription}
      placeholder="Checklist Description"
      class="input input-bordered w-full {duplicateChecklistDetected
        ? 'input-error'
        : ''}"
      id="checklist_description"
      on:change={() => {
        ruleSets.some(
          (rs) =>
            rs.name === checklistName &&
            rs.description === checklistDescription &&
            rs.id !== checklistId,
        );
      }}
    />
  </div>

  <div
    class="mt-4 w-full"
    class:tooltip={checklistName.length < 1 || duplicateChecklistDetected}
    data-tip={checklistName.length < 1
      ? "The checklist cannot have an empty name."
      : duplicateChecklistDetected
        ? "A checklist already exists with the same name and description"
        : ""}
  >
    <button
      on:click={addChecklist}
      class="btn btn-primary w-full"
      disabled={duplicateChecklistDetected || checklistName.length < 1}
    >
      Add Checklist
    </button>
  </div>
</Modal>
