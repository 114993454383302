<script>
  import Icon from "@iconify/svelte";
  import { onDestroy, onMount, tick } from "svelte";
  import { toast } from "svelte-sonner";
  import { link, push, querystring } from "svelte-spa-router";
  import ActionConfirmation from "../components/ActionConfirmation.svelte";
  import ApplyChecklists from "../components/new-stream/ApplyChecklists.svelte";
  import StreamAssetInfo from "../components/new-stream/StreamAssetInfo.svelte";
  import StreamUpload from "../components/new-stream/StreamUpload.svelte";
  import StreamWorkflow from "../components/new-stream/StreamWorkflow.svelte";
  import { fetchDelete, fetchGet } from "../helpers";
  import { currentUser, maximised } from "../stores";

  export let params = null;

  const steps = [
    "Folder Information",
    "Stream Workflow",
    "Upload Assets",
    "Apply Checklists",
  ];
  const stepsDesc = [
    "Enter basic information about this Asset Review Stream.",
    "All folders have, a default, first step of the work for you to initially upload and work with the documents called Upload, you do not need to create this step, nor worry about permissions, if you have upload or manage permissions you can use it",
    "Upload assets to be evaluated.",
    "Add and optimize your asset evaluations effortlessly. On this page, you can apply, customize, and fine-tune your rule-based checklists.",
  ];

  let report_id;
  let rulesToReRun = [];
  let currentStreamPerm = "none";
  let step = 0;
  let stepsCompletedTill = -1;
  let activeNewRuleSubSection = 0;
  let streamStatus;
  let isNewWorkflow = false;
  let selectedWorkflow = {
    id: 10,
    name: "",
    description: "",
    workflow_steps: [
      { id: 10, name: "", description: "", position: 1, teams: [], users: [] },
    ],
  };
  let uploadedFiles = [];
  let ran_reports = [];
  let deleting = false;
  let streamName = "";

  onMount(async () => {
    report_id = params.report_id;
    $maximised = false;
    const userPerm = await fetchGet(
      `/report/${report_id}/get-user-permission-to-report`,
    );
    await tick();

    if ($querystring && $querystring === "step=upload") {
      step = 2;
      stepsCompletedTill = 3;
    } else if ($querystring && $querystring === "step=checklist") {
      step = 3;
      stepsCompletedTill = 4;
    }

    if ($currentUser.role === "admin") currentStreamPerm = "edit";
    else {
      if (userPerm.permission === "none") {
        const orgPerm = await fetchGet(
          `/report/${report_id}/get-org-permission`,
        );

        if (["none", "view"].includes(orgPerm.permission)) {
          toast.error(
            "You don't have permission to edit this Asset Review Stream.",
          );
          push("/reviews");
        } else if (["edit", "upload"].includes(orgPerm.permission))
          currentStreamPerm = orgPerm.permission;
      } else if (
        ["none", "view", "upload", "edit"].includes(userPerm.permission)
      ) {
        if (["none", "view"].includes(userPerm.permission)) {
          toast.error(
            "You don't have permission to edit this Asset Review Stream.",
          );
          push("/reviews");
        } else if (["edit", "upload"].includes(userPerm.permission))
          currentStreamPerm = userPerm.permission;
      }
    }

    if (currentStreamPerm === "upload") step = 1;

    fetchGet(`/workflow/${report_id}`).then((r) => {
      isNewWorkflow = r.new_workflow;
      selectedWorkflow = r.data;
    });
    fetchGet(`/asset/report/${report_id}/document`).then(
      (r) => (uploadedFiles = r.assets_list),
    );

    const reportResponse = await fetchGet(`/report/${report_id}`);
    ran_reports = reportResponse.rules;
  });

  onDestroy(() => ($maximised = true));

  const deleteStream = async () => {
    deleting = true;
    const res = await fetchDelete("/report/" + report_id);
    deleting = false;

    if (res.success) push("/reviews");
  };

  $: if (currentStreamPerm === "upload" && step != 2) {
    toast.error(
      "You can only upload assets, as you do not have permission to edit this stream",
    );

    step = 2;
    stepsCompletedTill = 2;
  }

  // for ApplyChecklists
  let activeTab = 0;
  let runAddRuleinApplyChecklist = false;
  $: if (step !== 3) activeTab = 0;
</script>

<svelte:head>
  <title>Asset Review Editor - Haast</title>
</svelte:head>

<aside
  class="bg-base-content/[5%] fixed bottom-0 top-16 w-60 shrink-0 p-4"
  style="left: {$maximised ? '220px' : '48px'}"
>
  <h1 class="text-2xl font-semibold">Upload Assets</h1>

  <ul class="steps steps-vertical">
    {#each steps as steps, i}
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
      <li
        class="step cursor-pointer"
        class:step-primary={step >= i}
        class:pointer-events-none={stepsCompletedTill < i - 1}
        class:!cursor-not-allowed={stepsCompletedTill < i - 1}
        on:click={() => (step = i)}
      >
        {steps}
      </li>
    {/each}
  </ul>
  {#if step === 3 && activeNewRuleSubSection}
    <ul class="steps steps-vertical -mt-6 ml-10">
      <li
        class="step"
        class:step-primary={activeNewRuleSubSection >= 4.1}
        data-content="4.1"
      >
        Choose Type
      </li>
      <li
        class="step"
        class:step-primary={activeNewRuleSubSection >= 4.2}
        data-content="4.2"
      >
        Build Rule
      </li>
    </ul>
  {/if}
</aside>

<!-- 1024+240 = 1264px if apply checklist page -->
<main
  class="mx-auto max-w-screen-2xl pl-[240px]"
  style="max-width: {step === 3 && ![1, 2].includes(activeTab) ? 1264 : 1536}px"
>
  <div class="breadcrumbs text-sm">
    <ul>
      <li>
        <a href="/reviews" use:link>Asset Review</a>
      </li>
      <li>{streamName}</li>
      <li>{steps[step]}</li>
    </ul>
  </div>

  <div
    class="flex items-center justify-between gap-4"
    class:max-w-xl={step === 0}
  >
    <h1 class="items-center text-3xl font-semibold">
      {#if step === 3 && activeNewRuleSubSection}
        {activeNewRuleSubSection === 4.1 ? "Choose Rule Type" : "Build Rule"}

        <div
          class="tooltip tooltip-bottom font-normal"
          data-tip={activeNewRuleSubSection === 4.1
            ? "Each rule type defines how the system evaluates and processes your conditions, allowing you to tailor the behavior precisely."
            : "This section empowers you to build and fine-tune the rule's behavior according to your specific needs."}
        >
          <button class="btn btn-circle btn-ghost btn-xs">
            <Icon icon="iconoir:info-circle" class="text-base" />
          </button>
        </div>
      {:else}
        {steps[step]}

        <div
          class="tooltip tooltip-bottom font-normal"
          data-tip={stepsDesc[step]}
        >
          <button class="btn btn-circle btn-ghost btn-xs">
            <Icon icon="iconoir:info-circle" class="text-base" />
          </button>
        </div>
      {/if}
    </h1>

    {#if step === 0}
      <div class="dropdown dropdown-end">
        <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
        <label tabindex="0" class="btn btn-square btn-ghost btn-sm" for="">
          <Icon icon="iconoir:more-horiz" class="text-xl" />
        </label>
        <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
        <ul
          tabindex="0"
          class="menu dropdown-content bg-base-100 z-10 m-1 w-48 rounded border p-2"
        >
          <li>
            <!-- svelte-ignore missing-declaration -->
            <button on:click={() => confirmModal.showModal()}>
              <Icon icon="iconoir:trash" class="text-xl" /> Delete Folder
            </button>
          </li>
        </ul>
      </div>
    {:else if step === 3 && ![1, 2].includes(activeTab)}
      <div class="dropdown dropdown-end">
        <div tabindex="0" role="button" class="btn btn-primary btn-sm">New</div>
        <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
        <ul
          tabindex="0"
          class="menu dropdown-content menu-sm bg-base-100 z-[2] mt-1 w-48 rounded border p-2"
        >
          <li>
            <button on:click={() => (activeTab = 2)}>
              <Icon icon="iconoir:folder-plus" />
              Load Checklists
            </button>
          </li>
          <li>
            <!-- svelte-ignore missing-declaration -->
            <button on:click={() => newChecklistModal?.showModal()}>
              <Icon icon="iconoir:plus" />
              New Checklist
            </button>
          </li>
          <li>
            <button on:click={() => (runAddRuleinApplyChecklist = true)}>
              <Icon icon="iconoir:plus" />
              New Asset Rule
            </button>
          </li>
        </ul>
      </div>
    {/if}
  </div>

  {#if step === 0}
    <StreamAssetInfo
      bind:step
      bind:stepsCompletedTill
      bind:streamStatus
      bind:currentStreamPerm
      bind:streamName
      {report_id}
      classification={"asset"}
      type={"asset"}
      {isNewWorkflow}
      {uploadedFiles}
      {ran_reports}
    />
  {:else if step === 1}
    <StreamWorkflow
      bind:step
      bind:stepsCompletedTill
      {currentStreamPerm}
      {report_id}
      bind:isNewWorkflow
      bind:selectedWorkflow
      {uploadedFiles}
      {ran_reports}
    />
  {:else if step === 2}
    <StreamUpload
      bind:step
      bind:stepsCompletedTill
      bind:currentStreamPerm
      {report_id}
      {isNewWorkflow}
      bind:uploadedFiles
      {ran_reports}
    />
  {:else if step === 3}
    <ApplyChecklists
      bind:step
      bind:stepsCompletedTill
      bind:rulesToReRun
      bind:activeNewRuleSubSection
      bind:activeTab
      bind:runAddRuleinApplyChecklist
      {report_id}
      assetView={true}
      bind:ran_reports
    />
  {/if}
  <!-- svelte-ignore missing-declaration -->
  <ActionConfirmation
    message="Are you sure you want to delete this folder?"
    onConfirm={async () => {
      await deleteStream();
    }}
    onCancel={() => confirmModal.close()}
    showLoadingSpinner={true}
    loading={deleting}
  />
</main>
