<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { Toaster } from "svelte-sonner";
  import LogoLoader from "../LogoLoader.svelte";

  export let modalId = "my_modal";
  export let size: "sm" | "md" | "xl" | "2xl" = "sm";
  export let cornerCloseButton = true;
  export let cornerBackButton = false;
  let modalClasses = "";
  export { modalClasses as class };
  export let containerClasses = "";

  // TODO: refactor to primary: {text ,actions, loading, disabled ...} ...
  export let bottomButtons = {
    show: false,
    primaryDisabled: false,
    primaryDisabledTooltip: "",
    primaryText: "Yes",
    secondaryText: "No",
    loading: false,
    primaryAction: () => {},
    secondaryAction: () => {},
  };

  const dispatch = createEventDispatcher();
</script>

<!-- cornerCloseButton default to false? -->

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<dialog id={modalId} class="modal {modalClasses}" on:click|stopPropagation>
  <div
    class="modal-box overflow-hidden text-base"
    class:max-w-screen-md={size === "md"}
    class:max-w-screen-xl={size === "xl"}
    class:max-w-screen-2xl={size === "2xl"}
  >
    {#if cornerCloseButton}
      <form method="dialog">
        <button
          class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
          on:click={() => dispatch("close")}
        >
          ✕
        </button>
      </form>
    {/if}
    {#if cornerBackButton}
      <form method="dialog">
        <button
          class="btn btn-sm btn-circle btn-ghost text-primary absolute left-2 top-2"
          on:click={() => dispatch("close")}
        >
          ←
        </button>
      </form>
    {/if}

    <div
      class="h-full max-h-[calc(100vh-8em)] overflow-auto {containerClasses}"
      style:max-height="calc(100vh - 8rem - {bottomButtons.show ? 136 : 0}px)"
    >
      <slot>
        <h3 class="text-lg font-bold">Hello!</h3>
        <p class="py-4">Press ESC key or click outside to close</p>
      </slot>
    </div>

    <!-- try #key this -->
    {#if bottomButtons.show}
      <div class="bg-base-200 -m-6 mt-4 flex flex-col gap-2 p-6 pt-2">
        <div
          class:tooltip={bottomButtons.primaryDisabled}
          data-tip={bottomButtons.primaryDisabledTooltip}
        >
          <button
            class="btn btn-primary w-full"
            on:click={bottomButtons.primaryAction}
            disabled={bottomButtons.loading || bottomButtons.primaryDisabled}
          >
            {#if bottomButtons.loading}<LogoLoader />{/if}
            {bottomButtons.primaryText}
          </button>
        </div>

        <button
          class="btn btn-ghost text-primary"
          on:click={bottomButtons.secondaryAction}
        >
          {bottomButtons.secondaryText}
        </button>
      </div>
    {/if}

    <!-- else toast notifs come in the background of the modal -->
    <Toaster />
  </div>
  <form method="dialog" class="modal-backdrop">
    <button on:click={() => dispatch("close")}>close</button>
  </form>
</dialog>
