<script>
  import { onMount, tick } from "svelte";
  import { toast } from "svelte-sonner";
  import { push } from "svelte-spa-router";
  import { fetchGet, fetchPatch, fetchPost } from "../../helpers";
  import { deepCopy } from "../../lib/utils/GenericUtils";
  import { maximised } from "../../stores";
  import { defaultTimestampDialogData } from "../../lib/utils/TimestampUtils";
  import {
    getDataType,
    getUsableMediaLink,
  } from "../../lib/utils/GeneralDataUtils";
  import ActionConfirmation from "../ActionConfirmation.svelte";
  import LogoLoader from "../LogoLoader.svelte";
  import TimestampViewModal from "../Modals/TimestampViewModal.svelte";
  import TableFilterSidebar from "../TableViews/TableFilterSidebar.svelte";
  import TableHit from "../TableViews/TableHit.svelte";
  import TableLoading from "../TableViews/TableLoading.svelte";
  import TableSource from "../TableViews/TableSource.svelte";
  import TableType from "../TableViews/TableType.svelte";
  import Pagination from "../table/Pagination.svelte";

  export let step = 1;
  export let stepsCompletedTill = 0;
  export let report_id;
  export let rules = [];
  export let streamType;

  let initialLoad = true;
  let streamName = "";
  let streamDesc = "";
  let allResults = [];
  let filteredData = [];
  let currentPage = 1;
  let itemsPerPage = 10;
  let creatingStream = false;
  let initialDataLoaded = false;
  let confirmRun = false;
  let selectedRulesId = [];
  let selectedDataTypes = [];
  let selectedVendors = [];
  let selectedSocialTypes = [];
  let uniqueVendors = [];
  let uniqueIndexes = [];
  let uniqueDataTypes = [];
  let isTableSorted = false;
  let shouldSearch = false;
  let rulesHitsStats = new Map();
  let timestampModal = null;

  const maxDocumentsPerIndex = 100; // Top level variable to manage document limits
  let dialogData = { ...defaultTimestampDialogData };

  /**
   * @todo Add error handling based on HTTPStatus codes returned via API to better
   * indicate to user the issue. Additionally, we also need to do some additional work
   * on the backend to further _silo_ the rule operations e.g.:
   * 1. We should on the _previous_ page (step) **only** save the `Rule` entity.
   * 2. On _this_ page (step), if the `Rule` entity exists, run it, else, create then run.
   * @param rule
   */
  async function runRule(rule) {
    try {
      let delete_old_rule = !rule.created_date ?? false;

      if (delete_old_rule) {
        await fetchPost("/rule/run", {
          rule_id: rule.id,
          rule_name: rule.name,
          rule_description: rule.description,
          remediation_step: rule.remediation_step,
          query: rule.boolean,
          indexes: rule.data_type.split(", "),
          report_id: report_id,
          delete_old_rule: delete_old_rule,
        });
      } else {
        await fetchPatch(`/rule/${rule.id}`, {
          boolean: rule.boolean,
          name: rule.name,
          description: rule.description,
          remediation_step: rule.remediation_step,
          data_type: rule.data_type,
          default_hit_priority: rule.default_hit_priority,
        });
        await fetchPatch(`/rule/run/${rule.id}`, { rule });
      }
    } catch (error) {
      toast.error("Something went wrong while running rule...");
    }
  }

  const createStream = async () => {
    if (stepsCompletedTill < step) stepsCompletedTill = step;

    if (allResults.length >= 5000 && !confirmRun) {
      long_run_confirm.showModal();
      return;
    }

    toast.info(
      `${streamType === "new" ? "Creating" : "Saving"} Stream(${streamName}).`,
    );

    creatingStream = true;

    await fetchPost("/report/1", {
      report_id,
      name: streamName,
      description: streamDesc,
      status: "Live",
    });

    for (const rule of rules) {
      await runRule(rule);
    }

    creatingStream = false;
    push(`/stream/${report_id}`);
  };

  async function getRulePreviewHits(rule) {
    try {
      const response = await fetchPost("/rule/preview", {
        boolean: rule.boolean,
        data_types: rule.data_type.split(", "),
        full_preview: true,
        max_documents_per_index: maxDocumentsPerIndex,
      });

      if (!response.success)
        console.warn("Something went wrong while refreshing rule", rule.id);

      let preview = response.preview;
      preview = preview.map((hit) => {
        hit.rule_id = rule.id;
        hit.vendor = hit.vendor.charAt(0).toUpperCase() + hit.vendor.slice(1);

        return hit;
      });

      return preview;
    } catch (error) {
      toast.error("Something went wrong while refreshing rule");
      console.warn("Error while running the rule", error);
    }

    return [];
  }

  function getFilteredData() {
    const newFilteredData = allResults
      .filter((entry) => selectedRulesId.includes(entry.rule.id))
      .filter((entry) => selectedDataTypes.includes(entry.hit.data_type))
      .filter((entry) => selectedVendors.includes(entry.hit.vendor))
      .filter((entry) => selectedSocialTypes.includes(entry.hit.index))
      .sort((a, b) => {
        if (isTableSorted) return 0;
        isTableSorted = true;
        return a.hit.id - b.hit.id;
      });

    rules.forEach((r) => {
      const ruleHits = newFilteredData.filter(
        (nfd) => nfd.rule.id === r.id,
      ).length;
      rulesHitsStats.set(r.id, ruleHits > 500 ? "500+" : ruleHits);
    });
    rulesHitsStats = rulesHitsStats;

    return newFilteredData;
  }

  async function getPreview() {
    allResults = [];

    for (const rule of rules) {
      const preview = await getRulePreviewHits(rule);
      const formattedPreview = preview.map((hit) => {
        return { hit: hit, rule: rule };
      });
      allResults = [...allResults, ...formattedPreview];
    }

    selectedRulesId = rules.map((r) => r.id);
    selectedDataTypes = [...new Set(allResults.map((e) => e.hit.data_type))];
    selectedVendors = [...new Set(allResults.map((e) => e.hit.vendor))];
    selectedSocialTypes = [...new Set(allResults.map((e) => e.hit.index))];

    if (initialLoad) {
      uniqueIndexes = deepCopy(selectedSocialTypes);
      uniqueDataTypes = deepCopy(selectedDataTypes);
      uniqueVendors = deepCopy(selectedVendors);
      initialLoad = false;
    }

    filteredData = allResults;
    rules.forEach((r) => {
      const ruleHits = allResults.filter((nfd) => nfd.rule.id === r.id).length;
      rulesHitsStats.set(r.id, ruleHits > 500 ? "500+" : ruleHits);
    });
    rulesHitsStats = rulesHitsStats;
  }

  function handleUpdateFilteredData() {
    filteredData = getFilteredData();
    filteredData = filteredData;
    shouldSearch = false;
  }

  function handleTimestampData(timestamp) {
    dialogData = {
      title: timestamp.title,
      timestamp: timestamp.media_timestamp,
      url: getUsableMediaLink(
        timestamp.index,
        timestamp.data_type,
        timestamp.url,
        timestamp.content_link,
      ),
      caption: timestamp.highlight,
    };
    timestampModal.showModal();
  }

  onMount(async () => {
    initialDataLoaded = false;

    const res = await fetchGet(`/report/details/${report_id}`);
    if (res.success === false) return;

    streamName = res.report.name;
    streamDesc = res.report.description;

    await getPreview();

    currentPage = 1;
    initialDataLoaded = true;

    await tick();
    $maximised = false;
  });

  $: if (shouldSearch) {
    handleUpdateFilteredData();
  }
  $: handleUpdateFilteredData(),
    [selectedRulesId, selectedDataTypes, selectedVendors, selectedRulesId];
</script>

<ActionConfirmation
  modalId="long_run_confirm"
  message="Warning!"
  text="Refreshing rules that generate many hits or search across multiple channels may take some time. Would you like to refresh now?"
  onConfirm={() => {
    confirmRun = true;
    createStream();
    long_run_confirm.close();
  }}
  onCancel={() => long_run_confirm.close()}
  confirmText="Confirm"
  cancelText="Close"
/>

<TimestampViewModal
  bind:this={timestampModal}
  title={dialogData.title}
  caption={dialogData.caption}
  url={dialogData.url}
  timestamp={dialogData.timestamp}
/>

<div class="ml-56 flex items-center justify-between">
  <h1 class="text-xl">Draft Results</h1>
  <button class="btn btn-sm" on:click={() => (step = 1)}>Edit Rules</button>
</div>
<p class="ml-56 text-xs">
  This table provides a concise overview of a subset of all results limited to
  500 documents per rule. Where an individual document contains several hits
  (rows).
</p>

{#if initialDataLoaded}
  <div class="mt-2 flex">
    <TableFilterSidebar
      originalData={allResults}
      report_id={"preview"}
      exportRes={false}
      tableRootPage={"stream-preview"}
      bind:selectedRulesId
      bind:selectedDataTypes
      bind:selectedVendors
      bind:selectedSocialTypes
      bind:unique_data_types={uniqueDataTypes}
      bind:unique_vendors={uniqueVendors}
      bind:unique_indexes={uniqueIndexes}
      bind:rulesHitsStats
      bind:step
      isPreview={true}
      allRules={rules}
      on:selectChanged={() => (shouldSearch = true)}
    />

    <main class="w-full pl-2">
      <div
        class="max-h-[calc(100vh-328px)] min-h-[300px] overflow-x-auto rounded border"
      >
        <table class="table-zebra table">
          <thead>
            <tr>
              <th>Type</th>
              <th>Location</th>
              <th class="w-full">Hit</th>
              <th>Rules</th>
            </tr>
          </thead>
          <tbody>
            {#each filteredData
              .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
              .map((e) => e.hit) as result}
              <tr class="h-[73px]">
                <td
                  class="border-l-4"
                  class:!border-l-warning={result.data_type === "DOCUMENT"}
                  style="border-left-color: {result.index
                    .toLowerCase()
                    .includes('instagram')
                    ? '#FF0069'
                    : result.index.toLowerCase().includes('tiktok')
                      ? '#00f2ea'
                      : result.index.toLowerCase().includes('facebook')
                        ? '#4267B2'
                        : result.index.toLowerCase().includes('twitter')
                          ? '#1DA1F2'
                          : result.index.toLowerCase().includes('linkedin')
                            ? '#0077b5'
                            : result.index.toLowerCase().includes('youtube')
                              ? '#FF0000'
                              : '#2b3440'}"
                >
                  <div class="flex items-center gap-2">
                    <TableType
                      type={result.index}
                      data_type={getDataType(result)}
                      classes="text-3xl "
                    />
                    <div>
                      <h3 class="truncate font-semibold uppercase">
                        {getDataType(result)}
                      </h3>
                      <span class="whitespace-nowrap">
                        {result.vendor ? `- ${result.vendor}` : ""}
                      </span>
                    </div>
                  </div>
                </td>
                <td class="max-w-[200px]">
                  <TableSource
                    hitId={result.id}
                    hitUrl={result.url}
                    hitTitle={result.title}
                    hitContentLink={result.content_link}
                    hitPool={result.index}
                    hitDataType={result.data_type}
                  />
                </td>
                <td class="max-w-[max(400px,50vw)]">
                  <TableHit
                    hit={result.highlight}
                    hitUrl={result.url}
                    hitContentLink={result.content_link}
                    hitField={result.field}
                    hitPool={result.index}
                    hitDataType={result.data_type}
                    hitTimestamp={result.data_type.toLowerCase() == 'timestamp' ? result.media_timestamp : null}
                    on:click={() => handleTimestampData(result)}
                  />
                </td>
                <td class="min-w-[150px]">
                  <div
                    class="tooltip tooltip-left"
                    data-tip={rules.find((e) => e.id === result.rule_id).name}
                  >
                    <strong
                      class="line-clamp-2 overflow-hidden text-ellipsis text-start"
                    >
                      {rules.find((e) => e.id === result.rule_id).name}
                    </strong>
                  </div>
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
      </div>

      <Pagination
        bind:currentPage
        bind:itemsPerPage
        bind:totalHits={filteredData.length}
        canUseKeysToPaginate={true}
      />
    </main>
  </div>
{:else}
  <TableLoading tableRootPage="streamReview" />
{/if}

<div class="mt-4 flex gap-2">
  <button class="btn btn-sm" on:click={() => (step -= 1)}>Back</button>

  <button
    class="btn btn-primary btn-sm"
    disabled={creatingStream}
    on:click={createStream}
  >
    {#if creatingStream}
      <LogoLoader size="1.25rem" />
      {streamType === "new" ? "Creating" : "Saving"} Stream...
    {:else}
      {streamType === "new" ? "Create" : "Save"} Stream
    {/if}
  </button>
</div>
