<script>
  import { toast } from "svelte-sonner";
  import { onMount } from "svelte";
  import { fetchDelete } from "../../helpers";
  import { currentUser, org_users } from "../../stores";
  import Pagination from "../table/Pagination.svelte";
  import OrganizationRoleSetter from "./OrganizationRoleSetter.svelte";
  import ActionConfirmation from "../ActionConfirmation.svelte";
  import Icon from "@iconify/svelte";
  import {
    UserRole,
    UserAccountStatus,
  } from "../../lib/interfaces/User.interface";

  export let showRemovedUsers;

  let currentPage = 1;
  let itemsPerPage = 5;
  let userToDelete = null;
  let deletingUser = false;
  let loading = false;
  let users = [];

  async function deleteUser() {
    loading = true;

    try {
      if (!userToDelete) {
        throw new Error("No user to delete was selected.");
      }

      const res = await fetchDelete(`/user/${userToDelete.id}`, {
        newOwnerId: null,
      });

      if (!res.success) {
        throw new Error(res?.message);
      }

      org_users.update((users) => {
        const deletedUserIdx = users.findIndex(
          (ou) => ou.id === userToDelete.id,
        );
        users[deletedUserIdx].is_deleted = true;

        return users;
      });

      toast.success(
        `User ${userToDelete.first_name} ${userToDelete.last_name} deleted`,
      );
    } catch (e) {
      console.error(e);
      toast.error(`Failed to delete user`);
    }

    userToDelete = null;
    loading = false;
    deletingUser = false;
  }

  function populateAndSortUserList() {
    users = [
      ...$org_users
        .filter((ou) => (showRemovedUsers ? ou?.is_deleted : !ou.is_deleted))
        .sort((a, b) => a.first_name.localeCompare(b.first_name)),
    ];
  }

  onMount(() => {
    populateAndSortUserList();
  });

  $: populateAndSortUserList(), [$org_users, showRemovedUsers];
</script>

<!-- svelte-ignore missing-declaration -->
<ActionConfirmation
  modalId="userDeleteModal"
  message={!!userToDelete
    ? `Are you sure you want to delete User(${userToDelete.first_name} ${userToDelete.last_name})? All of their rules and rule sets will have their ownership transferred to you.`
    : ""}
  showLoadingSpinner={true}
  {loading}
  onConfirm={async () => {
    await deleteUser();
    userDeleteModal.close();
  }}
  onCancel={() => {
    userToDelete = null;
    userDeleteModal.close();
    deletingUser = false;
  }}
/>

<div class="overflow-auto">
  <table
    class="bg-base-200 text-base-content/80 table border-collapse text-sm *:h-12"
  >
    <thead>
      <tr
        class="text-base-content/90 border-gray-200 *:text-sm *:font-semibold"
      >
        {#if !showRemovedUsers}
          <th>Name</th>
        {/if}
        <th>Email</th>
        <th>Role</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      {#each users.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) as user}
        <tr>
          {#if !showRemovedUsers}
            <td>{user["first_name"]} {user["last_name"]}</td>
          {/if}
          <td>{user["email"]}</td>
          <td>
            <OrganizationRoleSetter
              role={user["role"]}
              userId={user["id"]}
              bind:selectDisabled={showRemovedUsers}
            />
          </td>
          <td>
            {#if user.id !== $currentUser.id && $currentUser.role === UserRole.ADMIN && !showRemovedUsers}
              <!-- svelte-ignore missing-declaration -->
              <button
                class="btn btn-outline btn-error btn-xs"
                disabled={deletingUser}
                on:click={() => {
                  userToDelete = user;
                  deletingUser = true;
                  userDeleteModal.showModal();
                }}
              >
                Delete
              </button>
            {/if}
            {#if $currentUser.role === UserRole.ADMIN && showRemovedUsers}
              <div class="tooltip" data-tip="Coming soon!">
                <button class="btn btn-disabled btn-primary btn-xs">
                  Restore
                </button>
              </div>
            {/if}
          </td>
        </tr>
      {:else}
        <tr>
          <td colspan={showRemovedUsers ? 3 : 5}>
            <div
              class="flex flex-col justify-center items-center p-4 text-base-content/50"
            >
              <Icon icon="iconoir:user" class="text-8xl" />
              <p class="text-sm text-center">
                No {showRemovedUsers
                  ? UserAccountStatus.REMOVED
                  : UserAccountStatus.ACTIVE} users.
              </p>
            </div>
          </td>
        </tr>
      {/each}
    </tbody>
  </table>
</div>

<Pagination bind:currentPage bind:itemsPerPage totalHits={users.length} />
