<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher } from "svelte";
  import { toast } from "svelte-sonner";
  import { slide } from "svelte/transition";
  import { fetchPost, saveAssetRule } from "../../../helpers";
  import { RuleSearchType } from "../../../lib/interfaces/Rule.interface";
  import { org_id, org_name } from "../../../stores";
  import AssetRuleInfoDialog from "../../AssetReview/AssetRuleInfoDialog.svelte";
  import ChecklistInfoModal from "../../ChecklistInfoModal.svelte";
  import DisplayPdf from "../../PDF/DisplayPDF.svelte";
  import AIRuleEditDialog from "../../Reports/AIRuleEditDialog.svelte";
  import Modal from "../Modal.svelte";
  import RegenerateRulesModal from "./RegenerateRulesModal.svelte";

  const dispatch = createEventDispatcher();

  export let thread_id;
  export let rules = [];
  export let selectedAssetIds = [];

  let activeAssetId;
  let activeCheckListId = 0;
  let activeRuleId = "";

  let selectedRuleIds = [];

  $: if (!activeAssetId && selectedAssetIds.length)
    activeAssetId = selectedAssetIds[0];

  const saveChecklists = async () => {
    if (selectedRuleIds.length === 0) {
      toast.warning("Please select at least one rule to save");
      return;
    }

    const createCheckListRes = await fetchPost("/ruleset/create", {
      name: currentChecklist.name,
      description: currentChecklist.description,
      share: false,
    });

    const selectedRules = rules.filter((r) => selectedRuleIds.includes(r.id));

    const savePromises = selectedRules.map(async (r) => {
      const rule_type =
        r.rule_type === "exact"
          ? RuleSearchType.LEXICAL
          : r.rule_type === "similarity"
            ? RuleSearchType.TENSOR
            : RuleSearchType.BOOLEAN;
      const examples =
        r.rule_type === "similarity"
          ? r.rule_query.map((item) => ({ text: item, score: 1 }))
          : r.rule_type === "exact"
            ? r.rule_query.map((item) => `"${item}"`).join(" OR ")
            : r.rule_query;

      const rule = {
        examples: examples,
        lexical: r.rule_type === "exact" ? examples : "",
        operator: "OR",
        search_type: rule_type,
        queryBoolean: r.rule_type === "boolean" ? r.rule_query : "",
        doc_query: r.rule_query,
        id: null,
        affirmative_rule: true,
        name: r.rule_title,
        description: r.explanation,
        query: JSON.stringify({
          examples: examples,
          doc_query: r.rule_query,
        }),
        no_match_threshold: "0.92000000",
        remediation_step: "",
        strong_match_threshold: "0.95000000",
        type: "asset",
        search_sources: {
          DOCUMENT: true,
          IMAGE_TEXT: false,
          IMAGE_FEATURE: false,
        },
        context: r.context,
        definitions: r.definitions,
        limit: 5,
      };

      return await saveRule(rule, createCheckListRes.rule_set.id); // Save each rule and return the promise
    });

    try {
      await Promise.all(savePromises); // Wait for all saveRule calls to complete
      dashboardModal?.close();
      dispatch("updateRules"); // Only dispatch after all rules are saved
    } catch (error) {
      console.error("Failed to save one or more rules:", error);
    }
  };

  const saveRule = async (rule, rs_id) => {
    const res = await saveAssetRule(rule, $org_id, true);

    // add each rule to the ruleset
    const res2 = await fetchPost("/ruleset/add-to-ruleset", {
      rule_id: res.rule.id,
      rule_set_id: rs_id,
    });

    if (!res.success) {
      toast.error(res.message);
      throw new Error(res.message); // Throw an error if saving fails
    }

    toast.success(`Rule saved successfully`);
  };

  let currentChecklist = {
    name: "AI Generated Checklist",
    description: "This checklist is AI Generated",
  };
</script>

<!-- svelte-ignore missing-declaration -->
<Modal
  modalId="dashboardModal"
  size="2xl"
  bottomButtons={{
    show: true,
    primaryText: "Save Checklists →",
    primaryDisabled: selectedRuleIds.length === 0,
    primaryDisabledTooltip: "Please select at least one rule to save",
    secondaryText: "Cancel",
    primaryAction: saveChecklists,
    secondaryAction: () =>
      document.querySelector("#unsavedChangesModal")?.showModal(),
  }}
  on:close={(e) => document.querySelector("#unsavedChangesModal")?.showModal()}
>
  <h1 class="text-3xl font-semibold">AI Generated Checklists</h1>

  <div class="flex items-center gap-4">
    <div class="flex w-full max-w-xl items-center justify-between">
      <label class="flex cursor-pointer items-center gap-2 p-4">
        <input
          type="checkbox"
          class="checkbox checkbox-primary checkbox-sm"
          checked={rules.every((r) => selectedRuleIds.includes(r.id))}
          on:change={(e) => {
            if (e.target.checked) selectedRuleIds = rules.map((r) => r.id);
            else selectedRuleIds = [];
          }}
        />
        Select All
      </label>

      <button
        class="btn btn-primary btn-sm"
        on:click={() => regenerateRulesModal?.showModal()}
      >
        <Icon icon="iconoir:refresh-double" /> Regenerate Rule
      </button>
    </div>

    <div class="mx-auto flex rounded-lg border">
      <button class="btn btn-primary btn-square btn-sm rounded-r-none">
        <Icon icon="iconoir:nav-arrow-left" class="text-lg" />
      </button>

      {#each selectedAssetIds as id, i}
        <button
          class="w-8 border-l border-r"
          class:bg-base-300={activeAssetId === id}
          on:click={() => (activeAssetId = id)}
        >
          {i + 1}
        </button>
      {/each}

      <button class="btn btn-primary btn-square btn-sm rounded-l-none">
        <Icon icon="iconoir:nav-arrow-right" class="text-lg" />
      </button>
    </div>
  </div>

  <div class="flex gap-4">
    <div class="flex h-full w-full max-w-xl shrink-0 flex-col rounded border">
      {#each [{}] as _, i}
        {#if i !== 0}
          <div class="divider mx-4 my-0 h-0" />
        {/if}

        <div class="flex flex-col gap-4 p-4">
          <div class="flex items-center gap-2">
            <input
              type="checkbox"
              class="checkbox checkbox-primary checkbox-sm"
              indeterminate={selectedRuleIds.length > 0 &&
                !rules.every((r) => selectedRuleIds.includes(r.id))}
              checked={rules.every((r) => selectedRuleIds.includes(r.id))}
              on:change={(e) => {
                if (e.target.checked) selectedRuleIds = rules.map((r) => r.id);
                else selectedRuleIds = [];
              }}
            />

            {currentChecklist.name}
            <ChecklistInfoModal
              data={{
                name: currentChecklist.name,
                description: currentChecklist.description,
                id: i,
              }}
            />

            <button
              class="btn btn-outline btn-xs ml-auto"
              on:click={() => editSavingChecklistName?.showModal()}>Edit</button
            >

            <Modal
              modalId="editSavingChecklistName"
              cornerCloseButton={false}
              bottomButtons={{
                show: true,
                primaryDisabled: currentChecklist.length === 0,
                primaryDisabledTooltip: "Checklist name cannot be empty",
                primaryText: "Save",
                secondaryText: "Cancel",
                loading: false,
                primaryAction: () => {
                  currentChecklist = {
                    name: document.querySelector(
                      "#editSavingChecklistName #checklist_name",
                    ).value,
                    description: document.querySelector(
                      "#editSavingChecklistName #checklist_description",
                    ).value,
                  };

                  editSavingChecklistName?.close();
                },
                secondaryAction: () => {
                  document.querySelector(
                    "#editSavingChecklistName > #checklist_name",
                  ).value = currentChecklist.name;

                  document.querySelector(
                    "#editSavingChecklistName #checklist_description",
                  ).value = currentChecklist.description;

                  editSavingChecklistName?.close();
                },
              }}
              on:close={() => {
                document.querySelector(
                  "#editSavingChecklistName #checklist_name",
                ).value = currentChecklist.name;

                document.querySelector(
                  "#editSavingChecklistName #checklist_description",
                ).value = currentChecklist.description;
              }}
            >
              <h1 class="text-xl font-semibold">Edit Checklist</h1>

              <div>
                <label class="label" for="checklist_name">
                  <span class="label-text">Checklist name</span>
                </label>
                <input
                  type="text"
                  value={currentChecklist.name}
                  placeholder="Checklist Name"
                  class="input input-bordered w-full"
                  id="checklist_name"
                />
              </div>

              <div>
                <label class="label" for="checklist_description">
                  <span class="label-text">Checklist description</span>
                </label>
                <input
                  type="text"
                  value={currentChecklist.description}
                  placeholder="Checklist Description"
                  class="input input-bordered w-full"
                  id="checklist_description"
                />
              </div>
            </Modal>

            <button
              class="btn btn-square btn-ghost btn-sm"
              on:click={() =>
                (activeCheckListId = activeCheckListId === i ? -1 : i)}
            >
              <Icon
                icon="iconoir:nav-arrow-down"
                class="text-lg transition-transform
                    {activeCheckListId === i ? 'rotate-180' : ''}"
              />
            </button>
          </div>
          {#if activeCheckListId === i}
            {#each rules as r (r.id)}
              {@const rule_type =
                r.rule_type === "exact"
                  ? RuleSearchType.LEXICAL
                  : r.rule_type === "similarity"
                    ? RuleSearchType.TENSOR
                    : RuleSearchType.BOOLEAN}

              {@const examples =
                r.rule_type === "similarity"
                  ? r.rule_query.reduce((obj, item) => {
                      obj[item] = 1;
                      return obj;
                    }, {})
                  : r.rule_type === "exact"
                    ? r.rule_query.map((item) => `"${item}"`).join(" OR ")
                    : r.rule_query}
              <div class="flex items-center gap-4" transition:slide>
                <input
                  type="checkbox"
                  class="checkbox checkbox-primary checkbox-sm"
                  value={r.id}
                  bind:group={selectedRuleIds}
                />

                <div class="bg-base-200 w-full rounded border p-4">
                  <div class="flex gap-2">
                    {r.rule_title}

                    <button
                      class="btn btn-circle btn-ghost btn-xs"
                      on:click={() =>
                        document
                          .querySelector(`#rule_info_modal_${r.id}`)
                          .showModal()}
                    >
                      <Icon icon="material-symbols:info-outline" />
                    </button>

                    <AssetRuleInfoDialog
                      rule={{
                        id: r.id,
                        affirmative_rule: true,
                        name: r.rule_title,
                        description: r.explanation,
                        rule_type,
                        examples: r.rule_query.toString(),
                        query: JSON.stringify({
                          examples: examples,
                          doc_query: r.rule_query,
                        }),
                      }}
                    />

                    <button
                      class="btn btn-outline btn-xs ml-auto"
                      on:click={() => {
                        document
                          .querySelector(`#ruleEdit-${r.id}`)
                          ?.showModal();
                      }}
                    >
                      Edit
                    </button>

                    {#key r}
                      <AIRuleEditDialog
                        rule={{
                          affirmative_rule: true,
                          boolean:
                            r.rule_type === "boolean" ? r.rule_query : "",
                          data_type: "vector-db-index",
                          description: r.explanation,
                          examples,
                          query: JSON.stringify({ examples }),
                          id: r.id,
                          name: r.rule_title,
                          rule_type,
                          type: "asset",
                        }}
                        on:save={(e) => {
                          const temp = e.detail;
                          console.log({ temp });

                          r.rule_title = temp.name;
                          r.explanation = temp.description;
                          r.rule_type =
                            temp.rule_type === RuleSearchType.LEXICAL
                              ? "exact"
                              : temp.rule_type === RuleSearchType.TENSOR
                                ? "similarity"
                                : "boolean";
                          r.rule_query =
                            r.rule_type === "exact"
                              ? temp.lexical
                                  .split(" OR ")
                                  .map((item) => item.replace(/"/g, ""))
                              : r.rule_type === "similarity"
                                ? temp.examples.map((i) => i.text)
                                : temp.boolean;

                          document.querySelector(`#ruleEdit-${r.id}`).close();
                        }}
                      />
                    {/key}

                    <button
                      class="btn btn-square btn-ghost btn-xs"
                      on:click={() =>
                        (activeRuleId = activeRuleId === r.id ? -1 : r.id)}
                    >
                      <Icon
                        icon="iconoir:nav-arrow-down"
                        class="text-lg transition-transform
                          {activeRuleId === r.id ? 'rotate-180' : ''}"
                      />
                    </button>
                  </div>

                  {#if activeRuleId === r.id}
                    <div
                      class="bg-base-100 mt-4 flex flex-col gap-4 rounded p-4"
                      transition:slide
                    >
                      <p>
                        <span class="font-semibold">Rule:</span>
                        {r.rule_title}
                      </p>
                      <p>
                        <span class="font-semibold">Type of Rule:</span>
                        {r.rule_type}
                      </p>
                      <p>
                        <span class="font-semibold">Description:</span>
                        {r.explanation}
                      </p>
                    </div>
                  {/if}
                </div>
              </div>
            {/each}
          {/if}
        </div>
      {/each}
    </div>

    <div class="min-h-96 w-full">
      {#key activeAssetId}
        {#if activeAssetId > 0}
          <DisplayPdf
            height="100%"
            open={true}
            documentURL="https://preex-staging.s3.ap-southeast-2.amazonaws.com/{$org_name}/rulegen/{activeAssetId}"
            documentType="proxy"
          />
        {/if}
      {/key}
    </div>
  </div>
</Modal>

<RegenerateRulesModal bind:rules {thread_id} />

<Modal
  modalId="unsavedChangesModal"
  bottomButtons={{
    show: true,
    primaryText: "Review and Save Selected Rules →",
    secondaryText: "Cancel",
    loading: false,
    primaryAction: () => {
      document.querySelector("#unsavedChangesModal")?.close();
      document.querySelector("#dashboardModal")?.showModal();
    },
    secondaryAction: () => {
      document.querySelector("#unsavedChangesModal")?.close();
      document.querySelector("#dashboardModal")?.close();
    },
  }}
  cornerCloseButton={false}
  containerClasses="flex flex-col items-center gap-4"
>
  <span
    class="bg-error flex h-12 w-12 items-center justify-center rounded-full"
  >
    <Icon icon="iconoir:warning-triangle" class="text-xl text-white" />
  </span>

  <p class="font-semibold">Unsaved Changes Detected</p>

  <p class="text-center text-sm">
    You have unsaved changes. If you navigate away from this page,
    <span class="font-semibold">you will lose your progress</span>
    with the AI-generated rules. Please review and save your selections before continuing.
  </p>
</Modal>
