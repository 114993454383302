<script>
  import { javascript } from "@codemirror/lang-javascript";
  import Icon from "@iconify/svelte";
  import { createEventDispatcher, onMount, tick } from "svelte";
  import CodeMirror from "svelte-codemirror-editor";
  import { toast } from "svelte-sonner";
  import { slide } from "svelte/transition";
  import {
    RuleScope,
    RuleSearchType,
    RuleStatus,
    RuleType,
  } from "../../../lib/interfaces/Rule.interface";
  import { NoRuleSetDefaults } from "../../../lib/interfaces/RuleSet.interface";
  import { SharedRulePermissions } from "../../../lib/interfaces/SharedRule.interface";
  import { deepCopy, isEmpty } from "../../../lib/utils/GenericUtils";
  import {
    isParentConditionalRule,
    ruleSearchTypeToLabel,
  } from "../../../lib/utils/RuleUtils";
  import { convertBoolToBuilder } from "../../../ruleBuilderConverter";
  import { currentUser, org_id, user_teams } from "../../../stores";
  import ContextualRuleRow from "../../AssetReview/ContextualRules/ContextualRuleRow.svelte";
  import QueryPrettyPrint from "../../AssetReview/QueryPrettyPrint.svelte";
  import Badge from "../../AssetReview/WorkflowBoard/Badge.svelte";
  import { tidyPoolLabel } from "./../../../helpers.js";
  import SavedRulesTableRowConditionalRules from "./SavedRulesTableRowConditionalRules.svelte";

  const dispatch = createEventDispatcher();

  export let ruleScope = RuleScope.MY;
  export let ruleSet = {};
  export let sharedWith = [];
  export let activeRuleId = -1;
  export let assetRulesTable = false;
  export let ruleToCopy = null;
  export let ruleToDelete = null;
  export let ruleToEdit = null;
  export let convertingSharedRule = false;
  export let userChecklistAccessPermission = SharedRulePermissions.VIEW;
  export let isViewing = false;

  let userRuleAccessPermissions = new Map();

  async function handleConvertSharedRuleToMyRule(rule) {
    convertingSharedRule = true;
    let res;

    if (rule.type === RuleType.ASSET) {
      rule.search_type = rule.rule_type;
      res = await saveSharedAssetRuleToMyRules(rule);
    } else if (rule.type === RuleType.LIVE) {
      if (!isEmpty(rule.data_type)) {
        rule.data_types = rule.data_type.split(",");
      } else {
        rule.data_types = [];
      }

      res = await saveLiveRule(rule, $org_id, true);
    }

    if (res.success) {
      toast.success("Shared Rule saved to My Rules successfully.");
      dispatch("updateRules");
      return;
    }

    convertingSharedRule = false;
    toast.error("There was an error saving the shared rule to My Rules.");
  }

  function populateUserRuleAccessPermissions() {
    if (!isEmpty(ruleSet.rules)) {
      ruleSet.rules.forEach((r) => {
        const associatedSharedRules = sharedWith.filter(
          (sr) =>
            sr.parent_rule_id === r.id &&
            (sr.user_id === $currentUser.id ||
              !!$user_teams.find((ut) => ut.id === sr.team_id)),
        );
        userRuleAccessPermissions.set(
          r.id,
          associatedSharedRules.some(
            (asr) => asr.permission === SharedRulePermissions.EDIT,
          )
            ? SharedRulePermissions.EDIT
            : SharedRulePermissions.VIEW,
        );
        userRuleAccessPermissions = userRuleAccessPermissions;
      });
    }
  }

  onMount(populateUserRuleAccessPermissions);

  $: populateUserRuleAccessPermissions(), [ruleSet, sharedWith];
</script>

<tr transition:slide>
  <!-- <td colspan={ruleScope !== RuleScope.MY ? 4 : 5 }> -->
  <td colspan="5">
    <div class="flex flex-col gap-1 p-2 pt-0" transition:slide>
      {#each ruleSet.rules as rule}
        <div class="bg-base-200 min-w-[750px] rounded border">
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <div
            class="grid grid-cols-3 items-center justify-start px-4 py-2"
            on:click={() =>
              (activeRuleId = activeRuleId === rule.id ? -1 : rule.id)}
          >
            <div class="col-span-1 flex items-center gap-4 text-start">
              <span class="break-words">{rule.name}</span>
            </div>
            {#if assetRulesTable}
              <!-- <div class="col-span-2 flex flex-nowrap justify-between gap-4"> -->
              <div class="col-span-1 flex flex-wrap items-center gap-2">
                <Badge bgColor="#929292" textColor="white">
                  {ruleSearchTypeToLabel(rule.rule_type)}
                </Badge>
                {#if isParentConditionalRule(rule)}
                  {#if rule.rule_type === RuleSearchType.MANUAL}
                    {#if rule.affirmative_rule}
                      <Badge bgColor="#929292" textColor="white">
                        {RuleStatus.TRUE} if Marked Complete
                      </Badge>
                    {:else}
                      <Badge bgColor="#929292" textColor="white">
                        {RuleStatus.FALSE} if Not Marked Complete
                      </Badge>
                    {/if}
                  {:else if rule.affirmative_rule}
                    <Badge bgColor="#929292" textColor="white">
                      {RuleStatus.TRUE} if Matched
                    </Badge>
                  {:else}
                    <Badge bgColor="#929292" textColor="white">
                      {RuleStatus.FALSE} if Matched
                    </Badge>
                  {/if}
                {:else if rule.rule_type === RuleSearchType.MANUAL}
                  {#if rule.affirmative_rule}
                    <Badge bgColor="#929292" textColor="white">
                      {RuleStatus.PASSED} if Marked Complete
                    </Badge>
                  {:else}
                    <Badge bgColor="#929292" textColor="white">
                      {RuleStatus.RISK} if Not Marked Complete
                    </Badge>
                  {/if}
                {:else if rule.affirmative_rule}
                  <Badge bgColor="#929292" textColor="white">
                    Passes if Matched
                  </Badge>
                {:else}
                  <Badge bgColor="#929292" textColor="white">
                    Failed if Matched
                  </Badge>
                {/if}
                {#if rule.is_conditional}
                  <Badge bgColor="#929292" textColor="white">Conditional</Badge>
                {/if}
              </div>
              <!-- </div> -->
            {:else}
              <div
                class="tooltip max-w-fit"
                data-tip={rule.data_type}
                class:invisible={activeRuleId === rule.id}
              >
                <p
                  class="col-span-1 overflow-hidden text-ellipsis whitespace-nowrap text-start text-sm"
                >
                  {tidyPoolLabel(rule.data_type)}
                </p>
              </div>
            {/if}
            <div class="col-span-1 ml-auto flex items-center gap-1">
              {#if (ruleScope !== RuleScope.SHARED && ruleScope !== RuleScope.INDUSTRY) || (ruleSet.rule_set?.id === NoRuleSetDefaults.ID && userRuleAccessPermissions.get(rule.id) === SharedRulePermissions.EDIT) || (ruleSet.rule_set?.id !== NoRuleSetDefaults.ID && userChecklistAccessPermission === SharedRulePermissions.EDIT)}
                <button
                  class="btn btn-outline btn-primary btn-sm"
                  on:click={async () => {
                    isViewing = false;
                    ruleToEdit = deepCopy(rule);
                    await tick();
                    document
                      .querySelector(`#ruleEdit-${rule?.id}`)
                      ?.showModal();
                  }}
                >
                  Edit
                </button>
              {:else}
                <button
                  class="btn btn-outline btn-primary btn-sm"
                  on:click={async () => {
                    ruleToEdit = rule;
                    isViewing = true;
                    await tick();
                    document
                      .querySelector(`#ruleView-${rule?.id}`)
                      ?.showModal();
                  }}
                >
                  View
                </button>
              {/if}
              {#if (ruleScope === RuleScope.SHARED && userRuleAccessPermissions.get(rule.id) === SharedRulePermissions.EDIT) || ruleScope !== RuleScope.SHARED}
                <button
                  class="btn btn-outline btn-primary btn-sm"
                  on:click={async () => {
                    ruleToCopy = deepCopy(rule);
                    ruleToCopy.id = null;
                    ruleToCopy.name = `Copy of ${ruleToCopy.name}`;

                    if (rule.type === RuleType.LIVE) {
                      ruleToCopy.data_types = rule.data_type.split(", ");
                      ruleToCopy.builder = convertBoolToBuilder(rule.boolean);
                    } else if (rule.type === RuleType.ASSET) {
                      ruleToCopy.search_sources = {
                        DOCUMENT: true,
                        IMAGE_TEXT: false,
                        IMAGE_FEATURE: false,
                      };
                      ruleToCopy.limit = 5;

                      if (ruleToCopy.is_conditional) {
                        ruleToCopy = {
                          ...ruleToCopy,
                          condition_type: "IF",
                          conditional_id: null,
                          sequence_order: 0,
                          index: "vector-db-index",
                        };

                        ruleToCopy.children = ruleToCopy.children.map((r) => ({
                          ...r,
                          id: null,
                          name: `Copy of ${r.name}`,
                          search_sources: {
                            DOCUMENT: true,
                            IMAGE_TEXT: false,
                            IMAGE_FEATURE: false,
                          },
                          limit: 5,
                          condition_type: "THEN",
                          conditional_id: null,
                          sequence_order: 1,
                          index: "vector-db-index",
                        }));
                      }
                    }

                    await tick();
                    document.querySelector(`#ruleCopy`)?.showModal();
                  }}
                >
                  Copy
                </button>
              {/if}
              <!-- svelte-ignore missing-declaration -->
              {#if (ruleScope !== RuleScope.SHARED && ruleScope !== RuleScope.INDUSTRY) || (ruleSet.rule_set?.id === NoRuleSetDefaults.ID && userRuleAccessPermissions.get(rule.id) === SharedRulePermissions.EDIT) || (ruleSet.rule_set?.id !== NoRuleSetDefaults.ID && userChecklistAccessPermission === SharedRulePermissions.EDIT)}
                <!-- svelte-ignore missing-declaration -->
                <button
                  class="btn btn-outline btn-error btn-sm"
                  on:click={async () => {
                    ruleToDelete = rule;
                    confirmModal.showModal();
                  }}
                >
                  Delete
                </button>
              {/if}
              <button class="btn btn-square btn-ghost btn-sm">
                <Icon
                  icon="iconoir:nav-arrow-{activeRuleId === rule.id
                    ? 'up'
                    : 'down'}"
                  class="text-lg"
                />
              </button>
            </div>
          </div>
          {#if activeRuleId === rule.id}
            <main
              class={`grid grid-cols-3 gap-4 px-4 py-2 pt-0`}
              transition:slide
            >
              {#if assetRulesTable}
                {#if rule.rule_type === RuleSearchType.MANUAL}
                  <div>
                    <p class="text-sm font-medium">ID</p>
                    <p class="text-sm font-light">
                      #{rule.id}
                    </p>
                  </div>
                  <div>
                    <p class="text-sm font-medium">Description</p>
                    <p class="text-sm font-light">
                      {rule.description}
                    </p>
                  </div>
                  <div>
                    <p class="text-sm font-medium">Remediation Step</p>
                    <p class="text-sm font-light">
                      {isEmpty(rule.remediation_step)
                        ? "No remediation step."
                        : rule.remediation_step}
                    </p>
                  </div>
                  <div class="col-span-3 grid grid-cols-6 gap-4">
                    <div class="col-span-3 mr-[36px] flex flex-col gap-1">
                      <p class="mt-1 text-sm font-medium">Query</p>
                      <QueryPrettyPrint
                        {rule}
                        isParentConditional={isParentConditionalRule(rule)}
                      />
                    </div>
                    <div class="col-span-2 col-start-5 flex flex-col gap-1">
                      <p class="mt-1 text-sm font-medium">Sub-Tasks</p>
                      <ul class="rounded-box list-disc">
                        {#each rule.examples.split(" AND ") as example}
                          <li class="ml-6">
                            <span class="badge h-auto"
                              >{example.slice(1, -1)}</span
                            >
                          </li>
                        {/each}
                      </ul>
                    </div>
                  </div>
                {:else if rule.rule_type === RuleSearchType.CONTEXTUAL}
                  <ContextualRuleRow
                    bind:rule
                    isSavedRulesTable={true}
                    on:editRule={async (event) => {
                      ruleToEdit = rule.children.find(
                        (r) => r.id === event.detail,
                      );
                      await tick();
                      document
                        .querySelector(`#ruleEdit-${ruleToEdit?.id}`)
                        ?.showModal();
                    }}
                  />
                {:else}
                  <div>
                    <p class="text-sm font-medium">ID</p>
                    <p class="text-sm font-light">
                      #{rule.id}
                    </p>
                  </div>
                  <div>
                    <p class="text-sm font-medium">Description</p>
                    <p class="text-sm font-light">
                      {rule.description}
                    </p>
                  </div>
                  <div>
                    <p class="text-sm font-medium">Remediation Step</p>
                    <p class="text-sm font-light">
                      {isEmpty(rule.remediation_step)
                        ? "No remediation step."
                        : rule.remediation_step}
                    </p>
                  </div>

                  <div class="col-span-3 grid grid-cols-2">
                    <div class="col-span-1 mr-[36px] flex flex-col gap-1">
                      <p class="mt-1 text-sm font-medium">Query</p>
                      <QueryPrettyPrint
                        {rule}
                        isParentConditional={isParentConditionalRule(rule)}
                      />
                    </div>
                  </div>
                {/if}
              {:else}
                <div>
                  <p class="text-sm font-medium">ID</p>
                  <p class="text-sm font-light">
                    #{rule.id}
                  </p>
                </div>
                <div>
                  <p class="text-sm font-medium">Description</p>
                  <p class="text-sm font-light">
                    {rule.description}
                  </p>
                </div>
                <div class="col-span-1">
                  <p class="text-sm font-medium">Channels</p>
                  <ul class="ml-4 list-disc text-sm">
                    {#each rule.data_type.split(", ") as d}
                      <li>{d}</li>
                    {/each}
                  </ul>
                </div>
                <div class="col-span-3">
                  <p class="text-sm font-medium">Remediation Step</p>
                  <p class="text-sm font-light">
                    {isEmpty(rule.remediation_step)
                      ? "No remediation step."
                      : rule.remediation_step}
                  </p>
                </div>
                <div class="col-span-3 grid grid-cols-2">
                  <div class="col-span-1 mr-[36px] flex flex-col gap-1">
                    <p class="mt-1 text-sm font-medium">Query</p>
                    <CodeMirror
                      bind:value={rule.boolean}
                      editable={false}
                      readonly={true}
                      lang={javascript()}
                      styles={{
                        "&": { background: "white", fontSize: "14px" },
                        ".cm-gutters": { display: "none" },
                      }}
                      lineWrapping={true}
                    />
                  </div>
                </div>
              {/if}

              {#if rule?.is_conditional}
                <div class="col-span-3 flex flex-col">
                  <div class="mb-2 gap-1">
                    <p class="text-sm font-medium">Conditional Child Rules</p>
                    <p class="text-sm font-light">
                      If this rule is
                      <Badge bgColor="#929292" textColor="white">
                        {RuleStatus.TRUE}
                      </Badge>, then the following rules will be ran.
                    </p>
                  </div>

                  <SavedRulesTableRowConditionalRules
                    bind:rules={rule.children}
                    bind:assetRulesTable
                    on:editRule={async (event) => {
                      ruleToEdit = rule.children.find(
                        (r) => r.id === event.detail,
                      );
                      await tick();
                      document
                        .querySelector(`#ruleEdit-${ruleToEdit?.id}`)
                        ?.showModal();
                    }}
                  />
                </div>
              {/if}
            </main>
          {/if}
        </div>
      {/each}
    </div>
  </td>
</tr>
