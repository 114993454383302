//pages
import AllAssets from "./pages/AllAssets.svelte";
import AllReports from "./pages/AllReports.svelte";
import AssetPage from "./pages/AssetPage.svelte";
import AssetReviewEditor from "./pages/AssetReviewEditor.svelte";
import AssetReviewPage from "./pages/AssetReviewPage.svelte";
import Dictionaries from "./pages/Dictionaries.svelte";
import Email from "./pages/Email.svelte";
import Home from "./pages/Home.svelte";
import IndustryRules from "./pages/IndustryRules.svelte";
import MyHits from "./pages/MyHits.svelte";
import MyAssets from "./pages/MyAssets.svelte";
import MyRules from "./pages/MyRules.svelte";
import NewSpellingReport from "./pages/NewSpellingReport.svelte";
import NewStream from "./pages/NewStream.svelte";
import NotFound from "./pages/NotFound.svelte";
import Notifications from "./pages/Notifications.svelte";
import OrganizationHits from "./pages/OrganizationHits.svelte";
import Reports from "./pages/Reports.svelte";
import Search from "./pages/Search.svelte";
import Settings from "./pages/Settings.svelte";
import SpellingReports from "./pages/SpellingReports.svelte";
import UserSettings from "./pages/UserSettings.svelte";
import Workflows from "./pages/Workflows.svelte";

export const baseRoutes = {
  "/": MyHits,
  "*": NotFound,
  "/notifications": Notifications,
};
const spellingRoutes = {
  "/spelling": AllReports,
  "/spelling/new/:report_id": NewSpellingReport,
  "/spelling/edit/:report_id": NewSpellingReport,
  "/spelling/dictionaries/:dictionary_id?": Dictionaries,
  "/spelling/:report_id/:issue_id?": SpellingReports,
};

const streamRoutes = {
  "/stream/new/:report_id": NewStream,
  "/stream/edit/:report_id": NewStream,
  "/stream/:report_id/:issue_id?": Reports,
  "/reports/:report_id/:issue_id?": Reports, //legacy support
  "/streams": AllReports,
  "/allreports": AllReports, //legacy support
  "/search": Search,
};
const assetReviewRoutes = {
  "/reviews": AllAssets,
  "/review/edit/:report_id": AssetReviewEditor,
  "/review/:report_id": AssetReviewPage,
  "/review/:report_id/asset/:asset_id?": AssetPage,
  "/workflows": Workflows,
};
const assigneePermissionRoutes = {
  "/": MyHits,
  "/myissues/:issue_id?": MyHits,
  "/stream/:report_id/:issue_id": MyHits,
  "/reports/:report_id/:issue_id": MyHits, //legacy support
  "/user-settings": UserSettings,
  "/notifications": Notifications,
  "*": NotFound,
};
const userPermissionRoutes = {
  "/": Home,
  "/home": Home,
  "/myissues/:issue_id?": MyHits,
  "/myassets": MyAssets,
  "/rules": MyRules,
  "/issues": OrganizationHits,
  "/email/:email_id/:email_type": Email,
  "/user-settings": UserSettings,
  "/notifications": Notifications,
  "*": NotFound,
};
const adminPermissionRoutes = {
  "/": Home,
  "/home": Home,
  "/myissues/:issue_id?": MyHits,
  "/myassets": MyAssets,
  "/rules": MyRules,
  "/issues": OrganizationHits,
  "/settings": Settings,
  "/user-settings": UserSettings,
  "/notifications": Notifications,
  "/email/:email_id/:email_type": Email,
  "*": NotFound,
};

let haastRoutes = {
  "/industryrules": IndustryRules,
};

let modules = {
  asset_review: assetReviewRoutes,
  live: streamRoutes,
  spelling: spellingRoutes,
};
const roleSpecificRoutes = {
  assignee: assigneePermissionRoutes,
  user: userPermissionRoutes,
  admin: adminPermissionRoutes,
};
// role is the users role (admin, user or assigness)
// disabledPermissons is an array of the modules this org has disabled (spelling,assetreview,live)
function allocateModules(disabledPermissions) {
  // Convert the modules object keys to an array, filter by disabled permissions, and reduce to combine routes
  const moduleRoutes = Object.keys(modules).reduce((acc, key) => {
    // Only add the module's routes if it's not in the disabled permissions
    if (!disabledPermissions.includes(key)) {
      return { ...acc, ...modules[key] };
    }
    return acc;
  }, {});

  return moduleRoutes;
}
export function allocatePermissions(role, disabledPermissions, org_name) {
  let grantedRoutes = {};
  let moduleRoutes = allocateModules(disabledPermissions);
  grantedRoutes = { ...grantedRoutes, ...moduleRoutes };

  if (org_name.toLowerCase() === "haast") {
    grantedRoutes = { ...grantedRoutes, ...haastRoutes };
  }

  if (roleSpecificRoutes[role]) {
    grantedRoutes = { ...grantedRoutes, ...roleSpecificRoutes[role] };
  } else {
    // Default routes if no role found (possibly log error or handle unknown role)
    grantedRoutes = {
      ...grantedRoutes,
      "/": MyHits,
      "*": NotFound,
      "/notifications": Notifications,
    };
  }

  return grantedRoutes;
}

export function createSidebar(
  disabledPermissions,
  my_issues_count,
  notifications_count,
  org_name,
) {
  const sidebarItems = [
    {
      icon: "iconoir:home-alt",
      href: "/home",
      description: "Home",
      roles: ["admin", "user"],
      disabled: false,
      modules: [],
    },
    {
      icon: "iconoir:search",
      href: "/search",
      description: "Quick Search",
      roles: ["admin", "user"],
      disabled: disabledPermissions.includes("live"),
      modules: ["live"],
    },
    {
      icon: "iconoir:page",
      href: "/streams",
      description: "Streams",
      roles: ["admin", "user"],
      disabled: disabledPermissions.includes("live"),
      modules: ["live"],
    },
    {
      icon: "fluent-mdl2:spelling", // No good spelling icons in iconoir
      href: "/spelling",
      description: "Spelling",
      roles: ["admin", "user"],
      disabled: disabledPermissions.includes("spelling"),
      modules: ["spelling"],
    },
    {
      icon: "iconoir:clipboard-check",
      href: "/reviews",
      description: "Asset Review",
      roles: ["admin", "user", "assignee"],
      disabled: disabledPermissions.includes("asset_review"),
      modules: ["asset_review"],
    },
    {
      type: "divider",
      roles: ["admin", "user"],
      disabled: false,
      modules: [],
    },
    {
      icon: "iconoir:user",
      href: "/myissues",
      description: `My Issues ${
        my_issues_count === 0 ? "" : `(${my_issues_count})`
      }`,
      roles: ["admin", "user", "assignee"],
      disabled: false,
      modules: [],
    },
    {
      icon: "iconoir:task-list",
      href: "/myassets",
      description: "My Assets",
      roles: ["admin", "user"],
      disabled: disabledPermissions.includes("asset_review"),
      modules: [],
    },
    {
      icon: "iconoir:group",
      href: "/issues",
      description: "All Issues",
      roles: ["admin", "user"],
      disabled: false,
      modules: [],
    },
    {
      icon: "iconoir:bookmark-empty",
      href: "/rules",
      description: "Saved Rules",
      roles: ["admin", "user"],
      disabled: false,
      modules: [],
    },
    {
      icon: "iconoir:bookmark-book",
      href: "/spelling/dictionaries",
      description: "Dictionaries",
      roles: ["admin", "user"],
      disabled: disabledPermissions.includes("spelling"),
      modules: ["spelling"],
    },
    {
      type: "divider",
      roles: ["admin", "user", "assignee"],
      disabled: false,
      modules: [],
    },
    {
      icon: "iconoir:bell",
      href: "/notifications",
      description: `Notifications ${
        notifications_count === 0 ? "" : `(${notifications_count})`
      }`,
      roles: ["admin", "user", "assignee"],
      disabled: false,
      modules: [],
    },
    {
      icon: "iconoir:network-left",
      href: "/workflows",
      description: "Org Workflows",
      roles: ["admin"],
      disabled: disabledPermissions.includes("asset_review") || disabledPermissions.includes("org_workflow"),
      modules: ["asset_review"],
    },
    {
      icon: "iconoir:settings",
      href: "/settings",
      description: "Settings",
      roles: ["admin"],
      disabled: false,
      modules: [],
    },
    {
      icon: "iconoir:settings",
      href: "/user-settings",
      description: "Settings",
      roles: ["user", "assignee"],
      disabled: false,
      modules: [],
    },
    {
      type: "logout",
      description: "Log Out",
      roles: ["admin", "user", "assignee"],
      disabled: false,
      modules: [],
    },
  ];

  if (org_name.toLowerCase() === "haast") {
    sidebarItems.push({
      icon: "iconoir:industry",
      href: "/industryrules",
      description: "Industry Rules",
      roles: ["admin", "user"],
      disabled: false,
      modules: [],
    });
  }

  return sidebarItems;
}
