import { persisted } from "svelte-persisted-store";
import { writable } from "svelte/store";

export const isAuthenticated = writable(false);
export const fullname = writable("");
export const accessToken = writable("");
export const org_users = writable([]);
export const org_teams = writable([]);
export const org_emails = writable([]);
export const org_name = writable("");
export const org_id = writable(-1);
export const dueDays = writable(14);
export const enableUploadedStage = writable(true);
export const enableApprovedStage = writable(true);
export const user_id = writable(null);
export const user_teams = writable([]);
export const actions = writable([]);
export const org_labels = writable([]);
export const issue_id = writable(null);
export const showLearnHaast = writable(true);
export const role = writable(null);
export const email_list = writable([]);
export const user_list = writable([]);
export const maximised = writable(true);
export const currentUser = writable(null);
export const canEditCurrentStream = writable(false);
export const defaultRBStyle = persisted("defaultRBStyle", "visual");
export const date_format = persisted("date_format", "ago"); // date or ago
export const notifications = writable({
  notifications: [],
  total_notifications: 0,
  unseen_notifications: 0,
});
export const disabledOrganisationModules = writable([]);
