<script>
  import Icon from "@iconify/svelte";
  import Modal from "../components/Modals/Modal.svelte";

  export let assets;

  // Setup object for exporting data
  let exportDataCheckboxes = {
    asset_name: true,
    asset_id: true,
    folder_name: true,
    folder_id: true,
    asset_approval_status: true,
    permitted_users: false,
    list_of_rules_applied: true,
    list_of_hits: false,
    asset_history: true,
  };
</script>

<Modal modalId="data_export_modal" cornerCloseButton={false}>
  <h2 class="text-lg font-bold">Export Data</h2>

  <div class="flex justify-between">
    <div>
      <h3 class="mb-2 ml-1 mt-4 font-bold underline">Assets Metadata</h3>

      <!-- Asset Name -->
      <label class="label w-min cursor-pointer">
        <input
          type="checkbox"
          class="checkbox"
          bind:checked={exportDataCheckboxes.asset_name}
        />
        <span class="label-text ml-2 w-52" style="vertical-align: middle;"
          >Asset Name</span
        >
      </label>

      <!-- Asset ID -->
      <label class="label w-min cursor-pointer">
        <input
          type="checkbox"
          class="checkbox"
          bind:checked={exportDataCheckboxes.asset_id}
        />
        <span class="label-text ml-2 w-52">Asset ID</span>
      </label>

      <!-- Folder Name -->
      <label class="label w-min cursor-pointer">
        <input
          type="checkbox"
          class="checkbox"
          bind:checked={exportDataCheckboxes.folder_name}
        />
        <span class="label-text ml-2 w-52">Folder Name</span>
      </label>

      <!-- Folder ID -->
      <label class="label w-min cursor-pointer">
        <input
          type="checkbox"
          class="checkbox"
          bind:checked={exportDataCheckboxes.folder_id}
        />
        <span class="label-text ml-2 w-52">Folder ID</span>
      </label>

      <!-- Asset Approval Status -->
      <label class="label w-min cursor-pointer">
        <input
          type="checkbox"
          class="checkbox"
          bind:checked={exportDataCheckboxes.asset_approval_status}
        />
        <span class="label-text ml-2 w-52">Asset Approval Status</span>
      </label>

      <!-- Permitted Users -->
      <label class="label w-min cursor-pointer">
        <input
          type="checkbox"
          class="checkbox"
          bind:checked={exportDataCheckboxes.permitted_users}
        />
        <span class="label-text ml-2 w-52">Permitted Users</span>
      </label>
    </div>

    <div>
      <h3 class="mt-4 font-bold underline">Detailed Information</h3>

      <!-- List of rules applied -->
      <label class="label w-min cursor-pointer">
        <input
          type="checkbox"
          class="checkbox"
          bind:checked={exportDataCheckboxes.list_of_rules_applied}
        />
        <span class="label-text ml-2 w-52">List of rules applied</span>
      </label>

      <!-- List of Hits -->
      <label class="label w-min cursor-pointer">
        <input
          type="checkbox"
          class="checkbox"
          bind:checked={exportDataCheckboxes.list_of_hits}
        />
        <span class="label-text ml-2 w-52">List of Hits</span>
      </label>

      <!-- Asset History -->
      <label class="label w-min cursor-pointer">
        <input
          type="checkbox"
          class="checkbox"
          bind:checked={exportDataCheckboxes.asset_history}
        />
        <span class="label-text ml-2 w-52">Asset History</span>
      </label>
    </div>
  </div>

  <form class="mt-4 flex flex-col gap-2" method="dialog">
    <button
      class="btn btn-primary"
      on:click={async () => {
        const response = await fetch("/asset/export", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            assets,
            fields: exportDataCheckboxes,
          }),
        });

        if (!response.ok) {
          console.error("Server response was not ok.");
          console.warn("Payload:", {
            assets,
            exportDataCheckboxes,
          });
          return;
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");

        a.style.display = "none";
        a.href = url;

        // Get current date and time
        let date = new Date();
        let formattedDate = `${date.getFullYear()}-${
          date.getMonth() + 1
        }-${date.getDate()}_${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`;

        // Set the download attribute to the desired filename
        a.download = `AssetExport-${formattedDate}.xlsx`;

        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }}
    >
      <Icon icon="iconoir:download" class="text-xl" />Export
    </button>
    <button class="btn btn-ghost text-primary">Cancel</button>
  </form>
</Modal>
