<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher, onMount, tick } from "svelte";
  import { toast } from "svelte-sonner";
  import { fetchGet, fetchPost } from "../../helpers";
  import { UserRole } from "../../lib/interfaces/User.interface";
  import { capitalise, isEmpty } from "../../lib/utils/GenericUtils";
  import { currentUser, org_users } from "../../stores";
  import LogoLoader from "../LogoLoader.svelte";
  import Select from "../Select.svelte";
  import { SharedRulePermissions } from "./../../lib/interfaces/SharedRule.interface.js";
  import Modal from "./Modal.svelte";

  const dispatch = createEventDispatcher();
  const maximumNameLength = 64;

  export let ruleSets = [];
  export let activeRuleTypeTab = "Live Rules";
  export let checklistName = "";
  export let checklistDescription = "";
  export let sharing = false;

  let allUsers = $org_users;
  let allTeams = [];
  let selectableEntities = [];
  let selectedPermission = null;
  let selectedEntity = null;
  let permissions = [
    {
      value: SharedRulePermissions.VIEW,
      label: capitalise(SharedRulePermissions.VIEW),
    },
    {
      value: SharedRulePermissions.EDIT,
      label: capitalise(SharedRulePermissions.EDIT),
    },
  ];
  let selectedUsers = new Map();
  let selectedTeams = new Map();
  let duplicateDetected = false;
  let exceedsMaxLength = false;
  let loading = false;

  function closeDialog() {
    checklistName = "";
    checklistDescription = "";
    sharing = false;
    newChecklistModal?.close();
    selectedUsers = new Map();
    selectedTeams = new Map();
    loading = false;
  }

  function getSelectableEntities() {
    let output = [
      ...allUsers.flatMap((user) => {
        if (
          user.role !== UserRole.ASSIGNEE &&
          user.id !== $currentUser.id &&
          !user.is_deleted
        ) {
          return {
            value: `user:${user.id}`,
            label: user.first_name + " " + user.last_name,
            group: "Users",
          };
        }

        return [];
      }),
      ...allTeams.map((team) => ({
        value: `team:${team.id}`,
        label: team.name,
        group: "Teams",
      })),
    ];

    return output;
  }

  async function getOrganizationTeams() {
    try {
      const response = await fetchGet("/team");

      if (!response.success) {
        throw new Error(response?.message);
      }

      allTeams = response?.teams;
    } catch (error) {
      toast.error(error);
    }
  }

  const addChecklist = async () => {
    loading = true;

    if (
      ruleSets.some(
        (rs) =>
          rs.name === checklistName && rs.description === checklistDescription,
      )
    ) {
      toast.error(
        "Could not create a new checklist, one already exists with the same name and description.",
      );

      duplicateDetected = true;
      return;
    }

    let res = await fetchPost("/ruleset/create", {
      name: checklistName,
      description: checklistDescription,
      sharing: sharing,
      users: Array.from(selectedUsers.values()),
      teams: Array.from(selectedTeams.values()),
    });

    if (!res.success) {
      toast.error("Something went wrong while adding the checklist.");
      return;
    }

    loading = false;
    toast.success("Checklist has been added successfully.");

    checklistName = "";
    checklistDescription = "";
    newChecklistModal?.close();

    // just to trigger a reload of <ruleTable and fetch the new checklist
    activeRuleTypeTab =
      activeRuleTypeTab === "Live Rules" ? "Asset Review Rules" : "Live Rules";

    await tick();

    activeRuleTypeTab =
      activeRuleTypeTab === "Live Rules" ? "Asset Review Rules" : "Live Rules";

    dispatch("updateRuleSets");
    closeDialog();
  };

  function handleDuplicateDetected() {
    if (duplicateDetected) {
      duplicateDetected = !duplicateDetected;
    }
  }

  function handleMaxLength() {
    if (checklistName.length > maximumNameLength) {
      exceedsMaxLength = true;
    } else {
      exceedsMaxLength = false;
    }
  }

  onMount(async () => {
    await getOrganizationTeams();
    selectableEntities = getSelectableEntities();
  });

  $: handleDuplicateDetected(), [checklistName, checklistDescription];
  $: handleMaxLength(), [checklistName];
</script>

<Modal modalId="newChecklistModal" on:close={closeDialog}>
  <h3 class="text-center font-bold">Create New Checklist</h3>

  <div>
    <label class="label" for="checklist_name">
      <span class="label-text">Checklist name</span>
    </label>
    <input
      type="text"
      bind:value={checklistName}
      placeholder="Checklist Name"
      class="input input-bordered w-full {duplicateDetected ||
      checklistName.length < 1 ||
      exceedsMaxLength
        ? 'input-error'
        : ''}"
      id="checklist_description"
      maxlength={maximumNameLength}
      required
      on:change={() => {
        if (
          ruleSets.some(
            (rs) =>
              rs.name === checklistName &&
              rs.description === checklistDescription,
          )
        ) {
          duplicateDetected = true;
        } else {
          duplicateDetected = false;
        }

        if (checklistName.length > 64) {
          exceedsMaxLength = true;
        } else {
          exceedsMaxLength = false;
        }
      }}
    />
  </div>
  <div>
    <label class="label" for="checklist_description">
      <span class="label-text">Checklist description</span>
    </label>
    <input
      type="text"
      bind:value={checklistDescription}
      placeholder="Checklist Description"
      class="input input-bordered w-full {duplicateDetected
        ? 'input-error'
        : ''}"
      id="checklist_description"
      on:change={() => {
        if (
          ruleSets.some(
            (rs) =>
              rs.name === checklistName &&
              rs.description === checklistDescription,
          )
        ) {
          duplicateDetected = true;
        } else {
          duplicateDetected = false;
        }
      }}
    />
  </div>
  <div class="flex w-full flex-col">
    <div class="flex w-full items-center gap-2 align-middle">
      <label
        class="label {sharing ? 'font-semibold' : ''}"
        for="toggle_sharing"
      >
        <span>Share checklist</span>
      </label>
      <input
        type="checkbox"
        class="checkbox checkbox-sm border-gray-300 [--chkbg:#c4d4bd] [--chkfg:#0064bd] checked:border-[#0064bd]"
        id="toggle_sharing"
        bind:value={sharing}
        bind:checked={sharing}
      />
    </div>
    <div
      class="bg-base-200 collapse
            {sharing ? 'collapse-open' : 'collapse-close'}"
    >
      <input
        type="checkbox"
        class="peer"
        name="toggle_show_share"
        bind:checked={sharing}
      />
      <div class="collapse-title">Add users or teams</div>
      <div class="collapse-content">
        <Select
          items={selectableEntities}
          bind:selectedValue={selectedEntity}
          placeholder="Add Users or Teams"
          classes="w-full"
          size="md"
          on:change={(e) => {
            selectedPermission = SharedRulePermissions.VIEW;
            selectedEntity = e.detail;
            const id = selectedEntity.split(":")[1];

            if (selectedEntity.startsWith("team")) {
              selectedTeams.set(id, {
                id: id,
                teamName: allTeams.find((team) => team.id == id)?.name,
                permission: selectedPermission,
              });
              selectedTeams = selectedTeams;
            } else if (selectedEntity.startsWith("user")) {
              const userDetails = allUsers.find((user) => user.id == id);
              selectedUsers.set(id, {
                id: id,
                userName: `${!isEmpty(userDetails?.first_name) ? userDetails.first_name : ""}${!isEmpty(userDetails?.last_name) ? " " + userDetails.last_name : ""}`,
                permission: selectedPermission,
              });
              selectedUsers = selectedUsers;
            }

            selectedPermission = null;
            selectedEntity = null;
          }}
        />

        <table class="mt-2 table">
          <thead>
            <tr>
              <td class="font-bold">People/Teams</td>
              <td colspan="2">
                <div
                  class="tooltip tooltip-bottom"
                  data-tip="Permission to view or edit this checklist and its rules."
                >
                  Permissions
                </div>
              </td>
            </tr>
          </thead>
          {#each [...selectedUsers] as [userId, user]}
            <tr id="user-{userId}">
              <td class="flex items-center gap-1">
                <Icon icon="iconoir:user" />
                {user.userName}
              </td>
              <td>
                <Select
                  items={permissions}
                  selectedValue={user.permission}
                  on:change={(e) => {
                    const userRecord = selectedUsers.get(userId);
                    userRecord.permission = e.detail;
                    selectedUsers.set(userId, userRecord);
                    selectedUsers = selectedUsers;
                  }}
                />
              </td>
              <td>
                <button
                  class="btn btn-square btn-ghost btn-sm hover:btn-error"
                  on:click={() => {
                    selectedUsers.delete(userId);
                    selectedUsers = selectedUsers;
                  }}
                >
                  <Icon icon="iconoir:cancel" />
                </button>
              </td>
            </tr>
          {/each}
          {#each [...selectedTeams] as [teamId, team]}
            <tr>
              <td class="flex items-center gap-1">
                <Icon icon="iconoir:community" />
                {team.teamName}
              </td>
              <td>
                <Select
                  items={permissions}
                  selectedValue={team.permission}
                  on:change={(e) => {
                    const teamRecord = selectedTeams.get(teamId);
                    teamRecord.permission = e.detail;
                    selectedTeams.set(teamId, teamRecord);
                    selectedTeams = selectedTeams;
                  }}
                />
              </td>
              <td>
                <button
                  class="btn btn-square btn-ghost btn-sm hover:btn-error"
                  on:click={() => {
                    selectedTeams.delete(teamId);
                    selectedTeams = selectedTeams;
                  }}
                >
                  <Icon icon="iconoir:cancel" />
                </button>
              </td>
            </tr>
          {/each}
        </table>
      </div>
    </div>
  </div>

  <!-- svelte-ignore missing-declaration -->
  <div
    class="mt-4 w-full"
    class:tooltip={checklistName.length < 1 ||
      duplicateDetected ||
      (sharing && selectedUsers.size < 1 && selectedTeams.size < 1) ||
      exceedsMaxLength}
    data-tip={checklistName.length < 1
      ? "The checklist cannot have an empty name."
      : duplicateDetected
        ? "A checklist already exists with the same name and description."
        : sharing && selectedUsers.size < 1 && selectedTeams.size < 1
          ? "When sharing a checklist, at least one user or team must be selected to share the checklist with."
          : exceedsMaxLength
            ? "The checklist name exceeds the maximum length."
            : ""}
  >
    <button
      on:click={addChecklist}
      class="btn btn-primary w-full"
      disabled={checklistName.length < 1 ||
        duplicateDetected ||
        (sharing && selectedUsers.size < 1 && selectedTeams.size < 1) ||
        exceedsMaxLength ||
        loading}
    >
      {#if loading}
        <LogoLoader size="1.25rem" />
      {/if}
      Add{sharing ? " & Share " : " "}Checklist
    </button>
  </div>
</Modal>
