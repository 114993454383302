<script>
  import { onMount } from "svelte";
  import { Toaster } from "svelte-sonner";
  import Router from "svelte-spa-router";
  import LogoLoader from "./components/LogoLoader.svelte";
  import NavBar from "./components/NavBar/NavBar.svelte";
  import { fetchGet } from "./helpers.js";
  import ForgotPassword from "./pages/ForgotPassword.svelte";
  import Login from "./pages/Login.svelte";
  import ResetPassword from "./pages/ResetPassword.svelte";
  import { allocatePermissions, baseRoutes } from "./routes";

  import {
    actions,
    currentUser,
    dueDays,
    email_list,
    enableApprovedStage,
    enableUploadedStage,
    fullname,
    isAuthenticated,
    maximised,
    org_emails,
    org_id,
    org_name,
    org_teams,
    org_users,
    role,
    user_id,
    user_list,
    user_teams,
    disabledOrganisationModules,
  } from "./stores";
  import ScrollToTop from "./ui_components/ScrollToTop.svelte";

  let showPasswordReset = false;
  let loadingText = "Loading...";

  async function checkLogin(isAdminDashboard = false) {
    let response;

    if (!isAdminDashboard) {
      if (!localStorage.getItem("accessToken")) {
        return false;
      }

      response = await fetchGet("/auth/validate-token");

      if (!response.logged_in) {
        return false;
      }
    } else {
      response = await fetchGet("/auth/checkLogin");

      if (!response.logged_in) {
        return false;
      }
    }

    fullname.set(response.user.first_name + " " + response.user.last_name);
    user_id.set(response.user.id);
    role.set(response.user.role);
    currentUser.set(response.user);

    return true;
  }

  async function orgDetails() {
    const res = await fetchGet("/organisation");

    org_name.set(res.name);
    org_users.set(res.users);
    org_teams.set(res.teams);
    org_id.set(res.id);
    user_teams.set(res.user_teams);

    //disabled permissions
    disabledPermissions = res.disabled_modules.map(
      (permission) => permission.name,
    );
    disabledOrganisationModules.set(disabledPermissions);

    let u_values = [];
    for (let i = 0; i < res.users.length; i++) {
      u_values = [
        ...u_values,
        {
          value: res.users[i].id,
          label: `${res.users[i].first_name} ${
            res.users[i].last_name ?? ""
          }`.trim(),
        },
      ];
    }
    user_list.set(u_values);
    dueDays.set(res.due_days ? res.due_days : 14);
    enableApprovedStage.set(res.enable_approved_stage);
    enableUploadedStage.set(res.enable_uploaded_stage);

    org_emails.set(res.emails);
    let e_list = [];
    for (let i = 0; i < res.emails.length; i++) {
      e_list = [
        ...e_list,
        {
          value: res.emails[i].id,
          label: res.emails[i].title,
        },
      ];
    }
    email_list.set(e_list);
  }

  async function actionDetails() {
    const response = await fetchGet("/action");
    actions.set(response.actions);
  }

  let routes = baseRoutes;
  let disabledPermissions = [];
  onMount(async () => {
    loadingText = "Checking login... (1/3)";
    // if ($date_format !== ("date" || "ago")){
    //   date_format.set("date")
    // }
    isAuthenticated.set(
      await checkLogin(
        import.meta.env.VITE_APP_MODE === "ADMIN" ? true : false,
      ),
    );

    if (
      window.location.href.includes("reset=1") &&
      window.location.href.includes("token=")
    ) {
      if ($isAuthenticated) {
        await fetchGet("/auth/logout");
        window.location.href = window.location.href;
      }

      loadingText = "";
      return;
    }

    screen.orientation.lock("landscape").catch((error) => {});

    if ($isAuthenticated) {
      loadingText = "Loading organisation details... (2/3)";
      await orgDetails();
      loadingText = "Loading action details... (3/3)";
      await actionDetails();
    }

    let grantedRoutes = await allocatePermissions(
      $role,
      disabledPermissions,
      $org_name,
    );
    routes = grantedRoutes;
    loadingText = "";

    // md breakdown is 768px
    window.addEventListener("resize", () => {
      if (window.innerWidth < 768) $maximised = false;
      else $maximised = true;
    });
  });
</script>

<Toaster />

{#if loadingText !== ""}
  <div
    class="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center gap-4"
  >
    <LogoLoader size="200px" />
    {loadingText}
  </div>
{:else if !$isAuthenticated}
  <!-- I'm terribly sorry for this nested if statement, but it works at least -->
  {#if window.location.href.includes("reset=1") && window.location.href.includes("token=")}
    <ResetPassword />
  {:else if showPasswordReset}
    <ForgotPassword bind:showPasswordReset />
  {:else}
    <Login bind:showPasswordReset />
  {/if}
{:else if $role}
  <NavBar role={$role} {disabledPermissions} />
  <main
    class="mt-16 min-h-[calc(100vh-64px)] px-12 py-4 pb-16"
    style={$maximised
      ? "width: calc(100% - 220px); margin-left: 220px;"
      : "width: calc(100% - 50px); margin-left: 50px;"}
  >
    <Router restoreScrollState={true} bind:routes />
    <ScrollToTop />
  </main>
{:else}
  <NavBar role={$role} {disabledPermissions} />
  <main
    class="mt-16 min-h-[calc(100vh-64px)] px-12 py-4 pb-16"
    style={$maximised
      ? "width: calc(100% - 220px); margin-left: 220px;"
      : "width: calc(100% - 50px); margin-left: 50px;"}
  >
    <Router restoreScrollState={true} routes={baseRoutes} />
    <ScrollToTop />
  </main>
{/if}
