<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher } from "svelte";
  import { toast } from "svelte-sonner";
  import { link } from "svelte-spa-router";
  import { fetchGet } from "../../helpers";
  import RuleInfoModal from "../Modals/RuleInfoModal.svelte";
  import ReportExport from "../Reports/ReportExport/ReportExport.svelte";

  const dispatch = createEventDispatcher();

  export let originalData;
  export let report_id;
  export let step = 2;
  export let exportRes = true;
  export let currentTab = "Open";
  export let tableRootPage = "";
  export let showGroupedTable = false;
  export let allRules = false;
  export let unique_data_types = [];
  export let unique_indexes = [];
  export let unique_vendors = [];
  export let canEditPermissions;
  export let selectedHits;
  export let selectedGroups = new Set();
  export let itemsPerPage = 10;
  export let selectedRulesId;
  export let selectedDataTypes;
  export let selectedVendors;
  export let selectedSocialTypes;
  export let loading = { state: false };
  export let hitSearchText = "";
  export let sourceSearchText = "";
  export let hitSearchSettings = {
    isCaseSensitive: { enabled: false, label: "Case sensitive" },
  };
  export let sourceSearchSettings = {
    isCaseSensitive: { enabled: false, label: "Case sensitive" },
  };
  export let disableEditing = false;
  export let reportFilters = {
    in_report: "Open",
    sort: "hit_id",
    search: "",
    limit: itemsPerPage,
    currentPage: 1,
    selectedDataTypes: selectedDataTypes,
    selectedVendors: selectedVendors,
    selectedRulesId: selectedRulesId,
    selectedSocialTypes: selectedSocialTypes,
    initial: true,
    columnToSortBy: "",
    showGroupedTable: showGroupedTable,
  };
  export let isPreview = false;
  export let rulesHitsStats = new Map();

  let lastSelectedRulesId = selectedRulesId;
  let lastSelectedDataTypes = selectedDataTypes;
  let lastSelectedVendors = selectedVendors;
  let lastselectedSocialTypes = selectedSocialTypes;

  $: if (
    lastSelectedRulesId !== selectedRulesId ||
    lastSelectedDataTypes !== selectedDataTypes ||
    lastSelectedVendors !== selectedVendors ||
    lastselectedSocialTypes !== selectedSocialTypes
  ) {
    dispatch("selectChanged");

    lastSelectedRulesId = selectedRulesId;
    lastSelectedDataTypes = selectedDataTypes;
    lastSelectedVendors = selectedVendors;
    lastselectedSocialTypes = selectedSocialTypes;
  }

  allRules = allRules
    ? allRules
    : [
        ...new Map(
          originalData.map((e) => e.rule).map((i) => [i.id, i]),
        ).values(),
      ];

  let saved_rules = [];

  function convertUTCDateToCurrentTimezone(dateString) {
    const date = new Date(dateString);
    const offset = date.getTimezoneOffset();
    const newDate = new Date(date.getTime() - offset * 60 * 1000);
    return newDate;
  }

  async function getHitsForRule() {
    if (!isPreview && allRules && allRules.length > 0) {
      const queryParams = {
        in_report: currentTab,
        initial: reportFilters?.initial,
        selectedDataTypes: selectedDataTypes,
        selectedVendors: selectedVendors,
        selectedRulesId: selectedRulesId,
        selectedSocialTypes: selectedSocialTypes,
        ruleIds: allRules.map((r) => r.id).join(","),
        hitSearchText: hitSearchText,
        isHitSearchCaseSensitive: hitSearchSettings.isCaseSensitive.enabled,
        sourceSearchText: sourceSearchText,
        isSourceSearchCaseSensitive:
          sourceSearchSettings.isCaseSensitive.enabled,
      };

      const queryString = new URLSearchParams(queryParams).toString();
      const res = await fetchGet(
        `/report/${report_id}/stats/hits?${queryString}`,
      );

      if (!res.success) {
        toast.error(
          "There was an error refreshing the number of hits per rule.",
        );
        return;
      }

      rulesHitsStats = new Map();
      res.counts.map((c) => {
        rulesHitsStats.set(c.rule_id, c.hits);
      });
      rulesHitsStats = rulesHitsStats;
    }
  }

  async function watchVariablesFilterBar() {
    if (!reportFilters && !loading.state) {
      if (
        reportFilters.selectedDataTypes != selectedDataTypes ||
        reportFilters.selectedVendors != selectedVendors ||
        reportFilters.selectedRulesId != selectedRulesId ||
        reportFilters.selectedSocialTypes != selectedSocialTypes
      ) {
        await getHitsForRule();
      }
    }
  }

  async function watchVariables() {
    if (!reportFilters.initial && !loading.state) {
      await getHitsForRule();
    }
  }

  $: watchVariables(), [currentTab];

  $: watchVariablesFilterBar(), [selectedDataTypes];
  $: watchVariablesFilterBar(), [selectedVendors];
  $: watchVariablesFilterBar(), [selectedRulesId];
  $: watchVariablesFilterBar(), [selectedSocialTypes];
</script>

<aside
  class="flex max-h-full w-52 shrink-0 flex-col overflow-y-auto rounded border"
  style:max-height={tableRootPage.includes("reports")
    ? "min(calc(100vh - 270px), 874px)"
    : !exportRes
      ? "min(calc(100vh - 272px), 833px)"
      : "auto"}
>
  <h2 class="flex items-center justify-between gap-2 p-2 font-semibold">
    Filters

    {#if tableRootPage.includes("reports")}
      <button
        class="btn btn-primary btn-xs"
        on:click={() => {
          selectedHits = new Set();
          selectedGroups = new Set();
          showGroupedTable = !showGroupedTable;
        }}
      >
        {showGroupedTable ? "Ungroup" : "Group"} Hits
      </button>
    {/if}
  </h2>

  <div class="divider my-0 h-0" />

  {#if tableRootPage !== "search" && tableRootPage !== "spelling"}
    <div class="collapse-arrow collapse shrink-0">
      <input type="checkbox" checked class="min-h-[unset]" />
      <div class="collapse-title min-h-[unset] pl-2 font-medium">
        <div class="flex items-center gap-1">
          <input
            type="checkbox"
            class="checkbox-primary checkbox checkbox-sm relative z-[1]"
            checked={selectedRulesId?.length === allRules?.length &&
              allRules.length > 0}
            indeterminate={selectedRulesId?.length > 0 &&
              selectedRulesId?.length !== allRules?.length}
            on:change={(e) => {
              if (e.target.checked) selectedRulesId = allRules.map((r) => r.id);
              else selectedRulesId = [];

              if (isPreview) dispatch("selectChanged");
            }}
          />

          Rules
          <!-- export res is true when we are not in Stream Review -->
          {#if !window.location.href.includes("issues")}
            {#if exportRes}
              <div
                class={disableEditing || !canEditPermissions
                  ? "tooltip fixed z-[1] ml-28"
                  : " z-[1] ml-auto"}
                data-tip={disableEditing && canEditPermissions
                  ? "This report has been archived and cannot be modified"
                  : "You do not have permission to modify this report"}
              >
                <a
                  href="/stream/edit/{report_id}?step=1"
                  class="btn btn-primary btn-xs relative z-[1] ml-auto"
                  class:btn-disabled={disableEditing}
                  use:link
                >
                  Edit
                </a>
              </div>
            {:else}
              <button
                class="btn btn-primary btn-xs relative z-[1] ml-auto"
                on:click={() => (step = 1)}
              >
                Edit
              </button>
            {/if}
          {/if}
        </div>
      </div>
      <div class="collapse-content !py-0 !pl-2">
        {#each allRules as rule}
          <div class="flex items-center">
            <label class="label cursor-pointer justify-start gap-2">
              <input
                type="checkbox"
                class="checkbox-primary checkbox checkbox-sm"
                checked={selectedRulesId.includes(rule.id)}
                disabled={selectedRulesId.includes(rule.id) &&
                  selectedRulesId.length === 1}
                on:click={(e) => {
                  if (e.target.checked)
                    selectedRulesId = [...selectedRulesId, rule.id];
                  else {
                    selectedRulesId = selectedRulesId.filter(
                      (e) => e !== rule.id,
                    );
                  }

                  if (isPreview) dispatch("selectChanged");
                }}
                lineWrapping={true}
              />
              <!-- based on sidebar size, adjust if changed -->
              {#key currentTab || selectedRulesId}
                <span
                  class="label-text block w-[122px] truncate !text-sm hover:whitespace-normal hover:[text-overflow:revert]"
                >
                  {#if rulesHitsStats.get(rule.id)}
                    ({rulesHitsStats.get(rule.id)})
                  {/if}
                  {rule.name}
                </span>
              {/key}
            </label>

            <button
              class="btn btn-circle btn-ghost btn-xs"
              on:click={() =>
                document
                  .getElementById("rule_info_modal_" + rule.id)
                  .showModal()}
            >
              <Icon icon="material-symbols:info-outline" />
              <!--Note: using material symbols here not iconify, as the iconify info-outline is not rendering properly-->
            </button>
          </div>

          <RuleInfoModal {rule} bind:saved_rules />
        {/each}
      </div>
    </div>
  {/if}

  <div class="collapse-arrow collapse shrink-0">
    <input type="checkbox" checked class="min-h-[unset]" />
    <div class="collapse-title min-h-[unset] pl-2 font-medium">
      <div class="flex items-center gap-1">
        <input
          type="checkbox"
          class="checkbox-primary checkbox checkbox-sm relative z-[1]"
          checked={selectedDataTypes?.length === unique_data_types?.length &&
            unique_data_types?.length > 0}
          indeterminate={selectedDataTypes?.length > 0 &&
            selectedDataTypes?.length !== unique_data_types?.length}
          on:change={(e) => {
            if (e.target.checked) selectedDataTypes = [...unique_data_types];
            else selectedDataTypes = [];

            if (isPreview) dispatch("selectChanged");
          }}
        />
        Asset Type
      </div>
    </div>
    <div class="collapse-content !py-0 !pl-2">
      {#each unique_data_types as d}
        <label class="label cursor-pointer justify-start gap-2">
          <input
            type="checkbox"
            class="checkbox-primary checkbox checkbox-sm"
            checked={selectedDataTypes?.includes(d)}
            disabled={selectedDataTypes?.includes(d) &&
              selectedDataTypes?.length === 1}
            on:change={(e) => {
              if (e.target.checked)
                selectedDataTypes = [...selectedDataTypes, d];
              else
                selectedDataTypes = [
                  ...selectedDataTypes.filter((e) => e !== d),
                ];

              if (isPreview) dispatch("selectChanged");
            }}
          />
          <span class="label-text !text-sm capitalize">{d?.toLowerCase()}</span>
        </label>
      {/each}
    </div>
  </div>

  <div class="collapse-arrow collapse shrink-0">
    <input type="checkbox" checked class="min-h-[unset]" />
    <div class="collapse-title min-h-[unset] pl-2 font-medium">
      <div class="flex items-center gap-1">
        <input
          type="checkbox"
          class="checkbox-primary checkbox checkbox-sm relative z-[1]"
          checked={selectedVendors?.length === unique_vendors?.length &&
            unique_vendors?.length > 0}
          indeterminate={selectedVendors?.length > 0 &&
            selectedVendors?.length !== unique_vendors?.length}
          on:change={(e) => {
            if (e.target.checked) selectedVendors = [...unique_vendors];
            else selectedVendors = [];

            if (isPreview) dispatch("selectChanged");
          }}
        />
        Company
      </div>
    </div>
    <div class="collapse-content !py-0 !pl-2">
      {#each unique_vendors as v}
        <label class="label cursor-pointer justify-start gap-2">
          <input
            type="checkbox"
            class="checkbox-primary checkbox checkbox-sm"
            checked={selectedVendors?.includes(v)}
            disabled={selectedVendors?.includes(v) &&
              selectedVendors?.length === 1}
            on:click={(e) => {
              if (e.target.checked) selectedVendors = [...selectedVendors, v];
              else selectedVendors = selectedVendors.filter((e) => e !== v);
              if (isPreview) dispatch("selectChanged");
            }}
          />
          <div class="tooltip max-w-[146px]" data-tip={v}>
            <span
              class="label-text block overflow-hidden text-ellipsis whitespace-nowrap !text-sm"
              >{v}</span
            >
          </div>
        </label>
      {/each}
    </div>
  </div>

  <div class="collapse-arrow collapse shrink-0">
    <input type="checkbox" checked class="min-h-[unset]" />
    <div class="collapse-title min-h-[unset] pl-2 font-medium">
      <div class="flex items-center gap-1">
        <input
          type="checkbox"
          class="checkbox-primary checkbox checkbox-sm relative z-[1]"
          checked={selectedSocialTypes?.length === unique_indexes?.length &&
            unique_indexes?.length > 0}
          indeterminate={selectedSocialTypes?.length > 0 &&
            selectedSocialTypes?.length !== unique_indexes?.length}
          on:change={(e) => {
            if (e.target.checked) selectedSocialTypes = [...unique_indexes];
            else selectedSocialTypes = [];

            if (isPreview) dispatch("selectChanged");
          }}
        />
        Channel Type
      </div>
    </div>
    <div class="collapse-content !py-0 !pl-2">
      {#each unique_indexes as s}
        <label class="label cursor-pointer justify-start gap-2">
          <input
            type="checkbox"
            class="checkbox-primary checkbox checkbox-sm"
            checked={selectedSocialTypes?.includes(s)}
            disabled={selectedSocialTypes?.includes(s) &&
              selectedSocialTypes?.length === 1}
            on:click={(e) => {
              if (e.target.checked)
                selectedSocialTypes = [...selectedSocialTypes, s];
              else
                selectedSocialTypes = [
                  ...selectedSocialTypes.filter((e) => e !== s),
                ];

              if (isPreview) dispatch("selectChanged");
            }}
          />
          <span class="label-text !text-sm capitalize"
            >{s.split("_")[0]} {s.split("_")[[s.split("_").length - 2]]}</span
          >
        </label>
      {/each}
    </div>
  </div>

  {#if exportRes}
    <ReportExport reportId={report_id} bind:reports={originalData} bind:allRules={allRules} />
  {/if}
</aside>

<style>
  .checkbox,
  .btn {
    animation: none !important;
  }
</style>
