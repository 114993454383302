<script>
  import { createEventDispatcher } from "svelte";
  import { link } from "svelte-spa-router";

  const dispatch = createEventDispatcher();

  export let report_id;
  export let isNewWorkflow = true;
  export let uploadedFiles = [];
  export let ran_reports = [];

  $: tooltip = isNewWorkflow
    ? "Select a workflow first"
    : uploadedFiles.length === 0
      ? "Upload assets first"
      : ran_reports.length === 0
        ? "You need at least one rule to proceed."
        : "";
</script>

<!-- if new workflow is false  -->

{#if tooltip === ""}
  <a
    href="/review/{report_id}"
    class="btn btn-primary btn-sm"
    use:link
    on:click={() => dispatch("click")}
  >
    Go to Folder
  </a>
{:else}
  <div class="tooltip" data-tip={tooltip}>
    <button class="btn btn-disabled btn-primary btn-sm">Go to Folder</button>
  </div>
{/if}
