<script>
  import MultiSelect from "../MultiSelect.svelte";

  export let vendors = [];
  export let query;
</script>

<MultiSelect
  items={vendors}
  bind:selectedValues={query.options}
  placeholder="Please Select"
/>
