<script lang="ts">
  import Icon from "@iconify/svelte";
  import { onMount } from "svelte";
  import { toast } from "svelte-sonner";
  import NewWorkflow from "../components/Workflow/NewWorkflow.svelte";
  import WorkflowVisualizer from "../components/Workflow/WorkflowVisualizer.svelte";
  import { fetchDelete, fetchGet, fetchPost } from "../helpers";
  import { isDefaultWorkflow } from "../lib/utils/WorkflowUtils";
  import { org_id } from "../stores";
  import Modal from "../components/Modals/Modal.svelte";

  let loaded = false;
  let workflows = [];
  let report_id = $org_id;
  let new_workflow = {
    id: 1,
    name: "Default Approval Workflow",
    description:
      "This is the default approval workflow for this organisation, use this workflow unless directed otherwise",
    workflow_steps: [
      {
        id: 1,
        name: "First line Review",
        description:
          "Agencies and internal marketing will start the process here",
        position: 1,
        teams: [],
        users: [],
      },
      {
        id: 2,
        name: "Second line review",
        description: "Compliance step of the process.",
        position: 2,
        teams: [],
        users: [],
      },
    ],
  };

  let bottomButtons = {
    show: true,
    primaryDisabled: false,
    primaryDisabledTooltip: "",
    primaryText: "Create Workflow",
    secondaryText: "Cancel",
    loading: false,
    primaryAction: async () => {
      await addWorkflow();
    },
    secondaryAction: () => {
      newWorkflowModal.close();
    },
  };

  /**
   * Stop-gap function, there appears to be a mismatch on the client end somewhere
   * within the Workflow components leading to different object types for users and
   * teams being sent to the API.
   *
   * TODO: We should investigate and make all workflow parsing result in the same
   * object structure.
   * @param workflow
   */
  async function normaliseWorkflowSteps(workflow) {
    workflow.workflow_steps.forEach((step) => {
      step.teams = step.teams.map((team) => {
        if (team.hasOwnProperty("team_id")) {
          return {
            id: team.team_id,
            permission: team.permission,
          };
        }
        return team;
      });
      step.users = step.users.map((user) => {
        if (user.hasOwnProperty("user_id")) {
          return {
            id: user.user_id,
            permission: user.permission,
          };
        }
        return user;
      });
    });

    return workflow;
  }

  async function addWorkflow() {
    bottomButtons.loading = true;
    try {
      const res = await fetchPost(`/workflow/${report_id}`, {
        workflow: await normaliseWorkflowSteps(new_workflow),
      });

      if (res.success) {
        workflows = [...workflows, res.workflow];
        resetNewWorkflow();
        toast.success("Workflow Added!");
        newWorkflowModal.close();
      } else {
        throw new Error("Error adding workflow");
      }
    } catch (error) {
      console.log(error);
    }
    bottomButtons.loading = false;
  }

  async function deleteWorkflow(workflowId) {
    toast.loading("Deleting Workflow...");

    let res = await fetchDelete(`/workflow/${workflowId}`, {
      workflow_id: workflowId,
    });

    if (res.success) {
      workflows = workflows.filter((w) => w.id !== workflowId);
      toast.success("Workflow Deleted!");
    } else toast.error("Error deleting workflow");
  }

  function duplicateWorkflow(workflow) {
    new_workflow = {
      ...workflow,
      id: Date.now(), // Temporary ID
      name: `${workflow.name} (Copy)`,
      workflow_steps: workflow.workflow_steps.map((step) => ({
        ...step,
        id: Date.now() + step.id,
      })), // Temporary IDs for steps
    };
    newWorkflowModal.showModal();
  }

  function resetNewWorkflow() {
    new_workflow = {
      id: 1,
      name: "Default Approval Workflow",
      description:
        "This is the default approval workflow for this organisation, use this workflow unless directed otherwise.",
      workflow_steps: [
        {
          id: 1,
          name: "First line Review",
          description: "First line review will start the process here.",
          position: 1,
          teams: [],
          users: [],
        },
        {
          id: 2,
          name: "Second line review",
          description: "Second line will conduct their review.",
          position: 2,
          teams: [],
          users: [],
        },
      ],
    };
  }

  onMount(async () => {
    const errorMessage = "Something went wrong retrieving workflows.";

    try {
      const response = await fetchGet("/workflow");

      if (!response.success) {
        throw new Error(response.message ?? errorMessage);
      }

      workflows = response.data;
      workflows = workflows;
    } catch (e) {
      console.error(e);
      toast.error(errorMessage);
    }

    loaded = true;
  });

  // Reactive statement to check if name and description are filled
  $: {
    if (!new_workflow.name.trim() || !new_workflow.description.trim()) {
      bottomButtons.primaryDisabled = true;
      bottomButtons.primaryDisabledTooltip = "Fill up all the fields";
    } else {
      bottomButtons.primaryDisabled = false;
      bottomButtons.primaryDisabledTooltip = "";
    }
  }
</script>

<svelte:head>
  <title>Organisation Workflows - Haast</title>
</svelte:head>

<div class="mx-auto flex max-w-screen-xl flex-col gap-6">
  <div class="my-4 flex items-center justify-between gap-4">
    <h1 class="text-3xl font-semibold">Organisation Workflows</h1>
    <!-- svelte-ignore missing-declaration -->
    <button
      class="btn btn-primary btn-sm"
      on:click={() => {
        resetNewWorkflow();
        newWorkflowModal.showModal();
      }}
    >
      Create New Workflow
    </button>
  </div>
  {#if loaded}
    <div class="join join-vertical w-full gap-4">
      {#each workflows as workflow}
        <div class="collapse-arrow bg-base-200 collapse">
          <input type="radio" name="my-accordion-4" checked="checked" />
          <div class="collapse-title">
            <h1 class="text-xl font-semibold">{workflow.name}</h1>
            <p class="mt-2 max-w-prose">
              {workflow.description}
            </p>
          </div>
          <div class="collapse-content overflow-auto">
            <WorkflowVisualizer selectedWorkflow={workflow} {report_id} />

            {#if !isDefaultWorkflow(workflow)}
              <div class="mt-4 flex gap-2">
                <button
                  class="btn btn-sm btn-outline btn-error"
                  on:click={async () => await deleteWorkflow(workflow.id)}
                  >Delete</button
                >
                <button
                  class="btn btn-sm btn-outline btn-primary"
                  on:click={() => duplicateWorkflow(workflow)}>Duplicate</button
                >
              </div>
            {/if}
          </div>
        </div>
      {/each}
    </div>
  {/if}
</div>

<Modal
  {bottomButtons}
  modalId="newWorkflowModal"
  size="xl"
  on:close={() => {
    resetNewWorkflow();
  }}
>
  <div class="space-y-2">
    <div
      class="bg-primary-content m-auto flex aspect-square w-16 items-center justify-center rounded-full"
    >
      <Icon icon="iconoir:network-left" class="text-primary text-3xl" />
    </div>
    <h3 class="text-center font-bold">Create New Workflow</h3>
    <p class="text-center text-sm">
      Create a custom workflow that fits one of your review processes. Assign
      Users or Teams to different workflow steps.
    </p>
  </div>

  <div class="flex flex-col items-center gap-2">
    <NewWorkflow {report_id} bind:selectedWorkflow={new_workflow} />
  </div>
</Modal>
