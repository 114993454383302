<script>
  import Icon from "@iconify/svelte";
  import { onMount } from "svelte";
  import { isEmpty } from "../../lib/utils/GenericUtils";
  import { invalidInputFocus } from "../../lib/Focus";
  import { slide } from "svelte/transition";

  export let rule;
  export let isViewing = false;

  let list = [""];
  let ready = false;
  let abandonedInputs = new Map();

  function handleAbandonedFocus(id, isAbandoned) {
    if (isAbandoned) abandonedInputs.set(id, isAbandoned);
    else abandonedInputs.delete(id);

    abandonedInputs = abandonedInputs;
  }

  onMount(async () => {
    if (rule.examples.length > 0 && typeof rule.examples[0] !== "object") {
      list = rule.examples.split(" AND ").map((e) => e.replaceAll('"', ""));
    }

    ready = true;
  });

  $: if (ready) {
    rule.examples = list
      .filter((e) => !isEmpty(e.trim()))
      .map((e) => `"${e.trim()}"`)
      .join(" AND ");
  }
</script>

{#each list as e, idx}
  <div class="flex items-center gap-2">
    <span class="px-3">{idx + 1}</span>
    <textarea
      placeholder="Example sub-task description"
      class="textarea textarea-bordered {rule?.blockSave && isEmpty(e)
        ? 'border-error'
        : ''} grow"
      rows="1"
      id="manual-task-example-{idx}"
      bind:value={e}
      use:invalidInputFocus={{
        id: `manual-task-example-${idx}`,
        onChange: handleAbandonedFocus,
        validateEmpty: true,
      }}
    ></textarea>
    <button
      class="!hover:btn-error btn btn-ghost border-base-content/20"
      disabled={isViewing}
      on:click={() => {
        list.splice(idx, 1);
        list = list; // Reassign to trigger rerender
      }}
    >
      <Icon icon="iconoir:trash" />
    </button>
  </div>
  {#if abandonedInputs.get(`manual-task-example-${idx}`) || false}
    <p class="text-error ml-11 first-line:text-xs" in:slide out:slide>
      *Cannot have an empty sub-task description.
    </p>
  {/if}
{/each}

<button
  class="btn btn-outline btn-primary btn-sm mr-auto"
  disabled={isViewing}
  on:click={() => (list = [...list, ""])}
>
  <Icon icon="iconoir:plus" />
  Add Sub-Task
</button>
