import { buildExamples, fetchPost } from "../../helpers";
import { HitColour } from "../interfaces/Hit.interface";
import {
  ConditionalRuleType,
  RuleSearchType,
  RuleSearchTypeLabel,
  RuleStatus,
} from "../interfaces/Rule.interface";
import {
  capitalise,
  isArrayOfObj,
  isEmpty,
  isEmptyObj,
  isObj,
} from "./GenericUtils";

export async function saveSharedAssetRuleToMyRules(rule) {
  const examples = buildExamples(rule);

  return await fetchPost("/rule/share/save", {
    rule: {
      examples,
      operator: rule.operator,
      doc_query:
        rule.search_type === RuleSearchType.BOOLEAN
          ? rule.queryBoolean
          : rule.doc_query,
      section_query: rule.section_query,
      index: rule.index,
      name: rule.name,
      description: rule.description,
      remeditation_step: rule.remeditation_step,
      strong_match_threshold: rule.strong_match_threshold,
      no_match_threshold: rule.no_match_threshold,
      affirmative_rule: rule.affirmative_rule,
      search_type: rule.search_type,
      limit: rule.limit,
      type: rule.type,
    },
  });
}

/**
 * @description Helper method to append any _missing_ checklist (RuleSet) details, i.e., the name
 * and ID to the provided `ruleSets` object.
 * @param {*} ruleSets
 * @param {*} rules
 */
export function appendMissingRuleSets(ruleSets, rules) {
  if (rules) {
    const existingRuleSetIds = new Set(ruleSets.map((rs) => rs.id));
    const ruleSetIdsInRules = new Set(rules.flatMap((rule) => rule.rule_sets));
    const missingRuleSetIds = [...ruleSetIdsInRules].filter(
      (id) => !existingRuleSetIds.has(id),
    );

    if (missingRuleSetIds.length <= 0) {
      return ruleSets;
    }

    const missingRuleSets = [];
    missingRuleSetIds.forEach((id) => {
      const rDetails = rules.find((r) => r.rule_sets.some((rs) => rs === id));
      const rsIndex = missingRuleSets.findIndex((rs) => rs && rs.id === id);

      if (rDetails && rsIndex === -1) {
        missingRuleSets.push({
          ...rDetails.rule_set_details.find((rsd) => rsd.id === id),
          rules: rules
            .filter((r) => r.rule_sets.some((rs) => rs === id))
            .map((r) => r.id),
        });
      } else if (rDetails && rsIndex !== -1) {
        missingRuleSets[rsIndex].rules.push(rDetails.id);
      }
    });

    return [...ruleSets, ...missingRuleSets];
  }

  return ruleSets;
}

/**
 * Checks if a rule is a child conditional rule.
 * A child conditional rule is identified by having a non-empty `conditional_id`, a `condition_type` of `THEN`, and a `sequence_order` not equal to 0.
 *
 * @param {Object} rule - The rule object to check.
 * @returns {boolean} - Returns `true` if the rule is a child conditional rule, otherwise `false`.
 */
export function isChildConditionalRule(rule) {
  if (
    !isEmpty(rule?.conditional_id) &&
    rule?.condition_type === ConditionalRuleType.THEN &&
    rule?.sequence_order !== 0
  ) {
    return true;
  }

  return false;
}

/**
 * Checks if a rule is a parent conditional rule.
 * A parent conditional rule is identified by having a non-empty `conditional_id`, a `condition_type` of `IF`, and a `sequence_order` of 0.
 *
 * @param {Object} rule - The rule object to check.
 * @returns {boolean} - Returns `true` if the rule is a parent conditional rule, otherwise `false`.
 */
export function isParentConditionalRule(rule) {
  if (
    !isEmpty(rule?.conditional_id) &&
    rule?.condition_type === ConditionalRuleType.IF &&
    rule?.sequence_order === 0
  ) {
    return true;
  }

  return false;
}

/**
 * Checks if a rule is a conditional rule.
 * A conditional rule can be either a parent or a child rule, determined by the `isParentConditionalRule` or `isChildConditionalRule` checks.
 *
 * @param {Object} rule - The rule object to check.
 * @returns {boolean} - Returns `true` if the rule is either a parent or child conditional rule, otherwise `false`.
 */
export function isConditionalRule(rule) {
  if (
    !isEmpty(rule?.conditional_id) &&
    (isParentConditionalRule(rule) || isChildConditionalRule(rule))
  ) {
    return true;
  }

  return false;
}

/**
 * Groups conditional rules by their parent-child relationships.
 * Non-conditional rules are returned as-is, while conditional rules are grouped by their `conditional_id`, with child rules attached to their corresponding parent.
 *
 * @param {Array<Object>} rules - An array of rule objects to group.
 * @returns {Array<Object>} - Returns an array of grouped rules, where parent rules contain their child rules in a `children` array.
 */
export function groupConditionalRules(rules) {
  const nonConditionalRules = [];
  const parentConditionalRules = [];
  const childConditionalRules = new Map();

  rules.forEach((rule) => {
    if (rule.conditional_id === null) {
      nonConditionalRules.push({ ...rule, is_conditional: false });
    } else if (rule.sequence_order === 0) {
      parentConditionalRules.push({ ...rule, is_conditional: true });
    } else {
      if (!childConditionalRules.has(rule.conditional_id)) {
        childConditionalRules.set(rule.conditional_id, []);
      }

      childConditionalRules
        .get(rule.conditional_id)
        .push({ ...rule, is_conditional: true });
    }
  });

  const groupedConditionalRules = parentConditionalRules.map((parentRule) => ({
    ...parentRule,
    children: childConditionalRules.get(parentRule.conditional_id) || [],
  }));

  return [...nonConditionalRules, ...groupedConditionalRules];
}

export async function handleConditionalRulePayload(
  rule,
  reportId,
  isAsset = false,
) {
  const flatRules = [rule, ...rule.children];
  let payloadRules = [];

  if (isAsset) {
    payloadRules = flatRules.map((flatRule) => ({
      report_id: reportId,
      examples: flatRule.examples,
      operator: flatRule.operator,
      doc_query:
        flatRule.rule_type === RuleSearchType.BOOLEAN
          ? flatRule.queryBoolean
          : flatRule.doc_query,
      section_query: flatRule.section_query,
      index: flatRule.index ?? flatRule.data_type,
      name: flatRule.name,
      description: flatRule.description,
      remediation_step: flatRule.remediation_step,
      strong_match_threshold: flatRule.strong_match_threshold,
      no_match_threshold: flatRule.no_match_threshold,
      affirmative_rule: flatRule.affirmative_rule,
      search_type: flatRule.rule_type,
      search_sources: Object.entries({
        DOCUMENT: true,
        IMAGE_TEXT: false,
        IMAGE_FEATURE: false,
      })
        .filter(([key, value]) => value)
        .map(([key, _]) => key),
      limit: 5,
      rule_type: flatRule.type,
      conditional_id: null,
      condition_type: flatRule.condition_type,
      sequence_order: flatRule.sequence_order,
    }));
  }

  return payloadRules.map(async (rule) => ({
    ...rule,
    examples: buildExamples(rule),
  }));
}

export async function saveOneRule(rule, reportId, isAsset = false) {
  let payload = {
    report_id: reportId,
    rules: [],
  };

  if (isAsset) {
    if (rule?.is_conditional) {
      payload.rules = await handleConditionalRulePayload(rule, reportId, true);
    } else {
      const examples = buildExamples(rule);
      payload.rules = [
        {
          report_id: reportId,
          examples,
          operator: rule.operator,
          boolean: rule.boolean,
          doc_query:
            rule.search_type === RuleSearchType.BOOLEAN
              ? rule.queryBoolean
              : rule.doc_query,
          section_query: rule.section_query,
          index: rule.index ?? rule.data_type,
          name: rule.name,
          description: rule.description,
          remediation_step: rule.remediation_step,
          strong_match_threshold: rule.strong_match_threshold,
          no_match_threshold: rule.no_match_threshold,
          affirmative_rule: rule.affirmative_rule,
          search_type: rule.search_type,
          search_sources: Object.entries(rule.search_sources)
            .filter(([key, value]) => value)
            .map(([key, _]) => key),
          limit: rule.limit,
          rule_type: rule.type,
          conditional_id: null,
          condition_type: rule.condition_type,
          sequence_order: rule.sequence_order,
          definitions: rule.definitions,
          context: rule.context,
        },
      ];
    }
  }

  return await fetchPost("/rule", payload);
}

export function getRuleColourDropDownOptions(rule) {
  if (isParentConditionalRule(rule)) {
    return [
      { color: "green", hex: "#2b3440", text: RuleStatus.TRUE },
      { color: "amber", hex: "#2b3440", text: RuleStatus.REVIEW },
      { color: "red", hex: "#2b3440", text: RuleStatus.FALSE },
    ];
  }

  return [
    { color: "green", hex: "#336A1D", text: RuleStatus.PASSED },
    { color: "amber", hex: "#FFAD48", text: RuleStatus.REVIEW },
    { color: "red", hex: "#FF4E4E", text: RuleStatus.RISK },
  ];
}

export function ruleColourToStyle(ruleColour, isParentConditional) {
  if (isParentConditional) {
    return "text-neutral";
  } else {
    switch (ruleColour) {
      case HitColour.PASSED:
        return "bg-[#DDFFD0] text-[#336A1D]";
      case HitColour.RISK:
        return "bg-[#FAE9E4] text-[#FF4E4E]";
      case HitColour.REVIEW:
        return "bg-[#FFF3E5] text-[#FFAD48]";
      default:
        return "text-neutral";
    }
  }
}

export function ruleColourToLabel(ruleColour, isParentConditional) {
  if (isParentConditional) {
    switch (ruleColour) {
      case HitColour.PASSED:
        return RuleStatus.TRUE;
      case HitColour.RISK:
        return RuleStatus.FALSE;
      case HitColour.REVIEW:
        return RuleStatus.REVIEW;
      default:
        return RuleStatus.UNKNOWN;
    }
  } else {
    switch (ruleColour) {
      case HitColour.PASSED:
        return RuleStatus.PASSED;
      case HitColour.RISK:
        return RuleStatus.RISK;
      case HitColour.REVIEW:
        return RuleStatus.REVIEW;
      default:
        return RuleStatus.UNKNOWN;
    }
  }
}

export function ruleSearchTypeToLabel(searchType = RuleSearchType.TENSOR) {
  if (isEmpty(searchType)) {
    return RuleStatus.UNKNOWN;
  }

  switch (searchType) {
    case RuleSearchType.TENSOR:
      return RuleSearchTypeLabel.TENSOR;
    case RuleSearchType.LEXICAL:
      return RuleSearchTypeLabel.LEXICAL;
    case RuleSearchType.BOOLEAN:
      return RuleSearchTypeLabel.BOOLEAN;
    case RuleSearchType.MANUAL:
      return RuleSearchTypeLabel.MANUAL;
    case RuleSearchType.CONTEXTUAL:
      return RuleSearchTypeLabel.CONTEXTUAL;
    case RuleSearchType.CONDITIONAL:
      return RuleSearchTypeLabel.CONDITIONAL;
    case RuleSearchType.IMAGE:
      return RuleSearchTypeLabel.IMAGE;
    case RuleSearchType.VISUAL:
      return RuleSearchTypeLabel.VISUAL;
    default:
      return capitalise(searchType);
  }
}

export function ruleStatusToConditionalLabel(ruleStatus = RuleStatus.UNKNOWN) {
  if (isEmpty(ruleStatus)) {
    return RuleStatus.UNKNOWN;
  }

  switch (ruleStatus) {
    case RuleStatus.PASSED:
      return RuleStatus.TRUE;
    case RuleStatus.FAILED:
      return RuleStatus.FALSE;
    default:
      return RuleStatus;
  }
}

export function validExamples(examples, searchType = RuleSearchType.BOOLEAN) {
  if (isEmpty(examples)) return false;

  if (
    typeof examples !== "string" &&
    ![
      RuleSearchType.CONTEXTUAL,
      RuleSearchType.IMAGE,
      RuleSearchType.TENSOR,
    ].includes(searchType)
  )
    return false;

  switch (searchType) {
    case RuleSearchType.TENSOR:
    case RuleSearchType.CONTEXTUAL:
    case RuleSearchType.IMAGE:
      if (!isArrayOfObj(examples)) return false;

      return examples.every(
        (ex) =>
          ex && isObj(ex) && !isEmpty(ex.text) && ex.hasOwnProperty("score"),
      );
    case RuleSearchType.LEXICAL:
      const splitOrStr = examples.split(" OR ");

      if (splitOrStr.length > 0) {
        return splitOrStr.every(
          (s) =>
            !isEmpty(s) &&
            s.trim() !== '""' &&
            s.replaceAll(/\s/g, "") !== '""',
        );
      }

      return false;
    case RuleSearchType.MANUAL:
      const splitAndStr = examples.split(" AND ");

      if (splitAndStr.length > 0) {
        return splitAndStr.every(
          (s) =>
            !isEmpty(s) &&
            s.trim() !== '""' &&
            s.replaceAll(/\s/g, "") !== '""',
        );
      }

      return false;
    case RuleSearchType.BOOLEAN:
      return examples !== '""';
    default:
      return false;
  }
}

export function isValidRule(rule, searchType = RuleSearchType.BOOLEAN) {
  if (isEmptyObj(rule)) return false;
  if (isEmpty(rule?.name)) return false;
  if (isEmpty(rule?.description)) return false;

  switch (searchType) {
    case RuleSearchType.LEXICAL:
      return validExamples(rule.lexical, searchType);
    case RuleSearchType.BOOLEAN:
      return validExamples(rule.boolean, searchType);
    case RuleSearchType.TENSOR:
    case RuleSearchType.CONTEXTUAL:
    case RuleSearchType.IMAGE:
    case RuleSearchType.MANUAL:
      return validExamples(rule.examples, searchType);
    default:
      return false;
  }
}

export function getRuleColourCounts(rules, colour) {
  let count = 0;

  if (!rules) {
    return count;
  }

  count += rules.filter(
    (r) => r.colour === colour && !isParentConditionalRule(r.rule),
  ).length;
  rules.forEach((r) => {
    if (isParentConditionalRule(r.rule) && r.colour === HitColour.PASSED) {
      count += r.rule.children.filter((ch) => ch.colour === colour).length;
    }
  });

  return count;
}
