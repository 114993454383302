<script>
  import Icon from "@iconify/svelte";
  import { onDestroy, onMount, tick } from "svelte";
  import { toast } from "svelte-sonner";
  import { link, push } from "svelte-spa-router";
  import AssetChecklists from "../components/AssetReview/AssetChecklists.svelte";
  import AssetComments from "../components/AssetReview/AssetComments.svelte";
  import ActiveAssetSubHeader from "../components/AssetReview/AssetReviewHeader/ActiveAssetSubHeader.svelte";
  import ApprovalModal from "../components/AssetReview/AssetReviewHeader/HotButtons/Modals/ApprovalModal.svelte";
  import ChangesRequestedModal from "../components/AssetReview/AssetReviewHeader/HotButtons/Modals/ChangesRequestedModal.svelte";
  import AssetManualChecklists from "../components/AssetReview/ManualTasks/AssetManualChecklists.svelte";
  import UploadNewVersion from "../components/AssetReview/UploadNewVersion.svelte";
  import Badge from "../components/AssetReview/WorkflowBoard/Badge.svelte";
  import ComparePdfModal from "../components/ComparePDFModal.svelte";
  import MiddleTruncator from "../components/MiddleTruncator.svelte";
  import DisplayPdf from "../components/PDF/DisplayPDF.svelte";
  import ReportHistory from "../components/ReportHistory.svelte";
  import { fetchGet } from "../helpers";
  import { AssetStatus } from "../lib/interfaces/Asset.interface";
  import { NotificationType } from "../lib/interfaces/Notifier.interface";
  import { RuleSearchType } from "../lib/interfaces/Rule.interface";
  import { NoRuleSetDefaults } from "../lib/interfaces/RuleSet.interface";
  import {
    getActiveVersion,
    getCurrentAssetApprovingPermission,
    getCurrentStepIndex,
    getPreviewUrl,
    retrieveVersions,
  } from "../lib/utils/AssetReviewUtils";
  import { calculatePercentage } from "../lib/utils/GenericUtils";
  import { currentUser, maximised, org_name, user_teams } from "../stores";
  import { ConditionalRuleType } from "./../lib/interfaces/Rule.interface.js";
  import { WebSocketService } from "./../lib/services/WebSocket.service.js";
  import { isParentConditionalRule } from "./../lib/utils/RuleUtils.js";

  export let params = null;

  let report;
  let currentAssetApprovingPermission = false;
  let editActiveAssetName;
  let loaded = false;
  let currentStreamPerm = "view";
  let previewUrl;
  let versions = [];
  let versionIdx = 0;
  let activeVersion = {};
  let reportId;
  let activeAssetId;
  let activeAsset = {};
  let groupedRules = [];
  let groupedManualTasks = [];
  let previewSearchText = "";
  let boundingBox = [
    [0, 0],
    [0, 0],
  ];
  let workflow = {};
  let manualTasks = [];
  let manualTasksLoading = false;
  let rulesLoading = false;
  let activeAssetViewTabLabel = "Checklist";
  let isInitialised = true;
  let versionUpdated = false;
  let assetStatus = {
    status: "",
    assetRuleStatuses: {
      ready: 0,
      processing: 0,
      failed: 0,
      total: 0,
    },
    percentageProgress: 0,
  };
  let assetStatusInterval;
  let showCompareVersions = false;
  // let webSocket;

  async function handleConditionalRuleLoading(activeAsset) {
    if (!activeAsset.rules) {
      return;
    }

    activeAsset.rules.forEach((r, rIdx) => {
      if (r.rule?.is_conditional) {
        r.rule.children.forEach((c, cIdx) => {
          if (c.rule.rule_type === RuleSearchType.MANUAL) {
            activeAsset.rules[rIdx].rule.children[cIdx] = {
              ...activeAsset.rules[rIdx].rule.children[cIdx],
              results: manualTasks.filter((h) => h.rule_id === c.rule.id),
            };
          }
        });
      }
    });
    activeAsset = activeAsset;
  }

  function sortByRuleColour(a, b, inverse = false) {
    if (inverse) {
      if (a.colour === "green" && b.colour !== "green") return -1;
      if (b.colour === "green" && a.colour !== "green") return 1;
      if (a.colour === "green" && b.colour === "green")
        return a.rule.name.localeCompare(b.rule.name);

      if (a.colour === "amber" && b.colour !== "amber") return -1;
      if (b.colour === "amber" && a.colour !== "amber") return 1;
      if (a.colour === "amber" && b.colour === "amber")
        return a.rule.name.localeCompare(b.rule.name);

      if (a.colour === "red" && b.colour !== "red") return -1;
      if (b.colour === "red" && a.colour !== "red") return 1;
      if (a.colour === "red" && b.colour === "red")
        return a.rule.name.localeCompare(b.rule.name);
    } else {
      if (a.colour === "red" && b.colour !== "red") return -1;
      if (b.colour === "red" && a.colour !== "red") return 1;
      if (a.colour === "red" && b.colour === "red")
        return a.rule.name.localeCompare(b.rule.name);

      if (a.colour === "amber" && b.colour !== "amber") return -1;
      if (b.colour === "amber" && a.colour !== "amber") return 1;
      if (a.colour === "amber" && b.colour === "amber")
        return a.rule.name.localeCompare(b.rule.name);

      if (a.colour === "green" && b.colour !== "green") return -1;
      if (b.colour === "green" && a.colour !== "green") return 1;
      if (a.colour === "green" && b.colour === "green")
        return a.rule.name.localeCompare(b.rule.name);
    }
  }

  // function initialiseWebSocket() {
  //   webSocket = new WebSocketService("", {
  //     onMessage: async (message) => {
  //       if (
  //         message?.type === NotificationType.PROGRESS &&
  //         message?.entity_id == activeAssetId
  //       ) {
  //         const { status, status_counts } = message?.record;
  //         assetStatus.status = status;
  //         activeAsset.asset.status = status;
  //         activeAsset = activeAsset;
  //         assetStatus.assetRuleStatuses = {
  //           ready: status_counts?.ready ?? 0,
  //           processing: status_counts?.processing ?? 0,
  //           failed: status_counts?.failed ?? 0,
  //           total: status_counts?.total ?? 0,
  //         };
  //         assetStatus.percentageProgress =
  //           assetStatus.status === AssetStatus.READY
  //             ? 100
  //             : calculatePercentage(status_counts?.ready, status_counts?.total);
  //         assetStatus = assetStatus;

  //         if (assetStatus.status === AssetStatus.READY) {
  //           await handleUpdateAssetFromStatusChange();
  //         }
  //       }
  //     },
  //     onError: (error) => {
  //       console.error("WebSocket error:", error);
  //     },
  //   });
  //   webSocket.connect();
  // }

  const tuplesToString = (arr) => {
    return arr.map(([key, value]) => `${key}, ${value}`).join(", ");
  };

  const groupActiveRules = (activeAsset) => {
    if (!activeAsset.rules) {
      return;
    }

    groupedRules = []; // need to reset grouped rules each time - as otherwise we keep pushing to the array every time we go in and out
    activeAsset.rules
      .filter((r) => r.rule.rule_type !== RuleSearchType.MANUAL)
      .map((r) => {
        if (r.rule.rule_sets && r.rule.rule_sets.length > 0) {
          if (
            !groupedRules.some((group) => group.setId === r.rule.rule_sets[0])
          ) {
            groupedRules.push({
              setId: r.rule.rule_sets[0],
              setName: r.rule.rule_set_details[0].name,
              rules: [r],
            });
          } else {
            groupedRules
              .find((group) => group.setId === r.rule.rule_sets[0])
              .rules.push(r);
          }
        } else {
          if (!groupedRules.some((group) => group.setId === -1)) {
            groupedRules.push({
              setId: -1,
              setName: NoRuleSetDefaults.NAME,
              rules: [r],
            });
          } else {
            groupedRules.find((group) => group.setId === -1).rules.push(r);
          }
        }
      });
    groupedRules = groupedRules.sort((a, b) => {
      if (a.setId === -1) return -1;
      if (b.setId === -1) return 1;
      return a.setName.localeCompare(b.setName);
    });
    groupedRules = groupedRules.map((group) => {
      group.rules = group.rules.sort((a, b) => {
        const aIsParentConditional = isParentConditionalRule(a.rule);
        const bIsParentConditional = isParentConditionalRule(b.rule);

        if (!aIsParentConditional && bIsParentConditional) return -1;
        if (aIsParentConditional && !bIsParentConditional) return 1;

        if (!aIsParentConditional && !bIsParentConditional) {
          return sortByRuleColour(a, b);
        }

        if (aIsParentConditional && bIsParentConditional) {
          return sortByRuleColour(a, b, true);
        }
      });

      return group;
    });
    groupedRules.forEach((group) => {
      group.rules.forEach((rule) => {
        if (isParentConditionalRule(rule.rule)) {
          rule.rule.children = rule.rule.children.sort((a, b) => {
            return sortByRuleColour(a, b);
          });
        }
      });
    });
    groupedRules = [...groupedRules];
  };

  const groupManualTasks = (activeAsset) => {
    if (!activeAsset.rules) {
      return;
    }

    groupedManualTasks = []; // need to reset grouped rules each time - as otherwise we keep pushing to the array every time we go in and out

    activeAsset.rules
      .filter((r) => r.rule.rule_type === RuleSearchType.MANUAL)
      .map((r) => {
        if (r.rule.rule_sets && r.rule.rule_sets.length > 0) {
          if (
            !groupedManualTasks.some(
              (group) => group.setId === r.rule.rule_sets[0],
            )
          ) {
            groupedManualTasks.push({
              setId: r.rule.rule_sets[0],
              setName: r.rule.rule_set_details[0].name,
              rules: [
                {
                  ...r,
                  results: manualTasks.filter((h) => h.rule_id === r.rule.id),
                },
              ],
            });
          } else {
            groupedManualTasks
              .find((group) => group.setId === r.rule.rule_sets[0])
              .rules.push({
                ...r,
                results: manualTasks.filter((h) => h.rule_id === r.rule.id),
              });
          }
        } else {
          if (!groupedManualTasks.some((group) => group.setId === -1)) {
            groupedManualTasks.push({
              setId: -1,
              setName: NoRuleSetDefaults.NAME,
              rules: [
                {
                  ...r,
                  results: manualTasks.filter((h) => h.rule_id === r.rule.id),
                },
              ],
            });
          } else {
            groupedManualTasks
              .find((group) => group.setId === -1)
              .rules.push({
                ...r,
                results: manualTasks.filter((h) => h.rule_id === r.rule.id),
              });
          }
        }
      });

    groupedManualTasks.forEach((group) => {
      group.rules.sort((a, b) => a.rule.name.localeCompare(b.rule.name));
    });
    groupedManualTasks = [
      ...groupedManualTasks.sort((a, b) => {
        if (a.setId === -1) return -1;
        if (b.setId === -1) return 1;
        return a.setName.localeCompare(b.setName);
      }),
    ];
  };

  const updateAssetComments = async () => {
    const res = await fetchGet(`/asset/comment/${activeAssetId}`);

    activeAsset.asset_comments = res.comments;
  };

  const getWorkflowDetails = async () => {
    let res = await fetchGet(`/workflow/${reportId}`);
    workflow = res.data;
  };

  const getStreamPerms = async () => {
    if ($currentUser.role == "admin") currentStreamPerm = "edit";
    else {
      const userPerm = await fetchGet(
        `/report/${reportId}/get-user-permission-to-report`,
      );

      if (userPerm.permission === "none") {
        const orgPerm = await fetchGet(
          `/report/${reportId}/get-org-permission`,
        );
        if (orgPerm.permission === "none") push("/streams");
        else if (["view", "edit", "upload"].includes(orgPerm.permission))
          currentStreamPerm = orgPerm.permission;
      } else {
        if (["view", "edit", "upload"].includes(userPerm.permission))
          currentStreamPerm = userPerm.permission;
      }
    }
  };

  const getAssetDetails = async () => {
    let res = await fetchGet(
      `/asset/report/${reportId}/document/${activeAssetId}`,
    );
    activeAsset = res.asset;
    activeAsset = activeAsset;

    if (activeAsset == undefined) {
      push(`/review/${reportId}`);
      toast.error("Asset does not exist");
    }

    report = res.report;
    await getManualTaskSubTasks();
    await handleConditionalRuleLoading(activeAsset);
    groupActiveRules(activeAsset);
    groupManualTasks(activeAsset);
  };

  async function getManualTaskSubTasks() {
    manualTasksLoading = true;

    if (!activeAsset) {
      manualTasksLoading = false;
      return;
    }

    const standardManualRuleIds = activeAsset.rules
      .filter((r) => r.rule.rule_type === RuleSearchType.MANUAL)
      .flatMap((r) => r.rule.id);
    const childManualRuleIds = activeAsset.rules
      .filter(
        (r) =>
          r.rule.conditional_id &&
          r.rule.condition_type === ConditionalRuleType.IF &&
          r.rule.sequence_order === 0,
      )
      .flatMap((r) =>
        r.rule.children
          .filter((cr) => cr.rule.rule_type === RuleSearchType.MANUAL)
          .flatMap((cr) => cr.rule.id),
      );
    const manualTaskRuleIds = [...standardManualRuleIds, ...childManualRuleIds];

    if (manualTaskRuleIds.length > 0) {
      const queryParams = new URLSearchParams({
        rules: manualTaskRuleIds.join(","),
      }).toString();

      try {
        const res = await fetchGet(
          `/hit/asset/${activeAssetId}/tasks?${queryParams}`,
        );

        if (!res.success) {
          throw new Error(res.message);
        }

        manualTasks = res.tasks;
        manualTasks = manualTasks;
      } catch (e) {
        console.error(e);
        toast.error(
          `Something went wrong loading Manual Tasks for Asset(${activeAssetId}).`,
        );
      }
    }

    manualTasksLoading = false;
  }

  async function handleVersionChange() {
    if (versionUpdated) {
      manualTasksLoading = true;
      rulesLoading = true;

      try {
        const queryParams = new URLSearchParams({
          field: "version",
          version: activeVersion.aws_version_id,
        }).toString();
        const response = await fetchGet(
          `/asset/${activeAssetId}?${queryParams}`,
        );

        if (!response.success) {
          throw new Error(
            response?.message ??
              `Something went wrong loading AssetAWSVersion(${activeVersion.aws_version_id}).`,
          );
        }

        activeAsset = response?.data;
        activeAsset = activeAsset;
      } catch (e) {
        console.error(e);
        toast.error(
          `Something went wrong loading Version(${activeVersion}) for Asset(${activeAssetId})`,
        );
      }

      manualTasksLoading = false;
      rulesLoading = false;
      versionUpdated = false;
    }
  }

  async function handleUpdateAssetFromStatusChange() {
    const errMsg = `Something went wrong updating Asset(ID: ${activeAssetId})`;

    try {
      loaded = false;
      getStreamPerms();

      await getAssetDetails().then(async () => {
        versions = await retrieveVersions(activeAssetId);
        activeVersion = getActiveVersion(versionIdx, versions);
        previewUrl = getPreviewUrl(
          $org_name,
          reportId,
          activeAssetId,
          activeVersion.aws_version_id,
        );
      });

      await getWorkflowDetails();

      currentAssetApprovingPermission = getCurrentAssetApprovingPermission(
        workflow,
        activeAsset,
        $currentUser.id,
        $user_teams.map((team) => team.id),
      );

      loaded = true;
      $maximised = false;

      await tick(); // else modal won't have rendered on the DOM

      if (activeAsset.asset.status === AssetStatus.READY) {
        toast.info(`Asset(${activeAsset.asset.name}) is ready!`);
      }
    } catch (err) {
      console.error(err);
      toast.error(errMsg);
    }
  }

  async function updateAssetStatus() {
    if (
      !activeAsset ||
      [
        AssetStatus.FAILED,
        AssetStatus.SEARCH_FAILED,
        AssetStatus.READY,
      ].includes(activeAsset.asset.status)
    ) {
      return;
    }

    const errMessage = `Something went wrong polling for updates for Asset(ID: ${activeAssetId}).`;

    try {
      const statusResponse = await fetchGet(`/asset/${activeAssetId}/status`);

      if (!statusResponse.success) {
        console.error(statusResponse?.message);
        throw new Error(errMessage);
      }

      if (
        [AssetStatus.FAILED, AssetStatus.SEARCH_FAILED].includes(
          statusResponse.status,
        )
      ) {
        activeAsset.asset.status = statusResponse.status;
        activeAsset = activeAsset;
        return;
      }

      let progressPercent = 100;

      if (statusResponse.status !== AssetStatus.READY) {
        progressPercent =
          statusResponse.asset_rule_statuses.ready > 0 &&
          statusResponse.asset_rule_statuses.total > 0
            ? Math.round(
                (100 * statusResponse.asset_rule_statuses.ready) /
                  statusResponse.asset_rule_statuses.total,
              )
            : 0;
      }

      assetStatus = {
        status: statusResponse.status,
        assetRuleStatuses: statusResponse.asset_rule_statuses,
        percentageProgress: progressPercent,
      };
      assetStatus = assetStatus;

      if (assetStatus.status !== activeAsset.asset.status) {
        activeAsset.asset.status = assetStatus.status;
        activeAsset = activeAsset;

        if (assetStatus.status === AssetStatus.READY) {
          await handleUpdateAssetFromStatusChange();
        }
      }
    } catch (err) {
      console.error(err);
      toast.error(errMessage);
    }
  }

  onMount(async () => {
    reportId = params.report_id;
    activeAssetId = params.asset_id;
    getStreamPerms();
    await getAssetDetails().then(async () => {
      versions = await retrieveVersions(activeAssetId);
      activeVersion = getActiveVersion(versionIdx, versions);
      previewUrl = getPreviewUrl(
        $org_name,
        reportId,
        activeAssetId,
        activeVersion.aws_version_id,
      );
    });
    await getWorkflowDetails();
    currentAssetApprovingPermission = getCurrentAssetApprovingPermission(
      workflow,
      activeAsset,
      $currentUser.id,
      $user_teams.map((team) => team.id),
    );
    await updateAssetStatus();
    // initialiseWebSocket();
    loaded = true;
    $maximised = false;
    await tick(); // else modal won't have rendered on the DOM
  });
  onDestroy(() => {
    clearInterval(assetStatusInterval);
    $maximised = true;

    // if (webSocket) webSocket.close();
  });

  $: if (activeAsset?.asset?.workflow_step?.id && workflow?.id)
    currentAssetApprovingPermission = getCurrentAssetApprovingPermission(
      workflow,
      activeAsset,
      $currentUser.id,
      $user_teams.map((team) => team.id),
    );

  $: if (activeAsset && typeof activeAsset === "object") {
    if (activeAsset.asset?.workflow_step?.id && workflow?.id) {
      currentAssetApprovingPermission = getCurrentAssetApprovingPermission(
        workflow,
        activeAsset,
        $currentUser.id,
        $user_teams.map((team) => team.id),
      );
    }

    if (Object.keys(activeAsset).length > 0 && activeAsset.rules) {
      handleConditionalRuleLoading(activeAsset).then(() => {
        groupActiveRules(activeAsset);
        groupManualTasks(activeAsset);
      });
    }
  }
  $: assetViewTabLabels = [
    {
      label: "Checklist",
      icon: "iconoir:task-list",
      count: groupedRules.length,
    },
    {
      label: "Manual Tasks",
      icon: "mdi:tick-box-multiple-outline",
      count: groupedManualTasks.length,
    },
    {
      label: "Comments",
      icon: "iconoir:chat-lines",
      count: activeAsset?.asset_comments?.length,
    },
  ];

  $: handleVersionChange(), [versionUpdated];
  assetStatusInterval = setInterval(updateAssetStatus, 10000);
</script>

{#if loaded && activeAsset}
  <header class="-m-4 mb-4 bg-white p-4">
    <div class="breadcrumbs text-sm">
      <ul>
        <li>
          <a href="/reviews" use:link>Asset Review</a>
        </li>
        <li>
          <a href="/review/{reportId}" use:link>{report.name}</a>
        </li>
        <li>
          <MiddleTruncator text={activeAsset.asset.name} direction="right" />
        </li>
        <li>
          {#key activeAsset.asset.workflow_step.id}
            <Badge bgColor="#E0E0E0" textColor="black">
              {workflow.workflow_steps[
                getCurrentStepIndex(workflow, activeAsset)
              ]?.name}
            </Badge>
          {/key}
        </li>
      </ul>
    </div>

    <ActiveAssetSubHeader
      bind:activeAsset
      bind:activeVersion
      bind:versions
      bind:currentAssetApprovingPermission
      bind:previewUrl
      bind:editActiveAssetName
      bind:currentStreamPerm
      bind:reportId
      bind:activeAssetId
      bind:workflow
      bind:isInitialised
      bind:report
      bind:versionUpdated
      bind:showCompareVersions
    />
  </header>

  <div class="flex gap-4">
    <div class="flex w-full max-w-xl flex-col gap-4 pb-2">
      <div class="tabs relative py-2">
        {#each assetViewTabLabels as item}
          <button
            class="tab whitespace-nowrap text-base font-medium"
            class:text-primary={activeAssetViewTabLabel === item.label}
            on:click={() => (activeAssetViewTabLabel = item.label)}
          >
            <Icon icon={item.icon} class="mr-1" />
            {item.label}
            {item.count ? `(${item.count})` : ""}
          </button>
        {/each}
        <div
          class="slider bg-primary absolute bottom-0 left-0 h-0.5 transition-all"
          style:width="{100 / assetViewTabLabels.length}%"
          style="translate: {assetViewTabLabels.findIndex(
            (i) => i.label === activeAssetViewTabLabel,
          ) * 100}%"
        />
      </div>
      {#if activeAssetViewTabLabel === "Comments"}
        <AssetComments bind:activeAssetId bind:activeAsset />
      {:else if activeAssetViewTabLabel === "Manual Tasks"}
        <AssetManualChecklists
          bind:activeVersion
          bind:activeAsset
          bind:groupedManualTasks
          bind:currentStreamPerm
          bind:currentAssetApprovingPermission
          bind:reportId
          bind:activeAssetId
          bind:versionUpdated
          bind:previewSearchText
          {assetStatus}
        />
      {:else}
        <AssetChecklists
          bind:activeVersion
          bind:activeAsset
          bind:groupedRules
          bind:currentStreamPerm
          bind:previewSearchText
          bind:boundingBox
          bind:currentAssetApprovingPermission
          bind:reportId
          bind:activeAssetId
          bind:rulesLoading
          bind:versionUpdated
          {assetStatus}
        />
      {/if}
      <div class="mt-auto flex gap-2">
        <a class="btn btn-sm" href="/review/{reportId}" use:link>
          Return to Folder
        </a>
      </div>
    </div>
    <div class="w-full bg-[#F5F5F5]">
      <div class="w-full max-w-screen-2xl">
        <!--TODO: work out a more elegant way to operate the textToSearch function than forcing the document to reload each time-->
        {#if activeAsset.asset.file_type.includes("image/")}
          {#key previewUrl}
            {#if boundingBox}
              {#key tuplesToString(boundingBox)}
                <DisplayPdf
                  documentURL={previewUrl}
                  documentType="proxy"
                  bind:boundingBox
                  height="calc(100vh - 300px)"
                  open={true}
                />
              {/key}
            {/if}
          {/key}
        {:else}
          {#key previewUrl}
            {#key previewSearchText}
              <DisplayPdf
                documentURL={previewUrl}
                documentType="proxy"
                bind:textToSearch={previewSearchText}
                height="calc(100vh - 300px)"
                open={true}
              />
            {/key}
          {/key}
        {/if}
      </div>
    </div>
  </div>

  <ReportHistory assetId={activeAssetId} />

  {#if showCompareVersions}
    <ComparePdfModal
      {versions}
      {activeAssetId}
      {reportId}
      bind:showCompareVersions
    />
  {/if}

  <UploadNewVersion
    assetId={activeAssetId}
    bind:reportId
    {versions}
    on:upload={() => push(`/review/${reportId}`)}
  />
  <ChangesRequestedModal
    bind:reportId
    bind:activeAsset
    bind:activeAssetId
    {workflow}
    on:updateComments={updateAssetComments}
  />

  <ApprovalModal
    bind:reportId
    bind:activeAsset
    bind:activeAssetId
    bind:workflow
    modalType="approve"
    on:updateComments={updateAssetComments}
  />
  <ApprovalModal
    bind:reportId
    bind:activeAsset
    bind:activeAssetId
    bind:workflow
    modalType="send"
    on:updateComments={updateAssetComments}
  />
  <ApprovalModal
    bind:reportId
    bind:activeAsset
    bind:activeAssetId
    bind:workflow
    modalType="submit"
    on:updateComments={updateAssetComments}
  />
{:else}
  <div class="text-center">
    <div class="loading loading-lg m-auto" />
  </div>
{/if}
