import { isEqual } from "lodash";
import { writable } from "svelte/store";
import { fetchGet, fetchPost } from "../../helpers";
import { AssetStatus } from "../interfaces/Asset.interface";
import { calculatePercentage } from "./GenericUtils";
import { isEmpty } from "./GenericUtils";

export let activeTabLabel = writable("Pending");

/**
 * Add JSDoc comments
 * @param {*} newLabel
 */
export function setActiveTabLabel(newLabel) {
  activeTabLabel = newLabel;
}

/**
 * @todo
 * Revisit this method to utilise enums (once moved to TS).
 * @description
 * Determines which tab should be active based on the status of the assets
 * @returns {string} A string corresponding to the active tab's label, e.g., "Pending", "In Review", etc.
 */
export const autoCheckActiveTab = (assets) => {
  if (!assets.length) return;

  if (assets.filter((a) => a.asset.approval_status == "Pending").length > 0)
    activeTabLabel = "Pending";
  else if (
    assets.filter((a) => a.asset.approval_status == "In Review").length > 0
  )
    activeTabLabel = "In Review";
  else if (
    assets.filter((a) => a.asset.approval_status == "Changed Requested")
      .length > 0
  )
    activeTabLabel = "Changes Requested";
  else if (
    assets.filter((a) => a.asset.approval_status == "Approved").length > 0
  )
    activeTabLabel = "Approved";
  else if (
    assets.filter((a) => a.asset.approval_status == "Roadblock").length > 0
  )
    activeTabLabel = "Roadblock";
  else activeTabLabel = "Pending"; //if there are no uploadedFiles, defaulted to To Review
};

/**
 * @todo
 * Add JSDoc description.
 *
 * @returns
 */
export async function saveDetails(
  isInitialised,
  report,
  reportId,
  currentUserId,
) {
  const name = report.name;
  const description = report.description;
  const status = report.status;

  let response = await fetchPost("/report/1", {
    report_id: reportId,
    name,
    description,
    status,
    classification: "asset",
  });

  if (!isInitialised) {
    await fetchPost(`/user/${currentUserId}/report/${reportId}`, {
      report_id: reportId,
      user_id: currentUserId,
      permission: "edit",
    });
  }

  if (!response.success) {
    return false;
  }

  return true;
}

/**
 * @todo
 * Add JSDoc description.
 * @param {*} orgName
 * @param {*} reportId
 * @param {*} activeAssetId
 * @param {*} awsVersionId
 * @returns
 */
export function getPreviewUrl(orgName, reportId, activeAssetId, awsVersionId) {
  return `https://${import.meta.env.VITE_APP_BUCKET}/${orgName}/${reportId}/${activeAssetId}?versionId=${awsVersionId}`;
}

/**
 * @todo
 * Add JSDoc description.
 * @param {*} currentAsset
 * @param {*} targetId
 * @param {*} assets
 * @returns
 */
export async function getActiveAsset(currentAsset, targetId, assets) {
  if (targetId === -1) {
    return {};
  }

  try {
    const newActiveAsset = assets.find(
      (a) => Number(a?.asset?.id) === Number(targetId),
    );

    if (isEqual(currentAsset, newActiveAsset)) {
      return currentAsset;
    }

    if (newActiveAsset) {
      return newActiveAsset;
    }

    throw new Error(
      `Unhandled Exception: No asset was found matching to AssetId(${targetId}).`,
    );
  } catch (error) {
    throw new Error(`Error retrieving active asset: ${error}`);
  }
}

/**
 * @todo
 * Add JSDoc description.
 * @param {*} assetId
 * @returns
 */
export async function retrieveVersions(assetId) {
  let versions = new Array();

  if (!assetId) {
    return versions;
  }

  try {
    await fetchGet(`/asset/${assetId}/version`).then(async (res) => {
      if (res.success) {
        versions = res.versions.map((version) => {
          return {
            version: version.version_count,
            date: version.created_date,
            uploader: version.user,
            aws_version_id: version.aws_version_id,
            fileType: version.file_type,
            currentVersion: false,
          };
        });

        if (versions.length > 0) {
          versions[0].currentVersion = true;
        }
      }
    });

    return versions;
  } catch (error) {
    throw new Error(
      `Unhandled Exception: Unable to retrieve asset versions due to error: ${error}`,
    );
  }
}

/**
 * @todo
 * Add JSDoc description.
 * @param {*} idx
 * @param {*} versions
 * @returns
 */
export function getActiveVersion(idx, versions) {
  if (versions.length <= 0) {
    return {};
  }

  return versions[idx] || {};
}

/**
 * @todo
 * Add JSDoc description.
 * @param {*} workflow
 * @param {*} activeAsset
 * @param {*} currentUserId
 * @param {*} currentUserTeamIds
 * @returns
 */
export function getCurrentAssetApprovingPermission(
  workflow,
  activeAsset,
  currentUserId,
  currentUserTeamIds,
) {
  let currentAssetApprovingPermission = false;
  // Check if necessary objects and properties exist
  if (
    !workflow ||
    !workflow.workflow_steps ||
    !activeAsset ||
    !activeAsset.asset ||
    !activeAsset.asset.workflow_step
  ) {
    //console.error("Missing necessary data structures for processing permissions.");
    return currentAssetApprovingPermission; // Return false immediately if data is incomplete
  }
  const activeWorkflowStep = workflow.workflow_steps.find(
    (ws) => ws.id === activeAsset.asset.workflow_step.id,
  );

  if (activeWorkflowStep) {
    const userHasPermission = activeWorkflowStep.users.some(
      (user) => user.user_id === currentUserId && user.permission === "approve",
    );
    const teamHasPermission = activeWorkflowStep.teams.some(
      (team) =>
        currentUserTeamIds.includes(team.team_id) &&
        team.permission === "approve",
    );
    //if it is the default step (e.g. uploaded or in the future archived)
    // no one has edit permissions on the rules
    const isDefaultStep = activeWorkflowStep.default;
    currentAssetApprovingPermission =
      (userHasPermission || teamHasPermission) && !isDefaultStep;
  }

  return currentAssetApprovingPermission;
}
export function getCurrentStepIndex(workflow, activeAsset) {
  return workflow.workflow_steps.findIndex(
    (step) => step.id === activeAsset.asset.workflow_step.id,
  );
}

function checkAssetStatusAndStep(asset, workflow) {
  if (!asset || !workflow || !workflow.workflow_steps) {
    console.error("Missing asset or workflow data.");
    return false;
  }

  const isArchived = asset.approval_status === "Archived";
  const isApprovedOnLastStep =
    asset.approval_status === "Approved" && isOnLastStep(asset, workflow);

  return isArchived || isApprovedOnLastStep;
}

function isOnLastStep(asset, workflow) {
  if (!asset.workflow_step || !workflow.workflow_steps.length) {
    return false;
  }

  const lastStep = workflow.workflow_steps.reduce(
    (max, step) => (step.position > max.position ? step : max),
    workflow.workflow_steps[0],
  );
  return asset.workflow_step.position === lastStep.position;
}

/// Need to get
/// is userAssignedToAsset  = boolean
// isFullyApprovedOrArchived = boolean
// isStepApprover = boolean
export function hotButtonPermissions(
  workflow,
  activeAsset,
  currentUserId,
  currentUserTeamIds,
) {
  let userAssignedToAsset = false;
  let isFullyApprovedOrArchived = false;
  let isDefault = false;
  let isLastStep = isOnLastStep(activeAsset.asset, workflow);
  // Check if necessary objects and properties exist
  const activeWorkflowStep = workflow.workflow_steps.find(
    (ws) => ws.id === activeAsset.asset.workflow_step.id,
  );
  // console.log(workflow);
  // console.log(activeAsset);
  // console.log(currentUserId);
  // console.log(currentUserTeamIds);

  if (activeWorkflowStep) {
    userAssignedToAsset = activeAsset.asset.asset_assigned_users.some(
      (user) => user.assigned_id === currentUserId,
    );
    isFullyApprovedOrArchived = checkAssetStatusAndStep(
      activeAsset.asset,
      workflow,
    );
    isDefault = activeWorkflowStep.default && activeWorkflowStep.postion == 0;
  }
  // console.log("isDefault", isDefault);
  // console.log("userAssignedToAsset", userAssignedToAsset);
  // console.log("isFullyApprovedOrArchived", isFullyApprovedOrArchived);

  return userAssignedToAsset, isFullyApprovedOrArchived, isDefault, isLastStep;
}

function getAssetStatusDetails(assetStatuses = new Map(), assetId = -1) {
  return assetStatuses.get(assetId);
}

export function getProcessingAssetRules(
  assetStatuses = new Map(),
  assetId = -1,
) {
  const assetStatus = getAssetStatusDetails(assetStatuses, assetId);

  if (assetStatus) {
    return assetStatus.assetRuleStatuses.ready;
  }

  return 0;
}

export function getTotalAssetRules(assetStatuses = new Map(), assetId = -1) {
  const assetStatus = getAssetStatusDetails(assetStatuses, assetId);

  if (assetStatus) {
    return assetStatus.assetRuleStatuses.total;
  }

  return 0;
}

export function getPercentageProgressAssetRules(
  assetStatuses = new Map(),
  status = "",
  assetId = -1,
) {
  const assetStatus = getAssetStatusDetails(assetStatuses, assetId);

  if (assetStatus) {
    switch (status) {
      case AssetStatus.READY:
        return 100;
      case AssetStatus.PROCESSING:
      case AssetStatus.SEARCHING:
        return calculatePercentage(
          assetStatus.assetRuleStatuses.ready,
          assetStatus.assetRuleStatuses.total,
        );
      default:
        return 0;
    }
  }
}

/**
 *
 * @param {*} contentType
 * @returns
 */
export function isCompareSupportedFile(contentType = "") {
  if (
    !isEmpty(contentType) &&
    ["application/pdf", "image/png", "image/jpeg"].includes(contentType)
  )
    return true;

  return false;
}
