<script>
  import { createEventDispatcher } from "svelte";
  import Modal from "../Modals/Modal.svelte";
  import Select from "../Select.svelte";

  export let all_workflows;
  export let selectedValue;

  let selectedWorkflow = all_workflows[0];

  const dispatch = createEventDispatcher();

  function updateBuildingWorkflow() {
    if (selectedValue)
      selectedWorkflow = all_workflows.find((wf) => wf.id == selectedValue);
  }
</script>

<Modal modalId="workflowTemplateModal" cornerCloseButton={false}>
  <Select
    classes="w-full"
    items={all_workflows.map((wf) => ({
      label: wf.name,
      value: wf.id,
    }))}
    placeholder="Select a template..."
    bind:selectedValue
    on:change={updateBuildingWorkflow}
    useFixedPositioning={false}
  />

  <ul class="timeline timeline-vertical timeline-compact my-4">
    {#each selectedWorkflow.workflow_steps as step, i}
      <li>
        {#if i !== 0}<hr class="bg-primary" />{/if}

        <div
          class="timeline-middle bg-primary w-5 rounded-full text-center text-sm text-white"
        >
          {i + 1}
        </div>

        <div class="timeline-end pl-4">
          <span class="font-semibold">{step.name}</span>
          <p class="text-base-content/70 text-sm">{step.description}</p>
        </div>

        {#if i !== selectedWorkflow.workflow_steps.length - 1}<hr
            class="bg-primary"
          />{/if}
      </li>
    {/each}
  </ul>

  <form class="flex flex-col gap-2" method="dialog">
    <button
      class="btn btn-primary"
      on:click={() => dispatch("confirm", selectedValue)}
    >
      Confirm
    </button>
    <button class="btn btn-ghost text-primary">Cancel</button>
  </form>
</Modal>
