<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher, onMount } from "svelte";
  import { toast } from "svelte-sonner";
  import { fetchDelete, fetchGet, tidyPoolLabel } from "../../../helpers";
  import { RuleScope } from "../../../lib/interfaces/Rule.interface";
  import { NoRuleSetDefaults } from "../../../lib/interfaces/RuleSet.interface";
  import { appendMissingRuleSets } from "../../../lib/utils/RuleUtils";
  import { currentUser, org_id, user_teams } from "../../../stores";
  import ActionConfirmation from "../../ActionConfirmation.svelte";
  import RuleCopyDialog from "../../Reports/RuleCopyDialog.svelte";
  import RuleEditDialog from "../../Reports/RuleEditDialog.svelte";
  import RuleViewDialog from "../../Reports/RuleViewDialog.svelte";
  import SavedRulesTableRow from "./SavedRulesTableRow.svelte";

  const dispatch = createEventDispatcher();

  export let ruleScope = RuleScope.MY;
  export let rules = [];
  export let assetRulesTable = false;
  export let ruleSets = [];
  export let sharedWith = [];
  export let showEmptyChecklists = true;

  let newRules = [];
  let activeChecklistIdx = -1;
  let ruleSetIdToDelete = -1;
  let ruleToDelete = null;
  let ruleToEdit = null;
  let dataTypes = null;
  let copyLoading = false;
  let convertingSharedRule = false;
  let ruleToCopy = null;
  let isViewing = false;

  async function getDataTypes() {
    const res = await fetchGet("/pool");
    let tempTypes = res.data;

    for (let i = 0; i < tempTypes.length; i++)
      tempTypes[i].label = tidyPoolLabel(tempTypes[i].label);

    dataTypes = tempTypes;
  }

  export const updateRuleSets = async () => {
    const res = await fetchGet("/ruleset");
    ruleSets = [...appendMissingRuleSets(res.rule_sets, rules)];
    sharedWith = [...res?.shared_with];

    dispatch("updateRules");
  };

  async function deleteRuleSet() {
    if (ruleSetIdToDelete !== -1) {
      ruleSets = [...ruleSets.filter((rs) => rs.id !== ruleSetIdToDelete)];
      rulesToNewRules();
      ruleSetIdToDelete = -1;
      newRules = newRules;
    }
  }

  onMount(async () => {
    await getDataTypes();
    await updateRuleSets();

    rulesToNewRules();
  });

  $: if (rules && ruleScope) rulesToNewRules();

  const rulesToNewRules = () => {
    newRules = [];
    let usedRuleSetIds = [];

    // Filter rules based on the current ruleScope
    const tempRules = rules.filter((rule) => {
      if (ruleScope === RuleScope.INDUSTRY) {
        return rule.is_industry_rule;
      } else if (ruleScope === RuleScope.MY) {
        const isMyRule =
          !rule.is_organization_rule &&
          !rule.is_industry_rule &&
          rule.owner === $currentUser.id &&
          rule.report_id === $org_id;
        return isMyRule;
      } else if (ruleScope === RuleScope.SHARED) {
        const isSharedRule =
          !rule.is_organization_rule &&
          !rule.is_industry_rule &&
          rule.owner !== $currentUser.id &&
          rule.report_id === $org_id;
        return isSharedRule;
      }
    });

    if (!showEmptyChecklists) {
      usedRuleSetIds = [...new Set(tempRules.map((r) => r.rule_sets).flat())];
    } else {
      usedRuleSetIds = [
        ...new Set(
          ruleSets.flatMap((rs) => {
            if (
              ruleScope !== RuleScope.SHARED ||
              (ruleScope === RuleScope.SHARED &&
                sharedWith.some(
                  (sr) =>
                    sr.parent_rule_set_id === rs.id &&
                    (sr.user_id === $currentUser.id ||
                      !!$user_teams.find((ut) => ut.id === sr.team_id)),
                ))
            ) {
              return rs.id;
            }

            return [];
          }),
        ),
      ];
    }

    if (
      tempRules.some((r) => r.rule_sets.length === 0) &&
      ruleScope !== RuleScope.SHARED
    ) {
      newRules = [
        {
          rule_set: {
            name: NoRuleSetDefaults.NAME,
            id: NoRuleSetDefaults.ID,
            owner: $currentUser.id,
            description: NoRuleSetDefaults.DESCRIPTION,
          },
          rules: tempRules.filter(
            (r) =>
              r.rule_sets.length === 0 ||
              sharedWith.some(
                (sr) =>
                  sr.parent_rule_id === r.id && sr.parent_rule_set_id === -1,
              ),
          ),
        },
      ];
    }

    newRules = [
      ...newRules,
      ...usedRuleSetIds.map((id) => {
        const ruleSet = ruleSets.find((rs) => rs.id === id);
        const associatedRules = tempRules.filter((r) =>
          r.rule_sets.includes(id),
        );

        return {
          rule_set: ruleSet,
          rules: associatedRules,
        };
      }),
    ];

    // Filter out certain rule sets based on ownership and scope (shared, my, industry)
    newRules = [
      ...newRules.filter(
        (rs) =>
          !(
            rs.rule_set?.id !== NoRuleSetDefaults.ID &&
            rs.rule_set?.owner === $currentUser.id &&
            ruleScope === RuleScope.SHARED
          ) &&
          !(
            rs.rule_set?.owner !== $currentUser.id && ruleScope === RuleScope.MY
          ),
        // &&
        // !(rs.rule_set?.owner && ruleScope === RuleScope.INDUSTRY)
      ),
    ];

    // Sort the new rules by the name of the rule set, with the default rule set appearing first
    newRules = [
      ...newRules.sort((a, b) => {
        if (a.rule_set.id === NoRuleSetDefaults.ID) return -1;
        if (b.rule_set.id === NoRuleSetDefaults.ID) return 1;

        return a.rule_set.name.localeCompare(b.rule_set.name);
      }),
    ];

    // Sort the rules within each checklist alphabetically by name
    newRules.forEach((checklist) => {
      checklist.rules.sort((a, b) => a.name.localeCompare(b.name));
    });

    // Update the newRules array and set the active checklist index if there's only one checklist
    newRules = newRules;

    if (newRules.length === 1) activeChecklistIdx = 0;
  };

  $: rulesToNewRules(), [showEmptyChecklists, ruleSets, rules, sharedWith];
</script>

<div class="overflow-x-auto rounded border">
  <table class="table">
    <thead class="bg-base-200">
      <tr>
        <th>Checklist Name</th>
        {#if ruleScope === RuleScope.SHARED}
          <th>Owner</th>
        {/if}
        {#if ruleScope !== RuleScope.INDUSTRY}
          <th>Shared With</th>
        {/if}
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      {#each newRules as checklist (checklist.rule_set.id)}
        <SavedRulesTableRow
          bind:ruleScope
          bind:ruleSet={checklist}
          bind:ruleSets
          bind:sharedWith
          bind:assetRulesTable
          bind:ruleToDelete
          bind:ruleToEdit
          bind:convertingSharedRule
          bind:ruleToCopy
          bind:ruleSetIdToDelete
          bind:isViewing
          on:deleteRuleSet={deleteRuleSet}
          on:updateRuleSets={updateRuleSets}
        />
      {:else}
        <tr>
          <td
            colspan={ruleScope === RuleScope.SHARED
              ? 5
              : ruleScope !== RuleScope.INDUSTRY
                ? 4
                : 3}
          >
            <div
              class="flex flex-col justify-center items-center p-4 text-base-content/50"
            >
              <Icon icon="iconoir:folder" class="text-8xl" />
              <p class="text-sm text-center">
                No checklist rules yet! <br />
                We're on a clean slate waiting for your brilliance.
              </p>
            </div>
          </td>
        </tr>
      {/each}
    </tbody>
  </table>
</div>

<!-- svelte-ignore missing-declaration -->
<ActionConfirmation
  message={`Are you sure you want to delete this ${sharedWith.some((sr) => sr.parent_rule_id === ruleToDelete?.id) ? "shared" : ""} rule?`}
  onConfirm={async () => {
    try {
      await fetchDelete(`/rule/${ruleToDelete.id}`);

      dispatch("updateRules");
      rulesToNewRules();
    } catch (error) {
      toast.error(
        `Something went wrong while deleting ${sharedWith.some((sr) => sr.parent_rule_id === ruleToDelete?.id) ? "shared" : ""} rule.`,
      );
    } finally {
      toast.info(`Successfully deleted Rule(${ruleToDelete.name})`);
      ruleToDelete = null;
      confirmModal.close(); //TODO: add rule deletion responsiveness
    }
  }}
  onCancel={() => confirmModal.close()}
/>

{#if ruleToCopy && dataTypes}
  <RuleCopyDialog
    bind:ruleToCopy
    bind:dataTypes
    bind:sharedWith
    bind:ruleSets
    on:updateRules={() => dispatch("updateRules")}
    on:updateRuleSets={updateRuleSets}
  />
{/if}

{#if ruleToEdit && dataTypes}
  <RuleEditDialog
    rule={{ ...ruleToEdit }}
    bind:ruleSets
    bind:sharedWith
    isSavedRule={true}
    on:editCancelled={() => {
      ruleToEdit = null;
      updateRuleSets();
    }}
    on:editSaved={(e) => {
      ruleToEdit = null;
      dispatch("updateRules");
    }}
    on:updateRuleSets={updateRuleSets}
  />
{/if}

{#if ruleToEdit && !isViewing}
  <RuleEditDialog
    bind:rule={ruleToEdit}
    bind:ruleSets
    bind:sharedWith
    on:editCancelled={() => {
      ruleToEdit = null;
    }}
    on:editSaved={() => {
      ruleToEdit = null;
      dispatch("updateRules");
    }}
    on:sharedRuleSaved={() => {
      ruleToEdit = null;
      dispatch("updateRules");
    }}
    on:updateRuleSets={updateRuleSets}
  />
{/if}

{#if ruleToEdit && isViewing}
  <RuleViewDialog
    bind:rule={ruleToEdit}
    bind:ruleSets
    on:viewCancelled={() => {
      ruleToEdit = null;
      isViewing = false;
    }}
    on:sharedRuleSaved={() => {
      ruleToEdit = null;
      isViewing = null;
      dispatch("updateRules");
    }}
  />
{/if}
